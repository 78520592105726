import { Injectable, inject } from '@angular/core';
import { Observable, combineLatest, map, of, shareReplay, switchMap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { DataModelStoreService } from '../data-model/services/data-model.store';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { HttpClient } from '@angular/common/http';
import { NavigationService } from '../navigation/navigation.service';
import { QuickSearchResultCategory } from 'portal-commons/dist/search/searchTypes';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class QuicksearchService {
  dataModelStore = inject(DataModelStoreService);
  navigationService = inject(NavigationService);
  constructor(private _httpClient: HttpClient) {}

  getPathLabels(recordType: string, paths: string[]) {
    const resultMap = new Map<string, string>();
    if (!recordType) {
      return resultMap;
    }
    for (const path of paths) {
      if (!path) {
        continue;
      }
      const label = this.dataModelStore.getLabelFromPath(recordType, path);
      if (label) {
        resultMap.set(path, label);
      }
    }
    return resultMap;
  }

  replacePathLabels(input: string, labelMap: Map<string, string>) {
    if (!labelMap || labelMap.size === 0) {
      return input;
    }
    let newResult = input;
    for (const [key, value] of labelMap.entries()) {
      newResult = newResult.replace(key, value);
    }
    return newResult;
  }

  mapData(allResults: QuickSearchResultCategory[]) {
    return allResults.map((data: QuickSearchResultCategory) => {
      if (
        !data.results ||
        data.results.length === 0 ||
        !data.searchPaths ||
        data.searchPaths.length === 0
      ) {
        return data;
      }
      const labels = this.getPathLabels(data.recordType!, data.searchPaths);
      return {
        ...data,
        results: data.results.map((r: any) => {
          return {
            ...r,
            subtitle: this.replacePathLabels(r.subtitle, labels),
          };
        }),
      };
    });
  }

  search(query: string, categories?: string[]) {
    if (!categories) {
      categories = ['*'];
    }
    return combineLatest([
      this._httpClient
        .post<QuickSearchResultCategory[]>('api/search', {
          query: query,
          limit: 5,
          categories: categories,
        })
        .pipe(shareReplay(1)),
      this.navigationService.filterNavLinks(query, categories).pipe(
        map((items) => this.mapNavLinks(items)),
        shareReplay(1),
      ),
    ]).pipe(
      switchMap(
        ([data, nav]: [QuickSearchResultCategory[], QuickSearchResultCategory | undefined]) => {
          const combo: QuickSearchResultCategory[] = [];
          if (data) {
            combo.push(...data);
          }
          if (nav) {
            combo.push(nav);
          }
          return of(combo);
        },
      ),
      map((data: QuickSearchResultCategory[]) => {
        return this.mapData(data);
      }),
      untilDestroyed(this),
      shareReplay(1),
    );
  }

  mapNavLinks(items: FuseNavigationItem[]): QuickSearchResultCategory | undefined {
    console.log('mapNavLinks', items);
    if (!items || items.length === 0) {
      return undefined;
    }
    const result: QuickSearchResultCategory = {
      label: 'Navigation',
      id: 'Nav',
      results: items.map((item) => {
        return {
          title: item.title!,
          subtitle: item.subtitle,
          link: item.link!,
        };
      }),
    };
    return result;
  }

  searchForResults(
    query: string,
    type: string,
    fieldList: string[],
  ): Observable<QuickSearchResultCategory[]> {
    return this._httpClient.post<QuickSearchResultCategory[]>('api/search', {
      query: query,
      type: type,
      fieldList: fieldList,
      limit: 10000,
      resultType: 'SEARCHRESULTS',
    });
  }
}
