<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  class="light bg-card print:hidden"
  [appearance]="navigationAppearance"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.default"
  [opened]="!isScreenSmall">
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex flex-row items-center justify-center h-20" title="Click for Home Page">
      <div class="w-10 flex-shrink-0 nav-header-logo-small cursor-pointer" routerLink="/">
        <img id="nav-header-logo-small" alt="Logo image small" />
      </div>
      <div class="w-60 flex-shrink-0 nav-header-logo-large cursor-pointer" routerLink="/">
        <img id="nav-header-logo-large" alt="Logo image large" />
      </div>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <div class="flex items-center pr-2 space-x-2">
      <!-- Navigation toggle button -->
      <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
      </button>
      <!-- Navigation appearance toggle button -->
      <button class="hidden md:inline-flex" mat-icon-button (click)="toggleNavigationAppearance()">
        <mat-icon [svgIcon]="'heroicons_outline:switch-horizontal'"></mat-icon>
      </button>
    </div>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <search [appearance]="'bar'"></search>
      <!-- <shortcuts></shortcuts> -->
      <app-messages-viewer></app-messages-viewer>
      <tb-notifications></tb-notifications>
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <!-- <div class="flex flex-col flex-auto"> -->
  <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
  <!-- <router-outlet *ngIf="true"></router-outlet>
    </div> -->

  <div class="flex flex-col flex-auto min-w-0">
    <!-- Main -->

    <!-- CONTENT GOES HERE -->
    <div class="flex flex-col flex-auto">
      <tb-alerts class="w-full"></tb-alerts>
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
      <router-outlet *ngIf="true"></router-outlet>
    </div>
  </div>
</div>
