import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  pairwise,
  shareReplay,
  skip,
  startWith,
  tap,
} from 'rxjs';
import { FilterTypeOption, filterTypeOptions } from 'app/modules/filters/models/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MetadataFieldType, RecordTypeFieldMetadata } from 'app/core/data-model/models/models';
import { ParentheticalTimePeriods, TimePeriod } from 'portal-commons/dist/data-filters/range';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BasicFieldTypes } from 'portal-commons/dist/data-model/record-types';
import { CustomValidators } from 'app/core/validators/custom-validators';
import { DataModelStoreService } from 'app/core/data-model/services/data-model.store';
import { FilterCondition } from 'portal-commons/dist/data-filters/models';
import { FilterType } from 'portal-commons/dist/data-filters/models';
import type { MaskitoOptions } from '@maskito/core';
import { dataModelTypeIsDateType } from 'portal-commons/dist/data-model/util-functions';
import { isFilterConditionPropertyRequired } from 'portal-commons/dist/views/models';
import maskDate from '../../../../shared/masks/datemask';
import { CodesApiService } from 'app/core/codes/codes-api.service';
import { Code } from 'portal-commons/dist/data-model/record-types/code';
import { LookupRecord } from '../../models/model';
import { convertEnumToSet } from 'app/core/utils/enum-helper';
import { getDateOnlyString } from 'app/core/utils/form-helper';

@UntilDestroy()
@Component({
  selector: 'tb-datamodel-field-filter',
  templateUrl: './datamodel-field-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatamodelFieldFilterComponent implements OnInit, AfterViewInit {
  @Input() condition: FilterCondition | undefined;
  @Input() form!: FormGroup;
  @Input() recordType: string;
  @Input() orientation: 'horizontal' | 'vertical' = 'vertical';
  @Input() rootFieldsOnly: boolean;
  @Input() includeListFields: boolean;
  readonly options: MaskitoOptions = maskDate;
  recordType$: Observable<string>;
  autoCompleteFieldClasses: string[] = [];

  _codesApi = inject(CodesApiService);
  _dataModelStore = inject(DataModelStoreService);
  _conditionField = new BehaviorSubject<RecordTypeFieldMetadata | undefined>(undefined);
  conditionField$ = this._conditionField.asObservable();

  private _codes = new BehaviorSubject<Code[]>([]);
  codes$ = this._codes.asObservable();
  private _codesMap = new Map<string, Code[]>();
  enumCodes$: Observable<LookupRecord[]>;

  @ViewChild('dateValue', { read: TemplateRef })
  dateValueTemplate!: TemplateRef<any>;
  @ViewChild('textValue', { read: TemplateRef })
  textValueTemplate!: TemplateRef<any>;
  @ViewChild('booleanValue', { read: TemplateRef })
  booleanValueTemplate!: TemplateRef<any>;
  @ViewChild('relativeDateValue', { read: TemplateRef })
  relativeDateValueTemplate!: TemplateRef<any>;
  @ViewChild('toDateValue', { read: TemplateRef })
  toDateValueTemplate!: TemplateRef<any>;
  @ViewChild('betweenDatesValue', { read: TemplateRef })
  betweenDatesValueTemplate!: TemplateRef<any>;
  @ViewChild('noValue', { read: TemplateRef })
  noValueTemplate!: TemplateRef<any>;
  @ViewChild('codedListValue', { read: TemplateRef })
  codedListValueTemplate!: TemplateRef<any>;
  @ViewChild('codedListMultipleValue', { read: TemplateRef })
  codedListMultipleValueTemplate!: TemplateRef<any>;
  @ViewChild('codeEnumValue', { read: TemplateRef })
  codeEnumTemplate!: TemplateRef<any>;

  private _valueTemplate: BehaviorSubject<TemplateRef<any> | undefined> = new BehaviorSubject(
    undefined,
  );
  valueTemplate$: Observable<TemplateRef<any>> = this._valueTemplate.asObservable();

  private _timePeriods: BehaviorSubject<TimePeriod[] | null> = new BehaviorSubject(null);
  timePeriods$: Observable<TimePeriod[] | null> = this._timePeriods.asObservable();

  filterOptions: FilterTypeOption[] = [];

  relativeFilterTypes = [FilterType.IntheCurrent, FilterType.IntheLast, FilterType.IntheNext];

  constructor(
    private builder: FormBuilder,
    private ref: ChangeDetectorRef,
    private dataModelStore: DataModelStoreService,
  ) { }

  inputMatched(value: RecordTypeFieldMetadata | null) {
    this.form.get('field')?.setValue(value);
  }

  fieldChanged(value: RecordTypeFieldMetadata | null) {
    if (!value) {
      this.form.get('field')?.setValue('');
    } else {
      this.form.get('field')?.setValue(value);
    }
  }

  setFilterOptions(field: RecordTypeFieldMetadata) {
    this.filterOptions = filterTypeOptions.filter(
      (f) =>
        field &&
        (f.allowedDataModelTypes.includes('*') ||
          f.allowedDataModelTypes.includes(field.dataModelFieldType as BasicFieldTypes | '*')),
    );
    console.log('setFilterOptions', field, [...this.filterOptions]);
    this.ref.markForCheck();
  }

  ngOnInit() {
    if (this.orientation === 'horizontal') {
      this.autoCompleteFieldClasses.push('min-w-64');
    }
    if (this.orientation === 'vertical') {
      this.autoCompleteFieldClasses.push('w-full');
    }

    this.form.addControl(
      'field',
      this.builder.control('', [Validators.required, CustomValidators.requireAutocompleteMatch]),
    );
    this.form.addControl('filterType', this.builder.control('', [Validators.required]));
    this.form.addControl(
      'searchParameter1',
      this.builder.control('', [
        CustomValidators.conditionalValidator(
          () =>
            isFilterConditionPropertyRequired(
              this.form.get('filterType')?.value,
              'searchParameter1',
            ),
          Validators.required,
        ),
      ]),
    );
    this.form.addControl(
      'searchParameter2',
      this.builder.control('', [
        CustomValidators.conditionalValidator(
          () =>
            isFilterConditionPropertyRequired(
              this.form.get('filterType')?.value,
              'searchParameter2',
            ),
          Validators.required,
        ),
      ]),
    );
    this.form.addControl(
      'searchParameter1Period',
      this.builder.control('', [
        CustomValidators.conditionalValidator(
          () =>
            isFilterConditionPropertyRequired(
              this.form.get('filterType')?.value,
              'searchParameter1Period',
            ),
          Validators.required,
        ),
      ]),
    );

    this.recordType$ = of(this.recordType);

    if (this.condition) {
      const dmField = this.dataModelStore.getFieldFromRecordTypeByFieldId(
        this.condition.fieldRecordType!,
        this.condition.fieldId!,
      );
      this._conditionField.next({
        fieldType: MetadataFieldType.default,
        dataModelFieldType: dmField?.fieldType,
        refName: this.condition.fieldPath!,
        recordTypeId: this.condition.fieldRecordType!,
        id: this.condition.fieldId ?? '',
        label: dmField?.label ?? '',
      });
      this.form.get('field')?.setValue(this._conditionField.getValue());
      this.form.get('filterType')?.setValue(this.condition.filterType);
      this.form.get('searchParameter1')?.setValue(this.condition.searchParameter1);
      this.form.get('searchParameter1Period')?.setValue(this.condition.searchParameter1Period);
      this.form.get('searchParameter2')?.setValue(this.condition.searchParameter2);
    }

    this._timePeriods.next(ParentheticalTimePeriods);

    this.form
      .get('field')
      ?.valueChanges.pipe(
        untilDestroyed(this),
        debounceTime(50),
        distinctUntilChanged(),
        startWith(''),
        map((field) => {
          this.setFilterOptions(field);
          if (!this.recordType || !field || !field.id) {
            return;
          }
          const dmField = this._dataModelStore.getFieldFromPath(
            this.recordType,
            field.refName ?? field.fieldRefName,
          );

          console.log('field-changed', dmField);

          if (dmField && dmField.fieldType === 'codelist' && !(dmField?.codeEnum ?? false)) {
            if (!this._codesMap.has(field.fieldId)) {
              const codeset = field.codeSet ?? dmField?.codeSet;
              this._codesApi
                .getCodes(codeset ?? '')
                .pipe(
                  tap((x) => this._codesMap.set(field.fieldId, x)),
                  tap((x) => this._codes.next(x)),
                )
                .subscribe();
            }
            return this._codes.next(this._codesMap.get(field.fieldId) ?? []);
          } else if (dmField && dmField?.codeEnum) {
            this.enumCodes$ = of(convertEnumToSet(dmField.codeEnum)).pipe(shareReplay(1));
          }
        }),
      )
      .subscribe();

    this.form
      .get('field')
      ?.valueChanges.pipe(
        untilDestroyed(this),
        debounceTime(100),
        distinctUntilChanged(),
        skip(1),
        map((field) => {
          console.log('fieldChanged-skip1');
          this.form.get('searchParameter1')?.setValue(null);
          this.form.get('searchParameter2')?.setValue(null);
          this.form.get('searchParameter1Period')?.setValue(null);
          this.updateModelFromForm();
          this.ref.markForCheck();
        }),
      )
      .subscribe();

    this.form
      .get('filterType')
      ?.valueChanges.pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        startWith(''),
        pairwise(),
        map(([prev, next]) => {
          if (!!prev && this.relativeFilterChange(prev, next)) {
            this.form.get('searchParameter1')?.setValue(null);
            this.form.get('searchParameter2')?.setValue(null);
            this.form.get('searchParameter1Period')?.setValue(null);
            this.updateModelFromForm();
          }
          this.ref.markForCheck();
        }),
      )
      .subscribe();

    combineLatest([
      this.form.get('field').valueChanges.pipe(startWith(''), distinctUntilChanged()),
      this.form.get('filterType').valueChanges.pipe(startWith(''), distinctUntilChanged()),
    ])
      .pipe(
        untilDestroyed(this),
        filter(([field]) => !!field),
        map(([field, filterType]) => {
          this.setValueTemplate(field, filterType);
        }),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.updateFormFromModel();
    if (this._conditionField.getValue()) {
      this.setFilterOptions(this._conditionField.getValue()!);
      this.setValueTemplate(this._conditionField.getValue()!, this.condition?.filterType);
      this.ref.markForCheck();
    }
  }

  private relativeFilterChange(prior: FilterType | null, next: FilterType | null): boolean {
    const priorWasRelative = prior && this.relativeFilterTypes.includes(prior);
    const nextWasRelative = next && this.relativeFilterTypes.includes(next);
    return priorWasRelative !== nextWasRelative;
  }

  private setValueTemplate(
    field: RecordTypeFieldMetadata,
    filterType: FilterType | null | undefined,
  ) {
    if (!filterType) {
      return this._valueTemplate.next(this.noValueTemplate);
    }

    const dmField = this._dataModelStore.getFieldFromRecordTypeByFieldId(
      this.recordType,
      field.id,
    );

    if (dmField?.codeEnum) {
      return this._valueTemplate.next(this.codeEnumTemplate);
    }

    if (dmField && dmField.fieldType === 'codelist') {
      return dmField?.isList
        ? this._valueTemplate.next(this.codedListMultipleValueTemplate)
        : this._valueTemplate.next(this.codedListValueTemplate);
    }

    switch (filterType) {
      case FilterType.IsEmpty:
      case FilterType.IsNotEmpty:
        return this._valueTemplate.next(this.noValueTemplate);
      case FilterType.IntheLast:
      case FilterType.IntheNext:
        return this._valueTemplate.next(this.relativeDateValueTemplate);
      case FilterType.IntheCurrent:
        return this._valueTemplate.next(this.toDateValueTemplate);
      case FilterType.Between:
        return this._valueTemplate.next(this.betweenDatesValueTemplate);
    }

    if (field && dataModelTypeIsDateType(field.dataModelFieldType)) {
      return this._valueTemplate.next(this.dateValueTemplate);
    }
    if (field && field.dataModelFieldType === 'boolean') {
      return this._valueTemplate.next(this.booleanValueTemplate);
    }

    console.log('textValueTemplate');
    this._valueTemplate.next(this.textValueTemplate);
  }

  updateModelFromForm() {
    this.ref.markForCheck();
    const field = this.form.get('field')?.value as RecordTypeFieldMetadata;
    if (!field) {
      return;
    }
    if (!this.condition) {
      this.condition = {
        fieldId: field.id,
        fieldRecordType: field.recordTypeId,
        fieldPath: field.refName,
      };
    }
    this.condition.filterType = this.form.get('filterType')?.value as FilterType;
    this.condition.searchParameter1 = this.form.get('searchParameter1')?.value as string;
    this.condition.searchParameter2 = this.form.get('searchParameter2')?.value as string;
    this.condition.searchParameter1Period = this.form.get('searchParameter1Period')
      ?.value as string;
  }

  updateFormFromModel() {
    this.ref.markForCheck();
    if (this._conditionField.getValue()) {
      this.form.get('field')?.setValue(this._conditionField.getValue());
    }
    if (this.condition && this.condition.searchParameter1) {
      this.form.get('searchParameter1')?.setValue(this.condition.searchParameter1);
    }
    if (this.condition && this.condition.searchParameter2) {
      this.form.get('searchParameter2')?.setValue(this.condition.searchParameter2);
    }
    if (this.condition && this.condition.searchParameter1Period) {
      this.form.get('searchParameter1Period')?.setValue(this.condition.searchParameter1Period);
    }
    if (this.condition && this.condition.filterType) {
      this.form.get('filterType')?.setValue(this.condition.filterType);
    }
  }

  date1Changed(event: any) {
    this.form.get('searchParameter1')?.setValue(getDateOnlyString(event.value));
    this.updateModelFromForm();
    this.ref.markForCheck();
  }

  date2Changed(event: any) {
    this.form.get('searchParameter2')?.setValue(getDateOnlyString(event.value));
    this.updateModelFromForm();
    this.ref.markForCheck();
  }

  displayFn(field: RecordTypeFieldMetadata): string {
    return field && field.label ? field.label : '';
  }
}
