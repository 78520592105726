var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { RoleCategories } from '../../../roleEnums';
import { TrailMapType } from '../../../trail-maps/models';
import { FieldData, RecordTypeData } from '../../meta-data-decorators';
const recordTypeId = 'PolicyholderDeposit';
let PolicyholderDeposit = class PolicyholderDeposit {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    policyHolderId;
    policyHolder;
    depositTimestamp;
    amount;
    policyPeriod;
    policyId;
    policy;
    policyPaymentId;
    policyPayment;
    description;
    depositType;
    balance;
};
__decorate([
    FieldData({ recordTypeId, label: 'Deposit ID', fieldType: 'uuid' })
], PolicyholderDeposit.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyholderDeposit.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyholderDeposit.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyholderDeposit.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], PolicyholderDeposit.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], PolicyholderDeposit.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], PolicyholderDeposit.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], PolicyholderDeposit.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], PolicyholderDeposit.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], PolicyholderDeposit.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Policyholder Id',
        referenceType: 'PolicyHolder',
    })
], PolicyholderDeposit.prototype, "policyHolderId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyHolder', label: 'Policyholder' })
], PolicyholderDeposit.prototype, "policyHolder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime' })
], PolicyholderDeposit.prototype, "depositTimestamp", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], PolicyholderDeposit.prototype, "amount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], PolicyholderDeposit.prototype, "policyPeriod", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', label: 'Policy Id', referenceType: 'Policy' })
], PolicyholderDeposit.prototype, "policyId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Policy', label: 'Policy' })
], PolicyholderDeposit.prototype, "policy", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Policy Payment Id',
        referenceType: 'PolicyPayment',
    })
], PolicyholderDeposit.prototype, "policyPaymentId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyPayment', label: 'Policy Payment' })
], PolicyholderDeposit.prototype, "policyPayment", void 0);
__decorate([
    FieldData({ recordTypeId })
], PolicyholderDeposit.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId })
], PolicyholderDeposit.prototype, "depositType", void 0);
PolicyholderDeposit = __decorate([
    RecordTypeData({
        usesNewDataModel: true,
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Policyholder Deposit',
        displayNamePlural: 'Policyholder Deposits',
        trailMapTypes: [
            TrailMapType.Detail
        ],
        supports: {
            views: true
        },
        dataModelCustomization: {
            hideFromAdmin: true
        },
        roleCategory: RoleCategories.Accounting,
    })
], PolicyholderDeposit);
export { PolicyholderDeposit };
