import { Injectable, Optional, SkipSelf, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { Auth, Hub } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { SessionUrlService } from './session-url.service';
import { environment } from 'src/environments/environment';

export interface ICognitoUserInput {
  username: string;
  password: string;
  showPassword?: boolean;
  code?: string;
  name?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  private readonly _signOuts = new Subject<boolean>();
  readonly signOuts$ = this._signOuts.asObservable();
  urlService = inject(SessionUrlService);

  private readonly _signIns = new Subject<{
    user?: any;
    token?: any;
    fromCode?: boolean;
  } | null>();
  readonly signIns$ = this._signIns.asObservable();

  private readonly _refreshToken = new Subject<any>();
  readonly refreshToken$ = this._refreshToken.asObservable();

  private readonly _oauthStates = new Subject<any>();
  readonly oauthStates$ = this._oauthStates.asObservable();

  constructor(@Optional() @SkipSelf() parent?: CognitoService) {
    if (parent) {
      throw Error('attempted to create duplicate CognitoService');
    }

    // Use Hub channel 'auth' to get notified on changes
    Hub.listen('auth', ({ payload: { event, data } }) => {
      // console.log('Hub Event', JSON.stringify(event), JSON.stringify(data), JSON.stringify(message));
      switch (event) {
        case 'signIn':
          this._signIns.next({ user: data });
          break;
        case 'signOut':
          this._signOuts.next(true);
          break;
        case 'codeFlow':
          this._signIns.next({ fromCode: true });
          break;
        case 'customOAuthState':
          this._oauthStates.next({ state: data });
          break;
        case 'cognitoHostedUI':
          // console.log('logged in via Cognito Hosted UI', data);
          break;
        case 'tokenRefresh':
          void this.getToken().then((next) => {
            this._refreshToken.next({ newToken: next });
          });
          break;
        case 'parsingCallbackUrl':
        case 'configured':
          //todo fallback to nothing, suppress message
          break;
        default:
          console.warn('Unhandled AuthEvent', event);
      }
    });
  }

  async currentAuthenticatedUser() {
    let user = null;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (e) {
      console.warn('unable to get current user', e);
    }

    let token = null;
    try {
      token = await this.getToken();
    } catch (e) {
      console.warn('unable to get current token', e);
    }

    return Promise.resolve({ user, token });
  }

  public async getToken(secondRun = false): Promise<string> {
    const session = await Auth.currentSession();
    if (session && session.isValid()) {
      return Promise.resolve(session.getAccessToken().getJwtToken());
    }
    if (!secondRun) {
      await this.refreshToken();
      return this.getToken(true);
    }
    console.error('could not get token after attempting refresh');
    return Promise.reject('could not get token even after refresh');
  }

  signIn(user: ICognitoUserInput) {
    return Auth.signIn(user.username.toLowerCase(), user.password);
  }

  public signOut() {
    return Auth.signOut();
  }

  async forgotPassword(username: string) {
    const tenant = this.urlService.getTenantFromCurrentUrl();
    if (!tenant) {
      throw Error('Unable to determine tenant');
    }

    return Auth.forgotPassword(username.toLowerCase(), {
      env: environment.env,
      tenant: tenant,
    });
  }

  resetPassword(username: string, newPassword: string, code: string) {
    return Auth.forgotPasswordSubmit(username.toLowerCase(), code, newPassword);
  }

  async changePassword(oldPassword: string, newPassword: string) {
    const cognitoUser = (await Auth.currentAuthenticatedUser()) as CognitoUser;
    return Auth.changePassword(cognitoUser, oldPassword, newPassword);
  }

  public async refreshToken() {
    const session = await Auth.currentSession();
    if (session) {
      try {
        const cognitoUser = (await Auth.currentAuthenticatedUser()) as CognitoUser;
        cognitoUser.refreshSession(session.getRefreshToken(), (err, session) => {
          if (err) {
            console.error('error refreshing token for session: ', session);
            console.error(err);
          }
          window.location.reload();
        });
      } catch (e) {
        console.error('unable to refresh token', e);
      }
    }
  }
}
