<div class="flex flex-wrap gap-x-4 gap-y-2">
  <ng-container *ngFor="let searchField of this.store.quickSearchFields$ | async">
    <tb-data-filter-quick-search-field
      [quickSearchField]="searchField"
      [formGroup]="this.formGroup"></tb-data-filter-quick-search-field>
  </ng-container>
  <div class="flex flex-row items-end">
    <button
      matSuffix
      type="button"
      mat-flat-button
      [disabled]="this.disableBtns$ | async"
      color="accent"
      matTooltip="Apply Search"
      aria-label="Apply Search"
      (click)="doSearch()"
      type="button">
      Apply
    </button>
    <button
      matSuffix
      *ngIf="(this.disableBtns$ | async) === false"
      tabindex="-1"
      type="button"
      mat-button
      matTooltip="Clear Fields"
      aria-label="Clear"
      (click)="this.store.clearQuickFilterFields()"
      type="button"
      class="text-secondary">
      Clear
    </button>
  </div>
</div>
