<ng-container *ngIf="this.activeAlerts$ | async as alerts">
  <div *ngIf="alerts.length > 0">
    <div *ngFor="let alert of alerts" class="w-full">
      <fuse-alert
        [appearance]="'outline'"
        [dismissible]="alert.requiresManualDismissal === false"
        [dismissed]="alert.dismissed"
        [name]="alert.id"
        [type]="alert.requiresManualDismissal ? 'warning' : 'info'"
        (dismissedChanged)="dismissAction($event, alert.id)">
        <span fuseAlertTitle>{{ alert.title }}</span>
        <span>{{ alert.message }}</span>
        <div *ngIf="alert.requiresManualDismissal" class="flex items-center justify-end mt-4 mb-4">
          <button
            mat-raised-button
            color="primary"
            matBadgePosition="before"
            class="px-6 ml-3"
            matBadgeColor="accent"
            style="float: right"
            (click)="SaveAcknowledgement($event, alert)"
            type="button">
            Acknowledge
          </button>
        </div>
      </fuse-alert>
    </div>
  </div>
</ng-container>
