import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { LookupResult, lookupInput } from 'portal-commons/dist/lookups/models';
import { shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  private httpClient = inject(HttpClient);

  recordTypeLookup(input: lookupInput) {
    return this.httpClient.post<LookupResult[]>('/api/views/lookup', input).pipe(shareReplay(1));
  }
}
