import { inject, Injectable } from '@angular/core';
import { DataModelStoreService } from '../data-model/services/data-model.store';
import {
  TrailMapConfig,
  TrailMapMode,
  TrailMapType,
  TrailMapTypeConfig,
} from 'portal-commons/dist/trail-maps/models';
import { orderBy, startCase } from 'lodash-es';
import { TrailMapContext } from 'app/modules/trail-maps/models/models';

@Injectable({
  providedIn: 'root',
})
export class TrailMapConfigService {
  dm = inject(DataModelStoreService);
  readonly DASHBOARD_AREA_KEY = 'DASHBOARDS';
  specialTypes: { [key: string]: string[] } = {
    [TrailMapType.HomePage]: [this.DASHBOARD_AREA_KEY],
  };
  private readonly coreAreaConfig: { [key: string]: TrailMapConfig } = {
    [this.DASHBOARD_AREA_KEY]: {
      HomePage: {
        maxAllowedUserMaps: 10,
        displayFilters: true,
      },
    },
    INVOICE: {
      Detail: {
        detailViewRelationships: [
          {
            joinPath: 'invoiceId',
            modelProperty: 'id',
            recordType: 'InvoiceLine',
          },
          {
            joinPath: 'invoiceId',
            modelProperty: 'id',
            recordType: 'InvoiceAppliedPayment',
          }
        ]
      }
    },
    POLICY: {
      Detail: {
        detailViewRelationships: [
          {
            joinPath: 'masterPolicyId',
            modelProperty: 'id',
            recordType: 'Policy',
          },
          {
            joinPath: 'policy.id',
            modelProperty: 'id',
            recordType: 'PolicyPayment',
          },
          {
            joinPath: 'policy.id',
            modelProperty: 'id',
            recordType: 'Claim',
          },
          {
            joinPath: 'policyId',
            modelProperty: 'id',
            recordType: 'Invoice',
          },
          {
            joinPath: 'policyId',
            modelProperty: 'id',
            recordType: 'PayrollReport',
          },
        ],
      },
    },
    SUBMISSION: {
      HomePage: {
        subtitle: 'View and submit applications',
      },
    },
    ENDORSEMENT: {
      HomePage: {
        subtitle: 'Manage ${displayNamePluralLower}',
      },
    },
    VIEW: {
      HomePage: {
        subtitle:
          'Views provide flexible data sets to be used in dashboards and other areas of the system.',
      },
    },
    POLICYHOLDER: {
      Detail: {
        detailViewRelationships: [
          {
            joinPath: 'policy.policyHolder.id',
            modelProperty: 'id',
            recordType: 'Claim',
          },
          {
            joinPath: 'policyHolder.id',
            modelProperty: 'id',
            recordType: 'Policy',
          },
          {
            joinPath: 'policyHolder.id',
            modelProperty: 'id',
            recordType: 'PolicyPayment',
          },
          {
            joinPath: 'policyHolder.id',
            modelProperty: 'id',
            recordType: 'Invoice',
          },
          {
            joinPath: 'policyHolderId',
            modelProperty: 'id',
            recordType: 'PayrollReport',
          },
        ],
      },
    },
    QUOTE: {
      Detail: {
        detailViewRelationships: [
          {
            joinPath: 'submissionId',
            modelProperty: 'id',
            recordType: 'Submission',
          },
          {
            joinPath: 'renewalId',
            modelProperty: 'id',
            recordType: 'RENEWAL',
          },
        ],
      },
    },
    AUTOPAYPLAN: {
      HomePage: {
        subtitle: 'Set up and manage ways to pay invoices automatically',
      },
    },
    AGENCY: {
      Detail: {
        detailViewRelationships: [
          {
            joinPath: 'policy.agency.id',
            modelProperty: 'id',
            recordType: 'Claim',
          },
          {
            joinPath: 'agency.id',
            modelProperty: 'id',
            recordType: 'Policy',
          },
          {
            joinPath: 'agent.agencyId',
            modelProperty: 'id',
            recordType: 'Submission',
          },
        ],
      },
    },
    SURPLUSCALCULATION: {
      Detail: {
        detailViewRelationships: [
          {
            joinPath: 'surplusCalculationId',
            modelProperty: 'id',
            recordType: 'SURPLUSCALCULATIONLINEITEM',
          },
        ],
      },
    },
  };

  formatMapArea(area: string | null | undefined) {
    if (!!!area) {
      return area;
    }
    const recType = this.dm.getRecordType(area);
    if (recType) {
      return recType.displayNamePlural;
    }
    return startCase(area.toLowerCase());
  }

  getAllAreas(): { area: string; description: string }[] {
    const results: { area: string; description: string }[] = [];

    const recTypeMatches = Array.from(this.dm.getRecordTypes()?.values() ?? []).filter(
      (f) => f.trailMapTypes && f.trailMapTypes.length > 0,
    );
    if (recTypeMatches && recTypeMatches.length > 0) {
      results.push(
        ...recTypeMatches.map((m) => {
          return { area: m.id.toUpperCase(), description: m.displayNamePlural };
        }),
      );
    }

    const specialTypes = new Set<string>();
    for (const specialKey of Object.keys(this.specialTypes)) {
      this.specialTypes[specialKey].forEach((f) => specialTypes.add(f));
    }

    if (specialTypes.size > 0) {
      results.push(
        ...Array.from(specialTypes).map((m) => {
          return { area: m.toUpperCase(), description: startCase(m.toLowerCase()) };
        }),
      );
    }

    return results;
  }

  getAreasForType(type: string): { area: string; description: string }[] {
    return this.getAreasForTypes([type]);
  }

  getAreasForTypes(types: string[]): { area: string; description: string }[] {
    const results: { area: string; description: string }[] = [];

    const recTypeMatches = Array.from(this.dm.getRecordTypes()?.values() ?? []).filter(
      (f) => f.trailMapTypes && f.trailMapTypes.find(f => types.includes(f)),
    );
    if (recTypeMatches && recTypeMatches.length > 0) {
      results.push(
        ...recTypeMatches.map((m) => {
          return { area: m.id.toUpperCase(), description: startCase(m.displayNamePlural) };
        }),
      );
    }
    if (types.includes(TrailMapType.QuickAdd)) {
      const tenantRecordTypes = Array.from(this.dm.getRecordTypes()?.values() ?? []).filter(
        (x) => x.scope === 'tenant' && !x.hasBackingTable,
      );
      results.push(
        ...tenantRecordTypes.map((m) => {
          return { area: m.id.toUpperCase(), description: startCase(m.displayNamePlural) };
        }),
      );
    }

    for (const type of types) {
      if (this.specialTypes[type] !== undefined) {
        results.push(
          ...this.specialTypes[type].map((m) => {
            return { area: m.toUpperCase(), description: startCase(m.toLowerCase()) };
          }),
        );
      }
    }

    return orderBy(results, 'description');
  }

  private defaultConfig: Partial<TrailMapContext> = {
    maxAllowedUserMaps: 0,
    mode: TrailMapMode.Module,
    subtitle: 'View ${displayNameSingularLower} information',
  };

  getContextForArea(type: string, area: string): TrailMapContext {
    let baseContext: Partial<TrailMapContext> | undefined = { ...this.defaultConfig };
    if (area === this.DASHBOARD_AREA_KEY) {
      baseContext = {
        title: 'My Dashboards',
        area: this.DASHBOARD_AREA_KEY,
        type: type as TrailMapType,
        noun: 'dashboard',
        nouns: 'dashboards',
        mode: TrailMapMode.Module,
      };
    } else {
      const recType = this.dm.getRecordType(area);
      if (!recType) {
        console.error('Unknown map area, no record type', area);
        throw Error('Unknown map area, no record type');
      }
      baseContext = {
        ...baseContext,
        title: recType.displayNamePlural,
        area: recType.id.toUpperCase(),
        type: type as TrailMapType,
        noun: recType.displayNameSingularLower,
        nouns: recType.displayNamePluralLower,
        recordType: recType.id.toUpperCase(),
      };
    }
    if (
      baseContext &&
      this.coreAreaConfig[area] &&
      this.coreAreaConfig[area][type as TrailMapType]
    ) {
      const coreConfig = { ...this.coreAreaConfig[area][type as TrailMapType] };
      baseContext = { ...baseContext, ...coreConfig };
    }
    baseContext.subtitle = this.resolveSubtitle(area, baseContext.subtitle);

    this.appendTenantRecordTypeViewRelationships(baseContext as TrailMapContext);

    return baseContext as TrailMapContext;
  }

  appendTenantRecordTypeViewRelationships(context: TrailMapContext) {
    const recType = this.dm.getRecordType(context.area);
    if (recType?.scope === 'tenant') { return; }
    for (const tenantRec of Array.from(this.dm.getRecordTypes()?.values() ?? []).filter(f => f.scope === 'tenant')) {
      for (const tenantRecField of (tenantRec.fields ?? []).filter(f => f.fieldType === recType?.id && !(f.isList ?? false))) {
        if (!context.detailViewRelationships) {
          context.detailViewRelationships = [];
        }
        if (!context.detailViewRelationships.find(f => f.recordType === tenantRec.id)) {
          context.detailViewRelationships.push({
            joinPath: `${tenantRecField.refName}.id`,
            modelProperty: 'id',
            recordType: tenantRec.id
          });
        }
      }
    }
  }

  resolveSubtitle(area: string, subtitle: TrailMapTypeConfig['subtitle']) {
    if (!subtitle) {
      return subtitle;
    }
    let newSubtitle = subtitle;
    const tags = [
      'displayNamePlural',
      'displayNameSingular',
      'displayNamePluralLower',
      'displayNameSingularLower',
    ];
    if (newSubtitle && tags.find((f) => newSubtitle.includes(f))) {
      const recType = this.dm.getRecordType(area);
      if (recType) {
        for (const tag of tags) {
          newSubtitle = newSubtitle.replaceAll(`\${${tag}}`, (recType as any)[tag]);
        }
      }
    }
    return newSubtitle;
  }
}
