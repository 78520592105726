var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'AccountLedgerDepositItem';
let AccountLedgerDepositItem = class AccountLedgerDepositItem {
    //pk
    id;
    //fk
    accounting_deposit_id;
    //jdata
    itemRef;
    itemType;
    itemAmount;
    sourceId;
};
__decorate([
    FieldData({ recordTypeId })
], AccountLedgerDepositItem.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, referenceType: 'AccountLedgerDepositItem' })
], AccountLedgerDepositItem.prototype, "accounting_deposit_id", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], AccountLedgerDepositItem.prototype, "itemRef", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], AccountLedgerDepositItem.prototype, "itemType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], AccountLedgerDepositItem.prototype, "itemAmount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], AccountLedgerDepositItem.prototype, "sourceId", void 0);
AccountLedgerDepositItem = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        displayNameSingular: 'Accounting Ledger Deposit Item',
        displayNamePlural: 'Accounting Ledger Deposit Items',
    })
], AccountLedgerDepositItem);
export { AccountLedgerDepositItem };
