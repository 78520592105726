var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../data-filters/models';
import { RoleCategories } from '../../roleEnums';
import { TrailMapType } from '../../trail-maps/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'Carrier';
let Carrier = class Carrier {
    id;
    name;
    policyholderId;
    policyholder;
    parentCarrierId;
    parentCarrier;
    address;
    phoneNumber;
    federalEin;
    selfInsured;
    email;
    financialSizeRating;
    financialStrengthRating;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Carrier.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], Carrier.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'PolicyHolder' })
], Carrier.prototype, "policyholderId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyHolder' })
], Carrier.prototype, "policyholder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Carrier' })
], Carrier.prototype, "parentCarrierId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Carrier' })
], Carrier.prototype, "parentCarrier", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address', jsonStorage: true })
], Carrier.prototype, "address", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Phone #', fieldType: 'phone-number' })
], Carrier.prototype, "phoneNumber", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'FEIN' })
], Carrier.prototype, "federalEin", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], Carrier.prototype, "selfInsured", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'email' })
], Carrier.prototype, "email", void 0);
__decorate([
    FieldData({
        recordTypeId, label: 'Financial Size ($M)', codeList: [
            { code: 'I', description: 'Less than 1' },
            { code: 'II', description: '1 to 2' },
            { code: 'III', description: '2 to 5' },
            { code: 'IV', description: '5 to 10' },
            { code: 'V', description: '10 to 25' },
            { code: 'VI', description: '25 to 50' },
            { code: 'VII', description: '50 to 100' },
            { code: 'VIII', description: '100 to 250' },
            { code: 'IX', description: '250 to 500' },
            { code: 'X', description: '500 to 750' },
            { code: 'XI', description: '750 to 1,000' },
            { code: 'XII', description: '1,000 to 1,250' },
            { code: 'XIII', description: '1,250 to 1,500' },
            { code: 'XIV', description: '1,500 to 2,000' },
            { code: 'XV', description: '2,000 or greater' }
        ]
    })
], Carrier.prototype, "financialSizeRating", void 0);
__decorate([
    FieldData({
        recordTypeId, codeList: [
            { code: 'A+', description: 'Superior' },
            { code: 'A', description: 'Excellent' },
            { code: 'B+', description: 'Good' },
            { code: 'B', description: 'Fair' },
            { code: 'C+', description: 'Marginal' },
            { code: 'C', description: 'Weak' },
            { code: 'D', description: 'Poor' },
        ]
    })
], Carrier.prototype, "financialStrengthRating", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Carrier.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Carrier.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Carrier.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], Carrier.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Carrier.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], Carrier.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Carrier.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], Carrier.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], Carrier.prototype, "deletedUTC", void 0);
Carrier = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        roleCategory: RoleCategories.Carriers,
        displayNameSingular: 'Carrier',
        displayNamePlural: 'Carriers',
        security: {
            useDefaultPersonaSecurity: true,
            allowCrossPolicyholderRecords: true
        },
        trailMapTypes: [TrailMapType.Detail, TrailMapType.HomePage, TrailMapType.DataEntry, TrailMapType.QuickAdd],
        supports: {
            files: true,
            notes: true,
            docTemplates: true,
            views: true
        },
        workflow: {
            enabled: true,
            generalEvents: true
        },
        dataModelCustomization: {
            allowCustomFields: true
        },
        quickFilterFields: [
            { recordType: recordTypeId, fieldPath: 'name', filterType: FilterType.Contains },
            { recordType: recordTypeId, fieldPath: 'federalEin', filterType: FilterType.Equals },
        ],
        lookupProperties: { resultProperties: ['name'] },
    })
], Carrier);
export { Carrier };
