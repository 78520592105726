var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { TrailMapType } from '../../trail-maps/models';
import { RoleCategories } from '../../roleEnums';
const recordTypeId = 'QuoteSubtotalDetail';
let QuoteSubtotalDetail = class QuoteSubtotalDetail {
    description;
    rate;
    amount;
    isCurrency;
};
__decorate([
    FieldData({ recordTypeId, label: 'Description' })
], QuoteSubtotalDetail.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal' })
], QuoteSubtotalDetail.prototype, "rate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Amount', fieldType: 'decimal' })
], QuoteSubtotalDetail.prototype, "amount", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Is Currency', fieldType: 'boolean' })
], QuoteSubtotalDetail.prototype, "isCurrency", void 0);
QuoteSubtotalDetail = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        displayNameSingular: 'Quote Subtotal Detail',
        displayNamePlural: 'Quote Subtotal Details',
        dataModelCustomization: {
            allowCustomFields: false
        },
        trailMapTypes: [TrailMapType.HomePage, TrailMapType.Detail],
        roleCategory: RoleCategories.Quotes,
    })
], QuoteSubtotalDetail);
export { QuoteSubtotalDetail };
