var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { RoleCategories } from '../../roleEnums';
import { TrailMapType } from '../../trail-maps/models';
export var SnapshotStatus;
(function (SnapshotStatus) {
    SnapshotStatus["Active"] = "active";
    SnapshotStatus["Archived"] = "archived";
})(SnapshotStatus || (SnapshotStatus = {}));
const recordTypeId = 'PolicySnapshot';
let PolicySnapshot = class PolicySnapshot {
    id;
    policyId;
    policy;
    snapshotStatus;
    snapshotEffectiveFromDate;
    snapshotEffectiveToDate;
    snapshotDate;
    // Identifiers and Primary Info
    policyNumber;
    status;
    // Policy Details
    policyholderId;
    policyHolder;
    agencyId;
    agency;
    xMod;
    worksheetFactor;
    policyPeriod;
    overdueInvoiceId;
    cancellationReason;
    billingScheduleTypeId;
    costDetails;
    // Policy Dates
    cancellationDate;
    pendingCancellationDate;
    calculationDate;
    effectiveDate;
    expirationDate;
    overduePayrollReportDate;
    // Financials
    annualContribution;
    estimatedPremium;
    writtenPremium;
    auditedPremium;
    fees;
    deposit;
    estimatedCommission;
    // Policy Features
    billingType;
    masterPolicyId;
    /* What quote created the snapshot? It's defined here*/
    quoteId;
    quote;
    worksheetFactorElements;
    payrollClassCodes;
    masterPolicy;
    isMasterPolicy;
    proratedDays;
    // Administrative Info
    billingScheduleType;
    // User-Related Fields
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    agentId;
    agent;
    latePayrollReportMonths;
};
__decorate([
    FieldData({ recordTypeId, label: 'Policy Snapshot ID', fieldType: 'uuid' })
], PolicySnapshot.prototype, "id", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Policy ID',
    })
], PolicySnapshot.prototype, "policyId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'Policy',
        label: 'Policy',
    })
], PolicySnapshot.prototype, "policy", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Snapshot Status', fieldType: 'string' })
], PolicySnapshot.prototype, "snapshotStatus", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Snapshot Effective From Date',
        fieldType: 'datetime',
    })
], PolicySnapshot.prototype, "snapshotEffectiveFromDate", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Snapshot Effective To Date',
        fieldType: 'datetime',
    })
], PolicySnapshot.prototype, "snapshotEffectiveToDate", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Snapshot Date',
        fieldType: 'datetime',
    })
], PolicySnapshot.prototype, "snapshotDate", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: false })
], PolicySnapshot.prototype, "policyNumber", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: false })
], PolicySnapshot.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: false })
], PolicySnapshot.prototype, "policyholderId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'PolicyHolder',
        label: 'Policyholder',
        jsonStorage: true,
    })
], PolicySnapshot.prototype, "policyHolder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: false })
], PolicySnapshot.prototype, "agencyId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Agency', label: 'Agency' })
], PolicySnapshot.prototype, "agency", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'decimal',
        label: 'Experience Modifier',
    })
], PolicySnapshot.prototype, "xMod", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', jsonStorage: false })
], PolicySnapshot.prototype, "worksheetFactor", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Policy Period', jsonStorage: false })
], PolicySnapshot.prototype, "policyPeriod", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: false })
], PolicySnapshot.prototype, "overdueInvoiceId", void 0);
__decorate([
    FieldData({ recordTypeId, codeSet: 'policyCancelledReasons' })
], PolicySnapshot.prototype, "cancellationReason", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        referenceType: 'PolicyBillingScheduleType',
        disableField: true,
    })
], PolicySnapshot.prototype, "billingScheduleTypeId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'jsonb',
        jsonStorage: true,
        disableField: true,
        isList: true,
    })
], PolicySnapshot.prototype, "costDetails", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], PolicySnapshot.prototype, "cancellationDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], PolicySnapshot.prototype, "pendingCancellationDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: false })
], PolicySnapshot.prototype, "calculationDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: false })
], PolicySnapshot.prototype, "effectiveDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: false })
], PolicySnapshot.prototype, "expirationDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: false })
], PolicySnapshot.prototype, "overduePayrollReportDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: false })
], PolicySnapshot.prototype, "annualContribution", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: false })
], PolicySnapshot.prototype, "estimatedPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: false })
], PolicySnapshot.prototype, "writtenPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: false })
], PolicySnapshot.prototype, "auditedPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: false })
], PolicySnapshot.prototype, "fees", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: false })
], PolicySnapshot.prototype, "deposit", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: false })
], PolicySnapshot.prototype, "estimatedCommission", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Billing Type', jsonStorage: false })
], PolicySnapshot.prototype, "billingType", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Master Policy Id',
        referenceType: 'Policy',
        jsonStorage: false,
    })
], PolicySnapshot.prototype, "masterPolicyId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Snapshot Creating Quote Id',
        referenceType: 'Quote',
        jsonStorage: false,
    })
], PolicySnapshot.prototype, "quoteId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'Quote',
        label: 'Quote',
    })
], PolicySnapshot.prototype, "quote", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'PayrollReportFactor',
        label: 'Worksheet Factors',
        isList: true,
        jsonStorage: true,
    })
], PolicySnapshot.prototype, "worksheetFactorElements", void 0);
__decorate([
    FieldData({
        recordTypeId,
        isList: true,
        fieldType: 'PayrollClassCode',
        jsonStorage: true,
    })
], PolicySnapshot.prototype, "payrollClassCodes", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'Policy',
        label: 'Master Policy',
        jsonStorage: false,
    })
], PolicySnapshot.prototype, "masterPolicy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true })
], PolicySnapshot.prototype, "isMasterPolicy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer', jsonStorage: false })
], PolicySnapshot.prototype, "proratedDays", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'PolicyBillingScheduleType',
        jsonStorage: true,
    })
], PolicySnapshot.prototype, "billingScheduleType", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created By',
        fieldType: 'User',
        overrideJoinProperty: 'createUser',
        jsonStorage: false,
    })
], PolicySnapshot.prototype, "createUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created On',
        fieldType: 'datetime',
        jsonStorage: false,
    })
], PolicySnapshot.prototype, "createdUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified By',
        fieldType: 'User',
        jsonStorage: false,
    })
], PolicySnapshot.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified On',
        fieldType: 'datetime',
        jsonStorage: false,
    })
], PolicySnapshot.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted By',
        fieldType: 'User',
        jsonStorage: true,
    })
], PolicySnapshot.prototype, "deleteUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted On',
        fieldType: 'datetime',
        jsonStorage: false,
    })
], PolicySnapshot.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: false })
], PolicySnapshot.prototype, "agentId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'Agent',
        label: 'Agent',
        jsonStorage: true,
    })
], PolicySnapshot.prototype, "agent", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', isList: true, jsonStorage: true })
], PolicySnapshot.prototype, "latePayrollReportMonths", void 0);
PolicySnapshot = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'PolicySnapshot',
        displayNamePlural: 'PolicySnapshots',
        usesNewDataModel: true,
        supports: {
            views: true,
        },
        dataModelCustomization: {
            allowCustomFields: false,
        },
        trailMapTypes: [TrailMapType.DataEntry, TrailMapType.Detail],
        lookupProperties: { resultProperties: ['policyNumber', 'effectiveDate', 'expirationDate'], filterProperties: ['policyNumber'] },
        roleCategory: RoleCategories.Policies,
        recordHistoryConfig: {
            enable: true,
        },
    })
], PolicySnapshot);
export { PolicySnapshot };
