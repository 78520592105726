import {
  BehaviorSubject,
  catchError,
  firstValueFrom,
  map,
  Observable,
  of,
  shareReplay,
} from 'rxjs';
import { Tenant } from 'portal-commons/dist/tenant/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureSubscription } from 'portal-commons/dist/data-model/record-types/feature-subscription';
import { CAPTCHA_KEY_PARAM } from 'portal-commons/dist/captcha/models';

@Injectable({
  providedIn: 'root',
})
export class TenantsService {
  constructor(private _httpClient: HttpClient) { }

  private _currentTenant = new BehaviorSubject<Tenant>({
    addresses: [],
    contacts: [],
    phones: [],
  });
  public readonly CurrentTenant = this._currentTenant.asObservable();

  tenantCheck(id: string): Observable<boolean> {
    return this._httpClient.get(`/api/tenants/${id.toLocaleLowerCase()}/check`).pipe(
      map((result: any) => {
        return result && result.status && result.status === 'success';
      }),
      catchError((err: any) => {
        console.error('Unable to check tenant', err);
        return of(false);
      }),
      shareReplay(1),
    );
  }

  getNewUserRequestSettings(tenant: string) {
    return this._httpClient.get<{
      status: 'match' | 'nomatch',
      requestId?: string
    }>(`/api/tenants/${tenant}/request-user-settings`).pipe(
      catchError((error) => {
        console.error(error);
        return of({ status: 'nomatch', requestId: undefined });
      }),
      shareReplay(1)
    );
  }

  submitNewUserRequest(tenant: string, requestId: string, form: any, captcha: any) {
    return this._httpClient.post<undefined | { message: string }>(`/api/tenants/${tenant}/request-user`, {
      requestId: requestId,
      [CAPTCHA_KEY_PARAM]: captcha,
      form: form
    });
  }

  async getCurrentTenant() {
    const result = await firstValueFrom(
      this._httpClient.get<Tenant>('/api/tenants/current').pipe(
        catchError((error: any) => {
          console.error('Unable to get current tenant details', error);
          return of(null);
        }),
        shareReplay(1),
      ),
    );
    if (result) {
      this._currentTenant.next(result);
    }
  }

  getFeatures() {
    return this._httpClient.get<FeatureSubscription[]>('/api/tenants/features').pipe(
      catchError((error: any) => {
        console.error('Unable to get current tenant features', error);
        return of(null);
      }),
      shareReplay(1),
    );
  }
}
