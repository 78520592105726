export function getObjValueFromPath(model: any, path: string) {
  try {
    if (model[path] !== undefined) {
      return model[path];
    }
    return path.split('.').reduce((a, b) => a[b], model);
  } catch (err) {
    //console.error(`Unable to infer path value ${path}`);
    return null;
  }
}

export function keyPathExists(obj: any, path: string): boolean {
  const pathParts = path.split('.');
  let currObj = obj;
  for (const pathPart of pathParts) {
    if (!Object.keys(currObj).map(m => m.toLowerCase()).includes(pathPart.toLowerCase())) {
      return false;
    }
    currObj = currObj[pathPart];
  }
  return true;
}
