import { Code } from 'portal-commons/dist/data-model/record-types/code';

export function convertCodeListToSet(codeList: Code[]): { id: string; displayName: string }[] {
  return codeList.map(m => {
    return { id: m.code, displayName: m.description }
  });
}

export function getCodeListDisplayValue(codeList: Code[], input: string | undefined | null) {
  if (!!!input) { return input; }
  return codeList.find(f => f.code === input)?.description;
}
