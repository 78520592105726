var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { FilterType } from '../../data-filters/models';
const recordTypeId = 'DataModelRecordTypeField';
let DataModelAdminField = class DataModelAdminField {
    id;
    recordTypeId;
    label;
    refName;
    fieldType;
    createUserId;
    createUser;
    createdUTC;
    modifiedUserId;
    modifiedUser;
    modifiedUTC;
    deleteUserId;
    deleteUser;
    deletedUTC;
    tenant;
    alias;
    pendingDeployment;
    lastTableBuildUTC;
    formatting;
    isList;
    codeSet;
    disableField;
    bulkEditDefault;
    calculatedFieldFormula;
    disableFieldFromFieldPanel;
    disableFieldFromFilter;
    disableFieldFromSort;
    referenceType;
    overrideJoinProperty;
    jsonStorage;
    hasColumn;
    pathOverride;
    formula;
    hidden;
    contactType;
    codeList;
};
__decorate([
    FieldData({ recordTypeId, label: 'ID', fieldType: 'uuid' })
], DataModelAdminField.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], DataModelAdminField.prototype, "recordTypeId", void 0);
__decorate([
    FieldData({ recordTypeId })
], DataModelAdminField.prototype, "label", void 0);
__decorate([
    FieldData({ recordTypeId })
], DataModelAdminField.prototype, "refName", void 0);
__decorate([
    FieldData({ recordTypeId })
], DataModelAdminField.prototype, "fieldType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], DataModelAdminField.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], DataModelAdminField.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], DataModelAdminField.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], DataModelAdminField.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], DataModelAdminField.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], DataModelAdminField.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], DataModelAdminField.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], DataModelAdminField.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], DataModelAdminField.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "tenant", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "alias", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "pendingDeployment", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "lastTableBuildUTC", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "formatting", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "isList", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "codeSet", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "disableField", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "bulkEditDefault", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "calculatedFieldFormula", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "disableFieldFromFieldPanel", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "disableFieldFromFilter", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "disableFieldFromSort", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "referenceType", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "overrideJoinProperty", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "jsonStorage", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "hasColumn", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "pathOverride", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "formula", void 0);
__decorate([
    FieldData({
        recordTypeId,
        jsonStorage: true,
        fieldType: 'jsonb',
        label: 'Hidden Areas',
        disableField: true,
    })
], DataModelAdminField.prototype, "hidden", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "contactType", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'jsonb', disableField: true })
], DataModelAdminField.prototype, "codeList", void 0);
DataModelAdminField = __decorate([
    RecordTypeData({
        usesNewDataModel: true,
        id: recordTypeId,
        hasBackingTable: true,
        dataModelCustomization: {
            hideFromAdmin: true,
        },
        displayNameSingular: 'Data Model Field',
        displayNamePlural: 'Data Model Fields',
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'label',
                label: 'Label',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'refNum',
                label: 'Propety',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'scope',
                filterType: FilterType.Equals,
            },
        ],
    })
], DataModelAdminField);
export { DataModelAdminField };
