import { Component, ViewEncapsulation } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { FuseAlertType } from '@fuse/components/alert';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tb-toast-component]',
  styleUrls: ['./tb-toast.component.scss'],
  templateUrl: './tb-toast.component.html',
  preserveWhitespaces: false,
  encapsulation: ViewEncapsulation.None,
})
export class TbToastComponent extends Toast {
  toastType: FuseAlertType;
  isDismissable = false;
  hasCustomIcon = false;
  customIcon: string;

  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
    if (toastPackage) {
      this.toastType = toastPackage.toastType.replace('tb-toast-', '') as FuseAlertType;
      this.isDismissable = toastPackage.config.closeButton;
      if (toastPackage.config.titleClass != 'toast-title') {
        this.hasCustomIcon = true;
        this.customIcon = toastPackage.config.titleClass;
      }
    }
  }
}
