import { Injectable, inject } from '@angular/core';
import { ReplaySubject, filter, tap } from 'rxjs';
import { Notification } from 'portal-commons/dist/data-model/record-types/notification';
import { WsNotificationListMessage } from 'portal-commons/dist/notifications/models';
import { WebsocketService } from 'app/core/auth/web-socket.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WsMessageTypes } from 'portal-commons/dist/ws/model';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<Notification[]>(1);

  notifications$ = this._notifications.asObservable();
  websocketService = inject(WebsocketService);

  constructor() {
    this.websocketService.incomingMessages$
      .pipe(
        untilDestroyed(this),
        filter((f: WsNotificationListMessage) => f._type === WsMessageTypes.notificationList),
        tap((payload: WsNotificationListMessage) => {
          this._notifications.next(payload.notifications);
        }),
      )
      .subscribe();
  }
}
