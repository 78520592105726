import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  DefaultUrlSerializer,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { ToastNotificationService } from 'app/core/notifications/toasts/toast-notification.service';
import { Observable, of } from 'rxjs';
import { AuthService } from '../auth.service';
import { SessionUrlService } from '../session-url.service';

interface OAuthSwapPayload {
  options: {
    tenant: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class TenantGuard implements CanActivate, CanActivateChild {
  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private urlService: SessionUrlService,
    private notificationService: ToastNotificationService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(redirectUrl, route ? route : undefined);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(redirectUrl, childRoute.parent);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @private
   */
  private _check(redirectURL: string, route: ActivatedRouteSnapshot | null): Observable<boolean> {
    if (route?.queryParamMap?.keys.includes('oAuthConfig') && this.urlService.urlIsBaseHost()) {
      const oAuthConfig = JSON.parse(
        route.queryParamMap.get('oAuthConfig') as string,
      ) as OAuthSwapPayload;
      this._authService.setTenant(oAuthConfig.options.tenant);
      return of(true);
    }

    if (route && route.queryParamMap.get('_showSSO')) {
      this._authService.setShowSSO(route.queryParamMap.get('_showSSO') === 'true');
    }

    if (!this.urlService.urlTenantValid(this._authService)) {
      if (this._authService.getTenant()) {
        if (!this.urlService.urlIsBaseHost()) {
          this._authService.setTenant(this.urlService.getTenantFromCurrentUrl());
          this.notificationService.info(
            'URL does not match current account.  Signing out session.',
          );
          //Then we can try to just reload this url but with the tenant set.
          const newTree = new DefaultUrlSerializer().parse('/sign-out');
          const newUrl = this.urlService.buildTenantUrl(this._authService, newTree);
          window.location.replace(newUrl);
        } else {
          //Then we can try to just reload this url but with the tenant set.
          const newTree = new DefaultUrlSerializer().parse(redirectURL);
          const newUrl = this.urlService.buildTenantUrl(this._authService, newTree);
          window.location.replace(newUrl);
        }
      } else {
        void this._router.navigate(['set-tenant'], { queryParams: { redirectURL } }).then();
      }
      return of(false);
    }
    this._authService.setTenant(this.urlService.getTenantFromCurrentUrl());
    return of(true);
  }
}
