import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { Highlightable, ListKeyManagerOption } from '@angular/cdk/a11y';

import { Icons } from 'app/core/icons/models';
import { ResultItem } from 'portal-commons/dist/search/searchTypes';
import { Router } from '@angular/router';

@Component({
  selector: 'tb-search-result',
  styles: [
  ],
  templateUrl: './search-result.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultComponent implements Highlightable, ListKeyManagerOption {
  @Input() disabled = false;
  @Input() result: any;
  @Input() last = false;
  @Output() resultSelected = new EventEmitter<ResultItem>();
  public _isActive = false;
  ref = inject(ChangeDetectorRef);
  router = inject(Router);
  Icons = Icons;

  @ViewChild('itemElement') itemElement: ElementRef;

  setActiveStyles() {
    this._isActive = true;
    // if (this.itemElement) {
    //   this.itemElement.nativeElement.scrollIntoView();
    // }
    this.ref.markForCheck();
  }

  setInactiveStyles() {
    this._isActive = false;
    this.ref.markForCheck();
  }

  getLabel() {
    return this.result.title;
  }

  selectResult(item: ResultItem) {
    this.resultSelected.emit(item);
  }
}
