var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'AppSetting';
let AppSetting = class AppSetting {
    //pk
    id;
    //jdata
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    name;
    category;
    value;
    subcategory;
    isSecret;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], AppSetting.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], AppSetting.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], AppSetting.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], AppSetting.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], AppSetting.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], AppSetting.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], AppSetting.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId })
], AppSetting.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId })
], AppSetting.prototype, "category", void 0);
__decorate([
    FieldData({ recordTypeId })
], AppSetting.prototype, "value", void 0);
__decorate([
    FieldData({ recordTypeId })
], AppSetting.prototype, "subcategory", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], AppSetting.prototype, "isSecret", void 0);
AppSetting = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        hasBackingTable: true,
        displayNameSingular: 'App Setting',
        displayNamePlural: 'App Settings',
        dataModelCustomization: {
            hideFromAdmin: true
        },
    })
], AppSetting);
export { AppSetting };
