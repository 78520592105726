import { AuthService, AuthState } from 'app/core/auth/auth.service';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ToastNotificationService } from 'app/core/notifications/toasts/toast-notification.service';
import { UserProfileComponent } from './user-profile/user-profile.component';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {
  user: AuthState;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _toastService: ToastNotificationService,
    private _authService: AuthService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
  /**
   * On init
   */
  ngOnInit() {
    this.user = this._authService.currentUser();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Sign out
   */
  signOut(): void {
    void this._router.navigate(['./sign-out']);
  }

  editProfile() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    const dialogRef = this._dialog.open(UserProfileComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((data) => {
      console.log('User Profile output:', data);
      if (data && data.success && data.message) {
        this._toastService.success(data.message);
      }
    });
  }
}
