var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'InvoiceRuleSet';
let InvoiceRuleSet = class InvoiceRuleSet {
    id;
    name;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    isDisabled;
    config;
};
__decorate([
    FieldData({ recordTypeId, label: 'ID', fieldType: 'uuid' })
], InvoiceRuleSet.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], InvoiceRuleSet.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], InvoiceRuleSet.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], InvoiceRuleSet.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], InvoiceRuleSet.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], InvoiceRuleSet.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], InvoiceRuleSet.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], InvoiceRuleSet.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], InvoiceRuleSet.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], InvoiceRuleSet.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], InvoiceRuleSet.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], InvoiceRuleSet.prototype, "isDisabled", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableFieldFromFilter: true })
], InvoiceRuleSet.prototype, "config", void 0);
InvoiceRuleSet = __decorate([
    RecordTypeData({
        usesNewDataModel: true,
        id: recordTypeId,
        hasBackingTable: true,
        lookupProperties: { resultProperties: ['name'] },
        displayNameSingular: 'Invoice Rule Set',
        displayNamePlural: 'Invoice Rule Sets',
        dataModelCustomization: {
            hideFromAdmin: true,
        },
    })
], InvoiceRuleSet);
export { InvoiceRuleSet };
