import { Injectable, Optional, SkipSelf } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Subject, shareReplay } from 'rxjs';
import { AuthState } from 'app/core/auth/auth.service';
import { CognitoService } from 'app/core/auth/cognito.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WsMessage } from 'portal-commons/dist/ws/model';

interface WebsocketConnectionState {
  connectionId: string;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private readonly _connectionState = new BehaviorSubject<WebsocketConnectionState>({
    connectionId: '',
  });
  private readonly _isConnected = new BehaviorSubject<boolean>(false);
  private readonly _incomingMessages = new Subject<any>();
  readonly isConnected$ = this._isConnected.asObservable();
  readonly connectionState$ = this._connectionState.asObservable();
  readonly incomingMessages$ = this._incomingMessages.asObservable().pipe(shareReplay(10));

  constructor(
    private _cognitoService: CognitoService,
    @Optional() @SkipSelf() parent?: WebsocketService,
  ) {
    if (parent) {
      throw Error('attempted to create duplicate WebsocketService');
    }
  }

  connect(authState: AuthState) {
    const wsUri =
      environment.inAws === false
        ? `${environment.wsDomainOverride}?authorization=${authState.lastToken}&tenant=${authState.tenant}`
        : `wss://${environment.baseHostName}${environment.wsPath}?authorization=${authState.lastToken}&tenant=${authState.tenant}`;
    const ws = new WebSocket(wsUri);

    ws.onopen = (event) => {
      console.log('ws.onopen', event);
      ws.send(JSON.stringify({ content: 'gimme some notifications!' }));
      this._isConnected.next(true);
    };

    ws.onclose = (event) => {
      console.log('ws.onclose', event);
      this._isConnected.next(false);
      console.warn('socket closed.  reconnecting in 30 seconds', event);
    };

    ws.onerror = (event) => {
      console.warn('socket encountered error: ', event);
      ws.close();
      this._isConnected.next(false);
    };

    ws.onmessage = (event) => {
      this.parseMessage(event);
    };
  }

  private parseMessage(event: MessageEvent) {
    if (event.data) {
      const data = JSON.parse(event.data) as WsMessage;
      console.log('ws-message', data);
      if (data.connectionId) {
        this._connectionState.next({ connectionId: data.connectionId });
      }
      this._incomingMessages.next(data);
    }
  }
}
