//Note for the property string value you should use an underscore _ where a folder structure exists in the assets/icons folder.  You can use hyphens to break up icon names

export type IconItem = {
  icon: string,
  description: string
}

export const Icons = {
  material: {
    outline: {
      '10K': 'mat_outline:10k',
      '10Mp': 'mat_outline:10mp',
      '11Mp': 'mat_outline:11mp',
      '12Mp': 'mat_outline:12mp',
      '13Mp': 'mat_outline:13mp',
      '14Mp': 'mat_outline:14mp',
      '15Mp': 'mat_outline:15mp',
      '16Mp': 'mat_outline:16mp',
      '17Mp': 'mat_outline:17mp',
      '18Mp': 'mat_outline:18mp',
      '19Mp': 'mat_outline:19mp',
      '1K': 'mat_outline:1k',
      '1KPlus': 'mat_outline:1k_plus',
      '1XMobiledata': 'mat_outline:1x_mobiledata',
      '20Mp': 'mat_outline:20mp',
      '21Mp': 'mat_outline:21mp',
      '22Mp': 'mat_outline:22mp',
      '23Mp': 'mat_outline:23mp',
      '24Mp': 'mat_outline:24mp',
      '2K': 'mat_outline:2k',
      '2KPlus': 'mat_outline:2k_plus',
      '2Mp': 'mat_outline:2mp',
      '30Fps': 'mat_outline:30fps',
      '30FpsSelect': 'mat_outline:30fps_select',
      '360': 'mat_outline:360',
      '3DRotation': 'mat_outline:3d_rotation',
      '3GMobiledata': 'mat_outline:3g_mobiledata',
      '3K': 'mat_outline:3k',
      '3KPlus': 'mat_outline:3k_plus',
      '3Mp': 'mat_outline:3mp',
      '3P': 'mat_outline:3p',
      '4GMobiledata': 'mat_outline:4g_mobiledata',
      '4GPlusMobiledata': 'mat_outline:4g_plus_mobiledata',
      '4K': 'mat_outline:4k',
      '4KPlus': 'mat_outline:4k_plus',
      '4Mp': 'mat_outline:4mp',
      '5G': 'mat_outline:5g',
      '5K': 'mat_outline:5k',
      '5KPlus': 'mat_outline:5k_plus',
      '5Mp': 'mat_outline:5mp',
      '60Fps': 'mat_outline:60fps',
      '60FpsSelect': 'mat_outline:60fps_select',
      '6FtApart': 'mat_outline:6_ft_apart',
      '6K': 'mat_outline:6k',
      '6KPlus': 'mat_outline:6k_plus',
      '6Mp': 'mat_outline:6mp',
      '7K': 'mat_outline:7k',
      '7KPlus': 'mat_outline:7k_plus',
      '7Mp': 'mat_outline:7mp',
      '8K': 'mat_outline:8k',
      '8KPlus': 'mat_outline:8k_plus',
      '8Mp': 'mat_outline:8mp',
      '9K': 'mat_outline:9k',
      '9KPlus': 'mat_outline:9k_plus',
      '9Mp': 'mat_outline:9mp',
      AcUnit: 'mat_outline:ac_unit',
      AccessAlarm: 'mat_outline:access_alarm',
      AccessAlarms: 'mat_outline:access_alarms',
      AccessTime: 'mat_outline:access_time',
      AccessTimeFilled: 'mat_outline:access_time_filled',
      Accessibility: 'mat_outline:accessibility',
      AccessibilityNew: 'mat_outline:accessibility_new',
      Accessible: 'mat_outline:accessible',
      AccessibleForward: 'mat_outline:accessible_forward',
      AccountBalance: 'mat_outline:account_balance',
      AccountBalanceWallet: 'mat_outline:account_balance_wallet',
      AccountBox: 'mat_outline:account_box',
      AccountCircle: 'mat_outline:account_circle',
      AccountTree: 'mat_outline:account_tree',
      AdUnits: 'mat_outline:ad_units',
      Adb: 'mat_outline:adb',
      Add: 'mat_outline:add',
      AddAPhoto: 'mat_outline:add_a_photo',
      AddAlarm: 'mat_outline:add_alarm',
      AddAlert: 'mat_outline:add_alert',
      AddBox: 'mat_outline:add_box',
      AddBusiness: 'mat_outline:add_business',
      AddChart: 'mat_outline:add_chart',
      AddCircle: 'mat_outline:add_circle',
      AddCircleOutline: 'mat_outline:add_circle_outline',
      AddComment: 'mat_outline:add_comment',
      AddIcCall: 'mat_outline:add_ic_call',
      AddLink: 'mat_outline:add_link',
      AddLocation: 'mat_outline:add_location',
      AddLocationAlt: 'mat_outline:add_location_alt',
      AddModerator: 'mat_outline:add_moderator',
      AddPhotoAlternate: 'mat_outline:add_photo_alternate',
      AddReaction: 'mat_outline:add_reaction',
      AddRoad: 'mat_outline:add_road',
      AddShoppingCart: 'mat_outline:add_shopping_cart',
      AddTask: 'mat_outline:add_task',
      AddToDrive: 'mat_outline:add_to_drive',
      AddToHomeScreen: 'mat_outline:add_to_home_screen',
      AddToPhotos: 'mat_outline:add_to_photos',
      AddToQueue: 'mat_outline:add_to_queue',
      Addchart: 'mat_outline:addchart',
      Adjust: 'mat_outline:adjust',
      AdminPanelSettings: 'mat_outline:admin_panel_settings',
      AdsClick: 'mat_outline:ads_click',
      Agriculture: 'mat_outline:agriculture',
      Air: 'mat_outline:air',
      AirlineSeatFlat: 'mat_outline:airline_seat_flat',
      AirlineSeatFlatAngled: 'mat_outline:airline_seat_flat_angled',
      AirlineSeatIndividualSuite: 'mat_outline:airline_seat_individual_suite',
      AirlineSeatLegroomExtra: 'mat_outline:airline_seat_legroom_extra',
      AirlineSeatLegroomNormal: 'mat_outline:airline_seat_legroom_normal',
      AirlineSeatLegroomReduced: 'mat_outline:airline_seat_legroom_reduced',
      AirlineSeatReclineExtra: 'mat_outline:airline_seat_recline_extra',
      AirlineSeatReclineNormal: 'mat_outline:airline_seat_recline_normal',
      AirplaneTicket: 'mat_outline:airplane_ticket',
      AirplanemodeActive: 'mat_outline:airplanemode_active',
      AirplanemodeInactive: 'mat_outline:airplanemode_inactive',
      Airplay: 'mat_outline:airplay',
      AirportShuttle: 'mat_outline:airport_shuttle',
      Alarm: 'mat_outline:alarm',
      AlarmAdd: 'mat_outline:alarm_add',
      AlarmOff: 'mat_outline:alarm_off',
      AlarmOn: 'mat_outline:alarm_on',
      Album: 'mat_outline:album',
      AlignHorizontalCenter: 'mat_outline:align_horizontal_center',
      AlignHorizontalLeft: 'mat_outline:align_horizontal_left',
      AlignHorizontalRight: 'mat_outline:align_horizontal_right',
      AlignVerticalBottom: 'mat_outline:align_vertical_bottom',
      AlignVerticalCenter: 'mat_outline:align_vertical_center',
      AlignVerticalTop: 'mat_outline:align_vertical_top',
      AllInbox: 'mat_outline:all_inbox',
      AllInclusive: 'mat_outline:all_inclusive',
      AllOut: 'mat_outline:all_out',
      AltRoute: 'mat_outline:alt_route',
      AlternateEmail: 'mat_outline:alternate_email',
      Analytics: 'mat_outline:analytics',
      Anchor: 'mat_outline:anchor',
      Android: 'mat_outline:android',
      Animation: 'mat_outline:animation',
      Announcement: 'mat_outline:announcement',
      Aod: 'mat_outline:aod',
      Apartment: 'mat_outline:apartment',
      Api: 'mat_outline:api',
      AppBlocking: 'mat_outline:app_blocking',
      AppRegistration: 'mat_outline:app_registration',
      AppSettingsAlt: 'mat_outline:app_settings_alt',
      Approval: 'mat_outline:approval',
      Apps: 'mat_outline:apps',
      Architecture: 'mat_outline:architecture',
      Archive: 'mat_outline:archive',
      AreaChart: 'mat_outline:area_chart',
      ArrowBack: 'mat_outline:arrow_back',
      ArrowBackIos: 'mat_outline:arrow_back_ios',
      ArrowBackIosNew: 'mat_outline:arrow_back_ios_new',
      ArrowCircleDown: 'mat_outline:arrow_circle_down',
      ArrowCircleUp: 'mat_outline:arrow_circle_up',
      ArrowDownward: 'mat_outline:arrow_downward',
      ArrowDropDown: 'mat_outline:arrow_drop_down',
      ArrowDropDownCircle: 'mat_outline:arrow_drop_down_circle',
      ArrowDropUp: 'mat_outline:arrow_drop_up',
      ArrowForward: 'mat_outline:arrow_forward',
      ArrowForwardIos: 'mat_outline:arrow_forward_ios',
      ArrowLeft: 'mat_outline:arrow_left',
      ArrowRight: 'mat_outline:arrow_right',
      ArrowRightAlt: 'mat_outline:arrow_right_alt',
      ArrowUpward: 'mat_outline:arrow_upward',
      ArtTrack: 'mat_outline:art_track',
      Article: 'mat_outline:article',
      AspectRatio: 'mat_outline:aspect_ratio',
      Assessment: 'mat_outline:assessment',
      Assignment: 'mat_outline:assignment',
      AssignmentInd: 'mat_outline:assignment_ind',
      AssignmentLate: 'mat_outline:assignment_late',
      AssignmentReturn: 'mat_outline:assignment_return',
      AssignmentReturned: 'mat_outline:assignment_returned',
      AssignmentTurnedIn: 'mat_outline:assignment_turned_in',
      Assistant: 'mat_outline:assistant',
      AssistantDirection: 'mat_outline:assistant_direction',
      AssistantPhoto: 'mat_outline:assistant_photo',
      Atm: 'mat_outline:atm',
      AttachEmail: 'mat_outline:attach_email',
      AttachFile: 'mat_outline:attach_file',
      AttachMoney: 'mat_outline:attach_money',
      Attachment: 'mat_outline:attachment',
      Attractions: 'mat_outline:attractions',
      Attribution: 'mat_outline:attribution',
      Audiotrack: 'mat_outline:audiotrack',
      AutoAwesome: 'mat_outline:auto_awesome',
      AutoAwesomeMosaic: 'mat_outline:auto_awesome_mosaic',
      AutoAwesomeMotion: 'mat_outline:auto_awesome_motion',
      AutoDelete: 'mat_outline:auto_delete',
      AutoFixHigh: 'mat_outline:auto_fix_high',
      AutoFixNormal: 'mat_outline:auto_fix_normal',
      AutoFixOff: 'mat_outline:auto_fix_off',
      AutoGraph: 'mat_outline:auto_graph',
      AutoStories: 'mat_outline:auto_stories',
      AutofpsSelect: 'mat_outline:autofps_select',
      Autorenew: 'mat_outline:autorenew',
      AvTimer: 'mat_outline:av_timer',
      BabyChangingStation: 'mat_outline:baby_changing_station',
      BackHand: 'mat_outline:back_hand',
      Backpack: 'mat_outline:backpack',
      Backspace: 'mat_outline:backspace',
      Backup: 'mat_outline:backup',
      BackupTable: 'mat_outline:backup_table',
      Badge: 'mat_outline:badge',
      BakeryDining: 'mat_outline:bakery_dining',
      Balcony: 'mat_outline:balcony',
      Ballot: 'mat_outline:ballot',
      BarChart: 'mat_outline:bar_chart',
      BatchPrediction: 'mat_outline:batch_prediction',
      Bathroom: 'mat_outline:bathroom',
      Bathtub: 'mat_outline:bathtub',
      BatteryAlert: 'mat_outline:battery_alert',
      BatteryChargingFull: 'mat_outline:battery_charging_full',
      BatteryFull: 'mat_outline:battery_full',
      BatterySaver: 'mat_outline:battery_saver',
      BatteryStd: 'mat_outline:battery_std',
      BatteryUnknown: 'mat_outline:battery_unknown',
      BeachAccess: 'mat_outline:beach_access',
      Bed: 'mat_outline:bed',
      BedroomBaby: 'mat_outline:bedroom_baby',
      BedroomChild: 'mat_outline:bedroom_child',
      BedroomParent: 'mat_outline:bedroom_parent',
      Bedtime: 'mat_outline:bedtime',
      Beenhere: 'mat_outline:beenhere',
      Bento: 'mat_outline:bento',
      BikeScooter: 'mat_outline:bike_scooter',
      Biotech: 'mat_outline:biotech',
      Blender: 'mat_outline:blender',
      Block: 'mat_outline:block',
      Bloodtype: 'mat_outline:bloodtype',
      Bluetooth: 'mat_outline:bluetooth',
      BluetoothAudio: 'mat_outline:bluetooth_audio',
      BluetoothConnected: 'mat_outline:bluetooth_connected',
      BluetoothDisabled: 'mat_outline:bluetooth_disabled',
      BluetoothDrive: 'mat_outline:bluetooth_drive',
      BluetoothSearching: 'mat_outline:bluetooth_searching',
      BlurCircular: 'mat_outline:blur_circular',
      BlurLinear: 'mat_outline:blur_linear',
      BlurOff: 'mat_outline:blur_off',
      BlurOn: 'mat_outline:blur_on',
      Bolt: 'mat_outline:bolt',
      Book: 'mat_outline:book',
      BookOnline: 'mat_outline:book_online',
      Bookmark: 'mat_outline:bookmark',
      BookmarkAdd: 'mat_outline:bookmark_add',
      BookmarkAdded: 'mat_outline:bookmark_added',
      BookmarkBorder: 'mat_outline:bookmark_border',
      BookmarkRemove: 'mat_outline:bookmark_remove',
      Bookmarks: 'mat_outline:bookmarks',
      BorderAll: 'mat_outline:border_all',
      BorderBottom: 'mat_outline:border_bottom',
      BorderClear: 'mat_outline:border_clear',
      BorderColor: 'mat_outline:border_color',
      BorderHorizontal: 'mat_outline:border_horizontal',
      BorderInner: 'mat_outline:border_inner',
      BorderLeft: 'mat_outline:border_left',
      BorderOuter: 'mat_outline:border_outer',
      BorderRight: 'mat_outline:border_right',
      BorderStyle: 'mat_outline:border_style',
      BorderTop: 'mat_outline:border_top',
      BorderVertical: 'mat_outline:border_vertical',
      BrandingWatermark: 'mat_outline:branding_watermark',
      BreakfastDining: 'mat_outline:breakfast_dining',
      Brightness1: 'mat_outline:brightness_1',
      Brightness2: 'mat_outline:brightness_2',
      Brightness3: 'mat_outline:brightness_3',
      Brightness4: 'mat_outline:brightness_4',
      Brightness5: 'mat_outline:brightness_5',
      Brightness6: 'mat_outline:brightness_6',
      Brightness7: 'mat_outline:brightness_7',
      BrightnessAuto: 'mat_outline:brightness_auto',
      BrightnessHigh: 'mat_outline:brightness_high',
      BrightnessLow: 'mat_outline:brightness_low',
      BrightnessMedium: 'mat_outline:brightness_medium',
      BrokenImage: 'mat_outline:broken_image',
      BrowserNotSupported: 'mat_outline:browser_not_supported',
      BrunchDining: 'mat_outline:brunch_dining',
      Brush: 'mat_outline:brush',
      BubbleChart: 'mat_outline:bubble_chart',
      BugReport: 'mat_outline:bug_report',
      Build: 'mat_outline:build',
      BuildCircle: 'mat_outline:build_circle',
      Bungalow: 'mat_outline:bungalow',
      BurstMode: 'mat_outline:burst_mode',
      BusAlert: 'mat_outline:bus_alert',
      Business: 'mat_outline:business',
      BusinessCenter: 'mat_outline:business_center',
      Cabin: 'mat_outline:cabin',
      Cable: 'mat_outline:cable',
      Cached: 'mat_outline:cached',
      Cake: 'mat_outline:cake',
      Calculate: 'mat_outline:calculate',
      CalendarToday: 'mat_outline:calendar_today',
      CalendarViewDay: 'mat_outline:calendar_view_day',
      CalendarViewMonth: 'mat_outline:calendar_view_month',
      CalendarViewWeek: 'mat_outline:calendar_view_week',
      Call: 'mat_outline:call',
      CallEnd: 'mat_outline:call_end',
      CallMade: 'mat_outline:call_made',
      CallMerge: 'mat_outline:call_merge',
      CallMissed: 'mat_outline:call_missed',
      CallMissedOutgoing: 'mat_outline:call_missed_outgoing',
      CallReceived: 'mat_outline:call_received',
      CallSplit: 'mat_outline:call_split',
      CallToAction: 'mat_outline:call_to_action',
      Camera: 'mat_outline:camera',
      CameraAlt: 'mat_outline:camera_alt',
      CameraEnhance: 'mat_outline:camera_enhance',
      CameraFront: 'mat_outline:camera_front',
      CameraIndoor: 'mat_outline:camera_indoor',
      CameraOutdoor: 'mat_outline:camera_outdoor',
      CameraRear: 'mat_outline:camera_rear',
      CameraRoll: 'mat_outline:camera_roll',
      Cameraswitch: 'mat_outline:cameraswitch',
      Campaign: 'mat_outline:campaign',
      Cancel: 'mat_outline:cancel',
      CancelPresentation: 'mat_outline:cancel_presentation',
      CancelScheduleSend: 'mat_outline:cancel_schedule_send',
      CarRental: 'mat_outline:car_rental',
      CarRepair: 'mat_outline:car_repair',
      CardGiftcard: 'mat_outline:card_giftcard',
      CardMembership: 'mat_outline:card_membership',
      CardTravel: 'mat_outline:card_travel',
      Carpenter: 'mat_outline:carpenter',
      Cases: 'mat_outline:cases',
      Casino: 'mat_outline:casino',
      Cast: 'mat_outline:cast',
      CastConnected: 'mat_outline:cast_connected',
      CastForEducation: 'mat_outline:cast_for_education',
      CatchingPokemon: 'mat_outline:catching_pokemon',
      Category: 'mat_outline:category',
      Celebration: 'mat_outline:celebration',
      CellWifi: 'mat_outline:cell_wifi',
      CenterFocusStrong: 'mat_outline:center_focus_strong',
      CenterFocusWeak: 'mat_outline:center_focus_weak',
      Chair: 'mat_outline:chair',
      ChairAlt: 'mat_outline:chair_alt',
      Chalet: 'mat_outline:chalet',
      ChangeCircle: 'mat_outline:change_circle',
      ChangeHistory: 'mat_outline:change_history',
      ChargingStation: 'mat_outline:charging_station',
      Chat: 'mat_outline:chat',
      ChatBubble: 'mat_outline:chat_bubble',
      ChatBubbleOutline: 'mat_outline:chat_bubble_outline',
      Check: 'mat_outline:check',
      CheckBox: 'mat_outline:check_box',
      CheckBoxOutlineBlank: 'mat_outline:check_box_outline_blank',
      CheckCircle: 'mat_outline:check_circle',
      CheckCircleOutline: 'mat_outline:check_circle_outline',
      Checklist: 'mat_outline:checklist',
      ChecklistRtl: 'mat_outline:checklist_rtl',
      Checkroom: 'mat_outline:checkroom',
      ChevronLeft: 'mat_outline:chevron_left',
      ChevronRight: 'mat_outline:chevron_right',
      ChildCare: 'mat_outline:child_care',
      ChildFriendly: 'mat_outline:child_friendly',
      ChromeReaderMode: 'mat_outline:chrome_reader_mode',
      Circle: 'mat_outline:circle',
      CircleNotifications: 'mat_outline:circle_notifications',
      Class: 'mat_outline:class',
      CleanHands: 'mat_outline:clean_hands',
      CleaningServices: 'mat_outline:cleaning_services',
      Clear: 'mat_outline:clear',
      ClearAll: 'mat_outline:clear_all',
      Close: 'mat_outline:close',
      CloseFullscreen: 'mat_outline:close_fullscreen',
      ClosedCaption: 'mat_outline:closed_caption',
      ClosedCaptionDisabled: 'mat_outline:closed_caption_disabled',
      ClosedCaptionOff: 'mat_outline:closed_caption_off',
      Cloud: 'mat_outline:cloud',
      CloudCircle: 'mat_outline:cloud_circle',
      CloudDone: 'mat_outline:cloud_done',
      CloudDownload: 'mat_outline:cloud_download',
      CloudOff: 'mat_outline:cloud_off',
      CloudQueue: 'mat_outline:cloud_queue',
      CloudUpload: 'mat_outline:cloud_upload',
      Code: 'mat_outline:code',
      CodeOff: 'mat_outline:code_off',
      Coffee: 'mat_outline:coffee',
      CoffeeMaker: 'mat_outline:coffee_maker',
      Collections: 'mat_outline:collections',
      CollectionsBookmark: 'mat_outline:collections_bookmark',
      ColorLens: 'mat_outline:color_lens',
      Colorize: 'mat_outline:colorize',
      Comment: 'mat_outline:comment',
      CommentBank: 'mat_outline:comment_bank',
      Commute: 'mat_outline:commute',
      Compare: 'mat_outline:compare',
      CompareArrows: 'mat_outline:compare_arrows',
      CompassCalibration: 'mat_outline:compass_calibration',
      Compost: 'mat_outline:compost',
      Compress: 'mat_outline:compress',
      Computer: 'mat_outline:computer',
      ConfirmationNumber: 'mat_outline:confirmation_number',
      ConnectWithoutContact: 'mat_outline:connect_without_contact',
      ConnectedTv: 'mat_outline:connected_tv',
      Construction: 'mat_outline:construction',
      ContactMail: 'mat_outline:contact_mail',
      ContactPage: 'mat_outline:contact_page',
      ContactPhone: 'mat_outline:contact_phone',
      ContactSupport: 'mat_outline:contact_support',
      Contactless: 'mat_outline:contactless',
      Contacts: 'mat_outline:contacts',
      ContentCopy: 'mat_outline:content_copy',
      ContentCut: 'mat_outline:content_cut',
      ContentPaste: 'mat_outline:content_paste',
      ContentPasteOff: 'mat_outline:content_paste_off',
      ControlCamera: 'mat_outline:control_camera',
      ControlPoint: 'mat_outline:control_point',
      ControlPointDuplicate: 'mat_outline:control_point_duplicate',
      CopyAll: 'mat_outline:copy_all',
      Copyright: 'mat_outline:copyright',
      Coronavirus: 'mat_outline:coronavirus',
      CorporateFare: 'mat_outline:corporate_fare',
      Cottage: 'mat_outline:cottage',
      Countertops: 'mat_outline:countertops',
      Create: 'mat_outline:create',
      CreateNewFolder: 'mat_outline:create_new_folder',
      CreditCard: 'mat_outline:credit_card',
      CreditCardOff: 'mat_outline:credit_card_off',
      CreditScore: 'mat_outline:credit_score',
      Crib: 'mat_outline:crib',
      Crop: 'mat_outline:crop',
      Crop169: 'mat_outline:crop_16_9',
      Crop32: 'mat_outline:crop_3_2',
      Crop54: 'mat_outline:crop_5_4',
      Crop75: 'mat_outline:crop_7_5',
      CropDin: 'mat_outline:crop_din',
      CropFree: 'mat_outline:crop_free',
      CropLandscape: 'mat_outline:crop_landscape',
      CropOriginal: 'mat_outline:crop_original',
      CropPortrait: 'mat_outline:crop_portrait',
      CropRotate: 'mat_outline:crop_rotate',
      CropSquare: 'mat_outline:crop_square',
      CrueltyFree: 'mat_outline:cruelty_free',
      Dangerous: 'mat_outline:dangerous',
      DarkMode: 'mat_outline:dark_mode',
      Dashboard: 'mat_outline:dashboard',
      DashboardCustomize: 'mat_outline:dashboard_customize',
      DataExploration: 'mat_outline:data_exploration',
      DataSaverOff: 'mat_outline:data_saver_off',
      DataSaverOn: 'mat_outline:data_saver_on',
      DataUsage: 'mat_outline:data_usage',
      DateRange: 'mat_outline:date_range',
      Deck: 'mat_outline:deck',
      Dehaze: 'mat_outline:dehaze',
      Delete: 'mat_outline:delete',
      DeleteForever: 'mat_outline:delete_forever',
      DeleteOutline: 'mat_outline:delete_outline',
      DeleteSweep: 'mat_outline:delete_sweep',
      DeliveryDining: 'mat_outline:delivery_dining',
      DepartureBoard: 'mat_outline:departure_board',
      Description: 'mat_outline:description',
      DesignServices: 'mat_outline:design_services',
      DesktopAccessDisabled: 'mat_outline:desktop_access_disabled',
      DesktopMac: 'mat_outline:desktop_mac',
      DesktopWindows: 'mat_outline:desktop_windows',
      Details: 'mat_outline:details',
      DeveloperBoard: 'mat_outline:developer_board',
      DeveloperBoardOff: 'mat_outline:developer_board_off',
      DeveloperMode: 'mat_outline:developer_mode',
      DeviceHub: 'mat_outline:device_hub',
      DeviceThermostat: 'mat_outline:device_thermostat',
      DeviceUnknown: 'mat_outline:device_unknown',
      Devices: 'mat_outline:devices',
      DevicesOther: 'mat_outline:devices_other',
      DialerSip: 'mat_outline:dialer_sip',
      Dialpad: 'mat_outline:dialpad',
      Dining: 'mat_outline:dining',
      DinnerDining: 'mat_outline:dinner_dining',
      Directions: 'mat_outline:directions',
      DirectionsBike: 'mat_outline:directions_bike',
      DirectionsBoat: 'mat_outline:directions_boat',
      DirectionsBoatFilled: 'mat_outline:directions_boat_filled',
      DirectionsBus: 'mat_outline:directions_bus',
      DirectionsBusFilled: 'mat_outline:directions_bus_filled',
      DirectionsCar: 'mat_outline:directions_car',
      DirectionsCarFilled: 'mat_outline:directions_car_filled',
      DirectionsOff: 'mat_outline:directions_off',
      DirectionsRailway: 'mat_outline:directions_railway',
      DirectionsRailwayFilled: 'mat_outline:directions_railway_filled',
      DirectionsRun: 'mat_outline:directions_run',
      DirectionsSubway: 'mat_outline:directions_subway',
      DirectionsSubwayFilled: 'mat_outline:directions_subway_filled',
      DirectionsTransit: 'mat_outline:directions_transit',
      DirectionsTransitFilled: 'mat_outline:directions_transit_filled',
      DirectionsWalk: 'mat_outline:directions_walk',
      DirtyLens: 'mat_outline:dirty_lens',
      DisabledByDefault: 'mat_outline:disabled_by_default',
      DisabledVisible: 'mat_outline:disabled_visible',
      DiscFull: 'mat_outline:disc_full',
      Dns: 'mat_outline:dns',
      DoDisturb: 'mat_outline:do_disturb',
      DoDisturbAlt: 'mat_outline:do_disturb_alt',
      DoDisturbOff: 'mat_outline:do_disturb_off',
      DoDisturbOn: 'mat_outline:do_disturb_on',
      DoNotDisturb: 'mat_outline:do_not_disturb',
      DoNotDisturbAlt: 'mat_outline:do_not_disturb_alt',
      DoNotDisturbOff: 'mat_outline:do_not_disturb_off',
      DoNotDisturbOn: 'mat_outline:do_not_disturb_on',
      DoNotDisturbOnTotalSilence: 'mat_outline:do_not_disturb_on_total_silence',
      DoNotStep: 'mat_outline:do_not_step',
      DoNotTouch: 'mat_outline:do_not_touch',
      Dock: 'mat_outline:dock',
      DocumentScanner: 'mat_outline:document_scanner',
      Domain: 'mat_outline:domain',
      DomainDisabled: 'mat_outline:domain_disabled',
      DomainVerification: 'mat_outline:domain_verification',
      Done: 'mat_outline:done',
      DoneAll: 'mat_outline:done_all',
      DoneOutline: 'mat_outline:done_outline',
      DonutLarge: 'mat_outline:donut_large',
      DonutSmall: 'mat_outline:donut_small',
      DoorBack: 'mat_outline:door_back',
      DoorFront: 'mat_outline:door_front',
      DoorSliding: 'mat_outline:door_sliding',
      Doorbell: 'mat_outline:doorbell',
      DoubleArrow: 'mat_outline:double_arrow',
      DownhillSkiing: 'mat_outline:downhill_skiing',
      Download: 'mat_outline:download',
      DownloadDone: 'mat_outline:download_done',
      DownloadForOffline: 'mat_outline:download_for_offline',
      Downloading: 'mat_outline:downloading',
      Drafts: 'mat_outline:drafts',
      DragHandle: 'mat_outline:drag_handle',
      DragIndicator: 'mat_outline:drag_indicator',
      Draw: 'mat_outline:draw',
      DriveEta: 'mat_outline:drive_eta',
      DriveFileMove: 'mat_outline:drive_file_move',
      DriveFileMoveRtl: 'mat_outline:drive_file_move_rtl',
      DriveFileRenameOutline: 'mat_outline:drive_file_rename_outline',
      DriveFolderUpload: 'mat_outline:drive_folder_upload',
      Dry: 'mat_outline:dry',
      DryCleaning: 'mat_outline:dry_cleaning',
      Duo: 'mat_outline:duo',
      Dvr: 'mat_outline:dvr',
      DynamicFeed: 'mat_outline:dynamic_feed',
      DynamicForm: 'mat_outline:dynamic_form',
      EMobiledata: 'mat_outline:e_mobiledata',
      Earbuds: 'mat_outline:earbuds',
      EarbudsBattery: 'mat_outline:earbuds_battery',
      East: 'mat_outline:east',
      EdgesensorHigh: 'mat_outline:edgesensor_high',
      EdgesensorLow: 'mat_outline:edgesensor_low',
      Edit: 'mat_outline:edit',
      EditAttributes: 'mat_outline:edit_attributes',
      EditCalendar: 'mat_outline:edit_calendar',
      EditLocation: 'mat_outline:edit_location',
      EditLocationAlt: 'mat_outline:edit_location_alt',
      EditNote: 'mat_outline:edit_note',
      EditNotifications: 'mat_outline:edit_notifications',
      EditOff: 'mat_outline:edit_off',
      EditRoad: 'mat_outline:edit_road',
      Eject: 'mat_outline:eject',
      Elderly: 'mat_outline:elderly',
      ElectricBike: 'mat_outline:electric_bike',
      ElectricCar: 'mat_outline:electric_car',
      ElectricMoped: 'mat_outline:electric_moped',
      ElectricRickshaw: 'mat_outline:electric_rickshaw',
      ElectricScooter: 'mat_outline:electric_scooter',
      ElectricalServices: 'mat_outline:electrical_services',
      Elevator: 'mat_outline:elevator',
      Email: 'mat_outline:email',
      Emergency: 'mat_outline:emergency',
      EmojiEmotions: 'mat_outline:emoji_emotions',
      EmojiEvents: 'mat_outline:emoji_events',
      EmojiFlags: 'mat_outline:emoji_flags',
      EmojiFoodBeverage: 'mat_outline:emoji_food_beverage',
      EmojiNature: 'mat_outline:emoji_nature',
      EmojiObjects: 'mat_outline:emoji_objects',
      EmojiPeople: 'mat_outline:emoji_people',
      EmojiSymbols: 'mat_outline:emoji_symbols',
      EmojiTransportation: 'mat_outline:emoji_transportation',
      Engineering: 'mat_outline:engineering',
      EnhancedEncryption: 'mat_outline:enhanced_encryption',
      Equalizer: 'mat_outline:equalizer',
      Error: 'mat_outline:error',
      ErrorOutline: 'mat_outline:error_outline',
      Escalator: 'mat_outline:escalator',
      EscalatorWarning: 'mat_outline:escalator_warning',
      Euro: 'mat_outline:euro',
      EuroSymbol: 'mat_outline:euro_symbol',
      EvStation: 'mat_outline:ev_station',
      Event: 'mat_outline:event',
      EventAvailable: 'mat_outline:event_available',
      EventBusy: 'mat_outline:event_busy',
      EventNote: 'mat_outline:event_note',
      EventSeat: 'mat_outline:event_seat',
      ExitToApp: 'mat_outline:exit_to_app',
      Expand: 'mat_outline:expand',
      ExpandLess: 'mat_outline:expand_less',
      ExpandMore: 'mat_outline:expand_more',
      Explicit: 'mat_outline:explicit',
      Explore: 'mat_outline:explore',
      ExploreOff: 'mat_outline:explore_off',
      Exposure: 'mat_outline:exposure',
      ExposureNeg1: 'mat_outline:exposure_neg_1',
      ExposureNeg2: 'mat_outline:exposure_neg_2',
      ExposurePlus1: 'mat_outline:exposure_plus_1',
      ExposurePlus2: 'mat_outline:exposure_plus_2',
      ExposureZero: 'mat_outline:exposure_zero',
      Extension: 'mat_outline:extension',
      ExtensionOff: 'mat_outline:extension_off',
      Face: 'mat_outline:face',
      FaceRetouchingNatural: 'mat_outline:face_retouching_natural',
      FaceRetouchingOff: 'mat_outline:face_retouching_off',
      Facebook: 'mat_outline:facebook',
      FactCheck: 'mat_outline:fact_check',
      FamilyRestroom: 'mat_outline:family_restroom',
      FastForward: 'mat_outline:fast_forward',
      FastRewind: 'mat_outline:fast_rewind',
      Fastfood: 'mat_outline:fastfood',
      Favorite: 'mat_outline:favorite',
      FavoriteBorder: 'mat_outline:favorite_border',
      FeaturedPlayList: 'mat_outline:featured_play_list',
      FeaturedVideo: 'mat_outline:featured_video',
      Feed: 'mat_outline:feed',
      Feedback: 'mat_outline:feedback',
      Female: 'mat_outline:female',
      Fence: 'mat_outline:fence',
      Festival: 'mat_outline:festival',
      FiberDvr: 'mat_outline:fiber_dvr',
      FiberManualRecord: 'mat_outline:fiber_manual_record',
      FiberNew: 'mat_outline:fiber_new',
      FiberPin: 'mat_outline:fiber_pin',
      FiberSmartRecord: 'mat_outline:fiber_smart_record',
      FileCopy: 'mat_outline:file_copy',
      FileDownload: 'mat_outline:file_download',
      FileDownloadDone: 'mat_outline:file_download_done',
      FileDownloadOff: 'mat_outline:file_download_off',
      FilePresent: 'mat_outline:file_present',
      FileUpload: 'mat_outline:file_upload',
      Filter: 'mat_outline:filter',
      Filter1: 'mat_outline:filter_1',
      Filter2: 'mat_outline:filter_2',
      Filter3: 'mat_outline:filter_3',
      Filter4: 'mat_outline:filter_4',
      Filter5: 'mat_outline:filter_5',
      Filter6: 'mat_outline:filter_6',
      Filter7: 'mat_outline:filter_7',
      Filter8: 'mat_outline:filter_8',
      Filter9: 'mat_outline:filter_9',
      Filter9Plus: 'mat_outline:filter_9_plus',
      FilterAlt: 'mat_outline:filter_alt',
      FilterBAndW: 'mat_outline:filter_b_and_w',
      FilterCenterFocus: 'mat_outline:filter_center_focus',
      FilterDrama: 'mat_outline:filter_drama',
      FilterFrames: 'mat_outline:filter_frames',
      FilterHdr: 'mat_outline:filter_hdr',
      FilterList: 'mat_outline:filter_list',
      FilterNone: 'mat_outline:filter_none',
      FilterTiltShift: 'mat_outline:filter_tilt_shift',
      FilterVintage: 'mat_outline:filter_vintage',
      FindInPage: 'mat_outline:find_in_page',
      FindReplace: 'mat_outline:find_replace',
      Fingerprint: 'mat_outline:fingerprint',
      FireExtinguisher: 'mat_outline:fire_extinguisher',
      Fireplace: 'mat_outline:fireplace',
      FirstPage: 'mat_outline:first_page',
      FitScreen: 'mat_outline:fit_screen',
      FitnessCenter: 'mat_outline:fitness_center',
      Flag: 'mat_outline:flag',
      Flaky: 'mat_outline:flaky',
      Flare: 'mat_outline:flare',
      FlashAuto: 'mat_outline:flash_auto',
      FlashOff: 'mat_outline:flash_off',
      FlashOn: 'mat_outline:flash_on',
      FlashlightOff: 'mat_outline:flashlight_off',
      FlashlightOn: 'mat_outline:flashlight_on',
      Flatware: 'mat_outline:flatware',
      Flight: 'mat_outline:flight',
      FlightLand: 'mat_outline:flight_land',
      FlightTakeoff: 'mat_outline:flight_takeoff',
      Flip: 'mat_outline:flip',
      FlipCameraAndroid: 'mat_outline:flip_camera_android',
      FlipCameraIos: 'mat_outline:flip_camera_ios',
      FlipToBack: 'mat_outline:flip_to_back',
      FlipToFront: 'mat_outline:flip_to_front',
      Flourescent: 'mat_outline:flourescent',
      FlutterDash: 'mat_outline:flutter_dash',
      FmdBad: 'mat_outline:fmd_bad',
      FmdGood: 'mat_outline:fmd_good',
      Folder: 'mat_outline:folder',
      FolderOpen: 'mat_outline:folder_open',
      FolderShared: 'mat_outline:folder_shared',
      FolderSpecial: 'mat_outline:folder_special',
      FollowTheSigns: 'mat_outline:follow_the_signs',
      FontDownload: 'mat_outline:font_download',
      FontDownloadOff: 'mat_outline:font_download_off',
      FoodBank: 'mat_outline:food_bank',
      FormatAlignCenter: 'mat_outline:format_align_center',
      FormatAlignJustify: 'mat_outline:format_align_justify',
      FormatAlignLeft: 'mat_outline:format_align_left',
      FormatAlignRight: 'mat_outline:format_align_right',
      FormatBold: 'mat_outline:format_bold',
      FormatClear: 'mat_outline:format_clear',
      FormatColorFill: 'mat_outline:format_color_fill',
      FormatColorReset: 'mat_outline:format_color_reset',
      FormatColorText: 'mat_outline:format_color_text',
      FormatIndentDecrease: 'mat_outline:format_indent_decrease',
      FormatIndentIncrease: 'mat_outline:format_indent_increase',
      FormatItalic: 'mat_outline:format_italic',
      FormatLineSpacing: 'mat_outline:format_line_spacing',
      FormatListBulleted: 'mat_outline:format_list_bulleted',
      FormatListNumbered: 'mat_outline:format_list_numbered',
      FormatListNumberedRtl: 'mat_outline:format_list_numbered_rtl',
      FormatPaint: 'mat_outline:format_paint',
      FormatQuote: 'mat_outline:format_quote',
      FormatShapes: 'mat_outline:format_shapes',
      FormatSize: 'mat_outline:format_size',
      FormatStrikethrough: 'mat_outline:format_strikethrough',
      FormatTextdirectionLToR: 'mat_outline:format_textdirection_l_to_r',
      FormatTextdirectionRToL: 'mat_outline:format_textdirection_r_to_l',
      FormatUnderlined: 'mat_outline:format_underlined',
      Forum: 'mat_outline:forum',
      Forward: 'mat_outline:forward',
      Forward10: 'mat_outline:forward_10',
      Forward30: 'mat_outline:forward_30',
      Forward5: 'mat_outline:forward_5',
      ForwardToInbox: 'mat_outline:forward_to_inbox',
      Foundation: 'mat_outline:foundation',
      FreeBreakfast: 'mat_outline:free_breakfast',
      FreeCancellation: 'mat_outline:free_cancellation',
      FrontHand: 'mat_outline:front_hand',
      Fullscreen: 'mat_outline:fullscreen',
      FullscreenExit: 'mat_outline:fullscreen_exit',
      Functions: 'mat_outline:functions',
      GMobiledata: 'mat_outline:g_mobiledata',
      GTranslate: 'mat_outline:g_translate',
      Gamepad: 'mat_outline:gamepad',
      Games: 'mat_outline:games',
      Garage: 'mat_outline:garage',
      Gavel: 'mat_outline:gavel',
      GeneratingTokens: 'mat_outline:generating_tokens',
      Gesture: 'mat_outline:gesture',
      GetApp: 'mat_outline:get_app',
      Gif: 'mat_outline:gif',
      Gite: 'mat_outline:gite',
      GolfCourse: 'mat_outline:golf_course',
      GppBad: 'mat_outline:gpp_bad',
      GppGood: 'mat_outline:gpp_good',
      GppMaybe: 'mat_outline:gpp_maybe',
      GpsFixed: 'mat_outline:gps_fixed',
      GpsNotFixed: 'mat_outline:gps_not_fixed',
      GpsOff: 'mat_outline:gps_off',
      Grade: 'mat_outline:grade',
      Gradient: 'mat_outline:gradient',
      Grading: 'mat_outline:grading',
      Grain: 'mat_outline:grain',
      GraphicEq: 'mat_outline:graphic_eq',
      Grass: 'mat_outline:grass',
      Grid3X3: 'mat_outline:grid_3x3',
      Grid4X4: 'mat_outline:grid_4x4',
      GridGoldenratio: 'mat_outline:grid_goldenratio',
      GridOff: 'mat_outline:grid_off',
      GridOn: 'mat_outline:grid_on',
      GridView: 'mat_outline:grid_view',
      Group: 'mat_outline:group',
      GroupAdd: 'mat_outline:group_add',
      GroupOff: 'mat_outline:group_off',
      GroupWork: 'mat_outline:group_work',
      Groups: 'mat_outline:groups',
      HMobiledata: 'mat_outline:h_mobiledata',
      HPlusMobiledata: 'mat_outline:h_plus_mobiledata',
      Hail: 'mat_outline:hail',
      Handyman: 'mat_outline:handyman',
      Hardware: 'mat_outline:hardware',
      Hd: 'mat_outline:hd',
      HdrAuto: 'mat_outline:hdr_auto',
      HdrAutoSelect: 'mat_outline:hdr_auto_select',
      HdrEnhancedSelect: 'mat_outline:hdr_enhanced_select',
      HdrOff: 'mat_outline:hdr_off',
      HdrOffSelect: 'mat_outline:hdr_off_select',
      HdrOn: 'mat_outline:hdr_on',
      HdrOnSelect: 'mat_outline:hdr_on_select',
      HdrPlus: 'mat_outline:hdr_plus',
      HdrStrong: 'mat_outline:hdr_strong',
      HdrWeak: 'mat_outline:hdr_weak',
      Headphones: 'mat_outline:headphones',
      HeadphonesBattery: 'mat_outline:headphones_battery',
      Headset: 'mat_outline:headset',
      HeadsetMic: 'mat_outline:headset_mic',
      HeadsetOff: 'mat_outline:headset_off',
      Healing: 'mat_outline:healing',
      HealthAndSafety: 'mat_outline:health_and_safety',
      Hearing: 'mat_outline:hearing',
      HearingDisabled: 'mat_outline:hearing_disabled',
      Height: 'mat_outline:height',
      Help: 'mat_outline:help',
      HelpCenter: 'mat_outline:help_center',
      HelpOutline: 'mat_outline:help_outline',
      Hevc: 'mat_outline:hevc',
      HideImage: 'mat_outline:hide_image',
      HideSource: 'mat_outline:hide_source',
      HighQuality: 'mat_outline:high_quality',
      Highlight: 'mat_outline:highlight',
      HighlightAlt: 'mat_outline:highlight_alt',
      HighlightOff: 'mat_outline:highlight_off',
      Hiking: 'mat_outline:hiking',
      History: 'mat_outline:history',
      HistoryEdu: 'mat_outline:history_edu',
      HistoryToggleOff: 'mat_outline:history_toggle_off',
      HolidayVillage: 'mat_outline:holiday_village',
      Home: 'mat_outline:home',
      HomeMax: 'mat_outline:home_max',
      HomeMini: 'mat_outline:home_mini',
      HomeRepairService: 'mat_outline:home_repair_service',
      HomeWork: 'mat_outline:home_work',
      HorizontalDistribute: 'mat_outline:horizontal_distribute',
      HorizontalRule: 'mat_outline:horizontal_rule',
      HorizontalSplit: 'mat_outline:horizontal_split',
      HotTub: 'mat_outline:hot_tub',
      Hotel: 'mat_outline:hotel',
      HotelClass: 'mat_outline:hotel_class',
      HourglassBottom: 'mat_outline:hourglass_bottom',
      HourglassDisabled: 'mat_outline:hourglass_disabled',
      HourglassEmpty: 'mat_outline:hourglass_empty',
      HourglassFull: 'mat_outline:hourglass_full',
      HourglassTop: 'mat_outline:hourglass_top',
      House: 'mat_outline:house',
      HouseSiding: 'mat_outline:house_siding',
      Houseboat: 'mat_outline:houseboat',
      HowToReg: 'mat_outline:how_to_reg',
      HowToVote: 'mat_outline:how_to_vote',
      Http: 'mat_outline:http',
      Https: 'mat_outline:https',
      Hvac: 'mat_outline:hvac',
      IceSkating: 'mat_outline:ice_skating',
      Icecream: 'mat_outline:icecream',
      Image: 'mat_outline:image',
      ImageAspectRatio: 'mat_outline:image_aspect_ratio',
      ImageNotSupported: 'mat_outline:image_not_supported',
      ImageSearch: 'mat_outline:image_search',
      ImagesearchRoller: 'mat_outline:imagesearch_roller',
      ImportContacts: 'mat_outline:import_contacts',
      ImportExport: 'mat_outline:import_export',
      ImportantDevices: 'mat_outline:important_devices',
      Inbox: 'mat_outline:inbox',
      IncompleteCircle: 'mat_outline:incomplete_circle',
      IndeterminateCheckBox: 'mat_outline:indeterminate_check_box',
      Info: 'mat_outline:info',
      Input: 'mat_outline:input',
      InsertChart: 'mat_outline:insert_chart',
      InsertChartOutlined: 'mat_outline:insert_chart_outlined',
      InsertComment: 'mat_outline:insert_comment',
      InsertDriveFile: 'mat_outline:insert_drive_file',
      InsertEmoticon: 'mat_outline:insert_emoticon',
      InsertInvitation: 'mat_outline:insert_invitation',
      InsertLink: 'mat_outline:insert_link',
      InsertPhoto: 'mat_outline:insert_photo',
      Insights: 'mat_outline:insights',
      IntegrationInstructions: 'mat_outline:integration_instructions',
      Inventory: 'mat_outline:inventory',
      Inventory2: 'mat_outline:inventory_2',
      InvertColors: 'mat_outline:invert_colors',
      InvertColorsOff: 'mat_outline:invert_colors_off',
      IosShare: 'mat_outline:ios_share',
      Iron: 'mat_outline:iron',
      Iso: 'mat_outline:iso',
      Kayaking: 'mat_outline:kayaking',
      Keyboard: 'mat_outline:keyboard',
      KeyboardAlt: 'mat_outline:keyboard_alt',
      KeyboardArrowDown: 'mat_outline:keyboard_arrow_down',
      KeyboardArrowLeft: 'mat_outline:keyboard_arrow_left',
      KeyboardArrowRight: 'mat_outline:keyboard_arrow_right',
      KeyboardArrowUp: 'mat_outline:keyboard_arrow_up',
      KeyboardBackspace: 'mat_outline:keyboard_backspace',
      KeyboardCapslock: 'mat_outline:keyboard_capslock',
      KeyboardHide: 'mat_outline:keyboard_hide',
      KeyboardReturn: 'mat_outline:keyboard_return',
      KeyboardTab: 'mat_outline:keyboard_tab',
      KeyboardVoice: 'mat_outline:keyboard_voice',
      KingBed: 'mat_outline:king_bed',
      Kitchen: 'mat_outline:kitchen',
      Kitesurfing: 'mat_outline:kitesurfing',
      Label: 'mat_outline:label',
      LabelImportant: 'mat_outline:label_important',
      LabelOff: 'mat_outline:label_off',
      Landscape: 'mat_outline:landscape',
      Language: 'mat_outline:language',
      Laptop: 'mat_outline:laptop',
      LaptopChromebook: 'mat_outline:laptop_chromebook',
      LaptopMac: 'mat_outline:laptop_mac',
      LaptopWindows: 'mat_outline:laptop_windows',
      LastPage: 'mat_outline:last_page',
      Launch: 'mat_outline:launch',
      Layers: 'mat_outline:layers',
      LayersClear: 'mat_outline:layers_clear',
      Leaderboard: 'mat_outline:leaderboard',
      LeakAdd: 'mat_outline:leak_add',
      LeakRemove: 'mat_outline:leak_remove',
      LegendToggle: 'mat_outline:legend_toggle',
      Lens: 'mat_outline:lens',
      LensBlur: 'mat_outline:lens_blur',
      LibraryAdd: 'mat_outline:library_add',
      LibraryAddCheck: 'mat_outline:library_add_check',
      LibraryBooks: 'mat_outline:library_books',
      LibraryMusic: 'mat_outline:library_music',
      Light: 'mat_outline:light',
      LightMode: 'mat_outline:light_mode',
      Lightbulb: 'mat_outline:lightbulb',
      LineStyle: 'mat_outline:line_style',
      LineWeight: 'mat_outline:line_weight',
      LinearScale: 'mat_outline:linear_scale',
      Link: 'mat_outline:link',
      LinkOff: 'mat_outline:link_off',
      LinkedCamera: 'mat_outline:linked_camera',
      Liquor: 'mat_outline:liquor',
      List: 'mat_outline:list',
      ListAlt: 'mat_outline:list_alt',
      LiveHelp: 'mat_outline:live_help',
      LiveTv: 'mat_outline:live_tv',
      Living: 'mat_outline:living',
      LocalActivity: 'mat_outline:local_activity',
      LocalAirport: 'mat_outline:local_airport',
      LocalAtm: 'mat_outline:local_atm',
      LocalBar: 'mat_outline:local_bar',
      LocalCafe: 'mat_outline:local_cafe',
      LocalCarWash: 'mat_outline:local_car_wash',
      LocalConvenienceStore: 'mat_outline:local_convenience_store',
      LocalDining: 'mat_outline:local_dining',
      LocalDrink: 'mat_outline:local_drink',
      LocalFireDepartment: 'mat_outline:local_fire_department',
      LocalFlorist: 'mat_outline:local_florist',
      LocalGasStation: 'mat_outline:local_gas_station',
      LocalGroceryStore: 'mat_outline:local_grocery_store',
      LocalHospital: 'mat_outline:local_hospital',
      LocalHotel: 'mat_outline:local_hotel',
      LocalLaundryService: 'mat_outline:local_laundry_service',
      LocalLibrary: 'mat_outline:local_library',
      LocalMall: 'mat_outline:local_mall',
      LocalMovies: 'mat_outline:local_movies',
      LocalOffer: 'mat_outline:local_offer',
      LocalParking: 'mat_outline:local_parking',
      LocalPharmacy: 'mat_outline:local_pharmacy',
      LocalPhone: 'mat_outline:local_phone',
      LocalPizza: 'mat_outline:local_pizza',
      LocalPlay: 'mat_outline:local_play',
      LocalPolice: 'mat_outline:local_police',
      LocalPostOffice: 'mat_outline:local_post_office',
      LocalPrintshop: 'mat_outline:local_printshop',
      LocalSee: 'mat_outline:local_see',
      LocalShipping: 'mat_outline:local_shipping',
      LocalTaxi: 'mat_outline:local_taxi',
      LocationCity: 'mat_outline:location_city',
      LocationDisabled: 'mat_outline:location_disabled',
      LocationOff: 'mat_outline:location_off',
      LocationOn: 'mat_outline:location_on',
      LocationSearching: 'mat_outline:location_searching',
      Lock: 'mat_outline:lock',
      LockClock: 'mat_outline:lock_clock',
      LockOpen: 'mat_outline:lock_open',
      Login: 'mat_outline:login',
      Logout: 'mat_outline:logout',
      Looks: 'mat_outline:looks',
      Looks3: 'mat_outline:looks_3',
      Looks4: 'mat_outline:looks_4',
      Looks5: 'mat_outline:looks_5',
      Looks6: 'mat_outline:looks_6',
      LooksOne: 'mat_outline:looks_one',
      LooksTwo: 'mat_outline:looks_two',
      Loop: 'mat_outline:loop',
      Loupe: 'mat_outline:loupe',
      LowPriority: 'mat_outline:low_priority',
      Loyalty: 'mat_outline:loyalty',
      LteMobiledata: 'mat_outline:lte_mobiledata',
      LtePlusMobiledata: 'mat_outline:lte_plus_mobiledata',
      Luggage: 'mat_outline:luggage',
      LunchDining: 'mat_outline:lunch_dining',
      Mail: 'mat_outline:mail',
      MailOutline: 'mat_outline:mail_outline',
      Male: 'mat_outline:male',
      ManageAccounts: 'mat_outline:manage_accounts',
      ManageSearch: 'mat_outline:manage_search',
      Map: 'mat_outline:map',
      MapsHomeWork: 'mat_outline:maps_home_work',
      MapsUgc: 'mat_outline:maps_ugc',
      Margin: 'mat_outline:margin',
      MarkAsUnread: 'mat_outline:mark_as_unread',
      MarkChatRead: 'mat_outline:mark_chat_read',
      MarkChatUnread: 'mat_outline:mark_chat_unread',
      MarkEmailRead: 'mat_outline:mark_email_read',
      MarkEmailUnread: 'mat_outline:mark_email_unread',
      Markunread: 'mat_outline:markunread',
      MarkunreadMailbox: 'mat_outline:markunread_mailbox',
      Masks: 'mat_outline:masks',
      Maximize: 'mat_outline:maximize',
      MediaBluetoothOff: 'mat_outline:media_bluetooth_off',
      MediaBluetoothOn: 'mat_outline:media_bluetooth_on',
      Mediation: 'mat_outline:mediation',
      MedicalServices: 'mat_outline:medical_services',
      Medication: 'mat_outline:medication',
      MeetingRoom: 'mat_outline:meeting_room',
      Memory: 'mat_outline:memory',
      Menu: 'mat_outline:menu',
      MenuBook: 'mat_outline:menu_book',
      MenuOpen: 'mat_outline:menu_open',
      MergeType: 'mat_outline:merge_type',
      Message: 'mat_outline:message',
      Mic: 'mat_outline:mic',
      MicExternalOff: 'mat_outline:mic_external_off',
      MicExternalOn: 'mat_outline:mic_external_on',
      MicNone: 'mat_outline:mic_none',
      MicOff: 'mat_outline:mic_off',
      Microwave: 'mat_outline:microwave',
      MilitaryTech: 'mat_outline:military_tech',
      Minimize: 'mat_outline:minimize',
      MiscellaneousServices: 'mat_outline:miscellaneous_services',
      MissedVideoCall: 'mat_outline:missed_video_call',
      Mms: 'mat_outline:mms',
      MobileFriendly: 'mat_outline:mobile_friendly',
      MobileOff: 'mat_outline:mobile_off',
      MobileScreenShare: 'mat_outline:mobile_screen_share',
      MobiledataOff: 'mat_outline:mobiledata_off',
      Mode: 'mat_outline:mode',
      ModeComment: 'mat_outline:mode_comment',
      ModeEdit: 'mat_outline:mode_edit',
      ModeEditOutline: 'mat_outline:mode_edit_outline',
      ModeNight: 'mat_outline:mode_night',
      ModeStandby: 'mat_outline:mode_standby',
      ModelTraining: 'mat_outline:model_training',
      MonetizationOn: 'mat_outline:monetization_on',
      Money: 'mat_outline:money',
      MoneyOff: 'mat_outline:money_off',
      MoneyOffCsred: 'mat_outline:money_off_csred',
      Monitor: 'mat_outline:monitor',
      MonitorWeight: 'mat_outline:monitor_weight',
      MonochromePhotos: 'mat_outline:monochrome_photos',
      Mood: 'mat_outline:mood',
      MoodBad: 'mat_outline:mood_bad',
      Moped: 'mat_outline:moped',
      More: 'mat_outline:more',
      MoreHoriz: 'mat_outline:more_horiz',
      MoreTime: 'mat_outline:more_time',
      MoreVert: 'mat_outline:more_vert',
      MotionPhotosAuto: 'mat_outline:motion_photos_auto',
      MotionPhotosOff: 'mat_outline:motion_photos_off',
      MotionPhotosOn: 'mat_outline:motion_photos_on',
      MotionPhotosPause: 'mat_outline:motion_photos_pause',
      MotionPhotosPaused: 'mat_outline:motion_photos_paused',
      Mouse: 'mat_outline:mouse',
      MoveToInbox: 'mat_outline:move_to_inbox',
      Movie: 'mat_outline:movie',
      MovieCreation: 'mat_outline:movie_creation',
      MovieFilter: 'mat_outline:movie_filter',
      Moving: 'mat_outline:moving',
      Mp: 'mat_outline:mp',
      MultilineChart: 'mat_outline:multiline_chart',
      MultipleStop: 'mat_outline:multiple_stop',
      Museum: 'mat_outline:museum',
      MusicNote: 'mat_outline:music_note',
      MusicOff: 'mat_outline:music_off',
      MusicVideo: 'mat_outline:music_video',
      MyLocation: 'mat_outline:my_location',
      Nat: 'mat_outline:nat',
      Nature: 'mat_outline:nature',
      NaturePeople: 'mat_outline:nature_people',
      NavigateBefore: 'mat_outline:navigate_before',
      NavigateNext: 'mat_outline:navigate_next',
      Navigation: 'mat_outline:navigation',
      NearMe: 'mat_outline:near_me',
      NearMeDisabled: 'mat_outline:near_me_disabled',
      NearbyError: 'mat_outline:nearby_error',
      NearbyOff: 'mat_outline:nearby_off',
      NetworkCell: 'mat_outline:network_cell',
      NetworkCheck: 'mat_outline:network_check',
      NetworkLocked: 'mat_outline:network_locked',
      NetworkWifi: 'mat_outline:network_wifi',
      NewLabel: 'mat_outline:new_label',
      NewReleases: 'mat_outline:new_releases',
      NextPlan: 'mat_outline:next_plan',
      NextWeek: 'mat_outline:next_week',
      Nfc: 'mat_outline:nfc',
      NightShelter: 'mat_outline:night_shelter',
      Nightlife: 'mat_outline:nightlife',
      Nightlight: 'mat_outline:nightlight',
      NightlightRound: 'mat_outline:nightlight_round',
      NightsStay: 'mat_outline:nights_stay',
      NoAccounts: 'mat_outline:no_accounts',
      NoBackpack: 'mat_outline:no_backpack',
      NoCell: 'mat_outline:no_cell',
      NoDrinks: 'mat_outline:no_drinks',
      NoEncryption: 'mat_outline:no_encryption',
      NoEncryptionGmailerrorred: 'mat_outline:no_encryption_gmailerrorred',
      NoFlash: 'mat_outline:no_flash',
      NoFood: 'mat_outline:no_food',
      NoLuggage: 'mat_outline:no_luggage',
      NoMeals: 'mat_outline:no_meals',
      NoMeetingRoom: 'mat_outline:no_meeting_room',
      NoPhotography: 'mat_outline:no_photography',
      NoSim: 'mat_outline:no_sim',
      NoStroller: 'mat_outline:no_stroller',
      NoTransfer: 'mat_outline:no_transfer',
      NordicWalking: 'mat_outline:nordic_walking',
      North: 'mat_outline:north',
      NorthEast: 'mat_outline:north_east',
      NorthWest: 'mat_outline:north_west',
      NotAccessible: 'mat_outline:not_accessible',
      NotInterested: 'mat_outline:not_interested',
      NotListedLocation: 'mat_outline:not_listed_location',
      NotStarted: 'mat_outline:not_started',
      Note: 'mat_outline:note',
      NoteAdd: 'mat_outline:note_add',
      NoteAlt: 'mat_outline:note_alt',
      Notes: 'mat_outline:notes',
      NotificationAdd: 'mat_outline:notification_add',
      NotificationImportant: 'mat_outline:notification_important',
      Notifications: 'mat_outline:notifications',
      NotificationsActive: 'mat_outline:notifications_active',
      NotificationsNone: 'mat_outline:notifications_none',
      NotificationsOff: 'mat_outline:notifications_off',
      NotificationsPaused: 'mat_outline:notifications_paused',
      OfflineBolt: 'mat_outline:offline_bolt',
      OfflinePin: 'mat_outline:offline_pin',
      OfflineShare: 'mat_outline:offline_share',
      OndemandVideo: 'mat_outline:ondemand_video',
      OnlinePrediction: 'mat_outline:online_prediction',
      Opacity: 'mat_outline:opacity',
      OpenInBrowser: 'mat_outline:open_in_browser',
      OpenInFull: 'mat_outline:open_in_full',
      OpenInNew: 'mat_outline:open_in_new',
      OpenInNewOff: 'mat_outline:open_in_new_off',
      OpenWith: 'mat_outline:open_with',
      OtherHouses: 'mat_outline:other_houses',
      Outbound: 'mat_outline:outbound',
      Outbox: 'mat_outline:outbox',
      OutdoorGrill: 'mat_outline:outdoor_grill',
      Outlet: 'mat_outline:outlet',
      OutlinedFlag: 'mat_outline:outlined_flag',
      Padding: 'mat_outline:padding',
      Pages: 'mat_outline:pages',
      Pageview: 'mat_outline:pageview',
      Paid: 'mat_outline:paid',
      Palette: 'mat_outline:palette',
      PanTool: 'mat_outline:pan_tool',
      Panorama: 'mat_outline:panorama',
      PanoramaFishEye: 'mat_outline:panorama_fish_eye',
      PanoramaHorizontal: 'mat_outline:panorama_horizontal',
      PanoramaHorizontalSelect: 'mat_outline:panorama_horizontal_select',
      PanoramaPhotosphere: 'mat_outline:panorama_photosphere',
      PanoramaPhotosphereSelect: 'mat_outline:panorama_photosphere_select',
      PanoramaVertical: 'mat_outline:panorama_vertical',
      PanoramaVerticalSelect: 'mat_outline:panorama_vertical_select',
      PanoramaWideAngle: 'mat_outline:panorama_wide_angle',
      PanoramaWideAngleSelect: 'mat_outline:panorama_wide_angle_select',
      Paragliding: 'mat_outline:paragliding',
      Park: 'mat_outline:park',
      PartyMode: 'mat_outline:party_mode',
      Password: 'mat_outline:password',
      Pattern: 'mat_outline:pattern',
      Pause: 'mat_outline:pause',
      PauseCircle: 'mat_outline:pause_circle',
      PauseCircleFilled: 'mat_outline:pause_circle_filled',
      PauseCircleOutline: 'mat_outline:pause_circle_outline',
      PausePresentation: 'mat_outline:pause_presentation',
      Payment: 'mat_outline:payment',
      Payments: 'mat_outline:payments',
      PedalBike: 'mat_outline:pedal_bike',
      Pending: 'mat_outline:pending',
      PendingActions: 'mat_outline:pending_actions',
      People: 'mat_outline:people',
      PeopleAlt: 'mat_outline:people_alt',
      PeopleOutline: 'mat_outline:people_outline',
      PermCameraMic: 'mat_outline:perm_camera_mic',
      PermContactCalendar: 'mat_outline:perm_contact_calendar',
      PermDataSetting: 'mat_outline:perm_data_setting',
      PermDeviceInformation: 'mat_outline:perm_device_information',
      PermIdentity: 'mat_outline:perm_identity',
      PermMedia: 'mat_outline:perm_media',
      PermPhoneMsg: 'mat_outline:perm_phone_msg',
      PermScanWifi: 'mat_outline:perm_scan_wifi',
      Person: 'mat_outline:person',
      PersonAdd: 'mat_outline:person_add',
      PersonAddAlt: 'mat_outline:person_add_alt',
      PersonAddAlt1: 'mat_outline:person_add_alt_1',
      PersonAddDisabled: 'mat_outline:person_add_disabled',
      PersonOff: 'mat_outline:person_off',
      PersonOutline: 'mat_outline:person_outline',
      PersonPin: 'mat_outline:person_pin',
      PersonPinCircle: 'mat_outline:person_pin_circle',
      PersonRemove: 'mat_outline:person_remove',
      PersonRemoveAlt1: 'mat_outline:person_remove_alt_1',
      PersonSearch: 'mat_outline:person_search',
      PersonalInjury: 'mat_outline:personal_injury',
      PersonalVideo: 'mat_outline:personal_video',
      PestControl: 'mat_outline:pest_control',
      PestControlRodent: 'mat_outline:pest_control_rodent',
      Pets: 'mat_outline:pets',
      Phone: 'mat_outline:phone',
      PhoneAndroid: 'mat_outline:phone_android',
      PhoneBluetoothSpeaker: 'mat_outline:phone_bluetooth_speaker',
      PhoneCallback: 'mat_outline:phone_callback',
      PhoneDisabled: 'mat_outline:phone_disabled',
      PhoneEnabled: 'mat_outline:phone_enabled',
      PhoneForwarded: 'mat_outline:phone_forwarded',
      PhoneInTalk: 'mat_outline:phone_in_talk',
      PhoneIphone: 'mat_outline:phone_iphone',
      PhoneLocked: 'mat_outline:phone_locked',
      PhoneMissed: 'mat_outline:phone_missed',
      PhonePaused: 'mat_outline:phone_paused',
      Phonelink: 'mat_outline:phonelink',
      PhonelinkErase: 'mat_outline:phonelink_erase',
      PhonelinkLock: 'mat_outline:phonelink_lock',
      PhonelinkOff: 'mat_outline:phonelink_off',
      PhonelinkRing: 'mat_outline:phonelink_ring',
      PhonelinkSetup: 'mat_outline:phonelink_setup',
      Photo: 'mat_outline:photo',
      PhotoAlbum: 'mat_outline:photo_album',
      PhotoCamera: 'mat_outline:photo_camera',
      PhotoCameraBack: 'mat_outline:photo_camera_back',
      PhotoCameraFront: 'mat_outline:photo_camera_front',
      PhotoFilter: 'mat_outline:photo_filter',
      PhotoLibrary: 'mat_outline:photo_library',
      PhotoSizeSelectActual: 'mat_outline:photo_size_select_actual',
      PhotoSizeSelectLarge: 'mat_outline:photo_size_select_large',
      PhotoSizeSelectSmall: 'mat_outline:photo_size_select_small',
      Piano: 'mat_outline:piano',
      PianoOff: 'mat_outline:piano_off',
      PictureAsPdf: 'mat_outline:picture_as_pdf',
      PictureInPicture: 'mat_outline:picture_in_picture',
      PictureInPictureAlt: 'mat_outline:picture_in_picture_alt',
      PieChart: 'mat_outline:pie_chart',
      PieChartOutline: 'mat_outline:pie_chart_outline',
      Pin: 'mat_outline:pin',
      PinDrop: 'mat_outline:pin_drop',
      PinEnd: 'mat_outline:pin_end',
      PinInvoke: 'mat_outline:pin_invoke',
      PivotTableChart: 'mat_outline:pivot_table_chart',
      Place: 'mat_outline:place',
      Plagiarism: 'mat_outline:plagiarism',
      PlayArrow: 'mat_outline:play_arrow',
      PlayCircle: 'mat_outline:play_circle',
      PlayCircleFilled: 'mat_outline:play_circle_filled',
      PlayCircleOutline: 'mat_outline:play_circle_outline',
      PlayDisabled: 'mat_outline:play_disabled',
      PlayForWork: 'mat_outline:play_for_work',
      PlayLesson: 'mat_outline:play_lesson',
      PlaylistAdd: 'mat_outline:playlist_add',
      PlaylistAddCheck: 'mat_outline:playlist_add_check',
      PlaylistPlay: 'mat_outline:playlist_play',
      Plumbing: 'mat_outline:plumbing',
      PlusOne: 'mat_outline:plus_one',
      Podcasts: 'mat_outline:podcasts',
      PointOfSale: 'mat_outline:point_of_sale',
      Policy: 'mat_outline:policy',
      Poll: 'mat_outline:poll',
      Polymer: 'mat_outline:polymer',
      Pool: 'mat_outline:pool',
      PortableWifiOff: 'mat_outline:portable_wifi_off',
      Portrait: 'mat_outline:portrait',
      PostAdd: 'mat_outline:post_add',
      Power: 'mat_outline:power',
      PowerInput: 'mat_outline:power_input',
      PowerOff: 'mat_outline:power_off',
      PowerSettingsNew: 'mat_outline:power_settings_new',
      PrecisionManufacturing: 'mat_outline:precision_manufacturing',
      PregnantWoman: 'mat_outline:pregnant_woman',
      PresentToAll: 'mat_outline:present_to_all',
      Preview: 'mat_outline:preview',
      PriceChange: 'mat_outline:price_change',
      PriceCheck: 'mat_outline:price_check',
      Print: 'mat_outline:print',
      PrintDisabled: 'mat_outline:print_disabled',
      PriorityHigh: 'mat_outline:priority_high',
      PrivacyTip: 'mat_outline:privacy_tip',
      PrivateConnectivity: 'mat_outline:private_connectivity',
      ProductionQuantityLimits: 'mat_outline:production_quantity_limits',
      Psychology: 'mat_outline:psychology',
      Public: 'mat_outline:public',
      PublicOff: 'mat_outline:public_off',
      Publish: 'mat_outline:publish',
      PublishedWithChanges: 'mat_outline:published_with_changes',
      PushPin: 'mat_outline:push_pin',
      QrCode: 'mat_outline:qr_code',
      QrCode2: 'mat_outline:qr_code_2',
      QrCodeScanner: 'mat_outline:qr_code_scanner',
      QueryBuilder: 'mat_outline:query_builder',
      QueryStats: 'mat_outline:query_stats',
      QuestionAnswer: 'mat_outline:question_answer',
      Queue: 'mat_outline:queue',
      QueueMusic: 'mat_outline:queue_music',
      QueuePlayNext: 'mat_outline:queue_play_next',
      Quickreply: 'mat_outline:quickreply',
      Quiz: 'mat_outline:quiz',
      RMobiledata: 'mat_outline:r_mobiledata',
      Radar: 'mat_outline:radar',
      Radio: 'mat_outline:radio',
      RadioButtonChecked: 'mat_outline:radio_button_checked',
      RadioButtonUnchecked: 'mat_outline:radio_button_unchecked',
      RailwayAlert: 'mat_outline:railway_alert',
      RamenDining: 'mat_outline:ramen_dining',
      RateReview: 'mat_outline:rate_review',
      RawOff: 'mat_outline:raw_off',
      RawOn: 'mat_outline:raw_on',
      ReadMore: 'mat_outline:read_more',
      RealEstateAgent: 'mat_outline:real_estate_agent',
      Receipt: 'mat_outline:receipt',
      ReceiptLong: 'mat_outline:receipt_long',
      RecentActors: 'mat_outline:recent_actors',
      Recommend: 'mat_outline:recommend',
      RecordVoiceOver: 'mat_outline:record_voice_over',
      Recycling: 'mat_outline:recycling',
      Redeem: 'mat_outline:redeem',
      Redo: 'mat_outline:redo',
      ReduceCapacity: 'mat_outline:reduce_capacity',
      Refresh: 'mat_outline:refresh',
      RememberMe: 'mat_outline:remember_me',
      Remove: 'mat_outline:remove',
      RemoveCircle: 'mat_outline:remove_circle',
      RemoveCircleOutline: 'mat_outline:remove_circle_outline',
      RemoveDone: 'mat_outline:remove_done',
      RemoveFromQueue: 'mat_outline:remove_from_queue',
      RemoveModerator: 'mat_outline:remove_moderator',
      RemoveRedEye: 'mat_outline:remove_red_eye',
      RemoveShoppingCart: 'mat_outline:remove_shopping_cart',
      Reorder: 'mat_outline:reorder',
      Repeat: 'mat_outline:repeat',
      RepeatOn: 'mat_outline:repeat_on',
      RepeatOne: 'mat_outline:repeat_one',
      RepeatOneOn: 'mat_outline:repeat_one_on',
      Replay: 'mat_outline:replay',
      Replay10: 'mat_outline:replay_10',
      Replay30: 'mat_outline:replay_30',
      Replay5: 'mat_outline:replay_5',
      ReplayCircleFilled: 'mat_outline:replay_circle_filled',
      Reply: 'mat_outline:reply',
      ReplyAll: 'mat_outline:reply_all',
      Report: 'mat_outline:report',
      ReportGmailerrorred: 'mat_outline:report_gmailerrorred',
      ReportOff: 'mat_outline:report_off',
      ReportProblem: 'mat_outline:report_problem',
      RequestPage: 'mat_outline:request_page',
      RequestQuote: 'mat_outline:request_quote',
      ResetTv: 'mat_outline:reset_tv',
      RestartAlt: 'mat_outline:restart_alt',
      Restaurant: 'mat_outline:restaurant',
      RestaurantMenu: 'mat_outline:restaurant_menu',
      Restore: 'mat_outline:restore',
      RestoreFromTrash: 'mat_outline:restore_from_trash',
      RestorePage: 'mat_outline:restore_page',
      Reviews: 'mat_outline:reviews',
      RiceBowl: 'mat_outline:rice_bowl',
      RingVolume: 'mat_outline:ring_volume',
      Roofing: 'mat_outline:roofing',
      Room: 'mat_outline:room',
      RoomPreferences: 'mat_outline:room_preferences',
      RoomService: 'mat_outline:room_service',
      Rotate90DegreesCcw: 'mat_outline:rotate_90_degrees_ccw',
      RotateLeft: 'mat_outline:rotate_left',
      RotateRight: 'mat_outline:rotate_right',
      RoundedCorner: 'mat_outline:rounded_corner',
      Router: 'mat_outline:router',
      Rowing: 'mat_outline:rowing',
      RssFeed: 'mat_outline:rss_feed',
      Rsvp: 'mat_outline:rsvp',
      Rtt: 'mat_outline:rtt',
      Rule: 'mat_outline:rule',
      RuleFolder: 'mat_outline:rule_folder',
      RunCircle: 'mat_outline:run_circle',
      RunningWithErrors: 'mat_outline:running_with_errors',
      RvHookup: 'mat_outline:rv_hookup',
      SafetyDivider: 'mat_outline:safety_divider',
      Sailing: 'mat_outline:sailing',
      Sanitizer: 'mat_outline:sanitizer',
      Satellite: 'mat_outline:satellite',
      Save: 'mat_outline:save',
      SaveAlt: 'mat_outline:save_alt',
      SavedSearch: 'mat_outline:saved_search',
      Savings: 'mat_outline:savings',
      Scanner: 'mat_outline:scanner',
      ScatterPlot: 'mat_outline:scatter_plot',
      Schedule: 'mat_outline:schedule',
      ScheduleSend: 'mat_outline:schedule_send',
      Schema: 'mat_outline:schema',
      School: 'mat_outline:school',
      Science: 'mat_outline:science',
      Score: 'mat_outline:score',
      ScreenLockLandscape: 'mat_outline:screen_lock_landscape',
      ScreenLockPortrait: 'mat_outline:screen_lock_portrait',
      ScreenLockRotation: 'mat_outline:screen_lock_rotation',
      ScreenRotation: 'mat_outline:screen_rotation',
      ScreenSearchDesktop: 'mat_outline:screen_search_desktop',
      ScreenShare: 'mat_outline:screen_share',
      Screenshot: 'mat_outline:screenshot',
      Sd: 'mat_outline:sd',
      SdCard: 'mat_outline:sd_card',
      SdCardAlert: 'mat_outline:sd_card_alert',
      SdStorage: 'mat_outline:sd_storage',
      Search: 'mat_outline:search',
      SearchOff: 'mat_outline:search_off',
      Security: 'mat_outline:security',
      SecurityUpdate: 'mat_outline:security_update',
      SecurityUpdateGood: 'mat_outline:security_update_good',
      SecurityUpdateWarning: 'mat_outline:security_update_warning',
      Segment: 'mat_outline:segment',
      SelectAll: 'mat_outline:select_all',
      SelfImprovement: 'mat_outline:self_improvement',
      Sell: 'mat_outline:sell',
      Send: 'mat_outline:send',
      SendAndArchive: 'mat_outline:send_and_archive',
      SendToMobile: 'mat_outline:send_to_mobile',
      SensorDoor: 'mat_outline:sensor_door',
      SensorWindow: 'mat_outline:sensor_window',
      Sensors: 'mat_outline:sensors',
      SensorsOff: 'mat_outline:sensors_off',
      SentimentDissatisfied: 'mat_outline:sentiment_dissatisfied',
      SentimentNeutral: 'mat_outline:sentiment_neutral',
      SentimentSatisfied: 'mat_outline:sentiment_satisfied',
      SentimentSatisfiedAlt: 'mat_outline:sentiment_satisfied_alt',
      SentimentVeryDissatisfied: 'mat_outline:sentiment_very_dissatisfied',
      SentimentVerySatisfied: 'mat_outline:sentiment_very_satisfied',
      SetMeal: 'mat_outline:set_meal',
      Settings: 'mat_outline:settings',
      SettingsAccessibility: 'mat_outline:settings_accessibility',
      SettingsApplications: 'mat_outline:settings_applications',
      SettingsBackupRestore: 'mat_outline:settings_backup_restore',
      SettingsBluetooth: 'mat_outline:settings_bluetooth',
      SettingsBrightness: 'mat_outline:settings_brightness',
      SettingsCell: 'mat_outline:settings_cell',
      SettingsEthernet: 'mat_outline:settings_ethernet',
      SettingsInputAntenna: 'mat_outline:settings_input_antenna',
      SettingsInputComponent: 'mat_outline:settings_input_component',
      SettingsInputComposite: 'mat_outline:settings_input_composite',
      SettingsInputHdmi: 'mat_outline:settings_input_hdmi',
      SettingsInputSvideo: 'mat_outline:settings_input_svideo',
      SettingsOverscan: 'mat_outline:settings_overscan',
      SettingsPhone: 'mat_outline:settings_phone',
      SettingsPower: 'mat_outline:settings_power',
      SettingsRemote: 'mat_outline:settings_remote',
      SettingsSuggest: 'mat_outline:settings_suggest',
      SettingsSystemDaydream: 'mat_outline:settings_system_daydream',
      SettingsVoice: 'mat_outline:settings_voice',
      Share: 'mat_outline:share',
      ShareLocation: 'mat_outline:share_location',
      Shield: 'mat_outline:shield',
      Shop: 'mat_outline:shop',
      Shop2: 'mat_outline:shop_2',
      ShopTwo: 'mat_outline:shop_two',
      ShoppingBag: 'mat_outline:shopping_bag',
      ShoppingBasket: 'mat_outline:shopping_basket',
      ShoppingCart: 'mat_outline:shopping_cart',
      ShortText: 'mat_outline:short_text',
      Shortcut: 'mat_outline:shortcut',
      ShowChart: 'mat_outline:show_chart',
      Shower: 'mat_outline:shower',
      Shuffle: 'mat_outline:shuffle',
      ShuffleOn: 'mat_outline:shuffle_on',
      ShutterSpeed: 'mat_outline:shutter_speed',
      Sick: 'mat_outline:sick',
      SignalCellular0Bar: 'mat_outline:signal_cellular_0_bar',
      SignalCellular4Bar: 'mat_outline:signal_cellular_4_bar',
      SignalCellularAlt: 'mat_outline:signal_cellular_alt',
      SignalCellularConnectedNoInternet0Bar:
        'mat_outline:signal_cellular_connected_no_internet_0_bar',
      SignalCellularConnectedNoInternet4Bar:
        'mat_outline:signal_cellular_connected_no_internet_4_bar',
      SignalCellularNoSim: 'mat_outline:signal_cellular_no_sim',
      SignalCellularNodata: 'mat_outline:signal_cellular_nodata',
      SignalCellularNull: 'mat_outline:signal_cellular_null',
      SignalCellularOff: 'mat_outline:signal_cellular_off',
      SignalWifi0Bar: 'mat_outline:signal_wifi_0_bar',
      SignalWifi4Bar: 'mat_outline:signal_wifi_4_bar',
      SignalWifi4BarLock: 'mat_outline:signal_wifi_4_bar_lock',
      SignalWifiBad: 'mat_outline:signal_wifi_bad',
      SignalWifiConnectedNoInternet4: 'mat_outline:signal_wifi_connected_no_internet_4',
      SignalWifiOff: 'mat_outline:signal_wifi_off',
      SignalWifiStatusbar4Bar: 'mat_outline:signal_wifi_statusbar_4_bar',
      SignalWifiStatusbarConnectedNoInternet4:
        'mat_outline:signal_wifi_statusbar_connected_no_internet_4',
      SignalWifiStatusbarNull: 'mat_outline:signal_wifi_statusbar_null',
      SimCard: 'mat_outline:sim_card',
      SimCardAlert: 'mat_outline:sim_card_alert',
      SimCardDownload: 'mat_outline:sim_card_download',
      SingleBed: 'mat_outline:single_bed',
      Sip: 'mat_outline:sip',
      Skateboarding: 'mat_outline:skateboarding',
      SkipNext: 'mat_outline:skip_next',
      SkipPrevious: 'mat_outline:skip_previous',
      Sledding: 'mat_outline:sledding',
      Slideshow: 'mat_outline:slideshow',
      SlowMotionVideo: 'mat_outline:slow_motion_video',
      SmartButton: 'mat_outline:smart_button',
      SmartDisplay: 'mat_outline:smart_display',
      SmartScreen: 'mat_outline:smart_screen',
      SmartToy: 'mat_outline:smart_toy',
      Smartphone: 'mat_outline:smartphone',
      SmokeFree: 'mat_outline:smoke_free',
      SmokingRooms: 'mat_outline:smoking_rooms',
      Sms: 'mat_outline:sms',
      SmsFailed: 'mat_outline:sms_failed',
      SnippetFolder: 'mat_outline:snippet_folder',
      Snooze: 'mat_outline:snooze',
      Snowboarding: 'mat_outline:snowboarding',
      Snowmobile: 'mat_outline:snowmobile',
      Snowshoeing: 'mat_outline:snowshoeing',
      Soap: 'mat_outline:soap',
      SocialDistance: 'mat_outline:social_distance',
      Sort: 'mat_outline:sort',
      SortByAlpha: 'mat_outline:sort_by_alpha',
      Source: 'mat_outline:source',
      South: 'mat_outline:south',
      SouthEast: 'mat_outline:south_east',
      SouthWest: 'mat_outline:south_west',
      Spa: 'mat_outline:spa',
      SpaceBar: 'mat_outline:space_bar',
      SpaceDashboard: 'mat_outline:space_dashboard',
      Speaker: 'mat_outline:speaker',
      SpeakerGroup: 'mat_outline:speaker_group',
      SpeakerNotes: 'mat_outline:speaker_notes',
      SpeakerNotesOff: 'mat_outline:speaker_notes_off',
      SpeakerPhone: 'mat_outline:speaker_phone',
      Speed: 'mat_outline:speed',
      Spellcheck: 'mat_outline:spellcheck',
      Splitscreen: 'mat_outline:splitscreen',
      Sports: 'mat_outline:sports',
      SportsBar: 'mat_outline:sports_bar',
      SportsBaseball: 'mat_outline:sports_baseball',
      SportsBasketball: 'mat_outline:sports_basketball',
      SportsCricket: 'mat_outline:sports_cricket',
      SportsEsports: 'mat_outline:sports_esports',
      SportsFootball: 'mat_outline:sports_football',
      SportsGolf: 'mat_outline:sports_golf',
      SportsHandball: 'mat_outline:sports_handball',
      SportsHockey: 'mat_outline:sports_hockey',
      SportsKabaddi: 'mat_outline:sports_kabaddi',
      SportsMma: 'mat_outline:sports_mma',
      SportsMotorsports: 'mat_outline:sports_motorsports',
      SportsRugby: 'mat_outline:sports_rugby',
      SportsScore: 'mat_outline:sports_score',
      SportsSoccer: 'mat_outline:sports_soccer',
      SportsTennis: 'mat_outline:sports_tennis',
      SportsVolleyball: 'mat_outline:sports_volleyball',
      SquareFoot: 'mat_outline:square_foot',
      StackedBarChart: 'mat_outline:stacked_bar_chart',
      StackedLineChart: 'mat_outline:stacked_line_chart',
      Stairs: 'mat_outline:stairs',
      Star: 'mat_outline:star',
      StarBorder: 'mat_outline:star_border',
      StarBorderPurple500: 'mat_outline:star_border_purple500',
      StarHalf: 'mat_outline:star_half',
      StarOutline: 'mat_outline:star_outline',
      StarPurple500: 'mat_outline:star_purple500',
      StarRate: 'mat_outline:star_rate',
      Stars: 'mat_outline:stars',
      StayCurrentLandscape: 'mat_outline:stay_current_landscape',
      StayCurrentPortrait: 'mat_outline:stay_current_portrait',
      StayPrimaryLandscape: 'mat_outline:stay_primary_landscape',
      StayPrimaryPortrait: 'mat_outline:stay_primary_portrait',
      StickyNote2: 'mat_outline:sticky_note_2',
      Stop: 'mat_outline:stop',
      StopCircle: 'mat_outline:stop_circle',
      StopScreenShare: 'mat_outline:stop_screen_share',
      Storage: 'mat_outline:storage',
      Store: 'mat_outline:store',
      StoreMallDirectory: 'mat_outline:store_mall_directory',
      Storefront: 'mat_outline:storefront',
      Storm: 'mat_outline:storm',
      Straighten: 'mat_outline:straighten',
      Stream: 'mat_outline:stream',
      Streetview: 'mat_outline:streetview',
      StrikethroughS: 'mat_outline:strikethrough_s',
      Stroller: 'mat_outline:stroller',
      Style: 'mat_outline:style',
      SubdirectoryArrowLeft: 'mat_outline:subdirectory_arrow_left',
      SubdirectoryArrowRight: 'mat_outline:subdirectory_arrow_right',
      Subject: 'mat_outline:subject',
      Subscript: 'mat_outline:subscript',
      Subscriptions: 'mat_outline:subscriptions',
      Subtitles: 'mat_outline:subtitles',
      SubtitlesOff: 'mat_outline:subtitles_off',
      Subway: 'mat_outline:subway',
      Summarize: 'mat_outline:summarize',
      Superscript: 'mat_outline:superscript',
      SupervisedUserCircle: 'mat_outline:supervised_user_circle',
      SupervisorAccount: 'mat_outline:supervisor_account',
      Support: 'mat_outline:support',
      SupportAgent: 'mat_outline:support_agent',
      Surfing: 'mat_outline:surfing',
      SurroundSound: 'mat_outline:surround_sound',
      SwapCalls: 'mat_outline:swap_calls',
      SwapHoriz: 'mat_outline:swap_horiz',
      SwapHorizontalCircle: 'mat_outline:swap_horizontal_circle',
      SwapVert: 'mat_outline:swap_vert',
      SwapVerticalCircle: 'mat_outline:swap_vertical_circle',
      Swipe: 'mat_outline:swipe',
      SwitchAccount: 'mat_outline:switch_account',
      SwitchCamera: 'mat_outline:switch_camera',
      SwitchLeft: 'mat_outline:switch_left',
      SwitchRight: 'mat_outline:switch_right',
      SwitchVideo: 'mat_outline:switch_video',
      Sync: 'mat_outline:sync',
      SyncAlt: 'mat_outline:sync_alt',
      SyncDisabled: 'mat_outline:sync_disabled',
      SyncProblem: 'mat_outline:sync_problem',
      SystemSecurityUpdate: 'mat_outline:system_security_update',
      SystemSecurityUpdateGood: 'mat_outline:system_security_update_good',
      SystemSecurityUpdateWarning: 'mat_outline:system_security_update_warning',
      SystemUpdate: 'mat_outline:system_update',
      SystemUpdateAlt: 'mat_outline:system_update_alt',
      Tab: 'mat_outline:tab',
      TabUnselected: 'mat_outline:tab_unselected',
      TableChart: 'mat_outline:table_chart',
      TableRows: 'mat_outline:table_rows',
      TableView: 'mat_outline:table_view',
      Tablet: 'mat_outline:tablet',
      TabletAndroid: 'mat_outline:tablet_android',
      TabletMac: 'mat_outline:tablet_mac',
      Tag: 'mat_outline:tag',
      TagFaces: 'mat_outline:tag_faces',
      TakeoutDining: 'mat_outline:takeout_dining',
      TapAndPlay: 'mat_outline:tap_and_play',
      Tapas: 'mat_outline:tapas',
      Task: 'mat_outline:task',
      TaskAlt: 'mat_outline:task_alt',
      TaxiAlert: 'mat_outline:taxi_alert',
      Terrain: 'mat_outline:terrain',
      TextFields: 'mat_outline:text_fields',
      TextFormat: 'mat_outline:text_format',
      TextRotateUp: 'mat_outline:text_rotate_up',
      TextRotateVertical: 'mat_outline:text_rotate_vertical',
      TextRotationAngledown: 'mat_outline:text_rotation_angledown',
      TextRotationAngleup: 'mat_outline:text_rotation_angleup',
      TextRotationDown: 'mat_outline:text_rotation_down',
      TextRotationNone: 'mat_outline:text_rotation_none',
      TextSnippet: 'mat_outline:text_snippet',
      Textsms: 'mat_outline:textsms',
      Texture: 'mat_outline:texture',
      TheaterComedy: 'mat_outline:theater_comedy',
      Theaters: 'mat_outline:theaters',
      Thermostat: 'mat_outline:thermostat',
      ThermostatAuto: 'mat_outline:thermostat_auto',
      ThumbDown: 'mat_outline:thumb_down',
      ThumbDownAlt: 'mat_outline:thumb_down_alt',
      ThumbDownOffAlt: 'mat_outline:thumb_down_off_alt',
      ThumbUp: 'mat_outline:thumb_up',
      ThumbUpAlt: 'mat_outline:thumb_up_alt',
      ThumbUpOffAlt: 'mat_outline:thumb_up_off_alt',
      ThumbsUpDown: 'mat_outline:thumbs_up_down',
      TimeToLeave: 'mat_outline:time_to_leave',
      Timelapse: 'mat_outline:timelapse',
      Timeline: 'mat_outline:timeline',
      Timer: 'mat_outline:timer',
      Timer10: 'mat_outline:timer_10',
      Timer10Select: 'mat_outline:timer_10_select',
      Timer3: 'mat_outline:timer_3',
      Timer3Select: 'mat_outline:timer_3_select',
      TimerOff: 'mat_outline:timer_off',
      TipsAndUpdates: 'mat_outline:tips_and_updates',
      Title: 'mat_outline:title',
      Toc: 'mat_outline:toc',
      Today: 'mat_outline:today',
      ToggleOff: 'mat_outline:toggle_off',
      ToggleOn: 'mat_outline:toggle_on',
      Toll: 'mat_outline:toll',
      Tonality: 'mat_outline:tonality',
      Topic: 'mat_outline:topic',
      TouchApp: 'mat_outline:touch_app',
      Tour: 'mat_outline:tour',
      Toys: 'mat_outline:toys',
      TrackChanges: 'mat_outline:track_changes',
      Traffic: 'mat_outline:traffic',
      Train: 'mat_outline:train',
      Tram: 'mat_outline:tram',
      TransferWithinAStation: 'mat_outline:transfer_within_a_station',
      Transform: 'mat_outline:transform',
      Transgender: 'mat_outline:transgender',
      TransitEnterexit: 'mat_outline:transit_enterexit',
      Translate: 'mat_outline:translate',
      TravelExplore: 'mat_outline:travel_explore',
      TrendingDown: 'mat_outline:trending_down',
      TrendingFlat: 'mat_outline:trending_flat',
      TrendingUp: 'mat_outline:trending_up',
      TripOrigin: 'mat_outline:trip_origin',
      Try: 'mat_outline:try',
      Tty: 'mat_outline:tty',
      Tune: 'mat_outline:tune',
      Tungsten: 'mat_outline:tungsten',
      TurnedIn: 'mat_outline:turned_in',
      TurnedInNot: 'mat_outline:turned_in_not',
      Tv: 'mat_outline:tv',
      TvOff: 'mat_outline:tv_off',
      TwoWheeler: 'mat_outline:two_wheeler',
      Umbrella: 'mat_outline:umbrella',
      Unarchive: 'mat_outline:unarchive',
      Undo: 'mat_outline:undo',
      UnfoldLess: 'mat_outline:unfold_less',
      UnfoldMore: 'mat_outline:unfold_more',
      Unpublished: 'mat_outline:unpublished',
      Unsubscribe: 'mat_outline:unsubscribe',
      Upcoming: 'mat_outline:upcoming',
      Update: 'mat_outline:update',
      UpdateDisabled: 'mat_outline:update_disabled',
      Upgrade: 'mat_outline:upgrade',
      Upload: 'mat_outline:upload',
      UploadFile: 'mat_outline:upload_file',
      Usb: 'mat_outline:usb',
      UsbOff: 'mat_outline:usb_off',
      Verified: 'mat_outline:verified',
      VerifiedUser: 'mat_outline:verified_user',
      VerticalAlignBottom: 'mat_outline:vertical_align_bottom',
      VerticalAlignCenter: 'mat_outline:vertical_align_center',
      VerticalAlignTop: 'mat_outline:vertical_align_top',
      VerticalDistribute: 'mat_outline:vertical_distribute',
      VerticalSplit: 'mat_outline:vertical_split',
      Vibration: 'mat_outline:vibration',
      VideoCall: 'mat_outline:video_call',
      VideoCameraBack: 'mat_outline:video_camera_back',
      VideoCameraFront: 'mat_outline:video_camera_front',
      VideoLabel: 'mat_outline:video_label',
      VideoLibrary: 'mat_outline:video_library',
      VideoSettings: 'mat_outline:video_settings',
      VideoStable: 'mat_outline:video_stable',
      Videocam: 'mat_outline:videocam',
      VideocamOff: 'mat_outline:videocam_off',
      VideogameAsset: 'mat_outline:videogame_asset',
      VideogameAssetOff: 'mat_outline:videogame_asset_off',
      ViewAgenda: 'mat_outline:view_agenda',
      ViewArray: 'mat_outline:view_array',
      ViewCarousel: 'mat_outline:view_carousel',
      ViewColumn: 'mat_outline:view_column',
      ViewComfy: 'mat_outline:view_comfy',
      ViewCompact: 'mat_outline:view_compact',
      ViewDay: 'mat_outline:view_day',
      ViewHeadline: 'mat_outline:view_headline',
      ViewInAr: 'mat_outline:view_in_ar',
      ViewList: 'mat_outline:view_list',
      ViewModule: 'mat_outline:view_module',
      ViewQuilt: 'mat_outline:view_quilt',
      ViewSidebar: 'mat_outline:view_sidebar',
      ViewStream: 'mat_outline:view_stream',
      ViewWeek: 'mat_outline:view_week',
      Vignette: 'mat_outline:vignette',
      Villa: 'mat_outline:villa',
      Visibility: 'mat_outline:visibility',
      VisibilityOff: 'mat_outline:visibility_off',
      VoiceChat: 'mat_outline:voice_chat',
      VoiceOverOff: 'mat_outline:voice_over_off',
      Voicemail: 'mat_outline:voicemail',
      VolumeDown: 'mat_outline:volume_down',
      VolumeMute: 'mat_outline:volume_mute',
      VolumeOff: 'mat_outline:volume_off',
      VolumeUp: 'mat_outline:volume_up',
      VolunteerActivism: 'mat_outline:volunteer_activism',
      VpnKey: 'mat_outline:vpn_key',
      VpnLock: 'mat_outline:vpn_lock',
      Vrpano: 'mat_outline:vrpano',
      Wallpaper: 'mat_outline:wallpaper',
      Warning: 'mat_outline:warning',
      WarningAmber: 'mat_outline:warning_amber',
      Wash: 'mat_outline:wash',
      Watch: 'mat_outline:watch',
      WatchLater: 'mat_outline:watch_later',
      Water: 'mat_outline:water',
      WaterDamage: 'mat_outline:water_damage',
      WaterDrop: 'mat_outline:water_drop',
      WaterfallChart: 'mat_outline:waterfall_chart',
      Waves: 'mat_outline:waves',
      WavingHand: 'mat_outline:waving_hand',
      WbAuto: 'mat_outline:wb_auto',
      WbCloudy: 'mat_outline:wb_cloudy',
      WbIncandescent: 'mat_outline:wb_incandescent',
      WbIridescent: 'mat_outline:wb_iridescent',
      WbShade: 'mat_outline:wb_shade',
      WbSunny: 'mat_outline:wb_sunny',
      WbTwilight: 'mat_outline:wb_twilight',
      Wc: 'mat_outline:wc',
      Web: 'mat_outline:web',
      WebAsset: 'mat_outline:web_asset',
      WebAssetOff: 'mat_outline:web_asset_off',
      Weekend: 'mat_outline:weekend',
      West: 'mat_outline:west',
      Whatshot: 'mat_outline:whatshot',
      WheelchairPickup: 'mat_outline:wheelchair_pickup',
      WhereToVote: 'mat_outline:where_to_vote',
      Widgets: 'mat_outline:widgets',
      Wifi: 'mat_outline:wifi',
      WifiCalling: 'mat_outline:wifi_calling',
      WifiCalling3: 'mat_outline:wifi_calling_3',
      WifiLock: 'mat_outline:wifi_lock',
      WifiOff: 'mat_outline:wifi_off',
      WifiProtectedSetup: 'mat_outline:wifi_protected_setup',
      WifiTethering: 'mat_outline:wifi_tethering',
      WifiTetheringErrorRounded: 'mat_outline:wifi_tethering_error_rounded',
      WifiTetheringOff: 'mat_outline:wifi_tethering_off',
      Window: 'mat_outline:window',
      WineBar: 'mat_outline:wine_bar',
      Work: 'mat_outline:work',
      WorkOff: 'mat_outline:work_off',
      WorkOutline: 'mat_outline:work_outline',
      Workspaces: 'mat_outline:workspaces',
      WrapText: 'mat_outline:wrap_text',
      WrongLocation: 'mat_outline:wrong_location',
      Wysiwyg: 'mat_outline:wysiwyg',
      Yard: 'mat_outline:yard',
      YoutubeSearchedFor: 'mat_outline:youtube_searched_for',
      ZoomIn: 'mat_outline:zoom_in',
      ZoomOut: 'mat_outline:zoom_out',
      ZoomOutMap: 'mat_outline:zoom_out_map',
    },
    solid: {
      '10K': 'mat_solid:10k',
      '10Mp': 'mat_solid:10mp',
      '11Mp': 'mat_solid:11mp',
      '12Mp': 'mat_solid:12mp',
      '13Mp': 'mat_solid:13mp',
      '14Mp': 'mat_solid:14mp',
      '15Mp': 'mat_solid:15mp',
      '16Mp': 'mat_solid:16mp',
      '17Mp': 'mat_solid:17mp',
      '18Mp': 'mat_solid:18mp',
      '19Mp': 'mat_solid:19mp',
      '1K': 'mat_solid:1k',
      '1KPlus': 'mat_solid:1k_plus',
      '1XMobiledata': 'mat_solid:1x_mobiledata',
      '20Mp': 'mat_solid:20mp',
      '21Mp': 'mat_solid:21mp',
      '22Mp': 'mat_solid:22mp',
      '23Mp': 'mat_solid:23mp',
      '24Mp': 'mat_solid:24mp',
      '2K': 'mat_solid:2k',
      '2KPlus': 'mat_solid:2k_plus',
      '2Mp': 'mat_solid:2mp',
      '30Fps': 'mat_solid:30fps',
      '30FpsSelect': 'mat_solid:30fps_select',
      '360': 'mat_solid:360',
      '3DRotation': 'mat_solid:3d_rotation',
      '3GMobiledata': 'mat_solid:3g_mobiledata',
      '3K': 'mat_solid:3k',
      '3KPlus': 'mat_solid:3k_plus',
      '3Mp': 'mat_solid:3mp',
      '3P': 'mat_solid:3p',
      '4GMobiledata': 'mat_solid:4g_mobiledata',
      '4GPlusMobiledata': 'mat_solid:4g_plus_mobiledata',
      '4K': 'mat_solid:4k',
      '4KPlus': 'mat_solid:4k_plus',
      '4Mp': 'mat_solid:4mp',
      '5G': 'mat_solid:5g',
      '5K': 'mat_solid:5k',
      '5KPlus': 'mat_solid:5k_plus',
      '5Mp': 'mat_solid:5mp',
      '60Fps': 'mat_solid:60fps',
      '60FpsSelect': 'mat_solid:60fps_select',
      '6FtApart': 'mat_solid:6_ft_apart',
      '6K': 'mat_solid:6k',
      '6KPlus': 'mat_solid:6k_plus',
      '6Mp': 'mat_solid:6mp',
      '7K': 'mat_solid:7k',
      '7KPlus': 'mat_solid:7k_plus',
      '7Mp': 'mat_solid:7mp',
      '8K': 'mat_solid:8k',
      '8KPlus': 'mat_solid:8k_plus',
      '8Mp': 'mat_solid:8mp',
      '9K': 'mat_solid:9k',
      '9KPlus': 'mat_solid:9k_plus',
      '9Mp': 'mat_solid:9mp',
      AcUnit: 'mat_solid:ac_unit',
      AccessAlarm: 'mat_solid:access_alarm',
      AccessAlarms: 'mat_solid:access_alarms',
      AccessTime: 'mat_solid:access_time',
      AccessTimeFilled: 'mat_solid:access_time_filled',
      Accessibility: 'mat_solid:accessibility',
      AccessibilityNew: 'mat_solid:accessibility_new',
      Accessible: 'mat_solid:accessible',
      AccessibleForward: 'mat_solid:accessible_forward',
      AccountBalance: 'mat_solid:account_balance',
      AccountBalanceWallet: 'mat_solid:account_balance_wallet',
      AccountBox: 'mat_solid:account_box',
      AccountCircle: 'mat_solid:account_circle',
      AccountTree: 'mat_solid:account_tree',
      AdUnits: 'mat_solid:ad_units',
      Adb: 'mat_solid:adb',
      Add: 'mat_solid:add',
      AddAPhoto: 'mat_solid:add_a_photo',
      AddAlarm: 'mat_solid:add_alarm',
      AddAlert: 'mat_solid:add_alert',
      AddBox: 'mat_solid:add_box',
      AddBusiness: 'mat_solid:add_business',
      AddChart: 'mat_solid:add_chart',
      AddCircle: 'mat_solid:add_circle',
      AddCircleOutline: 'mat_solid:add_circle_outline',
      AddComment: 'mat_solid:add_comment',
      AddIcCall: 'mat_solid:add_ic_call',
      AddLink: 'mat_solid:add_link',
      AddLocation: 'mat_solid:add_location',
      AddLocationAlt: 'mat_solid:add_location_alt',
      AddModerator: 'mat_solid:add_moderator',
      AddPhotoAlternate: 'mat_solid:add_photo_alternate',
      AddReaction: 'mat_solid:add_reaction',
      AddRoad: 'mat_solid:add_road',
      AddShoppingCart: 'mat_solid:add_shopping_cart',
      AddTask: 'mat_solid:add_task',
      AddToDrive: 'mat_solid:add_to_drive',
      AddToHomeScreen: 'mat_solid:add_to_home_screen',
      AddToPhotos: 'mat_solid:add_to_photos',
      AddToQueue: 'mat_solid:add_to_queue',
      Addchart: 'mat_solid:addchart',
      Adjust: 'mat_solid:adjust',
      AdminPanelSettings: 'mat_solid:admin_panel_settings',
      AdsClick: 'mat_solid:ads_click',
      Agriculture: 'mat_solid:agriculture',
      Air: 'mat_solid:air',
      AirlineSeatFlat: 'mat_solid:airline_seat_flat',
      AirlineSeatFlatAngled: 'mat_solid:airline_seat_flat_angled',
      AirlineSeatIndividualSuite: 'mat_solid:airline_seat_individual_suite',
      AirlineSeatLegroomExtra: 'mat_solid:airline_seat_legroom_extra',
      AirlineSeatLegroomNormal: 'mat_solid:airline_seat_legroom_normal',
      AirlineSeatLegroomReduced: 'mat_solid:airline_seat_legroom_reduced',
      AirlineSeatReclineExtra: 'mat_solid:airline_seat_recline_extra',
      AirlineSeatReclineNormal: 'mat_solid:airline_seat_recline_normal',
      AirplaneTicket: 'mat_solid:airplane_ticket',
      AirplanemodeActive: 'mat_solid:airplanemode_active',
      AirplanemodeInactive: 'mat_solid:airplanemode_inactive',
      Airplay: 'mat_solid:airplay',
      AirportShuttle: 'mat_solid:airport_shuttle',
      Alarm: 'mat_solid:alarm',
      AlarmAdd: 'mat_solid:alarm_add',
      AlarmOff: 'mat_solid:alarm_off',
      AlarmOn: 'mat_solid:alarm_on',
      Album: 'mat_solid:album',
      AlignHorizontalCenter: 'mat_solid:align_horizontal_center',
      AlignHorizontalLeft: 'mat_solid:align_horizontal_left',
      AlignHorizontalRight: 'mat_solid:align_horizontal_right',
      AlignVerticalBottom: 'mat_solid:align_vertical_bottom',
      AlignVerticalCenter: 'mat_solid:align_vertical_center',
      AlignVerticalTop: 'mat_solid:align_vertical_top',
      AllInbox: 'mat_solid:all_inbox',
      AllInclusive: 'mat_solid:all_inclusive',
      AllOut: 'mat_solid:all_out',
      AltRoute: 'mat_solid:alt_route',
      AlternateEmail: 'mat_solid:alternate_email',
      Analytics: 'mat_solid:analytics',
      Anchor: 'mat_solid:anchor',
      Android: 'mat_solid:android',
      Animation: 'mat_solid:animation',
      Announcement: 'mat_solid:announcement',
      Aod: 'mat_solid:aod',
      Apartment: 'mat_solid:apartment',
      Api: 'mat_solid:api',
      AppBlocking: 'mat_solid:app_blocking',
      AppRegistration: 'mat_solid:app_registration',
      AppSettingsAlt: 'mat_solid:app_settings_alt',
      Approval: 'mat_solid:approval',
      Apps: 'mat_solid:apps',
      Architecture: 'mat_solid:architecture',
      Archive: 'mat_solid:archive',
      AreaChart: 'mat_solid:area_chart',
      ArrowBack: 'mat_solid:arrow_back',
      ArrowBackIos: 'mat_solid:arrow_back_ios',
      ArrowBackIosNew: 'mat_solid:arrow_back_ios_new',
      ArrowCircleDown: 'mat_solid:arrow_circle_down',
      ArrowCircleUp: 'mat_solid:arrow_circle_up',
      ArrowDownward: 'mat_solid:arrow_downward',
      ArrowDropDown: 'mat_solid:arrow_drop_down',
      ArrowDropDownCircle: 'mat_solid:arrow_drop_down_circle',
      ArrowDropUp: 'mat_solid:arrow_drop_up',
      ArrowForward: 'mat_solid:arrow_forward',
      ArrowForwardIos: 'mat_solid:arrow_forward_ios',
      ArrowLeft: 'mat_solid:arrow_left',
      ArrowRight: 'mat_solid:arrow_right',
      ArrowRightAlt: 'mat_solid:arrow_right_alt',
      ArrowUpward: 'mat_solid:arrow_upward',
      ArtTrack: 'mat_solid:art_track',
      Article: 'mat_solid:article',
      AspectRatio: 'mat_solid:aspect_ratio',
      Assessment: 'mat_solid:assessment',
      Assignment: 'mat_solid:assignment',
      AssignmentInd: 'mat_solid:assignment_ind',
      AssignmentLate: 'mat_solid:assignment_late',
      AssignmentReturn: 'mat_solid:assignment_return',
      AssignmentReturned: 'mat_solid:assignment_returned',
      AssignmentTurnedIn: 'mat_solid:assignment_turned_in',
      Assistant: 'mat_solid:assistant',
      AssistantDirection: 'mat_solid:assistant_direction',
      AssistantPhoto: 'mat_solid:assistant_photo',
      Atm: 'mat_solid:atm',
      AttachEmail: 'mat_solid:attach_email',
      AttachFile: 'mat_solid:attach_file',
      AttachMoney: 'mat_solid:attach_money',
      Attachment: 'mat_solid:attachment',
      Attractions: 'mat_solid:attractions',
      Attribution: 'mat_solid:attribution',
      Audiotrack: 'mat_solid:audiotrack',
      AutoAwesome: 'mat_solid:auto_awesome',
      AutoAwesomeMosaic: 'mat_solid:auto_awesome_mosaic',
      AutoAwesomeMotion: 'mat_solid:auto_awesome_motion',
      AutoDelete: 'mat_solid:auto_delete',
      AutoFixHigh: 'mat_solid:auto_fix_high',
      AutoFixNormal: 'mat_solid:auto_fix_normal',
      AutoFixOff: 'mat_solid:auto_fix_off',
      AutoGraph: 'mat_solid:auto_graph',
      AutoStories: 'mat_solid:auto_stories',
      AutofpsSelect: 'mat_solid:autofps_select',
      Autorenew: 'mat_solid:autorenew',
      AvTimer: 'mat_solid:av_timer',
      BabyChangingStation: 'mat_solid:baby_changing_station',
      BackHand: 'mat_solid:back_hand',
      Backpack: 'mat_solid:backpack',
      Backspace: 'mat_solid:backspace',
      Backup: 'mat_solid:backup',
      BackupTable: 'mat_solid:backup_table',
      Badge: 'mat_solid:badge',
      BakeryDining: 'mat_solid:bakery_dining',
      Balcony: 'mat_solid:balcony',
      Ballot: 'mat_solid:ballot',
      BarChart: 'mat_solid:bar_chart',
      BatchPrediction: 'mat_solid:batch_prediction',
      Bathroom: 'mat_solid:bathroom',
      Bathtub: 'mat_solid:bathtub',
      BatteryAlert: 'mat_solid:battery_alert',
      BatteryChargingFull: 'mat_solid:battery_charging_full',
      BatteryFull: 'mat_solid:battery_full',
      BatterySaver: 'mat_solid:battery_saver',
      BatteryStd: 'mat_solid:battery_std',
      BatteryUnknown: 'mat_solid:battery_unknown',
      BeachAccess: 'mat_solid:beach_access',
      Bed: 'mat_solid:bed',
      BedroomBaby: 'mat_solid:bedroom_baby',
      BedroomChild: 'mat_solid:bedroom_child',
      BedroomParent: 'mat_solid:bedroom_parent',
      Bedtime: 'mat_solid:bedtime',
      Beenhere: 'mat_solid:beenhere',
      Bento: 'mat_solid:bento',
      BikeScooter: 'mat_solid:bike_scooter',
      Biotech: 'mat_solid:biotech',
      Blender: 'mat_solid:blender',
      Block: 'mat_solid:block',
      Bloodtype: 'mat_solid:bloodtype',
      Bluetooth: 'mat_solid:bluetooth',
      BluetoothAudio: 'mat_solid:bluetooth_audio',
      BluetoothConnected: 'mat_solid:bluetooth_connected',
      BluetoothDisabled: 'mat_solid:bluetooth_disabled',
      BluetoothDrive: 'mat_solid:bluetooth_drive',
      BluetoothSearching: 'mat_solid:bluetooth_searching',
      BlurCircular: 'mat_solid:blur_circular',
      BlurLinear: 'mat_solid:blur_linear',
      BlurOff: 'mat_solid:blur_off',
      BlurOn: 'mat_solid:blur_on',
      Bolt: 'mat_solid:bolt',
      Book: 'mat_solid:book',
      BookOnline: 'mat_solid:book_online',
      Bookmark: 'mat_solid:bookmark',
      BookmarkAdd: 'mat_solid:bookmark_add',
      BookmarkAdded: 'mat_solid:bookmark_added',
      BookmarkBorder: 'mat_solid:bookmark_border',
      BookmarkRemove: 'mat_solid:bookmark_remove',
      Bookmarks: 'mat_solid:bookmarks',
      BorderAll: 'mat_solid:border_all',
      BorderBottom: 'mat_solid:border_bottom',
      BorderClear: 'mat_solid:border_clear',
      BorderColor: 'mat_solid:border_color',
      BorderHorizontal: 'mat_solid:border_horizontal',
      BorderInner: 'mat_solid:border_inner',
      BorderLeft: 'mat_solid:border_left',
      BorderOuter: 'mat_solid:border_outer',
      BorderRight: 'mat_solid:border_right',
      BorderStyle: 'mat_solid:border_style',
      BorderTop: 'mat_solid:border_top',
      BorderVertical: 'mat_solid:border_vertical',
      BrandingWatermark: 'mat_solid:branding_watermark',
      BreakfastDining: 'mat_solid:breakfast_dining',
      Brightness1: 'mat_solid:brightness_1',
      Brightness2: 'mat_solid:brightness_2',
      Brightness3: 'mat_solid:brightness_3',
      Brightness4: 'mat_solid:brightness_4',
      Brightness5: 'mat_solid:brightness_5',
      Brightness6: 'mat_solid:brightness_6',
      Brightness7: 'mat_solid:brightness_7',
      BrightnessAuto: 'mat_solid:brightness_auto',
      BrightnessHigh: 'mat_solid:brightness_high',
      BrightnessLow: 'mat_solid:brightness_low',
      BrightnessMedium: 'mat_solid:brightness_medium',
      BrokenImage: 'mat_solid:broken_image',
      BrowserNotSupported: 'mat_solid:browser_not_supported',
      BrunchDining: 'mat_solid:brunch_dining',
      Brush: 'mat_solid:brush',
      BubbleChart: 'mat_solid:bubble_chart',
      BugReport: 'mat_solid:bug_report',
      Build: 'mat_solid:build',
      BuildCircle: 'mat_solid:build_circle',
      Bungalow: 'mat_solid:bungalow',
      BurstMode: 'mat_solid:burst_mode',
      BusAlert: 'mat_solid:bus_alert',
      Business: 'mat_solid:business',
      BusinessCenter: 'mat_solid:business_center',
      Cabin: 'mat_solid:cabin',
      Cable: 'mat_solid:cable',
      Cached: 'mat_solid:cached',
      Cake: 'mat_solid:cake',
      Calculate: 'mat_solid:calculate',
      CalendarToday: 'mat_solid:calendar_today',
      CalendarViewDay: 'mat_solid:calendar_view_day',
      CalendarViewMonth: 'mat_solid:calendar_view_month',
      CalendarViewWeek: 'mat_solid:calendar_view_week',
      Call: 'mat_solid:call',
      CallEnd: 'mat_solid:call_end',
      CallMade: 'mat_solid:call_made',
      CallMerge: 'mat_solid:call_merge',
      CallMissed: 'mat_solid:call_missed',
      CallMissedOutgoing: 'mat_solid:call_missed_outgoing',
      CallReceived: 'mat_solid:call_received',
      CallSplit: 'mat_solid:call_split',
      CallToAction: 'mat_solid:call_to_action',
      Camera: 'mat_solid:camera',
      CameraAlt: 'mat_solid:camera_alt',
      CameraEnhance: 'mat_solid:camera_enhance',
      CameraFront: 'mat_solid:camera_front',
      CameraIndoor: 'mat_solid:camera_indoor',
      CameraOutdoor: 'mat_solid:camera_outdoor',
      CameraRear: 'mat_solid:camera_rear',
      CameraRoll: 'mat_solid:camera_roll',
      Cameraswitch: 'mat_solid:cameraswitch',
      Campaign: 'mat_solid:campaign',
      Cancel: 'mat_solid:cancel',
      CancelPresentation: 'mat_solid:cancel_presentation',
      CancelScheduleSend: 'mat_solid:cancel_schedule_send',
      CarRental: 'mat_solid:car_rental',
      CarRepair: 'mat_solid:car_repair',
      CardGiftcard: 'mat_solid:card_giftcard',
      CardMembership: 'mat_solid:card_membership',
      CardTravel: 'mat_solid:card_travel',
      Carpenter: 'mat_solid:carpenter',
      Cases: 'mat_solid:cases',
      Casino: 'mat_solid:casino',
      Cast: 'mat_solid:cast',
      CastConnected: 'mat_solid:cast_connected',
      CastForEducation: 'mat_solid:cast_for_education',
      CatchingPokemon: 'mat_solid:catching_pokemon',
      Category: 'mat_solid:category',
      Celebration: 'mat_solid:celebration',
      CellWifi: 'mat_solid:cell_wifi',
      CenterFocusStrong: 'mat_solid:center_focus_strong',
      CenterFocusWeak: 'mat_solid:center_focus_weak',
      Chair: 'mat_solid:chair',
      ChairAlt: 'mat_solid:chair_alt',
      Chalet: 'mat_solid:chalet',
      ChangeCircle: 'mat_solid:change_circle',
      ChangeHistory: 'mat_solid:change_history',
      ChargingStation: 'mat_solid:charging_station',
      Chat: 'mat_solid:chat',
      ChatBubble: 'mat_solid:chat_bubble',
      ChatBubbleOutline: 'mat_solid:chat_bubble_outline',
      Check: 'mat_solid:check',
      CheckBox: 'mat_solid:check_box',
      CheckBoxOutlineBlank: 'mat_solid:check_box_outline_blank',
      CheckCircle: 'mat_solid:check_circle',
      CheckCircleOutline: 'mat_solid:check_circle_outline',
      Checklist: 'mat_solid:checklist',
      ChecklistRtl: 'mat_solid:checklist_rtl',
      Checkroom: 'mat_solid:checkroom',
      ChevronLeft: 'mat_solid:chevron_left',
      ChevronRight: 'mat_solid:chevron_right',
      ChildCare: 'mat_solid:child_care',
      ChildFriendly: 'mat_solid:child_friendly',
      ChromeReaderMode: 'mat_solid:chrome_reader_mode',
      Circle: 'mat_solid:circle',
      CircleNotifications: 'mat_solid:circle_notifications',
      Class: 'mat_solid:class',
      CleanHands: 'mat_solid:clean_hands',
      CleaningServices: 'mat_solid:cleaning_services',
      Clear: 'mat_solid:clear',
      ClearAll: 'mat_solid:clear_all',
      Close: 'mat_solid:close',
      CloseFullscreen: 'mat_solid:close_fullscreen',
      ClosedCaption: 'mat_solid:closed_caption',
      ClosedCaptionDisabled: 'mat_solid:closed_caption_disabled',
      ClosedCaptionOff: 'mat_solid:closed_caption_off',
      Cloud: 'mat_solid:cloud',
      CloudCircle: 'mat_solid:cloud_circle',
      CloudDone: 'mat_solid:cloud_done',
      CloudDownload: 'mat_solid:cloud_download',
      CloudOff: 'mat_solid:cloud_off',
      CloudQueue: 'mat_solid:cloud_queue',
      CloudUpload: 'mat_solid:cloud_upload',
      Code: 'mat_solid:code',
      CodeOff: 'mat_solid:code_off',
      Coffee: 'mat_solid:coffee',
      CoffeeMaker: 'mat_solid:coffee_maker',
      Collections: 'mat_solid:collections',
      CollectionsBookmark: 'mat_solid:collections_bookmark',
      ColorLens: 'mat_solid:color_lens',
      Colorize: 'mat_solid:colorize',
      Comment: 'mat_solid:comment',
      CommentBank: 'mat_solid:comment_bank',
      Commute: 'mat_solid:commute',
      Compare: 'mat_solid:compare',
      CompareArrows: 'mat_solid:compare_arrows',
      CompassCalibration: 'mat_solid:compass_calibration',
      Compost: 'mat_solid:compost',
      Compress: 'mat_solid:compress',
      Computer: 'mat_solid:computer',
      ConfirmationNumber: 'mat_solid:confirmation_number',
      ConnectWithoutContact: 'mat_solid:connect_without_contact',
      ConnectedTv: 'mat_solid:connected_tv',
      Construction: 'mat_solid:construction',
      ContactMail: 'mat_solid:contact_mail',
      ContactPage: 'mat_solid:contact_page',
      ContactPhone: 'mat_solid:contact_phone',
      ContactSupport: 'mat_solid:contact_support',
      Contactless: 'mat_solid:contactless',
      Contacts: 'mat_solid:contacts',
      ContentCopy: 'mat_solid:content_copy',
      ContentCut: 'mat_solid:content_cut',
      ContentPaste: 'mat_solid:content_paste',
      ContentPasteOff: 'mat_solid:content_paste_off',
      ControlCamera: 'mat_solid:control_camera',
      ControlPoint: 'mat_solid:control_point',
      ControlPointDuplicate: 'mat_solid:control_point_duplicate',
      CopyAll: 'mat_solid:copy_all',
      Copyright: 'mat_solid:copyright',
      Coronavirus: 'mat_solid:coronavirus',
      CorporateFare: 'mat_solid:corporate_fare',
      Cottage: 'mat_solid:cottage',
      Countertops: 'mat_solid:countertops',
      Create: 'mat_solid:create',
      CreateNewFolder: 'mat_solid:create_new_folder',
      CreditCard: 'mat_solid:credit_card',
      CreditCardOff: 'mat_solid:credit_card_off',
      CreditScore: 'mat_solid:credit_score',
      Crib: 'mat_solid:crib',
      Crop: 'mat_solid:crop',
      Crop169: 'mat_solid:crop_16_9',
      Crop32: 'mat_solid:crop_3_2',
      Crop54: 'mat_solid:crop_5_4',
      Crop75: 'mat_solid:crop_7_5',
      CropDin: 'mat_solid:crop_din',
      CropFree: 'mat_solid:crop_free',
      CropLandscape: 'mat_solid:crop_landscape',
      CropOriginal: 'mat_solid:crop_original',
      CropPortrait: 'mat_solid:crop_portrait',
      CropRotate: 'mat_solid:crop_rotate',
      CropSquare: 'mat_solid:crop_square',
      CrueltyFree: 'mat_solid:cruelty_free',
      Dangerous: 'mat_solid:dangerous',
      DarkMode: 'mat_solid:dark_mode',
      Dashboard: 'mat_solid:dashboard',
      DashboardCustomize: 'mat_solid:dashboard_customize',
      DataExploration: 'mat_solid:data_exploration',
      DataSaverOff: 'mat_solid:data_saver_off',
      DataSaverOn: 'mat_solid:data_saver_on',
      DataUsage: 'mat_solid:data_usage',
      DateRange: 'mat_solid:date_range',
      Deck: 'mat_solid:deck',
      Dehaze: 'mat_solid:dehaze',
      Delete: 'mat_solid:delete',
      DeleteForever: 'mat_solid:delete_forever',
      DeleteOutline: 'mat_solid:delete_outline',
      DeleteSweep: 'mat_solid:delete_sweep',
      DeliveryDining: 'mat_solid:delivery_dining',
      DepartureBoard: 'mat_solid:departure_board',
      Description: 'mat_solid:description',
      DesignServices: 'mat_solid:design_services',
      DesktopAccessDisabled: 'mat_solid:desktop_access_disabled',
      DesktopMac: 'mat_solid:desktop_mac',
      DesktopWindows: 'mat_solid:desktop_windows',
      Details: 'mat_solid:details',
      DeveloperBoard: 'mat_solid:developer_board',
      DeveloperBoardOff: 'mat_solid:developer_board_off',
      DeveloperMode: 'mat_solid:developer_mode',
      DeviceHub: 'mat_solid:device_hub',
      DeviceThermostat: 'mat_solid:device_thermostat',
      DeviceUnknown: 'mat_solid:device_unknown',
      Devices: 'mat_solid:devices',
      DevicesOther: 'mat_solid:devices_other',
      DialerSip: 'mat_solid:dialer_sip',
      Dialpad: 'mat_solid:dialpad',
      Dining: 'mat_solid:dining',
      DinnerDining: 'mat_solid:dinner_dining',
      Directions: 'mat_solid:directions',
      DirectionsBike: 'mat_solid:directions_bike',
      DirectionsBoat: 'mat_solid:directions_boat',
      DirectionsBoatFilled: 'mat_solid:directions_boat_filled',
      DirectionsBus: 'mat_solid:directions_bus',
      DirectionsBusFilled: 'mat_solid:directions_bus_filled',
      DirectionsCar: 'mat_solid:directions_car',
      DirectionsCarFilled: 'mat_solid:directions_car_filled',
      DirectionsOff: 'mat_solid:directions_off',
      DirectionsRailway: 'mat_solid:directions_railway',
      DirectionsRailwayFilled: 'mat_solid:directions_railway_filled',
      DirectionsRun: 'mat_solid:directions_run',
      DirectionsSubway: 'mat_solid:directions_subway',
      DirectionsSubwayFilled: 'mat_solid:directions_subway_filled',
      DirectionsTransit: 'mat_solid:directions_transit',
      DirectionsTransitFilled: 'mat_solid:directions_transit_filled',
      DirectionsWalk: 'mat_solid:directions_walk',
      DirtyLens: 'mat_solid:dirty_lens',
      DisabledByDefault: 'mat_solid:disabled_by_default',
      DisabledVisible: 'mat_solid:disabled_visible',
      DiscFull: 'mat_solid:disc_full',
      Dns: 'mat_solid:dns',
      DoDisturb: 'mat_solid:do_disturb',
      DoDisturbAlt: 'mat_solid:do_disturb_alt',
      DoDisturbOff: 'mat_solid:do_disturb_off',
      DoDisturbOn: 'mat_solid:do_disturb_on',
      DoNotDisturb: 'mat_solid:do_not_disturb',
      DoNotDisturbAlt: 'mat_solid:do_not_disturb_alt',
      DoNotDisturbOff: 'mat_solid:do_not_disturb_off',
      DoNotDisturbOn: 'mat_solid:do_not_disturb_on',
      DoNotDisturbOnTotalSilence: 'mat_solid:do_not_disturb_on_total_silence',
      DoNotStep: 'mat_solid:do_not_step',
      DoNotTouch: 'mat_solid:do_not_touch',
      Dock: 'mat_solid:dock',
      DocumentScanner: 'mat_solid:document_scanner',
      Domain: 'mat_solid:domain',
      DomainDisabled: 'mat_solid:domain_disabled',
      DomainVerification: 'mat_solid:domain_verification',
      Done: 'mat_solid:done',
      DoneAll: 'mat_solid:done_all',
      DoneOutline: 'mat_solid:done_outline',
      DonutLarge: 'mat_solid:donut_large',
      DonutSmall: 'mat_solid:donut_small',
      DoorBack: 'mat_solid:door_back',
      DoorFront: 'mat_solid:door_front',
      DoorSliding: 'mat_solid:door_sliding',
      Doorbell: 'mat_solid:doorbell',
      DoubleArrow: 'mat_solid:double_arrow',
      DownhillSkiing: 'mat_solid:downhill_skiing',
      Download: 'mat_solid:download',
      DownloadDone: 'mat_solid:download_done',
      DownloadForOffline: 'mat_solid:download_for_offline',
      Downloading: 'mat_solid:downloading',
      Drafts: 'mat_solid:drafts',
      DragHandle: 'mat_solid:drag_handle',
      DragIndicator: 'mat_solid:drag_indicator',
      Draw: 'mat_solid:draw',
      DriveEta: 'mat_solid:drive_eta',
      DriveFileMove: 'mat_solid:drive_file_move',
      DriveFileMoveRtl: 'mat_solid:drive_file_move_rtl',
      DriveFileRenameOutline: 'mat_solid:drive_file_rename_outline',
      DriveFolderUpload: 'mat_solid:drive_folder_upload',
      Dry: 'mat_solid:dry',
      DryCleaning: 'mat_solid:dry_cleaning',
      Duo: 'mat_solid:duo',
      Dvr: 'mat_solid:dvr',
      DynamicFeed: 'mat_solid:dynamic_feed',
      DynamicForm: 'mat_solid:dynamic_form',
      EMobiledata: 'mat_solid:e_mobiledata',
      Earbuds: 'mat_solid:earbuds',
      EarbudsBattery: 'mat_solid:earbuds_battery',
      East: 'mat_solid:east',
      EdgesensorHigh: 'mat_solid:edgesensor_high',
      EdgesensorLow: 'mat_solid:edgesensor_low',
      Edit: 'mat_solid:edit',
      EditAttributes: 'mat_solid:edit_attributes',
      EditCalendar: 'mat_solid:edit_calendar',
      EditLocation: 'mat_solid:edit_location',
      EditLocationAlt: 'mat_solid:edit_location_alt',
      EditNote: 'mat_solid:edit_note',
      EditNotifications: 'mat_solid:edit_notifications',
      EditOff: 'mat_solid:edit_off',
      EditRoad: 'mat_solid:edit_road',
      Eject: 'mat_solid:eject',
      Elderly: 'mat_solid:elderly',
      ElectricBike: 'mat_solid:electric_bike',
      ElectricCar: 'mat_solid:electric_car',
      ElectricMoped: 'mat_solid:electric_moped',
      ElectricRickshaw: 'mat_solid:electric_rickshaw',
      ElectricScooter: 'mat_solid:electric_scooter',
      ElectricalServices: 'mat_solid:electrical_services',
      Elevator: 'mat_solid:elevator',
      Email: 'mat_solid:email',
      Emergency: 'mat_solid:emergency',
      EmojiEmotions: 'mat_solid:emoji_emotions',
      EmojiEvents: 'mat_solid:emoji_events',
      EmojiFlags: 'mat_solid:emoji_flags',
      EmojiFoodBeverage: 'mat_solid:emoji_food_beverage',
      EmojiNature: 'mat_solid:emoji_nature',
      EmojiObjects: 'mat_solid:emoji_objects',
      EmojiPeople: 'mat_solid:emoji_people',
      EmojiSymbols: 'mat_solid:emoji_symbols',
      EmojiTransportation: 'mat_solid:emoji_transportation',
      Engineering: 'mat_solid:engineering',
      EnhancedEncryption: 'mat_solid:enhanced_encryption',
      Equalizer: 'mat_solid:equalizer',
      Error: 'mat_solid:error',
      ErrorOutline: 'mat_solid:error_outline',
      Escalator: 'mat_solid:escalator',
      EscalatorWarning: 'mat_solid:escalator_warning',
      Euro: 'mat_solid:euro',
      EuroSymbol: 'mat_solid:euro_symbol',
      EvStation: 'mat_solid:ev_station',
      Event: 'mat_solid:event',
      EventAvailable: 'mat_solid:event_available',
      EventBusy: 'mat_solid:event_busy',
      EventNote: 'mat_solid:event_note',
      EventSeat: 'mat_solid:event_seat',
      ExitToApp: 'mat_solid:exit_to_app',
      Expand: 'mat_solid:expand',
      ExpandLess: 'mat_solid:expand_less',
      ExpandMore: 'mat_solid:expand_more',
      Explicit: 'mat_solid:explicit',
      Explore: 'mat_solid:explore',
      ExploreOff: 'mat_solid:explore_off',
      Exposure: 'mat_solid:exposure',
      ExposureNeg1: 'mat_solid:exposure_neg_1',
      ExposureNeg2: 'mat_solid:exposure_neg_2',
      ExposurePlus1: 'mat_solid:exposure_plus_1',
      ExposurePlus2: 'mat_solid:exposure_plus_2',
      ExposureZero: 'mat_solid:exposure_zero',
      Extension: 'mat_solid:extension',
      ExtensionOff: 'mat_solid:extension_off',
      Face: 'mat_solid:face',
      FaceRetouchingNatural: 'mat_solid:face_retouching_natural',
      FaceRetouchingOff: 'mat_solid:face_retouching_off',
      Facebook: 'mat_solid:facebook',
      FactCheck: 'mat_solid:fact_check',
      FamilyRestroom: 'mat_solid:family_restroom',
      FastForward: 'mat_solid:fast_forward',
      FastRewind: 'mat_solid:fast_rewind',
      Fastfood: 'mat_solid:fastfood',
      Favorite: 'mat_solid:favorite',
      FavoriteBorder: 'mat_solid:favorite_border',
      FeaturedPlayList: 'mat_solid:featured_play_list',
      FeaturedVideo: 'mat_solid:featured_video',
      Feed: 'mat_solid:feed',
      Feedback: 'mat_solid:feedback',
      Female: 'mat_solid:female',
      Fence: 'mat_solid:fence',
      Festival: 'mat_solid:festival',
      FiberDvr: 'mat_solid:fiber_dvr',
      FiberManualRecord: 'mat_solid:fiber_manual_record',
      FiberNew: 'mat_solid:fiber_new',
      FiberPin: 'mat_solid:fiber_pin',
      FiberSmartRecord: 'mat_solid:fiber_smart_record',
      FileCopy: 'mat_solid:file_copy',
      FileDownload: 'mat_solid:file_download',
      FileDownloadDone: 'mat_solid:file_download_done',
      FileDownloadOff: 'mat_solid:file_download_off',
      FilePresent: 'mat_solid:file_present',
      FileUpload: 'mat_solid:file_upload',
      Filter: 'mat_solid:filter',
      Filter1: 'mat_solid:filter_1',
      Filter2: 'mat_solid:filter_2',
      Filter3: 'mat_solid:filter_3',
      Filter4: 'mat_solid:filter_4',
      Filter5: 'mat_solid:filter_5',
      Filter6: 'mat_solid:filter_6',
      Filter7: 'mat_solid:filter_7',
      Filter8: 'mat_solid:filter_8',
      Filter9: 'mat_solid:filter_9',
      Filter9Plus: 'mat_solid:filter_9_plus',
      FilterAlt: 'mat_solid:filter_alt',
      FilterBAndW: 'mat_solid:filter_b_and_w',
      FilterCenterFocus: 'mat_solid:filter_center_focus',
      FilterDrama: 'mat_solid:filter_drama',
      FilterFrames: 'mat_solid:filter_frames',
      FilterHdr: 'mat_solid:filter_hdr',
      FilterList: 'mat_solid:filter_list',
      FilterNone: 'mat_solid:filter_none',
      FilterTiltShift: 'mat_solid:filter_tilt_shift',
      FilterVintage: 'mat_solid:filter_vintage',
      FindInPage: 'mat_solid:find_in_page',
      FindReplace: 'mat_solid:find_replace',
      Fingerprint: 'mat_solid:fingerprint',
      FireExtinguisher: 'mat_solid:fire_extinguisher',
      Fireplace: 'mat_solid:fireplace',
      FirstPage: 'mat_solid:first_page',
      FitScreen: 'mat_solid:fit_screen',
      FitnessCenter: 'mat_solid:fitness_center',
      Flag: 'mat_solid:flag',
      Flaky: 'mat_solid:flaky',
      Flare: 'mat_solid:flare',
      FlashAuto: 'mat_solid:flash_auto',
      FlashOff: 'mat_solid:flash_off',
      FlashOn: 'mat_solid:flash_on',
      FlashlightOff: 'mat_solid:flashlight_off',
      FlashlightOn: 'mat_solid:flashlight_on',
      Flatware: 'mat_solid:flatware',
      Flight: 'mat_solid:flight',
      FlightLand: 'mat_solid:flight_land',
      FlightTakeoff: 'mat_solid:flight_takeoff',
      Flip: 'mat_solid:flip',
      FlipCameraAndroid: 'mat_solid:flip_camera_android',
      FlipCameraIos: 'mat_solid:flip_camera_ios',
      FlipToBack: 'mat_solid:flip_to_back',
      FlipToFront: 'mat_solid:flip_to_front',
      Flourescent: 'mat_solid:flourescent',
      FlutterDash: 'mat_solid:flutter_dash',
      FmdBad: 'mat_solid:fmd_bad',
      FmdGood: 'mat_solid:fmd_good',
      Folder: 'mat_solid:folder',
      FolderOpen: 'mat_solid:folder_open',
      FolderShared: 'mat_solid:folder_shared',
      FolderSpecial: 'mat_solid:folder_special',
      FollowTheSigns: 'mat_solid:follow_the_signs',
      FontDownload: 'mat_solid:font_download',
      FontDownloadOff: 'mat_solid:font_download_off',
      FoodBank: 'mat_solid:food_bank',
      FormatAlignCenter: 'mat_solid:format_align_center',
      FormatAlignJustify: 'mat_solid:format_align_justify',
      FormatAlignLeft: 'mat_solid:format_align_left',
      FormatAlignRight: 'mat_solid:format_align_right',
      FormatBold: 'mat_solid:format_bold',
      FormatClear: 'mat_solid:format_clear',
      FormatColorFill: 'mat_solid:format_color_fill',
      FormatColorReset: 'mat_solid:format_color_reset',
      FormatColorText: 'mat_solid:format_color_text',
      FormatIndentDecrease: 'mat_solid:format_indent_decrease',
      FormatIndentIncrease: 'mat_solid:format_indent_increase',
      FormatItalic: 'mat_solid:format_italic',
      FormatLineSpacing: 'mat_solid:format_line_spacing',
      FormatListBulleted: 'mat_solid:format_list_bulleted',
      FormatListNumbered: 'mat_solid:format_list_numbered',
      FormatListNumberedRtl: 'mat_solid:format_list_numbered_rtl',
      FormatPaint: 'mat_solid:format_paint',
      FormatQuote: 'mat_solid:format_quote',
      FormatShapes: 'mat_solid:format_shapes',
      FormatSize: 'mat_solid:format_size',
      FormatStrikethrough: 'mat_solid:format_strikethrough',
      FormatTextdirectionLToR: 'mat_solid:format_textdirection_l_to_r',
      FormatTextdirectionRToL: 'mat_solid:format_textdirection_r_to_l',
      FormatUnderlined: 'mat_solid:format_underlined',
      Forum: 'mat_solid:forum',
      Forward: 'mat_solid:forward',
      Forward10: 'mat_solid:forward_10',
      Forward30: 'mat_solid:forward_30',
      Forward5: 'mat_solid:forward_5',
      ForwardToInbox: 'mat_solid:forward_to_inbox',
      Foundation: 'mat_solid:foundation',
      FreeBreakfast: 'mat_solid:free_breakfast',
      FreeCancellation: 'mat_solid:free_cancellation',
      FrontHand: 'mat_solid:front_hand',
      Fullscreen: 'mat_solid:fullscreen',
      FullscreenExit: 'mat_solid:fullscreen_exit',
      Functions: 'mat_solid:functions',
      GMobiledata: 'mat_solid:g_mobiledata',
      GTranslate: 'mat_solid:g_translate',
      Gamepad: 'mat_solid:gamepad',
      Games: 'mat_solid:games',
      Garage: 'mat_solid:garage',
      Gavel: 'mat_solid:gavel',
      GeneratingTokens: 'mat_solid:generating_tokens',
      Gesture: 'mat_solid:gesture',
      GetApp: 'mat_solid:get_app',
      Gif: 'mat_solid:gif',
      Gite: 'mat_solid:gite',
      GolfCourse: 'mat_solid:golf_course',
      GppBad: 'mat_solid:gpp_bad',
      GppGood: 'mat_solid:gpp_good',
      GppMaybe: 'mat_solid:gpp_maybe',
      GpsFixed: 'mat_solid:gps_fixed',
      GpsNotFixed: 'mat_solid:gps_not_fixed',
      GpsOff: 'mat_solid:gps_off',
      Grade: 'mat_solid:grade',
      Gradient: 'mat_solid:gradient',
      Grading: 'mat_solid:grading',
      Grain: 'mat_solid:grain',
      GraphicEq: 'mat_solid:graphic_eq',
      Grass: 'mat_solid:grass',
      Grid3X3: 'mat_solid:grid_3x3',
      Grid4X4: 'mat_solid:grid_4x4',
      GridGoldenratio: 'mat_solid:grid_goldenratio',
      GridOff: 'mat_solid:grid_off',
      GridOn: 'mat_solid:grid_on',
      GridView: 'mat_solid:grid_view',
      Group: 'mat_solid:group',
      GroupAdd: 'mat_solid:group_add',
      GroupOff: 'mat_solid:group_off',
      GroupWork: 'mat_solid:group_work',
      Groups: 'mat_solid:groups',
      HMobiledata: 'mat_solid:h_mobiledata',
      HPlusMobiledata: 'mat_solid:h_plus_mobiledata',
      Hail: 'mat_solid:hail',
      Handyman: 'mat_solid:handyman',
      Hardware: 'mat_solid:hardware',
      Hd: 'mat_solid:hd',
      HdrAuto: 'mat_solid:hdr_auto',
      HdrAutoSelect: 'mat_solid:hdr_auto_select',
      HdrEnhancedSelect: 'mat_solid:hdr_enhanced_select',
      HdrOff: 'mat_solid:hdr_off',
      HdrOffSelect: 'mat_solid:hdr_off_select',
      HdrOn: 'mat_solid:hdr_on',
      HdrOnSelect: 'mat_solid:hdr_on_select',
      HdrPlus: 'mat_solid:hdr_plus',
      HdrStrong: 'mat_solid:hdr_strong',
      HdrWeak: 'mat_solid:hdr_weak',
      Headphones: 'mat_solid:headphones',
      HeadphonesBattery: 'mat_solid:headphones_battery',
      Headset: 'mat_solid:headset',
      HeadsetMic: 'mat_solid:headset_mic',
      HeadsetOff: 'mat_solid:headset_off',
      Healing: 'mat_solid:healing',
      HealthAndSafety: 'mat_solid:health_and_safety',
      Hearing: 'mat_solid:hearing',
      HearingDisabled: 'mat_solid:hearing_disabled',
      Height: 'mat_solid:height',
      Help: 'mat_solid:help',
      HelpCenter: 'mat_solid:help_center',
      HelpOutline: 'mat_solid:help_outline',
      Hevc: 'mat_solid:hevc',
      HideImage: 'mat_solid:hide_image',
      HideSource: 'mat_solid:hide_source',
      HighQuality: 'mat_solid:high_quality',
      Highlight: 'mat_solid:highlight',
      HighlightAlt: 'mat_solid:highlight_alt',
      HighlightOff: 'mat_solid:highlight_off',
      Hiking: 'mat_solid:hiking',
      History: 'mat_solid:history',
      HistoryEdu: 'mat_solid:history_edu',
      HistoryToggleOff: 'mat_solid:history_toggle_off',
      HolidayVillage: 'mat_solid:holiday_village',
      Home: 'mat_solid:home',
      HomeMax: 'mat_solid:home_max',
      HomeMini: 'mat_solid:home_mini',
      HomeRepairService: 'mat_solid:home_repair_service',
      HomeWork: 'mat_solid:home_work',
      HorizontalDistribute: 'mat_solid:horizontal_distribute',
      HorizontalRule: 'mat_solid:horizontal_rule',
      HorizontalSplit: 'mat_solid:horizontal_split',
      HotTub: 'mat_solid:hot_tub',
      Hotel: 'mat_solid:hotel',
      HotelClass: 'mat_solid:hotel_class',
      HourglassBottom: 'mat_solid:hourglass_bottom',
      HourglassDisabled: 'mat_solid:hourglass_disabled',
      HourglassEmpty: 'mat_solid:hourglass_empty',
      HourglassFull: 'mat_solid:hourglass_full',
      HourglassTop: 'mat_solid:hourglass_top',
      House: 'mat_solid:house',
      HouseSiding: 'mat_solid:house_siding',
      Houseboat: 'mat_solid:houseboat',
      HowToReg: 'mat_solid:how_to_reg',
      HowToVote: 'mat_solid:how_to_vote',
      Http: 'mat_solid:http',
      Https: 'mat_solid:https',
      Hvac: 'mat_solid:hvac',
      IceSkating: 'mat_solid:ice_skating',
      Icecream: 'mat_solid:icecream',
      Image: 'mat_solid:image',
      ImageAspectRatio: 'mat_solid:image_aspect_ratio',
      ImageNotSupported: 'mat_solid:image_not_supported',
      ImageSearch: 'mat_solid:image_search',
      ImagesearchRoller: 'mat_solid:imagesearch_roller',
      ImportContacts: 'mat_solid:import_contacts',
      ImportExport: 'mat_solid:import_export',
      ImportantDevices: 'mat_solid:important_devices',
      Inbox: 'mat_solid:inbox',
      IncompleteCircle: 'mat_solid:incomplete_circle',
      IndeterminateCheckBox: 'mat_solid:indeterminate_check_box',
      Info: 'mat_solid:info',
      Input: 'mat_solid:input',
      InsertChart: 'mat_solid:insert_chart',
      InsertChartOutlined: 'mat_solid:insert_chart_outlined',
      InsertComment: 'mat_solid:insert_comment',
      InsertDriveFile: 'mat_solid:insert_drive_file',
      InsertEmoticon: 'mat_solid:insert_emoticon',
      InsertInvitation: 'mat_solid:insert_invitation',
      InsertLink: 'mat_solid:insert_link',
      InsertPhoto: 'mat_solid:insert_photo',
      Insights: 'mat_solid:insights',
      IntegrationInstructions: 'mat_solid:integration_instructions',
      Inventory: 'mat_solid:inventory',
      Inventory2: 'mat_solid:inventory_2',
      InvertColors: 'mat_solid:invert_colors',
      InvertColorsOff: 'mat_solid:invert_colors_off',
      IosShare: 'mat_solid:ios_share',
      Iron: 'mat_solid:iron',
      Iso: 'mat_solid:iso',
      Kayaking: 'mat_solid:kayaking',
      Keyboard: 'mat_solid:keyboard',
      KeyboardAlt: 'mat_solid:keyboard_alt',
      KeyboardArrowDown: 'mat_solid:keyboard_arrow_down',
      KeyboardArrowLeft: 'mat_solid:keyboard_arrow_left',
      KeyboardArrowRight: 'mat_solid:keyboard_arrow_right',
      KeyboardArrowUp: 'mat_solid:keyboard_arrow_up',
      KeyboardBackspace: 'mat_solid:keyboard_backspace',
      KeyboardCapslock: 'mat_solid:keyboard_capslock',
      KeyboardHide: 'mat_solid:keyboard_hide',
      KeyboardReturn: 'mat_solid:keyboard_return',
      KeyboardTab: 'mat_solid:keyboard_tab',
      KeyboardVoice: 'mat_solid:keyboard_voice',
      KingBed: 'mat_solid:king_bed',
      Kitchen: 'mat_solid:kitchen',
      Kitesurfing: 'mat_solid:kitesurfing',
      Label: 'mat_solid:label',
      LabelImportant: 'mat_solid:label_important',
      LabelOff: 'mat_solid:label_off',
      Landscape: 'mat_solid:landscape',
      Language: 'mat_solid:language',
      Laptop: 'mat_solid:laptop',
      LaptopChromebook: 'mat_solid:laptop_chromebook',
      LaptopMac: 'mat_solid:laptop_mac',
      LaptopWindows: 'mat_solid:laptop_windows',
      LastPage: 'mat_solid:last_page',
      Launch: 'mat_solid:launch',
      Layers: 'mat_solid:layers',
      LayersClear: 'mat_solid:layers_clear',
      Leaderboard: 'mat_solid:leaderboard',
      LeakAdd: 'mat_solid:leak_add',
      LeakRemove: 'mat_solid:leak_remove',
      LegendToggle: 'mat_solid:legend_toggle',
      Lens: 'mat_solid:lens',
      LensBlur: 'mat_solid:lens_blur',
      LibraryAdd: 'mat_solid:library_add',
      LibraryAddCheck: 'mat_solid:library_add_check',
      LibraryBooks: 'mat_solid:library_books',
      LibraryMusic: 'mat_solid:library_music',
      Light: 'mat_solid:light',
      LightMode: 'mat_solid:light_mode',
      Lightbulb: 'mat_solid:lightbulb',
      LineStyle: 'mat_solid:line_style',
      LineWeight: 'mat_solid:line_weight',
      LinearScale: 'mat_solid:linear_scale',
      Link: 'mat_solid:link',
      LinkOff: 'mat_solid:link_off',
      LinkedCamera: 'mat_solid:linked_camera',
      Liquor: 'mat_solid:liquor',
      List: 'mat_solid:list',
      ListAlt: 'mat_solid:list_alt',
      LiveHelp: 'mat_solid:live_help',
      LiveTv: 'mat_solid:live_tv',
      Living: 'mat_solid:living',
      LocalActivity: 'mat_solid:local_activity',
      LocalAirport: 'mat_solid:local_airport',
      LocalAtm: 'mat_solid:local_atm',
      LocalBar: 'mat_solid:local_bar',
      LocalCafe: 'mat_solid:local_cafe',
      LocalCarWash: 'mat_solid:local_car_wash',
      LocalConvenienceStore: 'mat_solid:local_convenience_store',
      LocalDining: 'mat_solid:local_dining',
      LocalDrink: 'mat_solid:local_drink',
      LocalFireDepartment: 'mat_solid:local_fire_department',
      LocalFlorist: 'mat_solid:local_florist',
      LocalGasStation: 'mat_solid:local_gas_station',
      LocalGroceryStore: 'mat_solid:local_grocery_store',
      LocalHospital: 'mat_solid:local_hospital',
      LocalHotel: 'mat_solid:local_hotel',
      LocalLaundryService: 'mat_solid:local_laundry_service',
      LocalLibrary: 'mat_solid:local_library',
      LocalMall: 'mat_solid:local_mall',
      LocalMovies: 'mat_solid:local_movies',
      LocalOffer: 'mat_solid:local_offer',
      LocalParking: 'mat_solid:local_parking',
      LocalPharmacy: 'mat_solid:local_pharmacy',
      LocalPhone: 'mat_solid:local_phone',
      LocalPizza: 'mat_solid:local_pizza',
      LocalPlay: 'mat_solid:local_play',
      LocalPolice: 'mat_solid:local_police',
      LocalPostOffice: 'mat_solid:local_post_office',
      LocalPrintshop: 'mat_solid:local_printshop',
      LocalSee: 'mat_solid:local_see',
      LocalShipping: 'mat_solid:local_shipping',
      LocalTaxi: 'mat_solid:local_taxi',
      LocationCity: 'mat_solid:location_city',
      LocationDisabled: 'mat_solid:location_disabled',
      LocationOff: 'mat_solid:location_off',
      LocationOn: 'mat_solid:location_on',
      LocationSearching: 'mat_solid:location_searching',
      Lock: 'mat_solid:lock',
      LockClock: 'mat_solid:lock_clock',
      LockOpen: 'mat_solid:lock_open',
      Login: 'mat_solid:login',
      Logout: 'mat_solid:logout',
      Looks: 'mat_solid:looks',
      Looks3: 'mat_solid:looks_3',
      Looks4: 'mat_solid:looks_4',
      Looks5: 'mat_solid:looks_5',
      Looks6: 'mat_solid:looks_6',
      LooksOne: 'mat_solid:looks_one',
      LooksTwo: 'mat_solid:looks_two',
      Loop: 'mat_solid:loop',
      Loupe: 'mat_solid:loupe',
      LowPriority: 'mat_solid:low_priority',
      Loyalty: 'mat_solid:loyalty',
      LteMobiledata: 'mat_solid:lte_mobiledata',
      LtePlusMobiledata: 'mat_solid:lte_plus_mobiledata',
      Luggage: 'mat_solid:luggage',
      LunchDining: 'mat_solid:lunch_dining',
      Mail: 'mat_solid:mail',
      MailOutline: 'mat_solid:mail_outline',
      Male: 'mat_solid:male',
      ManageAccounts: 'mat_solid:manage_accounts',
      ManageSearch: 'mat_solid:manage_search',
      Map: 'mat_solid:map',
      MapsHomeWork: 'mat_solid:maps_home_work',
      MapsUgc: 'mat_solid:maps_ugc',
      Margin: 'mat_solid:margin',
      MarkAsUnread: 'mat_solid:mark_as_unread',
      MarkChatRead: 'mat_solid:mark_chat_read',
      MarkChatUnread: 'mat_solid:mark_chat_unread',
      MarkEmailRead: 'mat_solid:mark_email_read',
      MarkEmailUnread: 'mat_solid:mark_email_unread',
      Markunread: 'mat_solid:markunread',
      MarkunreadMailbox: 'mat_solid:markunread_mailbox',
      Masks: 'mat_solid:masks',
      Maximize: 'mat_solid:maximize',
      MediaBluetoothOff: 'mat_solid:media_bluetooth_off',
      MediaBluetoothOn: 'mat_solid:media_bluetooth_on',
      Mediation: 'mat_solid:mediation',
      MedicalServices: 'mat_solid:medical_services',
      Medication: 'mat_solid:medication',
      MeetingRoom: 'mat_solid:meeting_room',
      Memory: 'mat_solid:memory',
      Menu: 'mat_solid:menu',
      MenuBook: 'mat_solid:menu_book',
      MenuOpen: 'mat_solid:menu_open',
      MergeType: 'mat_solid:merge_type',
      Message: 'mat_solid:message',
      Mic: 'mat_solid:mic',
      MicExternalOff: 'mat_solid:mic_external_off',
      MicExternalOn: 'mat_solid:mic_external_on',
      MicNone: 'mat_solid:mic_none',
      MicOff: 'mat_solid:mic_off',
      Microwave: 'mat_solid:microwave',
      MilitaryTech: 'mat_solid:military_tech',
      Minimize: 'mat_solid:minimize',
      MiscellaneousServices: 'mat_solid:miscellaneous_services',
      MissedVideoCall: 'mat_solid:missed_video_call',
      Mms: 'mat_solid:mms',
      MobileFriendly: 'mat_solid:mobile_friendly',
      MobileOff: 'mat_solid:mobile_off',
      MobileScreenShare: 'mat_solid:mobile_screen_share',
      MobiledataOff: 'mat_solid:mobiledata_off',
      Mode: 'mat_solid:mode',
      ModeComment: 'mat_solid:mode_comment',
      ModeEdit: 'mat_solid:mode_edit',
      ModeEditOutline: 'mat_solid:mode_edit_outline',
      ModeNight: 'mat_solid:mode_night',
      ModeStandby: 'mat_solid:mode_standby',
      ModelTraining: 'mat_solid:model_training',
      MonetizationOn: 'mat_solid:monetization_on',
      Money: 'mat_solid:money',
      MoneyOff: 'mat_solid:money_off',
      MoneyOffCsred: 'mat_solid:money_off_csred',
      Monitor: 'mat_solid:monitor',
      MonitorWeight: 'mat_solid:monitor_weight',
      MonochromePhotos: 'mat_solid:monochrome_photos',
      Mood: 'mat_solid:mood',
      MoodBad: 'mat_solid:mood_bad',
      Moped: 'mat_solid:moped',
      More: 'mat_solid:more',
      MoreHoriz: 'mat_solid:more_horiz',
      MoreTime: 'mat_solid:more_time',
      MoreVert: 'mat_solid:more_vert',
      MotionPhotosAuto: 'mat_solid:motion_photos_auto',
      MotionPhotosOff: 'mat_solid:motion_photos_off',
      MotionPhotosOn: 'mat_solid:motion_photos_on',
      MotionPhotosPause: 'mat_solid:motion_photos_pause',
      MotionPhotosPaused: 'mat_solid:motion_photos_paused',
      Mouse: 'mat_solid:mouse',
      MoveToInbox: 'mat_solid:move_to_inbox',
      Movie: 'mat_solid:movie',
      MovieCreation: 'mat_solid:movie_creation',
      MovieFilter: 'mat_solid:movie_filter',
      Moving: 'mat_solid:moving',
      Mp: 'mat_solid:mp',
      MultilineChart: 'mat_solid:multiline_chart',
      MultipleStop: 'mat_solid:multiple_stop',
      Museum: 'mat_solid:museum',
      MusicNote: 'mat_solid:music_note',
      MusicOff: 'mat_solid:music_off',
      MusicVideo: 'mat_solid:music_video',
      MyLocation: 'mat_solid:my_location',
      Nat: 'mat_solid:nat',
      Nature: 'mat_solid:nature',
      NaturePeople: 'mat_solid:nature_people',
      NavigateBefore: 'mat_solid:navigate_before',
      NavigateNext: 'mat_solid:navigate_next',
      Navigation: 'mat_solid:navigation',
      NearMe: 'mat_solid:near_me',
      NearMeDisabled: 'mat_solid:near_me_disabled',
      NearbyError: 'mat_solid:nearby_error',
      NearbyOff: 'mat_solid:nearby_off',
      NetworkCell: 'mat_solid:network_cell',
      NetworkCheck: 'mat_solid:network_check',
      NetworkLocked: 'mat_solid:network_locked',
      NetworkWifi: 'mat_solid:network_wifi',
      NewLabel: 'mat_solid:new_label',
      NewReleases: 'mat_solid:new_releases',
      NextPlan: 'mat_solid:next_plan',
      NextWeek: 'mat_solid:next_week',
      Nfc: 'mat_solid:nfc',
      NightShelter: 'mat_solid:night_shelter',
      Nightlife: 'mat_solid:nightlife',
      Nightlight: 'mat_solid:nightlight',
      NightlightRound: 'mat_solid:nightlight_round',
      NightsStay: 'mat_solid:nights_stay',
      NoAccounts: 'mat_solid:no_accounts',
      NoBackpack: 'mat_solid:no_backpack',
      NoCell: 'mat_solid:no_cell',
      NoDrinks: 'mat_solid:no_drinks',
      NoEncryption: 'mat_solid:no_encryption',
      NoEncryptionGmailerrorred: 'mat_solid:no_encryption_gmailerrorred',
      NoFlash: 'mat_solid:no_flash',
      NoFood: 'mat_solid:no_food',
      NoLuggage: 'mat_solid:no_luggage',
      NoMeals: 'mat_solid:no_meals',
      NoMeetingRoom: 'mat_solid:no_meeting_room',
      NoPhotography: 'mat_solid:no_photography',
      NoSim: 'mat_solid:no_sim',
      NoStroller: 'mat_solid:no_stroller',
      NoTransfer: 'mat_solid:no_transfer',
      NordicWalking: 'mat_solid:nordic_walking',
      North: 'mat_solid:north',
      NorthEast: 'mat_solid:north_east',
      NorthWest: 'mat_solid:north_west',
      NotAccessible: 'mat_solid:not_accessible',
      NotInterested: 'mat_solid:not_interested',
      NotListedLocation: 'mat_solid:not_listed_location',
      NotStarted: 'mat_solid:not_started',
      Note: 'mat_solid:note',
      NoteAdd: 'mat_solid:note_add',
      NoteAlt: 'mat_solid:note_alt',
      Notes: 'mat_solid:notes',
      NotificationAdd: 'mat_solid:notification_add',
      NotificationImportant: 'mat_solid:notification_important',
      Notifications: 'mat_solid:notifications',
      NotificationsActive: 'mat_solid:notifications_active',
      NotificationsNone: 'mat_solid:notifications_none',
      NotificationsOff: 'mat_solid:notifications_off',
      NotificationsPaused: 'mat_solid:notifications_paused',
      OfflineBolt: 'mat_solid:offline_bolt',
      OfflinePin: 'mat_solid:offline_pin',
      OfflineShare: 'mat_solid:offline_share',
      OndemandVideo: 'mat_solid:ondemand_video',
      OnlinePrediction: 'mat_solid:online_prediction',
      Opacity: 'mat_solid:opacity',
      OpenInBrowser: 'mat_solid:open_in_browser',
      OpenInFull: 'mat_solid:open_in_full',
      OpenInNew: 'mat_solid:open_in_new',
      OpenInNewOff: 'mat_solid:open_in_new_off',
      OpenWith: 'mat_solid:open_with',
      OtherHouses: 'mat_solid:other_houses',
      Outbound: 'mat_solid:outbound',
      Outbox: 'mat_solid:outbox',
      OutdoorGrill: 'mat_solid:outdoor_grill',
      Outlet: 'mat_solid:outlet',
      OutlinedFlag: 'mat_solid:outlined_flag',
      Padding: 'mat_solid:padding',
      Pages: 'mat_solid:pages',
      Pageview: 'mat_solid:pageview',
      Paid: 'mat_solid:paid',
      Palette: 'mat_solid:palette',
      PanTool: 'mat_solid:pan_tool',
      Panorama: 'mat_solid:panorama',
      PanoramaFishEye: 'mat_solid:panorama_fish_eye',
      PanoramaHorizontal: 'mat_solid:panorama_horizontal',
      PanoramaHorizontalSelect: 'mat_solid:panorama_horizontal_select',
      PanoramaPhotosphere: 'mat_solid:panorama_photosphere',
      PanoramaPhotosphereSelect: 'mat_solid:panorama_photosphere_select',
      PanoramaVertical: 'mat_solid:panorama_vertical',
      PanoramaVerticalSelect: 'mat_solid:panorama_vertical_select',
      PanoramaWideAngle: 'mat_solid:panorama_wide_angle',
      PanoramaWideAngleSelect: 'mat_solid:panorama_wide_angle_select',
      Paragliding: 'mat_solid:paragliding',
      Park: 'mat_solid:park',
      PartyMode: 'mat_solid:party_mode',
      Password: 'mat_solid:password',
      Pattern: 'mat_solid:pattern',
      Pause: 'mat_solid:pause',
      PauseCircle: 'mat_solid:pause_circle',
      PauseCircleFilled: 'mat_solid:pause_circle_filled',
      PauseCircleOutline: 'mat_solid:pause_circle_outline',
      PausePresentation: 'mat_solid:pause_presentation',
      Payment: 'mat_solid:payment',
      Payments: 'mat_solid:payments',
      PedalBike: 'mat_solid:pedal_bike',
      Pending: 'mat_solid:pending',
      PendingActions: 'mat_solid:pending_actions',
      People: 'mat_solid:people',
      PeopleAlt: 'mat_solid:people_alt',
      PeopleOutline: 'mat_solid:people_outline',
      PermCameraMic: 'mat_solid:perm_camera_mic',
      PermContactCalendar: 'mat_solid:perm_contact_calendar',
      PermDataSetting: 'mat_solid:perm_data_setting',
      PermDeviceInformation: 'mat_solid:perm_device_information',
      PermIdentity: 'mat_solid:perm_identity',
      PermMedia: 'mat_solid:perm_media',
      PermPhoneMsg: 'mat_solid:perm_phone_msg',
      PermScanWifi: 'mat_solid:perm_scan_wifi',
      Person: 'mat_solid:person',
      PersonAdd: 'mat_solid:person_add',
      PersonAddAlt: 'mat_solid:person_add_alt',
      PersonAddAlt1: 'mat_solid:person_add_alt_1',
      PersonAddDisabled: 'mat_solid:person_add_disabled',
      PersonOff: 'mat_solid:person_off',
      PersonOutline: 'mat_solid:person_outline',
      PersonPin: 'mat_solid:person_pin',
      PersonPinCircle: 'mat_solid:person_pin_circle',
      PersonRemove: 'mat_solid:person_remove',
      PersonRemoveAlt1: 'mat_solid:person_remove_alt_1',
      PersonSearch: 'mat_solid:person_search',
      PersonalInjury: 'mat_solid:personal_injury',
      PersonalVideo: 'mat_solid:personal_video',
      PestControl: 'mat_solid:pest_control',
      PestControlRodent: 'mat_solid:pest_control_rodent',
      Pets: 'mat_solid:pets',
      Phone: 'mat_solid:phone',
      PhoneAndroid: 'mat_solid:phone_android',
      PhoneBluetoothSpeaker: 'mat_solid:phone_bluetooth_speaker',
      PhoneCallback: 'mat_solid:phone_callback',
      PhoneDisabled: 'mat_solid:phone_disabled',
      PhoneEnabled: 'mat_solid:phone_enabled',
      PhoneForwarded: 'mat_solid:phone_forwarded',
      PhoneInTalk: 'mat_solid:phone_in_talk',
      PhoneIphone: 'mat_solid:phone_iphone',
      PhoneLocked: 'mat_solid:phone_locked',
      PhoneMissed: 'mat_solid:phone_missed',
      PhonePaused: 'mat_solid:phone_paused',
      Phonelink: 'mat_solid:phonelink',
      PhonelinkErase: 'mat_solid:phonelink_erase',
      PhonelinkLock: 'mat_solid:phonelink_lock',
      PhonelinkOff: 'mat_solid:phonelink_off',
      PhonelinkRing: 'mat_solid:phonelink_ring',
      PhonelinkSetup: 'mat_solid:phonelink_setup',
      Photo: 'mat_solid:photo',
      PhotoAlbum: 'mat_solid:photo_album',
      PhotoCamera: 'mat_solid:photo_camera',
      PhotoCameraBack: 'mat_solid:photo_camera_back',
      PhotoCameraFront: 'mat_solid:photo_camera_front',
      PhotoFilter: 'mat_solid:photo_filter',
      PhotoLibrary: 'mat_solid:photo_library',
      PhotoSizeSelectActual: 'mat_solid:photo_size_select_actual',
      PhotoSizeSelectLarge: 'mat_solid:photo_size_select_large',
      PhotoSizeSelectSmall: 'mat_solid:photo_size_select_small',
      Piano: 'mat_solid:piano',
      PianoOff: 'mat_solid:piano_off',
      PictureAsPdf: 'mat_solid:picture_as_pdf',
      PictureInPicture: 'mat_solid:picture_in_picture',
      PictureInPictureAlt: 'mat_solid:picture_in_picture_alt',
      PieChart: 'mat_solid:pie_chart',
      PieChartOutline: 'mat_solid:pie_chart_outline',
      Pin: 'mat_solid:pin',
      PinDrop: 'mat_solid:pin_drop',
      PinEnd: 'mat_solid:pin_end',
      PinInvoke: 'mat_solid:pin_invoke',
      PivotTableChart: 'mat_solid:pivot_table_chart',
      Place: 'mat_solid:place',
      Plagiarism: 'mat_solid:plagiarism',
      PlayArrow: 'mat_solid:play_arrow',
      PlayCircle: 'mat_solid:play_circle',
      PlayCircleFilled: 'mat_solid:play_circle_filled',
      PlayCircleOutline: 'mat_solid:play_circle_outline',
      PlayDisabled: 'mat_solid:play_disabled',
      PlayForWork: 'mat_solid:play_for_work',
      PlayLesson: 'mat_solid:play_lesson',
      PlaylistAdd: 'mat_solid:playlist_add',
      PlaylistAddCheck: 'mat_solid:playlist_add_check',
      PlaylistPlay: 'mat_solid:playlist_play',
      Plumbing: 'mat_solid:plumbing',
      PlusOne: 'mat_solid:plus_one',
      Podcasts: 'mat_solid:podcasts',
      PointOfSale: 'mat_solid:point_of_sale',
      Policy: 'mat_solid:policy',
      Poll: 'mat_solid:poll',
      Polymer: 'mat_solid:polymer',
      Pool: 'mat_solid:pool',
      PortableWifiOff: 'mat_solid:portable_wifi_off',
      Portrait: 'mat_solid:portrait',
      PostAdd: 'mat_solid:post_add',
      Power: 'mat_solid:power',
      PowerInput: 'mat_solid:power_input',
      PowerOff: 'mat_solid:power_off',
      PowerSettingsNew: 'mat_solid:power_settings_new',
      PrecisionManufacturing: 'mat_solid:precision_manufacturing',
      PregnantWoman: 'mat_solid:pregnant_woman',
      PresentToAll: 'mat_solid:present_to_all',
      Preview: 'mat_solid:preview',
      PriceChange: 'mat_solid:price_change',
      PriceCheck: 'mat_solid:price_check',
      Print: 'mat_solid:print',
      PrintDisabled: 'mat_solid:print_disabled',
      PriorityHigh: 'mat_solid:priority_high',
      PrivacyTip: 'mat_solid:privacy_tip',
      PrivateConnectivity: 'mat_solid:private_connectivity',
      ProductionQuantityLimits: 'mat_solid:production_quantity_limits',
      Psychology: 'mat_solid:psychology',
      Public: 'mat_solid:public',
      PublicOff: 'mat_solid:public_off',
      Publish: 'mat_solid:publish',
      PublishedWithChanges: 'mat_solid:published_with_changes',
      PushPin: 'mat_solid:push_pin',
      QrCode: 'mat_solid:qr_code',
      QrCode2: 'mat_solid:qr_code_2',
      QrCodeScanner: 'mat_solid:qr_code_scanner',
      QueryBuilder: 'mat_solid:query_builder',
      QueryStats: 'mat_solid:query_stats',
      QuestionAnswer: 'mat_solid:question_answer',
      Queue: 'mat_solid:queue',
      QueueMusic: 'mat_solid:queue_music',
      QueuePlayNext: 'mat_solid:queue_play_next',
      Quickreply: 'mat_solid:quickreply',
      Quiz: 'mat_solid:quiz',
      RMobiledata: 'mat_solid:r_mobiledata',
      Radar: 'mat_solid:radar',
      Radio: 'mat_solid:radio',
      RadioButtonChecked: 'mat_solid:radio_button_checked',
      RadioButtonUnchecked: 'mat_solid:radio_button_unchecked',
      RailwayAlert: 'mat_solid:railway_alert',
      RamenDining: 'mat_solid:ramen_dining',
      RateReview: 'mat_solid:rate_review',
      RawOff: 'mat_solid:raw_off',
      RawOn: 'mat_solid:raw_on',
      ReadMore: 'mat_solid:read_more',
      RealEstateAgent: 'mat_solid:real_estate_agent',
      Receipt: 'mat_solid:receipt',
      ReceiptLong: 'mat_solid:receipt_long',
      RecentActors: 'mat_solid:recent_actors',
      Recommend: 'mat_solid:recommend',
      RecordVoiceOver: 'mat_solid:record_voice_over',
      Recycling: 'mat_solid:recycling',
      Redeem: 'mat_solid:redeem',
      Redo: 'mat_solid:redo',
      ReduceCapacity: 'mat_solid:reduce_capacity',
      Refresh: 'mat_solid:refresh',
      RememberMe: 'mat_solid:remember_me',
      Remove: 'mat_solid:remove',
      RemoveCircle: 'mat_solid:remove_circle',
      RemoveCircleOutline: 'mat_solid:remove_circle_outline',
      RemoveDone: 'mat_solid:remove_done',
      RemoveFromQueue: 'mat_solid:remove_from_queue',
      RemoveModerator: 'mat_solid:remove_moderator',
      RemoveRedEye: 'mat_solid:remove_red_eye',
      RemoveShoppingCart: 'mat_solid:remove_shopping_cart',
      Reorder: 'mat_solid:reorder',
      Repeat: 'mat_solid:repeat',
      RepeatOn: 'mat_solid:repeat_on',
      RepeatOne: 'mat_solid:repeat_one',
      RepeatOneOn: 'mat_solid:repeat_one_on',
      Replay: 'mat_solid:replay',
      Replay10: 'mat_solid:replay_10',
      Replay30: 'mat_solid:replay_30',
      Replay5: 'mat_solid:replay_5',
      ReplayCircleFilled: 'mat_solid:replay_circle_filled',
      Reply: 'mat_solid:reply',
      ReplyAll: 'mat_solid:reply_all',
      Report: 'mat_solid:report',
      ReportGmailerrorred: 'mat_solid:report_gmailerrorred',
      ReportOff: 'mat_solid:report_off',
      ReportProblem: 'mat_solid:report_problem',
      RequestPage: 'mat_solid:request_page',
      RequestQuote: 'mat_solid:request_quote',
      ResetTv: 'mat_solid:reset_tv',
      RestartAlt: 'mat_solid:restart_alt',
      Restaurant: 'mat_solid:restaurant',
      RestaurantMenu: 'mat_solid:restaurant_menu',
      Restore: 'mat_solid:restore',
      RestoreFromTrash: 'mat_solid:restore_from_trash',
      RestorePage: 'mat_solid:restore_page',
      Reviews: 'mat_solid:reviews',
      RiceBowl: 'mat_solid:rice_bowl',
      RingVolume: 'mat_solid:ring_volume',
      Roofing: 'mat_solid:roofing',
      Room: 'mat_solid:room',
      RoomPreferences: 'mat_solid:room_preferences',
      RoomService: 'mat_solid:room_service',
      Rotate90DegreesCcw: 'mat_solid:rotate_90_degrees_ccw',
      RotateLeft: 'mat_solid:rotate_left',
      RotateRight: 'mat_solid:rotate_right',
      RoundedCorner: 'mat_solid:rounded_corner',
      Router: 'mat_solid:router',
      Rowing: 'mat_solid:rowing',
      RssFeed: 'mat_solid:rss_feed',
      Rsvp: 'mat_solid:rsvp',
      Rtt: 'mat_solid:rtt',
      Rule: 'mat_solid:rule',
      RuleFolder: 'mat_solid:rule_folder',
      RunCircle: 'mat_solid:run_circle',
      RunningWithErrors: 'mat_solid:running_with_errors',
      RvHookup: 'mat_solid:rv_hookup',
      SafetyDivider: 'mat_solid:safety_divider',
      Sailing: 'mat_solid:sailing',
      Sanitizer: 'mat_solid:sanitizer',
      Satellite: 'mat_solid:satellite',
      Save: 'mat_solid:save',
      SaveAlt: 'mat_solid:save_alt',
      SavedSearch: 'mat_solid:saved_search',
      Savings: 'mat_solid:savings',
      Scanner: 'mat_solid:scanner',
      ScatterPlot: 'mat_solid:scatter_plot',
      Schedule: 'mat_solid:schedule',
      ScheduleSend: 'mat_solid:schedule_send',
      Schema: 'mat_solid:schema',
      School: 'mat_solid:school',
      Science: 'mat_solid:science',
      Score: 'mat_solid:score',
      ScreenLockLandscape: 'mat_solid:screen_lock_landscape',
      ScreenLockPortrait: 'mat_solid:screen_lock_portrait',
      ScreenLockRotation: 'mat_solid:screen_lock_rotation',
      ScreenRotation: 'mat_solid:screen_rotation',
      ScreenSearchDesktop: 'mat_solid:screen_search_desktop',
      ScreenShare: 'mat_solid:screen_share',
      Screenshot: 'mat_solid:screenshot',
      Sd: 'mat_solid:sd',
      SdCard: 'mat_solid:sd_card',
      SdCardAlert: 'mat_solid:sd_card_alert',
      SdStorage: 'mat_solid:sd_storage',
      Search: 'mat_solid:search',
      SearchOff: 'mat_solid:search_off',
      Security: 'mat_solid:security',
      SecurityUpdate: 'mat_solid:security_update',
      SecurityUpdateGood: 'mat_solid:security_update_good',
      SecurityUpdateWarning: 'mat_solid:security_update_warning',
      Segment: 'mat_solid:segment',
      SelectAll: 'mat_solid:select_all',
      SelfImprovement: 'mat_solid:self_improvement',
      Sell: 'mat_solid:sell',
      Send: 'mat_solid:send',
      SendAndArchive: 'mat_solid:send_and_archive',
      SendToMobile: 'mat_solid:send_to_mobile',
      SensorDoor: 'mat_solid:sensor_door',
      SensorWindow: 'mat_solid:sensor_window',
      Sensors: 'mat_solid:sensors',
      SensorsOff: 'mat_solid:sensors_off',
      SentimentDissatisfied: 'mat_solid:sentiment_dissatisfied',
      SentimentNeutral: 'mat_solid:sentiment_neutral',
      SentimentSatisfied: 'mat_solid:sentiment_satisfied',
      SentimentSatisfiedAlt: 'mat_solid:sentiment_satisfied_alt',
      SentimentVeryDissatisfied: 'mat_solid:sentiment_very_dissatisfied',
      SentimentVerySatisfied: 'mat_solid:sentiment_very_satisfied',
      SetMeal: 'mat_solid:set_meal',
      Settings: 'mat_solid:settings',
      SettingsAccessibility: 'mat_solid:settings_accessibility',
      SettingsApplications: 'mat_solid:settings_applications',
      SettingsBackupRestore: 'mat_solid:settings_backup_restore',
      SettingsBluetooth: 'mat_solid:settings_bluetooth',
      SettingsBrightness: 'mat_solid:settings_brightness',
      SettingsCell: 'mat_solid:settings_cell',
      SettingsEthernet: 'mat_solid:settings_ethernet',
      SettingsInputAntenna: 'mat_solid:settings_input_antenna',
      SettingsInputComponent: 'mat_solid:settings_input_component',
      SettingsInputComposite: 'mat_solid:settings_input_composite',
      SettingsInputHdmi: 'mat_solid:settings_input_hdmi',
      SettingsInputSvideo: 'mat_solid:settings_input_svideo',
      SettingsOverscan: 'mat_solid:settings_overscan',
      SettingsPhone: 'mat_solid:settings_phone',
      SettingsPower: 'mat_solid:settings_power',
      SettingsRemote: 'mat_solid:settings_remote',
      SettingsSuggest: 'mat_solid:settings_suggest',
      SettingsSystemDaydream: 'mat_solid:settings_system_daydream',
      SettingsVoice: 'mat_solid:settings_voice',
      Share: 'mat_solid:share',
      ShareLocation: 'mat_solid:share_location',
      Shield: 'mat_solid:shield',
      Shop: 'mat_solid:shop',
      Shop2: 'mat_solid:shop_2',
      ShopTwo: 'mat_solid:shop_two',
      ShoppingBag: 'mat_solid:shopping_bag',
      ShoppingBasket: 'mat_solid:shopping_basket',
      ShoppingCart: 'mat_solid:shopping_cart',
      ShortText: 'mat_solid:short_text',
      Shortcut: 'mat_solid:shortcut',
      ShowChart: 'mat_solid:show_chart',
      Shower: 'mat_solid:shower',
      Shuffle: 'mat_solid:shuffle',
      ShuffleOn: 'mat_solid:shuffle_on',
      ShutterSpeed: 'mat_solid:shutter_speed',
      Sick: 'mat_solid:sick',
      SignalCellular0Bar: 'mat_solid:signal_cellular_0_bar',
      SignalCellular4Bar: 'mat_solid:signal_cellular_4_bar',
      SignalCellularAlt: 'mat_solid:signal_cellular_alt',
      SignalCellularConnectedNoInternet0Bar:
        'mat_solid:signal_cellular_connected_no_internet_0_bar',
      SignalCellularConnectedNoInternet4Bar:
        'mat_solid:signal_cellular_connected_no_internet_4_bar',
      SignalCellularNoSim: 'mat_solid:signal_cellular_no_sim',
      SignalCellularNodata: 'mat_solid:signal_cellular_nodata',
      SignalCellularNull: 'mat_solid:signal_cellular_null',
      SignalCellularOff: 'mat_solid:signal_cellular_off',
      SignalWifi0Bar: 'mat_solid:signal_wifi_0_bar',
      SignalWifi4Bar: 'mat_solid:signal_wifi_4_bar',
      SignalWifi4BarLock: 'mat_solid:signal_wifi_4_bar_lock',
      SignalWifiBad: 'mat_solid:signal_wifi_bad',
      SignalWifiConnectedNoInternet4: 'mat_solid:signal_wifi_connected_no_internet_4',
      SignalWifiOff: 'mat_solid:signal_wifi_off',
      SignalWifiStatusbar4Bar: 'mat_solid:signal_wifi_statusbar_4_bar',
      SignalWifiStatusbarConnectedNoInternet4:
        'mat_solid:signal_wifi_statusbar_connected_no_internet_4',
      SignalWifiStatusbarNull: 'mat_solid:signal_wifi_statusbar_null',
      SimCard: 'mat_solid:sim_card',
      SimCardAlert: 'mat_solid:sim_card_alert',
      SimCardDownload: 'mat_solid:sim_card_download',
      SingleBed: 'mat_solid:single_bed',
      Sip: 'mat_solid:sip',
      Skateboarding: 'mat_solid:skateboarding',
      SkipNext: 'mat_solid:skip_next',
      SkipPrevious: 'mat_solid:skip_previous',
      Sledding: 'mat_solid:sledding',
      Slideshow: 'mat_solid:slideshow',
      SlowMotionVideo: 'mat_solid:slow_motion_video',
      SmartButton: 'mat_solid:smart_button',
      SmartDisplay: 'mat_solid:smart_display',
      SmartScreen: 'mat_solid:smart_screen',
      SmartToy: 'mat_solid:smart_toy',
      Smartphone: 'mat_solid:smartphone',
      SmokeFree: 'mat_solid:smoke_free',
      SmokingRooms: 'mat_solid:smoking_rooms',
      Sms: 'mat_solid:sms',
      SmsFailed: 'mat_solid:sms_failed',
      SnippetFolder: 'mat_solid:snippet_folder',
      Snooze: 'mat_solid:snooze',
      Snowboarding: 'mat_solid:snowboarding',
      Snowmobile: 'mat_solid:snowmobile',
      Snowshoeing: 'mat_solid:snowshoeing',
      Soap: 'mat_solid:soap',
      SocialDistance: 'mat_solid:social_distance',
      Sort: 'mat_solid:sort',
      SortByAlpha: 'mat_solid:sort_by_alpha',
      Source: 'mat_solid:source',
      South: 'mat_solid:south',
      SouthEast: 'mat_solid:south_east',
      SouthWest: 'mat_solid:south_west',
      Spa: 'mat_solid:spa',
      SpaceBar: 'mat_solid:space_bar',
      SpaceDashboard: 'mat_solid:space_dashboard',
      Speaker: 'mat_solid:speaker',
      SpeakerGroup: 'mat_solid:speaker_group',
      SpeakerNotes: 'mat_solid:speaker_notes',
      SpeakerNotesOff: 'mat_solid:speaker_notes_off',
      SpeakerPhone: 'mat_solid:speaker_phone',
      Speed: 'mat_solid:speed',
      Spellcheck: 'mat_solid:spellcheck',
      Splitscreen: 'mat_solid:splitscreen',
      Sports: 'mat_solid:sports',
      SportsBar: 'mat_solid:sports_bar',
      SportsBaseball: 'mat_solid:sports_baseball',
      SportsBasketball: 'mat_solid:sports_basketball',
      SportsCricket: 'mat_solid:sports_cricket',
      SportsEsports: 'mat_solid:sports_esports',
      SportsFootball: 'mat_solid:sports_football',
      SportsGolf: 'mat_solid:sports_golf',
      SportsHandball: 'mat_solid:sports_handball',
      SportsHockey: 'mat_solid:sports_hockey',
      SportsKabaddi: 'mat_solid:sports_kabaddi',
      SportsMma: 'mat_solid:sports_mma',
      SportsMotorsports: 'mat_solid:sports_motorsports',
      SportsRugby: 'mat_solid:sports_rugby',
      SportsScore: 'mat_solid:sports_score',
      SportsSoccer: 'mat_solid:sports_soccer',
      SportsTennis: 'mat_solid:sports_tennis',
      SportsVolleyball: 'mat_solid:sports_volleyball',
      SquareFoot: 'mat_solid:square_foot',
      StackedBarChart: 'mat_solid:stacked_bar_chart',
      StackedLineChart: 'mat_solid:stacked_line_chart',
      Stairs: 'mat_solid:stairs',
      Star: 'mat_solid:star',
      StarBorder: 'mat_solid:star_border',
      StarBorderPurple500: 'mat_solid:star_border_purple500',
      StarHalf: 'mat_solid:star_half',
      StarOutline: 'mat_solid:star_outline',
      StarPurple500: 'mat_solid:star_purple500',
      StarRate: 'mat_solid:star_rate',
      Stars: 'mat_solid:stars',
      StayCurrentLandscape: 'mat_solid:stay_current_landscape',
      StayCurrentPortrait: 'mat_solid:stay_current_portrait',
      StayPrimaryLandscape: 'mat_solid:stay_primary_landscape',
      StayPrimaryPortrait: 'mat_solid:stay_primary_portrait',
      StickyNote2: 'mat_solid:sticky_note_2',
      Stop: 'mat_solid:stop',
      StopCircle: 'mat_solid:stop_circle',
      StopScreenShare: 'mat_solid:stop_screen_share',
      Storage: 'mat_solid:storage',
      Store: 'mat_solid:store',
      StoreMallDirectory: 'mat_solid:store_mall_directory',
      Storefront: 'mat_solid:storefront',
      Storm: 'mat_solid:storm',
      Straighten: 'mat_solid:straighten',
      Stream: 'mat_solid:stream',
      Streetview: 'mat_solid:streetview',
      StrikethroughS: 'mat_solid:strikethrough_s',
      Stroller: 'mat_solid:stroller',
      Style: 'mat_solid:style',
      SubdirectoryArrowLeft: 'mat_solid:subdirectory_arrow_left',
      SubdirectoryArrowRight: 'mat_solid:subdirectory_arrow_right',
      Subject: 'mat_solid:subject',
      Subscript: 'mat_solid:subscript',
      Subscriptions: 'mat_solid:subscriptions',
      Subtitles: 'mat_solid:subtitles',
      SubtitlesOff: 'mat_solid:subtitles_off',
      Subway: 'mat_solid:subway',
      Summarize: 'mat_solid:summarize',
      Superscript: 'mat_solid:superscript',
      SupervisedUserCircle: 'mat_solid:supervised_user_circle',
      SupervisorAccount: 'mat_solid:supervisor_account',
      Support: 'mat_solid:support',
      SupportAgent: 'mat_solid:support_agent',
      Surfing: 'mat_solid:surfing',
      SurroundSound: 'mat_solid:surround_sound',
      SwapCalls: 'mat_solid:swap_calls',
      SwapHoriz: 'mat_solid:swap_horiz',
      SwapHorizontalCircle: 'mat_solid:swap_horizontal_circle',
      SwapVert: 'mat_solid:swap_vert',
      SwapVerticalCircle: 'mat_solid:swap_vertical_circle',
      Swipe: 'mat_solid:swipe',
      SwitchAccount: 'mat_solid:switch_account',
      SwitchCamera: 'mat_solid:switch_camera',
      SwitchLeft: 'mat_solid:switch_left',
      SwitchRight: 'mat_solid:switch_right',
      SwitchVideo: 'mat_solid:switch_video',
      Sync: 'mat_solid:sync',
      SyncAlt: 'mat_solid:sync_alt',
      SyncDisabled: 'mat_solid:sync_disabled',
      SyncProblem: 'mat_solid:sync_problem',
      SystemSecurityUpdate: 'mat_solid:system_security_update',
      SystemSecurityUpdateGood: 'mat_solid:system_security_update_good',
      SystemSecurityUpdateWarning: 'mat_solid:system_security_update_warning',
      SystemUpdate: 'mat_solid:system_update',
      SystemUpdateAlt: 'mat_solid:system_update_alt',
      Tab: 'mat_solid:tab',
      TabUnselected: 'mat_solid:tab_unselected',
      TableChart: 'mat_solid:table_chart',
      TableRows: 'mat_solid:table_rows',
      TableView: 'mat_solid:table_view',
      Tablet: 'mat_solid:tablet',
      TabletAndroid: 'mat_solid:tablet_android',
      TabletMac: 'mat_solid:tablet_mac',
      Tag: 'mat_solid:tag',
      TagFaces: 'mat_solid:tag_faces',
      TakeoutDining: 'mat_solid:takeout_dining',
      TapAndPlay: 'mat_solid:tap_and_play',
      Tapas: 'mat_solid:tapas',
      Task: 'mat_solid:task',
      TaskAlt: 'mat_solid:task_alt',
      TaxiAlert: 'mat_solid:taxi_alert',
      Terrain: 'mat_solid:terrain',
      TextFields: 'mat_solid:text_fields',
      TextFormat: 'mat_solid:text_format',
      TextRotateUp: 'mat_solid:text_rotate_up',
      TextRotateVertical: 'mat_solid:text_rotate_vertical',
      TextRotationAngledown: 'mat_solid:text_rotation_angledown',
      TextRotationAngleup: 'mat_solid:text_rotation_angleup',
      TextRotationDown: 'mat_solid:text_rotation_down',
      TextRotationNone: 'mat_solid:text_rotation_none',
      TextSnippet: 'mat_solid:text_snippet',
      Textsms: 'mat_solid:textsms',
      Texture: 'mat_solid:texture',
      TheaterComedy: 'mat_solid:theater_comedy',
      Theaters: 'mat_solid:theaters',
      Thermostat: 'mat_solid:thermostat',
      ThermostatAuto: 'mat_solid:thermostat_auto',
      ThumbDown: 'mat_solid:thumb_down',
      ThumbDownAlt: 'mat_solid:thumb_down_alt',
      ThumbDownOffAlt: 'mat_solid:thumb_down_off_alt',
      ThumbUp: 'mat_solid:thumb_up',
      ThumbUpAlt: 'mat_solid:thumb_up_alt',
      ThumbUpOffAlt: 'mat_solid:thumb_up_off_alt',
      ThumbsUpDown: 'mat_solid:thumbs_up_down',
      TimeToLeave: 'mat_solid:time_to_leave',
      Timelapse: 'mat_solid:timelapse',
      Timeline: 'mat_solid:timeline',
      Timer: 'mat_solid:timer',
      Timer10: 'mat_solid:timer_10',
      Timer10Select: 'mat_solid:timer_10_select',
      Timer3: 'mat_solid:timer_3',
      Timer3Select: 'mat_solid:timer_3_select',
      TimerOff: 'mat_solid:timer_off',
      TipsAndUpdates: 'mat_solid:tips_and_updates',
      Title: 'mat_solid:title',
      Toc: 'mat_solid:toc',
      Today: 'mat_solid:today',
      ToggleOff: 'mat_solid:toggle_off',
      ToggleOn: 'mat_solid:toggle_on',
      Toll: 'mat_solid:toll',
      Tonality: 'mat_solid:tonality',
      Topic: 'mat_solid:topic',
      TouchApp: 'mat_solid:touch_app',
      Tour: 'mat_solid:tour',
      Toys: 'mat_solid:toys',
      TrackChanges: 'mat_solid:track_changes',
      Traffic: 'mat_solid:traffic',
      Train: 'mat_solid:train',
      Tram: 'mat_solid:tram',
      TransferWithinAStation: 'mat_solid:transfer_within_a_station',
      Transform: 'mat_solid:transform',
      Transgender: 'mat_solid:transgender',
      TransitEnterexit: 'mat_solid:transit_enterexit',
      Translate: 'mat_solid:translate',
      TravelExplore: 'mat_solid:travel_explore',
      TrendingDown: 'mat_solid:trending_down',
      TrendingFlat: 'mat_solid:trending_flat',
      TrendingUp: 'mat_solid:trending_up',
      TripOrigin: 'mat_solid:trip_origin',
      Try: 'mat_solid:try',
      Tty: 'mat_solid:tty',
      Tune: 'mat_solid:tune',
      Tungsten: 'mat_solid:tungsten',
      TurnedIn: 'mat_solid:turned_in',
      TurnedInNot: 'mat_solid:turned_in_not',
      Tv: 'mat_solid:tv',
      TvOff: 'mat_solid:tv_off',
      TwoWheeler: 'mat_solid:two_wheeler',
      Umbrella: 'mat_solid:umbrella',
      Unarchive: 'mat_solid:unarchive',
      Undo: 'mat_solid:undo',
      UnfoldLess: 'mat_solid:unfold_less',
      UnfoldMore: 'mat_solid:unfold_more',
      Unpublished: 'mat_solid:unpublished',
      Unsubscribe: 'mat_solid:unsubscribe',
      Upcoming: 'mat_solid:upcoming',
      Update: 'mat_solid:update',
      UpdateDisabled: 'mat_solid:update_disabled',
      Upgrade: 'mat_solid:upgrade',
      Upload: 'mat_solid:upload',
      UploadFile: 'mat_solid:upload_file',
      Usb: 'mat_solid:usb',
      UsbOff: 'mat_solid:usb_off',
      Verified: 'mat_solid:verified',
      VerifiedUser: 'mat_solid:verified_user',
      VerticalAlignBottom: 'mat_solid:vertical_align_bottom',
      VerticalAlignCenter: 'mat_solid:vertical_align_center',
      VerticalAlignTop: 'mat_solid:vertical_align_top',
      VerticalDistribute: 'mat_solid:vertical_distribute',
      VerticalSplit: 'mat_solid:vertical_split',
      Vibration: 'mat_solid:vibration',
      VideoCall: 'mat_solid:video_call',
      VideoCameraBack: 'mat_solid:video_camera_back',
      VideoCameraFront: 'mat_solid:video_camera_front',
      VideoLabel: 'mat_solid:video_label',
      VideoLibrary: 'mat_solid:video_library',
      VideoSettings: 'mat_solid:video_settings',
      VideoStable: 'mat_solid:video_stable',
      Videocam: 'mat_solid:videocam',
      VideocamOff: 'mat_solid:videocam_off',
      VideogameAsset: 'mat_solid:videogame_asset',
      VideogameAssetOff: 'mat_solid:videogame_asset_off',
      ViewAgenda: 'mat_solid:view_agenda',
      ViewArray: 'mat_solid:view_array',
      ViewCarousel: 'mat_solid:view_carousel',
      ViewColumn: 'mat_solid:view_column',
      ViewComfy: 'mat_solid:view_comfy',
      ViewCompact: 'mat_solid:view_compact',
      ViewDay: 'mat_solid:view_day',
      ViewHeadline: 'mat_solid:view_headline',
      ViewInAr: 'mat_solid:view_in_ar',
      ViewList: 'mat_solid:view_list',
      ViewModule: 'mat_solid:view_module',
      ViewQuilt: 'mat_solid:view_quilt',
      ViewSidebar: 'mat_solid:view_sidebar',
      ViewStream: 'mat_solid:view_stream',
      ViewWeek: 'mat_solid:view_week',
      Vignette: 'mat_solid:vignette',
      Villa: 'mat_solid:villa',
      Visibility: 'mat_solid:visibility',
      VisibilityOff: 'mat_solid:visibility_off',
      VoiceChat: 'mat_solid:voice_chat',
      VoiceOverOff: 'mat_solid:voice_over_off',
      Voicemail: 'mat_solid:voicemail',
      VolumeDown: 'mat_solid:volume_down',
      VolumeMute: 'mat_solid:volume_mute',
      VolumeOff: 'mat_solid:volume_off',
      VolumeUp: 'mat_solid:volume_up',
      VolunteerActivism: 'mat_solid:volunteer_activism',
      VpnKey: 'mat_solid:vpn_key',
      VpnLock: 'mat_solid:vpn_lock',
      Vrpano: 'mat_solid:vrpano',
      Wallpaper: 'mat_solid:wallpaper',
      Warning: 'mat_solid:warning',
      WarningAmber: 'mat_solid:warning_amber',
      Wash: 'mat_solid:wash',
      Watch: 'mat_solid:watch',
      WatchLater: 'mat_solid:watch_later',
      Water: 'mat_solid:water',
      WaterDamage: 'mat_solid:water_damage',
      WaterDrop: 'mat_solid:water_drop',
      WaterfallChart: 'mat_solid:waterfall_chart',
      Waves: 'mat_solid:waves',
      WavingHand: 'mat_solid:waving_hand',
      WbAuto: 'mat_solid:wb_auto',
      WbCloudy: 'mat_solid:wb_cloudy',
      WbIncandescent: 'mat_solid:wb_incandescent',
      WbIridescent: 'mat_solid:wb_iridescent',
      WbShade: 'mat_solid:wb_shade',
      WbSunny: 'mat_solid:wb_sunny',
      WbTwilight: 'mat_solid:wb_twilight',
      Wc: 'mat_solid:wc',
      Web: 'mat_solid:web',
      WebAsset: 'mat_solid:web_asset',
      WebAssetOff: 'mat_solid:web_asset_off',
      Weekend: 'mat_solid:weekend',
      West: 'mat_solid:west',
      Whatshot: 'mat_solid:whatshot',
      WheelchairPickup: 'mat_solid:wheelchair_pickup',
      WhereToVote: 'mat_solid:where_to_vote',
      Widgets: 'mat_solid:widgets',
      Wifi: 'mat_solid:wifi',
      WifiCalling: 'mat_solid:wifi_calling',
      WifiCalling3: 'mat_solid:wifi_calling_3',
      WifiLock: 'mat_solid:wifi_lock',
      WifiOff: 'mat_solid:wifi_off',
      WifiProtectedSetup: 'mat_solid:wifi_protected_setup',
      WifiTethering: 'mat_solid:wifi_tethering',
      WifiTetheringErrorRounded: 'mat_solid:wifi_tethering_error_rounded',
      WifiTetheringOff: 'mat_solid:wifi_tethering_off',
      Window: 'mat_solid:window',
      WineBar: 'mat_solid:wine_bar',
      Work: 'mat_solid:work',
      WorkOff: 'mat_solid:work_off',
      WorkOutline: 'mat_solid:work_outline',
      Workspaces: 'mat_solid:workspaces',
      WrapText: 'mat_solid:wrap_text',
      WrongLocation: 'mat_solid:wrong_location',
      Wysiwyg: 'mat_solid:wysiwyg',
      Yard: 'mat_solid:yard',
      YoutubeSearchedFor: 'mat_solid:youtube_searched_for',
      ZoomIn: 'mat_solid:zoom_in',
      ZoomOut: 'mat_solid:zoom_out',
      ZoomOutMap: 'mat_solid:zoom_out_map',
    },
  },
  heroicons: {
    outline: {
      AcademicCap: 'heroicons_outline:academic-cap',
      AdjustmentsVertical: 'heroicons_outline:adjustments-vertical',
      AdjustmentsHorizontal: 'heroicons_outline:adjustments-horizontal',
      ArchiveBox: 'heroicons_outline:archive-box',
      ArchiveBoxXMark: 'heroicons_outline:archive-box-x-mark',
      ArrowDownCircle: 'heroicons_outline:arrow-down-circle',
      ArrowDownOnSquareStack: 'heroicons_outline:arrow-down-on-square-stack',
      ArrowDownRight: 'heroicons_outline:arrow-down-right',
      ArchiveBoxArrowDown: 'heroicons_outline:archive-box-arrow-down',
      ArrowLeftOnRectangle: 'heroicons_outline:arrow-left-on-rectangle',
      ArrowDown: 'heroicons_outline:arrow-down',
      ArrowDownTray: 'heroicons_outline:arrow-down-tray',
      ArrowLeftCircle: 'heroicons_outline:arrow-left-circle',
      ArrowLeft: 'heroicons_outline:arrow-left',
      ArrowLongRight: 'heroicons_outline:arrow-long-right',
      ArrowLongUp: 'heroicons_outline:arrow-long-up',
      ArrowLongLeft: 'heroicons_outline:arrow-long-left',
      ArrowPathRoundedSquare: 'heroicons_outline:arrow-path-rounded-square',
      ArrowDownLeft: 'heroicons_outline:arrow-down-left',
      ArrowLongDown: 'heroicons_outline:arrow-long-down',
      ArrowPath: 'heroicons_outline:arrow-path',
      ArrowRightCircle: 'heroicons_outline:arrow-right-circle',
      ArrowSmallDown: 'heroicons_outline:arrow-small-down',
      ArrowDownOnSquare: 'heroicons_outline:arrow-down-on-square',
      ArrowTopRightOnSquare: 'heroicons_outline:arrow-top-right-on-square',
      ArrowTrendingDown: 'heroicons_outline:arrow-trending-down',
      ArrowSmallRight: 'heroicons_outline:arrow-small-right',
      ArrowRight: 'heroicons_outline:arrow-right',
      ArrowUpCircle: 'heroicons_outline:arrow-up-circle',
      ArrowTrendingUp: 'heroicons_outline:arrow-trending-up',
      ArrowUpLeft: 'heroicons_outline:arrow-up-left',
      ArrowRightOnRectangle: 'heroicons_outline:arrow-right-on-rectangle',
      ArrowSmallUp: 'heroicons_outline:arrow-small-up',
      ArrowUpOnSquare: 'heroicons_outline:arrow-up-on-square',
      ArrowUpTray: 'heroicons_outline:arrow-up-tray',
      ArrowSmallLeft: 'heroicons_outline:arrow-small-left',
      ArrowUpOnSquareStack: 'heroicons_outline:arrow-up-on-square-stack',
      ArrowUturnDown: 'heroicons_outline:arrow-uturn-down',
      ArrowUturnRight: 'heroicons_outline:arrow-uturn-right',
      ArrowUturnLeft: 'heroicons_outline:arrow-uturn-left',
      ArrowUp: 'heroicons_outline:arrow-up',
      ArrowsPointingIn: 'heroicons_outline:arrows-pointing-in',
      ArrowsUpDown: 'heroicons_outline:arrows-up-down',
      ArrowsRightLeft: 'heroicons_outline:arrows-right-left',
      AtSymbol: 'heroicons_outline:at-symbol',
      ArrowUpRight: 'heroicons_outline:arrow-up-right',
      Backspace: 'heroicons_outline:backspace',
      Banknotes: 'heroicons_outline:banknotes',
      Backward: 'heroicons_outline:backward',
      Bars3BottomRight: 'heroicons_outline:bars-3-bottom-right',
      ArrowsPointingOut: 'heroicons_outline:arrows-pointing-out',
      Bars3CenterLeft: 'heroicons_outline:bars-3-center-left',
      Bars4: 'heroicons_outline:bars-4',
      Bars2: 'heroicons_outline:bars-2',
      ArrowUturnUp: 'heroicons_outline:arrow-uturn-up',
      Bars3: 'heroicons_outline:bars-3',
      Bars3BottomLeft: 'heroicons_outline:bars-3-bottom-left',
      Battery0: 'heroicons_outline:battery-0',
      BarsArrowUp: 'heroicons_outline:bars-arrow-up',
      Battery50: 'heroicons_outline:battery-50',
      Battery100: 'heroicons_outline:battery-100',
      BellAlert: 'heroicons_outline:bell-alert',
      BarsArrowDown: 'heroicons_outline:bars-arrow-down',
      Bell: 'heroicons_outline:bell',
      BellSlash: 'heroicons_outline:bell-slash',
      BellSnooze: 'heroicons_outline:bell-snooze',
      Beaker: 'heroicons_outline:beaker',
      BookmarkSlash: 'heroicons_outline:bookmark-slash',
      BoltSlash: 'heroicons_outline:bolt-slash',
      BookOpen: 'heroicons_outline:book-open',
      Bookmark: 'heroicons_outline:bookmark',
      BuildingOffice2: 'heroicons_outline:building-office-2',
      Briefcase: 'heroicons_outline:briefcase',
      BuildingLibrary: 'heroicons_outline:building-library',
      BugAnt: 'heroicons_outline:bug-ant',
      BuildingStorefront: 'heroicons_outline:building-storefront',
      BookmarkSquare: 'heroicons_outline:bookmark-square',
      BuildingOffice: 'heroicons_outline:building-office',
      Calculator: 'heroicons_outline:calculator',
      Cake: 'heroicons_outline:cake',
      Camera: 'heroicons_outline:camera',
      Calendar: 'heroicons_outline:calendar',
      CalendarDays: 'heroicons_outline:calendar-days',
      ChartBarSquare: 'heroicons_outline:chart-bar-square',
      Bolt: 'heroicons_outline:bolt',
      ChatBubbleBottomCenter: 'heroicons_outline:chat-bubble-bottom-center',
      ChatBubbleLeftRight: 'heroicons_outline:chat-bubble-left-right',
      ChatBubbleLeft: 'heroicons_outline:chat-bubble-left',
      ChatBubbleLeftEllipsis: 'heroicons_outline:chat-bubble-left-ellipsis',
      ChatBubbleOvalLeftEllipsis: 'heroicons_outline:chat-bubble-oval-left-ellipsis',
      ChartBar: 'heroicons_outline:chart-bar',
      ChatBubbleBottomCenterText: 'heroicons_outline:chat-bubble-bottom-center-text',
      Check: 'heroicons_outline:check',
      ChevronDoubleDown: 'heroicons_outline:chevron-double-down',
      ChatBubbleOvalLeft: 'heroicons_outline:chat-bubble-oval-left',
      CheckBadge: 'heroicons_outline:check-badge',
      ChevronDoubleLeft: 'heroicons_outline:chevron-double-left',
      ChevronLeft: 'heroicons_outline:chevron-left',
      ChevronDoubleUp: 'heroicons_outline:chevron-double-up',
      ChevronDoubleRight: 'heroicons_outline:chevron-double-right',
      ChevronDown: 'heroicons_outline:chevron-down',
      ChevronUp: 'heroicons_outline:chevron-up',
      ChevronRight: 'heroicons_outline:chevron-right',
      CheckCircle: 'heroicons_outline:check-circle',
      ChartPie: 'heroicons_outline:chart-pie',
      CircleStack: 'heroicons_outline:circle-stack',
      ClipboardDocumentCheck: 'heroicons_outline:clipboard-document-check',
      ClipboardDocument: 'heroicons_outline:clipboard-document',
      Clipboard: 'heroicons_outline:clipboard',
      Clock: 'heroicons_outline:clock',
      ChevronUpDown: 'heroicons_outline:chevron-up-down',
      ClipboardDocumentList: 'heroicons_outline:clipboard-document-list',
      CloudArrowUp: 'heroicons_outline:cloud-arrow-up',
      CloudArrowDown: 'heroicons_outline:cloud-arrow-down',
      CodeBracket: 'heroicons_outline:code-bracket',
      Cog6Tooth: 'heroicons_outline:cog-6-tooth',
      Cog8Tooth: 'heroicons_outline:cog-8-tooth',
      Cog: 'heroicons_outline:cog',
      CpuChip: 'heroicons_outline:cpu-chip',
      ComputerDesktop: 'heroicons_outline:computer-desktop',
      CodeBracketSquare: 'heroicons_outline:code-bracket-square',
      CreditCard: 'heroicons_outline:credit-card',
      Cube: 'heroicons_outline:cube',
      CubeTransparent: 'heroicons_outline:cube-transparent',
      CurrencyBangladeshi: 'heroicons_outline:currency-bangladeshi',
      Cloud: 'heroicons_outline:cloud',
      CurrencyEuro: 'heroicons_outline:currency-euro',
      CurrencyYen: 'heroicons_outline:currency-yen',
      CommandLine: 'heroicons_outline:command-line',
      CurrencyRupee: 'heroicons_outline:currency-rupee',
      CurrencyPound: 'heroicons_outline:currency-pound',
      CursorArrowRays: 'heroicons_outline:cursor-arrow-rays',
      CurrencyDollar: 'heroicons_outline:currency-dollar',
      DevicePhoneMobile: 'heroicons_outline:device-phone-mobile',
      CursorArrowRipple: 'heroicons_outline:cursor-arrow-ripple',
      DeviceTablet: 'heroicons_outline:device-tablet',
      DocumentCheck: 'heroicons_outline:document-check',
      DocumentDuplicate: 'heroicons_outline:document-duplicate',
      DocumentMinus: 'heroicons_outline:document-minus',
      DocumentMagnifyingGlass: 'heroicons_outline:document-magnifying-glass',
      DocumentText: 'heroicons_outline:document-text',
      DocumentArrowDown: 'heroicons_outline:document-arrow-down',
      DocumentPlus: 'heroicons_outline:document-plus',
      DocumentArrowUp: 'heroicons_outline:document-arrow-up',
      Document: 'heroicons_outline:document',
      EllipsisHorizontalCircle: 'heroicons_outline:ellipsis-horizontal-circle',
      Envelope: 'heroicons_outline:envelope',
      ExclamationCircle: 'heroicons_outline:exclamation-circle',
      EnvelopeOpen: 'heroicons_outline:envelope-open',
      EyeSlash: 'heroicons_outline:eye-slash',
      ExclamationTriangle: 'heroicons_outline:exclamation-triangle',
      EllipsisVertical: 'heroicons_outline:ellipsis-vertical',
      EllipsisHorizontal: 'heroicons_outline:ellipsis-horizontal',
      Eye: 'heroicons_outline:eye',
      FaceSmile: 'heroicons_outline:face-smile',
      FaceFrown: 'heroicons_outline:face-frown',
      DocumentChartBar: 'heroicons_outline:document-chart-bar',
      EyeDropper: 'heroicons_outline:eye-dropper',
      Film: 'heroicons_outline:film',
      Flag: 'heroicons_outline:flag',
      FolderMinus: 'heroicons_outline:folder-minus',
      FolderArrowDown: 'heroicons_outline:folder-arrow-down',
      Folder: 'heroicons_outline:folder',
      FolderOpen: 'heroicons_outline:folder-open',
      FingerPrint: 'heroicons_outline:finger-print',
      Fire: 'heroicons_outline:fire',
      FolderPlus: 'heroicons_outline:folder-plus',
      Funnel: 'heroicons_outline:funnel',
      Gif: 'heroicons_outline:gif',
      Forward: 'heroicons_outline:forward',
      Gift: 'heroicons_outline:gift',
      GlobeAlt: 'heroicons_outline:globe-alt',
      GiftTop: 'heroicons_outline:gift-top',
      GlobeAmericas: 'heroicons_outline:globe-americas',
      GlobeEuropeAfrica: 'heroicons_outline:globe-europe-africa',
      GlobeAsiaAustralia: 'heroicons_outline:globe-asia-australia',
      HandRaised: 'heroicons_outline:hand-raised',
      Hashtag: 'heroicons_outline:hashtag',
      Heart: 'heroicons_outline:heart',
      HandThumbUp: 'heroicons_outline:hand-thumb-up',
      Home: 'heroicons_outline:home',
      HandThumbDown: 'heroicons_outline:hand-thumb-down',
      Identification: 'heroicons_outline:identification',
      InboxArrowDown: 'heroicons_outline:inbox-arrow-down',
      InboxStack: 'heroicons_outline:inbox-stack',
      Inbox: 'heroicons_outline:inbox',
      Key: 'heroicons_outline:key',
      InformationCircle: 'heroicons_outline:information-circle',
      Language: 'heroicons_outline:language',
      LightBulb: 'heroicons_outline:light-bulb',
      Lifebuoy: 'heroicons_outline:lifebuoy',
      ListBullet: 'heroicons_outline:list-bullet',
      LockClosed: 'heroicons_outline:lock-closed',
      MagnifyingGlassCircle: 'heroicons_outline:magnifying-glass-circle',
      HomeModern: 'heroicons_outline:home-modern',
      LockOpen: 'heroicons_outline:lock-open',
      MagnifyingGlassMinus: 'heroicons_outline:magnifying-glass-minus',
      MagnifyingGlass: 'heroicons_outline:magnifying-glass',
      Link: 'heroicons_outline:link',
      MapPin: 'heroicons_outline:map-pin',
      Map: 'heroicons_outline:map',
      MagnifyingGlassPlus: 'heroicons_outline:magnifying-glass-plus',
      Microphone: 'heroicons_outline:microphone',
      MinusSmall: 'heroicons_outline:minus-small',
      Moon: 'heroicons_outline:moon',
      MinusCircle: 'heroicons_outline:minus-circle',
      MusicalNote: 'heroicons_outline:musical-note',
      Megaphone: 'heroicons_outline:megaphone',
      PaintBrush: 'heroicons_outline:paint-brush',
      PaperAirplane: 'heroicons_outline:paper-airplane',
      NoSymbol: 'heroicons_outline:no-symbol',
      PaperClip: 'heroicons_outline:paper-clip',
      Minus: 'heroicons_outline:minus',
      Newspaper: 'heroicons_outline:newspaper',
      Pause: 'heroicons_outline:pause',
      PauseCircle: 'heroicons_outline:pause-circle',
      Pencil: 'heroicons_outline:pencil',
      PhoneArrowUpRight: 'heroicons_outline:phone-arrow-up-right',
      PhoneXMark: 'heroicons_outline:phone-x-mark',
      PhoneArrowDownLeft: 'heroicons_outline:phone-arrow-down-left',
      Phone: 'heroicons_outline:phone',
      PencilSquare: 'heroicons_outline:pencil-square',
      PlayCircle: 'heroicons_outline:play-circle',
      Photo: 'heroicons_outline:photo',
      Play: 'heroicons_outline:play',
      Plus: 'heroicons_outline:plus',
      PlusSmall: 'heroicons_outline:plus-small',
      PlayPause: 'heroicons_outline:play-pause',
      Power: 'heroicons_outline:power',
      PresentationChartLine: 'heroicons_outline:presentation-chart-line',
      PlusCircle: 'heroicons_outline:plus-circle',
      Printer: 'heroicons_outline:printer',
      PuzzlePiece: 'heroicons_outline:puzzle-piece',
      PresentationChartBar: 'heroicons_outline:presentation-chart-bar',
      QuestionMarkCircle: 'heroicons_outline:question-mark-circle',
      QueueList: 'heroicons_outline:queue-list',
      Radio: 'heroicons_outline:radio',
      RectangleGroup: 'heroicons_outline:rectangle-group',
      QrCode: 'heroicons_outline:qr-code',
      ReceiptPercent: 'heroicons_outline:receipt-percent',
      ReceiptRefund: 'heroicons_outline:receipt-refund',
      RocketLaunch: 'heroicons_outline:rocket-launch',
      RectangleStack: 'heroicons_outline:rectangle-stack',
      ServerStack: 'heroicons_outline:server-stack',
      Scissors: 'heroicons_outline:scissors',
      Server: 'heroicons_outline:server',
      Share: 'heroicons_outline:share',
      Scale: 'heroicons_outline:scale',
      ShieldCheck: 'heroicons_outline:shield-check',
      Rss: 'heroicons_outline:rss',
      ShoppingBag: 'heroicons_outline:shopping-bag',
      ShoppingCart: 'heroicons_outline:shopping-cart',
      SignalSlash: 'heroicons_outline:signal-slash',
      ShieldExclamation: 'heroicons_outline:shield-exclamation',
      Sparkles: 'heroicons_outline:sparkles',
      SpeakerWave: 'heroicons_outline:speaker-wave',
      Square3Stack3D: 'heroicons_outline:square-3-stack-3d',
      Signal: 'heroicons_outline:signal',
      SpeakerXMark: 'heroicons_outline:speaker-x-mark',
      Squares2X2: 'heroicons_outline:squares-2x2',
      SquaresPlus: 'heroicons_outline:squares-plus',
      Star: 'heroicons_outline:star',
      StopCircle: 'heroicons_outline:stop-circle',
      Square2Stack: 'heroicons_outline:square-2-stack',
      Stop: 'heroicons_outline:stop',
      Sun: 'heroicons_outline:sun',
      Swatch: 'heroicons_outline:swatch',
      Ticket: 'heroicons_outline:ticket',
      Trophy: 'heroicons_outline:trophy',
      Truck: 'heroicons_outline:truck',
      Tag: 'heroicons_outline:tag',
      Trash: 'heroicons_outline:trash',
      TableCells: 'heroicons_outline:table-cells',
      Tv: 'heroicons_outline:tv',
      UserGroup: 'heroicons_outline:user-group',
      UserPlus: 'heroicons_outline:user-plus',
      Users: 'heroicons_outline:users',
      UserMinus: 'heroicons_outline:user-minus',
      Variable: 'heroicons_outline:variable',
      User: 'heroicons_outline:user',
      UserCircle: 'heroicons_outline:user-circle',
      VideoCameraSlash: 'heroicons_outline:video-camera-slash',
      ViewfinderCircle: 'heroicons_outline:viewfinder-circle',
      ViewColumns: 'heroicons_outline:view-columns',
      VideoCamera: 'heroicons_outline:video-camera',
      Window: 'heroicons_outline:window',
      WrenchScrewdriver: 'heroicons_outline:wrench-screwdriver',
      Wrench: 'heroicons_outline:wrench',
      Wallet: 'heroicons_outline:wallet',
      Wifi: 'heroicons_outline:wifi',
      XCircle: 'heroicons_outline:x-circle',
      XMark: 'heroicons_outline:x-mark',
    },
    solid: {
      AcademicCap: 'heroicons_solid:academic-cap',
      Annotation: 'heroicons_solid:annotation',
      Adjustments: 'heroicons_solid:adjustments',
      Archive: 'heroicons_solid:archive',
      ArrowCircleDown: 'heroicons_solid:arrow-circle-down',
      ArrowCircleLeft: 'heroicons_solid:arrow-circle-left',
      ArrowCircleRight: 'heroicons_solid:arrow-circle-right',
      ArrowCircleUp: 'heroicons_solid:arrow-circle-up',
      ArrowDown: 'heroicons_solid:arrow-down',
      ArrowLeft: 'heroicons_solid:arrow-left',
      ArrowNarrowDown: 'heroicons_solid:arrow-narrow-down',
      ArrowNarrowLeft: 'heroicons_solid:arrow-narrow-left',
      ArrowNarrowRight: 'heroicons_solid:arrow-narrow-right',
      ArrowNarrowUp: 'heroicons_solid:arrow-narrow-up',
      ArrowRight: 'heroicons_solid:arrow-right',
      ArrowSmLeft: 'heroicons_solid:arrow-sm-left',
      ArrowSmDown: 'heroicons_solid:arrow-sm-down',
      ArrowSmRight: 'heroicons_solid:arrow-sm-right',
      ArrowSmUp: 'heroicons_solid:arrow-sm-up',
      ArrowUp: 'heroicons_solid:arrow-up',
      ArrowsExpand: 'heroicons_solid:arrows-expand',
      AtSymbol: 'heroicons_solid:at-symbol',
      BadgeCheck: 'heroicons_solid:badge-check',
      Backspace: 'heroicons_solid:backspace',
      Ban: 'heroicons_solid:ban',
      Beaker: 'heroicons_solid:beaker',
      Bell: 'heroicons_solid:bell',
      BookOpen: 'heroicons_solid:book-open',
      BookmarkAlt: 'heroicons_solid:bookmark-alt',
      Bookmark: 'heroicons_solid:bookmark',
      Briefcase: 'heroicons_solid:briefcase',
      Cake: 'heroicons_solid:cake',
      Calculator: 'heroicons_solid:calculator',
      Calendar: 'heroicons_solid:calendar',
      Camera: 'heroicons_solid:camera',
      Cash: 'heroicons_solid:cash',
      ChartBar: 'heroicons_solid:chart-bar',
      ChartPie: 'heroicons_solid:chart-pie',
      ChartSquareBar: 'heroicons_solid:chart-square-bar',
      ChatAlt2: 'heroicons_solid:chat-alt-2',
      ChatAlt: 'heroicons_solid:chat-alt',
      Chat: 'heroicons_solid:chat',
      CheckCircle: 'heroicons_solid:check-circle',
      Check: 'heroicons_solid:check',
      ChevronDoubleDown: 'heroicons_solid:chevron-double-down',
      ChevronDoubleLeft: 'heroicons_solid:chevron-double-left',
      ChevronDoubleUp: 'heroicons_solid:chevron-double-up',
      ChevronDoubleRight: 'heroicons_solid:chevron-double-right',
      ChevronDown: 'heroicons_solid:chevron-down',
      ChevronLeft: 'heroicons_solid:chevron-left',
      ChevronRight: 'heroicons_solid:chevron-right',
      ChevronUp: 'heroicons_solid:chevron-up',
      Chip: 'heroicons_solid:chip',
      ClipboardCheck: 'heroicons_solid:clipboard-check',
      ClipboardCopy: 'heroicons_solid:clipboard-copy',
      ClipboardList: 'heroicons_solid:clipboard-list',
      Clipboard: 'heroicons_solid:clipboard',
      Clock: 'heroicons_solid:clock',
      CloudDownload: 'heroicons_solid:cloud-download',
      CloudUpload: 'heroicons_solid:cloud-upload',
      Cloud: 'heroicons_solid:cloud',
      Code: 'heroicons_solid:code',
      Cog: 'heroicons_solid:cog',
      Collection: 'heroicons_solid:collection',
      CreditCard: 'heroicons_solid:credit-card',
      ColorSwatch: 'heroicons_solid:color-swatch',
      CubeTransparent: 'heroicons_solid:cube-transparent',
      Cube: 'heroicons_solid:cube',
      CurrencyBangladeshi: 'heroicons_solid:currency-bangladeshi',
      CurrencyDollar: 'heroicons_solid:currency-dollar',
      CurrencyEuro: 'heroicons_solid:currency-euro',
      CurrencyPound: 'heroicons_solid:currency-pound',
      CurrencyRupee: 'heroicons_solid:currency-rupee',
      CurrencyYen: 'heroicons_solid:currency-yen',
      CursorClick: 'heroicons_solid:cursor-click',
      Database: 'heroicons_solid:database',
      DesktopComputer: 'heroicons_solid:desktop-computer',
      DeviceMobile: 'heroicons_solid:device-mobile',
      DeviceTablet: 'heroicons_solid:device-tablet',
      DocumentAdd: 'heroicons_solid:document-add',
      DocumentDownload: 'heroicons_solid:document-download',
      DocumentDuplicate: 'heroicons_solid:document-duplicate',
      DocumentRemove: 'heroicons_solid:document-remove',
      DocumentReport: 'heroicons_solid:document-report',
      DocumentSearch: 'heroicons_solid:document-search',
      DocumentText: 'heroicons_solid:document-text',
      Document: 'heroicons_solid:document',
      DotsCircleHorizontal: 'heroicons_solid:dots-circle-horizontal',
      DotsHorizontal: 'heroicons_solid:dots-horizontal',
      DotsVertical: 'heroicons_solid:dots-vertical',
      Download: 'heroicons_solid:download',
      Duplicate: 'heroicons_solid:duplicate',
      EmojiHappy: 'heroicons_solid:emoji-happy',
      EmojiSad: 'heroicons_solid:emoji-sad',
      ExclamationCircle: 'heroicons_solid:exclamation-circle',
      Exclamation: 'heroicons_solid:exclamation',
      ExternalLink: 'heroicons_solid:external-link',
      EyeOff: 'heroicons_solid:eye-off',
      Eye: 'heroicons_solid:eye',
      FastForward: 'heroicons_solid:fast-forward',
      Film: 'heroicons_solid:film',
      Filter: 'heroicons_solid:filter',
      FingerPrint: 'heroicons_solid:finger-print',
      Fire: 'heroicons_solid:fire',
      Flag: 'heroicons_solid:flag',
      FolderAdd: 'heroicons_solid:folder-add',
      FolderDownload: 'heroicons_solid:folder-download',
      FolderOpen: 'heroicons_solid:folder-open',
      FolderRemove: 'heroicons_solid:folder-remove',
      Folder: 'heroicons_solid:folder',
      Gift: 'heroicons_solid:gift',
      GlobeAlt: 'heroicons_solid:globe-alt',
      Globe: 'heroicons_solid:globe',
      Hand: 'heroicons_solid:hand',
      Hashtag: 'heroicons_solid:hashtag',
      Heart: 'heroicons_solid:heart',
      Home: 'heroicons_solid:home',
      Identification: 'heroicons_solid:identification',
      InboxIn: 'heroicons_solid:inbox-in',
      Inbox: 'heroicons_solid:inbox',
      InformationCircle: 'heroicons_solid:information-circle',
      Key: 'heroicons_solid:key',
      Library: 'heroicons_solid:library',
      LightBulb: 'heroicons_solid:light-bulb',
      LightningBolt: 'heroicons_solid:lightning-bolt',
      Link: 'heroicons_solid:link',
      LocationMarker: 'heroicons_solid:location-marker',
      LockClosed: 'heroicons_solid:lock-closed',
      LockOpen: 'heroicons_solid:lock-open',
      Login: 'heroicons_solid:login',
      Logout: 'heroicons_solid:logout',
      MailOpen: 'heroicons_solid:mail-open',
      Mail: 'heroicons_solid:mail',
      Map: 'heroicons_solid:map',
      MenuAlt1: 'heroicons_solid:menu-alt-1',
      MenuAlt2: 'heroicons_solid:menu-alt-2',
      MenuAlt3: 'heroicons_solid:menu-alt-3',
      MenuAlt4: 'heroicons_solid:menu-alt-4',
      Menu: 'heroicons_solid:menu',
      Microphone: 'heroicons_solid:microphone',
      MinusCircle: 'heroicons_solid:minus-circle',
      MinusSm: 'heroicons_solid:minus-sm',
      Minus: 'heroicons_solid:minus',
      Moon: 'heroicons_solid:moon',
      MusicNote: 'heroicons_solid:music-note',
      Newspaper: 'heroicons_solid:newspaper',
      OfficeBuilding: 'heroicons_solid:office-building',
      PaperAirplane: 'heroicons_solid:paper-airplane',
      PaperClip: 'heroicons_solid:paper-clip',
      Pause: 'heroicons_solid:pause',
      PencilAlt: 'heroicons_solid:pencil-alt',
      Pencil: 'heroicons_solid:pencil',
      PhoneIncoming: 'heroicons_solid:phone-incoming',
      PhoneMissedCall: 'heroicons_solid:phone-missed-call',
      PhoneOutgoing: 'heroicons_solid:phone-outgoing',
      Photograph: 'heroicons_solid:photograph',
      Phone: 'heroicons_solid:phone',
      Play: 'heroicons_solid:play',
      PlusCircle: 'heroicons_solid:plus-circle',
      PlusSm: 'heroicons_solid:plus-sm',
      Plus: 'heroicons_solid:plus',
      PresentationChartBar: 'heroicons_solid:presentation-chart-bar',
      PresentationChartLine: 'heroicons_solid:presentation-chart-line',
      Printer: 'heroicons_solid:printer',
      Qrcode: 'heroicons_solid:qrcode',
      Puzzle: 'heroicons_solid:puzzle',
      QuestionMarkCircle: 'heroicons_solid:question-mark-circle',
      ReceiptRefund: 'heroicons_solid:receipt-refund',
      ReceiptTax: 'heroicons_solid:receipt-tax',
      Refresh: 'heroicons_solid:refresh',
      Reply: 'heroicons_solid:reply',
      Rewind: 'heroicons_solid:rewind',
      Rss: 'heroicons_solid:rss',
      SaveAs: 'heroicons_solid:save-as',
      Save: 'heroicons_solid:save',
      Scale: 'heroicons_solid:scale',
      Scissors: 'heroicons_solid:scissors',
      SearchCircle: 'heroicons_solid:search-circle',
      Search: 'heroicons_solid:search',
      Selector: 'heroicons_solid:selector',
      Server: 'heroicons_solid:server',
      Share: 'heroicons_solid:share',
      ShieldCheck: 'heroicons_solid:shield-check',
      ShieldExclamation: 'heroicons_solid:shield-exclamation',
      ShoppingBag: 'heroicons_solid:shopping-bag',
      ShoppingCart: 'heroicons_solid:shopping-cart',
      SortAscending: 'heroicons_solid:sort-ascending',
      SortDescending: 'heroicons_solid:sort-descending',
      Speakerphone: 'heroicons_solid:speakerphone',
      Sparkles: 'heroicons_solid:sparkles',
      Star: 'heroicons_solid:star',
      StatusOnline: 'heroicons_solid:status-online',
      StatusOffline: 'heroicons_solid:status-offline',
      Stop: 'heroicons_solid:stop',
      Sun: 'heroicons_solid:sun',
      Support: 'heroicons_solid:support',
      SwitchVertical: 'heroicons_solid:switch-vertical',
      SwitchHorizontal: 'heroicons_solid:switch-horizontal',
      Table: 'heroicons_solid:table',
      Tag: 'heroicons_solid:tag',
      Template: 'heroicons_solid:template',
      Terminal: 'heroicons_solid:terminal',
      ThumbUp: 'heroicons_solid:thumb-up',
      ThumbDown: 'heroicons_solid:thumb-down',
      Ticket: 'heroicons_solid:ticket',
      Translate: 'heroicons_solid:translate',
      Trash: 'heroicons_solid:trash',
      TrendingDown: 'heroicons_solid:trending-down',
      TrendingUp: 'heroicons_solid:trending-up',
      Truck: 'heroicons_solid:truck',
      Upload: 'heroicons_solid:upload',
      UserAdd: 'heroicons_solid:user-add',
      UserGroup: 'heroicons_solid:user-group',
      UserCircle: 'heroicons_solid:user-circle',
      UserRemove: 'heroicons_solid:user-remove',
      User: 'heroicons_solid:user',
      Users: 'heroicons_solid:users',
      Variable: 'heroicons_solid:variable',
      VideoCamera: 'heroicons_solid:video-camera',
      ViewBoards: 'heroicons_solid:view-boards',
      ViewGridAdd: 'heroicons_solid:view-grid-add',
      ViewGrid: 'heroicons_solid:view-grid',
      ViewList: 'heroicons_solid:view-list',
      VolumeUp: 'heroicons_solid:volume-up',
      VolumeOff: 'heroicons_solid:volume-off',
      XCircle: 'heroicons_solid:x-circle',
      ZoomIn: 'heroicons_solid:zoom-in',
      Wifi: 'heroicons_solid:wifi',
      ZoomOut: 'heroicons_solid:zoom-out',
      X: 'heroicons_solid:x',
    },
  },
  feather: {
    Activity: 'feather:activity',
    Airplay: 'feather:airplay',
    AlertCircle: 'feather:alert-circle',
    AlertOctagon: 'feather:alert-octagon',
    AlertTriangle: 'feather:alert-triangle',
    AlignCenter: 'feather:align-center',
    AlignJustify: 'feather:align-justify',
    AlignLeft: 'feather:align-left',
    AlignRight: 'feather:align-right',
    Anchor: 'feather:anchor',
    Aperture: 'feather:aperture',
    Archive: 'feather:archive',
    ArrowDownCircle: 'feather:arrow-down-circle',
    ArrowDownLeft: 'feather:arrow-down-left',
    ArrowDownRight: 'feather:arrow-down-right',
    ArrowDown: 'feather:arrow-down',
    ArrowLeftCircle: 'feather:arrow-left-circle',
    ArrowLeft: 'feather:arrow-left',
    ArrowRightCircle: 'feather:arrow-right-circle',
    ArrowRight: 'feather:arrow-right',
    ArrowUpCircle: 'feather:arrow-up-circle',
    ArrowUpLeft: 'feather:arrow-up-left',
    ArrowUpRight: 'feather:arrow-up-right',
    ArrowUp: 'feather:arrow-up',
    AtSign: 'feather:at-sign',
    Award: 'feather:award',
    BarChart2: 'feather:bar-chart-2',
    BarChart: 'feather:bar-chart',
    BatteryCharging: 'feather:battery-charging',
    Battery: 'feather:battery',
    BellOff: 'feather:bell-off',
    Bell: 'feather:bell',
    Bluetooth: 'feather:bluetooth',
    Bold: 'feather:bold',
    BookOpen: 'feather:book-open',
    Book: 'feather:book',
    Bookmark: 'feather:bookmark',
    Box: 'feather:box',
    Briefcase: 'feather:briefcase',
    Calendar: 'feather:calendar',
    CameraOff: 'feather:camera-off',
    Camera: 'feather:camera',
    Cast: 'feather:cast',
    CheckCircle: 'feather:check-circle',
    CheckSquare: 'feather:check-square',
    Check: 'feather:check',
    ChevronDown: 'feather:chevron-down',
    ChevronLeft: 'feather:chevron-left',
    ChevronRight: 'feather:chevron-right',
    ChevronUp: 'feather:chevron-up',
    ChevronsDown: 'feather:chevrons-down',
    ChevronsLeft: 'feather:chevrons-left',
    ChevronsRight: 'feather:chevrons-right',
    ChevronsUp: 'feather:chevrons-up',
    Chrome: 'feather:chrome',
    Circle: 'feather:circle',
    Clipboard: 'feather:clipboard',
    Clock: 'feather:clock',
    CloudDrizzle: 'feather:cloud-drizzle',
    CloudLightning: 'feather:cloud-lightning',
    CloudOff: 'feather:cloud-off',
    CloudRain: 'feather:cloud-rain',
    CloudSnow: 'feather:cloud-snow',
    Cloud: 'feather:cloud',
    Code: 'feather:code',
    Codepen: 'feather:codepen',
    Codesandbox: 'feather:codesandbox',
    Coffee: 'feather:coffee',
    Columns: 'feather:columns',
    Command: 'feather:command',
    Compass: 'feather:compass',
    Copy: 'feather:copy',
    CornerDownLeft: 'feather:corner-down-left',
    CornerDownRight: 'feather:corner-down-right',
    CornerLeftDown: 'feather:corner-left-down',
    CornerLeftUp: 'feather:corner-left-up',
    CornerRightDown: 'feather:corner-right-down',
    CornerRightUp: 'feather:corner-right-up',
    CornerUpLeft: 'feather:corner-up-left',
    CornerUpRight: 'feather:corner-up-right',
    Cpu: 'feather:cpu',
    CreditCard: 'feather:credit-card',
    Crop: 'feather:crop',
    Crosshair: 'feather:crosshair',
    Database: 'feather:database',
    Delete: 'feather:delete',
    Disc: 'feather:disc',
    DollarSign: 'feather:dollar-sign',
    DownloadCloud: 'feather:download-cloud',
    Download: 'feather:download',
    Droplet: 'feather:droplet',
    Edit2: 'feather:edit-2',
    Edit3: 'feather:edit-3',
    Edit: 'feather:edit',
    ExternalLink: 'feather:external-link',
    EyeOff: 'feather:eye-off',
    Eye: 'feather:eye',
    Facebook: 'feather:facebook',
    FastForward: 'feather:fast-forward',
    Feather: 'feather:feather',
    Figma: 'feather:figma',
    FileMinus: 'feather:file-minus',
    FilePlus: 'feather:file-plus',
    FileText: 'feather:file-text',
    File: 'feather:file',
    Film: 'feather:film',
    Filter: 'feather:filter',
    Flag: 'feather:flag',
    FolderMinus: 'feather:folder-minus',
    FolderPlus: 'feather:folder-plus',
    Folder: 'feather:folder',
    Framer: 'feather:framer',
    Frown: 'feather:frown',
    Gift: 'feather:gift',
    GitBranch: 'feather:git-branch',
    GitCommit: 'feather:git-commit',
    GitMerge: 'feather:git-merge',
    GitPullRequest: 'feather:git-pull-request',
    Github: 'feather:github',
    Gitlab: 'feather:gitlab',
    Globe: 'feather:globe',
    Grid: 'feather:grid',
    HardDrive: 'feather:hard-drive',
    Hash: 'feather:hash',
    Headphones: 'feather:headphones',
    Heart: 'feather:heart',
    HelpCircle: 'feather:help-circle',
    Hexagon: 'feather:hexagon',
    Home: 'feather:home',
    Image: 'feather:image',
    Inbox: 'feather:inbox',
    Info: 'feather:info',
    Instagram: 'feather:instagram',
    Italic: 'feather:italic',
    Key: 'feather:key',
    Layers: 'feather:layers',
    Layout: 'feather:layout',
    LifeBuoy: 'feather:life-buoy',
    Link2: 'feather:link-2',
    Link: 'feather:link',
    Linkedin: 'feather:linkedin',
    List: 'feather:list',
    Loader: 'feather:loader',
    Lock: 'feather:lock',
    LogIn: 'feather:log-in',
    LogOut: 'feather:log-out',
    Mail: 'feather:mail',
    MapPin: 'feather:map-pin',
    Map: 'feather:map',
    Maximize2: 'feather:maximize-2',
    Maximize: 'feather:maximize',
    Meh: 'feather:meh',
    Menu: 'feather:menu',
    MessageCircle: 'feather:message-circle',
    MessageSquare: 'feather:message-square',
    MicOff: 'feather:mic-off',
    Mic: 'feather:mic',
    Minimize2: 'feather:minimize-2',
    Minimize: 'feather:minimize',
    MinusCircle: 'feather:minus-circle',
    MinusSquare: 'feather:minus-square',
    Minus: 'feather:minus',
    Monitor: 'feather:monitor',
    Moon: 'feather:moon',
    MoreHorizontal: 'feather:more-horizontal',
    MoreVertical: 'feather:more-vertical',
    MousePointer: 'feather:mouse-pointer',
    Move: 'feather:move',
    Music: 'feather:music',
    Navigation2: 'feather:navigation-2',
    Navigation: 'feather:navigation',
    Octagon: 'feather:octagon',
    Package: 'feather:package',
    Paperclip: 'feather:paperclip',
    PauseCircle: 'feather:pause-circle',
    Pause: 'feather:pause',
    PenTool: 'feather:pen-tool',
    Percent: 'feather:percent',
    PhoneCall: 'feather:phone-call',
    PhoneForwarded: 'feather:phone-forwarded',
    PhoneIncoming: 'feather:phone-incoming',
    PhoneMissed: 'feather:phone-missed',
    PhoneOff: 'feather:phone-off',
    PhoneOutgoing: 'feather:phone-outgoing',
    Phone: 'feather:phone',
    PieChart: 'feather:pie-chart',
    PlayCircle: 'feather:play-circle',
    Play: 'feather:play',
    PlusCircle: 'feather:plus-circle',
    PlusSquare: 'feather:plus-square',
    Plus: 'feather:plus',
    Pocket: 'feather:pocket',
    Power: 'feather:power',
    Printer: 'feather:printer',
    Radio: 'feather:radio',
    RefreshCcw: 'feather:refresh-ccw',
    RefreshCw: 'feather:refresh-cw',
    Repeat: 'feather:repeat',
    Rewind: 'feather:rewind',
    RotateCcw: 'feather:rotate-ccw',
    RotateCw: 'feather:rotate-cw',
    Rss: 'feather:rss',
    Save: 'feather:save',
    Scissors: 'feather:scissors',
    Search: 'feather:search',
    Send: 'feather:send',
    Server: 'feather:server',
    Settings: 'feather:settings',
    Share2: 'feather:share-2',
    Share: 'feather:share',
    ShieldOff: 'feather:shield-off',
    Shield: 'feather:shield',
    ShoppingBag: 'feather:shopping-bag',
    ShoppingCart: 'feather:shopping-cart',
    Shuffle: 'feather:shuffle',
    Sidebar: 'feather:sidebar',
    SkipBack: 'feather:skip-back',
    SkipForward: 'feather:skip-forward',
    Slack: 'feather:slack',
    Slash: 'feather:slash',
    Sliders: 'feather:sliders',
    Smartphone: 'feather:smartphone',
    Smile: 'feather:smile',
    Speaker: 'feather:speaker',
    Square: 'feather:square',
    Star: 'feather:star',
    StopCircle: 'feather:stop-circle',
    Sun: 'feather:sun',
    Sunrise: 'feather:sunrise',
    Sunset: 'feather:sunset',
    Tablet: 'feather:tablet',
    Tag: 'feather:tag',
    Target: 'feather:target',
    Terminal: 'feather:terminal',
    Thermometer: 'feather:thermometer',
    ThumbsDown: 'feather:thumbs-down',
    ThumbsUp: 'feather:thumbs-up',
    ToggleLeft: 'feather:toggle-left',
    ToggleRight: 'feather:toggle-right',
    Tool: 'feather:tool',
    Trash2: 'feather:trash-2',
    Trash: 'feather:trash',
    Trello: 'feather:trello',
    TrendingDown: 'feather:trending-down',
    TrendingUp: 'feather:trending-up',
    Triangle: 'feather:triangle',
    Truck: 'feather:truck',
    Tv: 'feather:tv',
    Twitch: 'feather:twitch',
    Twitter: 'feather:twitter',
    Type: 'feather:type',
    Umbrella: 'feather:umbrella',
    Underline: 'feather:underline',
    Unlock: 'feather:unlock',
    UploadCloud: 'feather:upload-cloud',
    Upload: 'feather:upload',
    UserCheck: 'feather:user-check',
    UserMinus: 'feather:user-minus',
    UserPlus: 'feather:user-plus',
    UserX: 'feather:user-x',
    User: 'feather:user',
    Users: 'feather:users',
    VideoOff: 'feather:video-off',
    Video: 'feather:video',
    Voicemail: 'feather:voicemail',
    Volume1: 'feather:volume-1',
    Volume2: 'feather:volume-2',
    VolumeX: 'feather:volume-x',
    Volume: 'feather:volume',
    Watch: 'feather:watch',
    WifiOff: 'feather:wifi-off',
    Wifi: 'feather:wifi',
    Wind: 'feather:wind',
    XCircle: 'feather:x-circle',
    XOctagon: 'feather:x-octagon',
    XSquare: 'feather:x-square',
    X: 'feather:x',
    Youtube: 'feather:youtube',
    ZapOff: 'feather:zap-off',
    Zap: 'feather:zap',
    ZoomIn: 'feather:zoom-in',
    ZoomOut: 'feather:zoom-out',
  },
  custom: {
    LedgerAccount: 'custom_ledger-account',
    Accounting: 'custom_accounting',
    Policy: 'custom_policy',
    NoData: 'custom_no-data',
    DatabaseFilter: 'custom_database-filter',
    Claim: 'custom_claim',
    AWSBrand: 'custom_aws-brands',
  },
  phosphor: {
    regular: {
      TreeStructure: 'phosphor_regular_tree-structure',
      FilePdf: 'phosphor_regular_file-pdf',
      Function: 'phosphor_regular_function',
      AddressBook: 'phosphor_regular_address-book',
      Umbrella: 'phosphor_regular_umbrella',
      UmbrellaSimple: 'phosphor_regular_umbrella-simple',
      BuildingOffice: 'phosphor_regular_building-office',
      ShieldCheck: 'phosphor_regular_shield-check',
      Wallet: 'phosphor_regular_wallet',
    },
    light: {
      FilePdf: 'phosphor_light_file-pdf-light',
      Function: 'phosphor_light_function-light',
      AddressBook: 'phosphor_light_address-book-light',
      Umbrella: 'phosphor_light_umbrella-light',
      UmbrellaSimple: 'phosphor_light_umbrella-simple-light',
      BuildingOffice: 'phosphor_light_building-office-light',
      ShieldCheck: 'phosphor_light_shield-check-light',
      Wallet: 'phosphor_light_wallet-light',
    }
  },
} as const;

export function getCustomIconSets(): { key: string; url: string }[] {
  const sets: { key: string; url: string }[] = [];
  for (const value of Object.values(Icons)) {
    let valStrings: string[] | undefined = undefined;
    if (value instanceof Array) {
      valStrings = value;
    } else {
      valStrings = recursiveGetIconString(value);
    }

    if (valStrings !== undefined && valStrings.length > 0) {
      for (const valString of valStrings) {
        sets.push({
          key: valString,
          url: `${valString.split('_').join('/')}.svg`,
        });
      }
    }
  }
  return sets;
}

function recursiveGetIconString(node: any): string[] {
  const parts: string[] = [];
  if (typeof node === 'string') {
    return [node];
  }
  if (node instanceof Array) {
    return node;
  }
  if (typeof node === 'object') {
    for (const value of Object.values(node)) {
      if (value instanceof Array) {
        parts.push(...value);
      } else {
        parts.push(...recursiveGetIconString(value));
      }
    }
  }
  return parts;
}
