import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Amplify } from 'aws-amplify';
import { AuthService } from './core/auth/auth.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { RouterService } from './core/services/router-data.service';
import { TitleService } from './services/title.service';
import { UpdateAppService } from './core/serviceWorkers/update-app.service';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';
import { FileDownloadListenerService } from './core/services/file-download-listener.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public router: Router,
    authService: AuthService,
    private fileDownloadService: FileDownloadListenerService,
    private gtmService: GoogleTagManagerService,
    private updateAppService: UpdateAppService,
    private routerService: RouterService,
    private titleService: TitleService,
  ) {
    console.log('SourceSHA', environment.sourceSHA);
    router.canceledNavigationResolution = 'computed';
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      console.log('navigationEnd event being called');
      // Ensure default title is set only if no title has been explicitly set during navigation
      this.titleService.ensureTitleAfterNavigation();
      // Reset the title set flag for the next navigation
      this.titleService.resetTitleFlag();
    });

    //console.log('env vars', environment);
    Amplify.configure({
      aws_project_region: environment.awsRegion,
      aws_cognito_region: environment.awsRegion,
      Auth: {
        // (required)- Amazon Cognito Region
        region: environment.awsRegion,

        // (optional) - Amazon Cognito User Pool ID
        userPoolId: environment.cognitoUserpoolId,

        // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
        userPoolWebClientId: environment.cognitoWebclientId,

        // (optional) - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,

        // (optional) - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        cookieStorage: {
          // - Cookie domain (only required if cookieStorage is provided)
          domain: environment.baseHostName,
          // (optional) - Cookie path
          path: '/',
          // (optional) - Cookie expiration in days
          expires: 365,
          // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
          sameSite: 'strict',
          // (optional) - Cookie secure flag
          // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
          secure: true,
        },

        // (optional) - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        //clientMetadata: { myCustomKey: 'myCustomValue' },

        // (optional) - Hosted UI configuration
        oauth: {
          domain: environment.cognitoDomain,
          scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: `https://${environment.baseHostName}/oauth/sign-in`,
          redirectSignOut: `https://${environment.baseHostName}/oauth/sign-out`,
          clientId: environment.cognitoWebclientId,
          responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    });
  }

  //todo: evaluate this logic or remove.  added voids vs fixing awaits
  async ngOnInit() {
    // push GTM data layer for every visited page
    await this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };

        void this.gtmService.pushTag(gtmTag);
      }
    });
  }
}
