<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'" class="min-h-screen"></empty-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Compact -->
<compact-layout *ngIf="layout === 'compact'" class="min-h-screen"></compact-layout>

<!-- Dense -->
<dense-layout *ngIf="layout === 'dense'" class="min-h-screen"></dense-layout>

<!-- Thin -->
<thin-layout *ngIf="layout === 'thin'" class="min-h-screen"></thin-layout>
