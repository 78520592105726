import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { AuthService } from 'app/core/auth/auth.service';
import { MessagePermissions, RoleCategories } from 'portal-commons/dist/roleEnums';
import { MessagesService } from './messages.service';
import {
  Observable,
  Subject,
  catchError,
  combineLatest,
  filter,
  map,
  of,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { ToastNotificationService } from 'app/core/notifications/toasts/toast-notification.service';
import { UserMessage } from 'portal-commons/dist/messages/userMessage';
import { UserPreview } from 'portal-commons/dist/identity/models/userPreview';
import { ErrorMessagePipe } from 'app/shared/pipes/error-message.pipe';
import { WebsocketService } from 'app/core/auth/web-socket.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessagePreview, WsMessageListMessage } from 'portal-commons/dist/messages/messagePreview';
import { IsLoadingService } from '@service-work/is-loading';
import { PaginatedTableOptions } from 'portal-commons/dist/data-table/models';
import { MessageReadStatus } from 'portal-commons/dist/messages/messageEnums';
import { WsMessageTypes } from 'portal-commons/dist/ws/model';

export interface MessagesStoreState {
  canAdd: boolean;
  messageList: UserMessage[] | null;
  sentMessageList: UserMessage[] | null;
  activeMessages: MessagePreview[] | null;
  message?: UserMessage;
  editMode: boolean;
}

const DEFAULT_STATE: MessagesStoreState = {
  canAdd: false,
  editMode: false,
  messageList: null,
  sentMessageList: null,
  activeMessages: null,
};

const defaultMessage: Partial<UserMessage> = {
  id: '',
  parentRecordType: '',
  parentRecordId: '',
  body: '',
  subject: '',
  toUsers: [],
  messageGroupId: '',
};

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MessageStore extends ComponentStore<MessagesStoreState> {
  authService = inject(AuthService);
  api = inject(MessagesService);
  toastService = inject(ToastNotificationService);
  errorMessagePipe = inject(ErrorMessagePipe);
  websocketService = inject(WebsocketService);
  loadingService = inject(IsLoadingService);
  stateSet = false;

  constructor() {
    super(DEFAULT_STATE);
    this.initStore();
  }

  readonly canAdd$ = this.select(({ canAdd }) => canAdd);
  readonly activeMessages$ = this.select(({ activeMessages }) => activeMessages);
  readonly messageList$ = this.select(({ messageList }) => messageList);
  readonly sentMessageList$ = this.select(({ sentMessageList }) => sentMessageList);
  readonly message$ = this.select(({ message }) => message);
  readonly editMode$ = this.select(({ editMode }) => editMode);
  readonly messageIsNew$ = this.select(
    this.message$,
    (message) => message !== undefined && message !== null && message.id === '',
  );
  readonly canEdit$ = combineLatest([this.canAdd$, this.message$]).pipe(
    untilDestroyed(this),
    withLatestFrom(this.authService.auth$),
    map(([[canAdd, message], auth]) => {
      if (!canAdd) {
        return false;
      }
      if (!message || !message.from) {
        return false;
      }
      return message.from.userId === (!!auth.id ? auth.id : auth.userId);
    }),
  );
  private messageSaveEvent = new Subject<UserMessage>();
  readonly messageSaveEvent$ = this.messageSaveEvent.asObservable();

  private drawerCloseEvent = new Subject<boolean>();
  readonly drawerCloseEvent$ = this.drawerCloseEvent.asObservable().pipe(shareReplay(1));

  readonly updateState = this.updater((state, updates: Partial<MessagesStoreState>) => ({
    ...state,
    ...updates,
  }));

  initStore() {
    console.log('initStore', this.stateSet);
    if (this.stateSet) {
      return;
    }
    this.setState(DEFAULT_STATE);
    this.updateState({
      canAdd: false,
    });

    this.authService
      .hasPermission$(RoleCategories.Messages, MessagePermissions.Create)
      .subscribe((value) => {
        this.updateState({ canAdd: value });
      });

    this.websocketService.incomingMessages$
      .pipe(
        untilDestroyed(this),
        filter((f: WsMessageListMessage) => f._type === WsMessageTypes.messageList),
        tap((payload: WsMessageListMessage) => {
          if (payload.messages.length > 0) {
            this.updateState({ activeMessages: payload.messages });
          }
        }),
        shareReplay(1),
      )
      .subscribe();

    this.stateSet = true;
  }

  readonly loadMessage = this.effect((origin$: Observable<{ id: string; loadingKey: string }>) =>
    origin$.pipe(
      switchMap((payload) => {
        if (!!!payload.id) {
          payload.id = 'new';
        }
        if (payload.id === 'new') {
          const auth = this.authService.currentUser();
          const from: UserPreview = {
            userId: auth.userId,
            email: auth.email,
            fullName: auth.fullName,
          };
          return of({ ...defaultMessage, from: from } as UserMessage);
        }
        return this.loadingService.add(this.api.getUserMessage(payload.id), {
          key: payload.loadingKey,
        });
      }),
      catchError((err) => {
        this.toastService.error(this.errorMessagePipe.transform(err), 'Unable to load message');
        return of(null);
      }),
      tap((message: UserMessage | null) => {
        if (message) {
          this.updateState({ message: message });
        }
      }),
    ),
  );

  readonly markMessagesReadStatus = this.effect(
    (origin$: Observable<{ ids: string[]; read: boolean }>) =>
      origin$.pipe(
        switchMap((payload) => {
          return this.api
            .updateMessages(
              payload.ids.map((m) => {
                return {
                  id: m,
                  messageRead: payload.read ? MessageReadStatus.read : MessageReadStatus.unread,
                };
              }),
            )
            .pipe(
              catchError((err) => {
                this.toastService.error(
                  this.errorMessagePipe.transform(err),
                  'Unable to update messages',
                );
                return of(null);
              }),
            );
        }),
        catchError((err) => {
          this.toastService.error(
            this.errorMessagePipe.transform(err),
            'Unable to update messages',
          );
          return of(null);
        }),
        tap((messages: UserMessage[] | null) => {
          if (messages) {
            console.log('Messages updated', messages);
          }
        }),
      ),
  );

  readonly deleteMessage = this.effect((origin$: Observable<string>) =>
    origin$.pipe(
      switchMap((messageId) => {
        return this.api.deleteUserMessage(messageId).pipe(
          catchError((err) => {
            this.toastService.error(
              this.errorMessagePipe.transform(err),
              'Unable to delete message',
            );
            return of(null);
          }),
        );
      }),
      catchError((err) => {
        this.toastService.error(this.errorMessagePipe.transform(err), 'Unable to delete message');
        return of(null);
      }),
      tap((message: UserMessage | null) => {
        if (message) {
          console.log('Message deleted', message);
        }
      }),
    ),
  );

  readonly saveMessage = this.effect(
    (
      origin$: Observable<{
        message: Partial<UserMessage>;
        loadingKey: string;
      }>,
    ) =>
      origin$.pipe(
        switchMap((payload) => {
          if (!!payload.message.id) {
            return this.loadingService.add(
              this.api.updateMessage(payload.message).pipe(
                catchError((err) => {
                  this.toastService.error(
                    this.errorMessagePipe.transform(err),
                    'Unable to save message',
                  );
                  return of(null);
                }),
              ),
              { key: payload.loadingKey },
            );
          }
          return this.loadingService.add(
            this.api.createMessage(payload.message).pipe(
              catchError((err) => {
                this.toastService.error(
                  this.errorMessagePipe.transform(err),
                  'Unable to save message',
                );
                return of(null);
              }),
            ),
            { key: payload.loadingKey },
          );
        }),
        catchError((err) => {
          this.toastService.error(this.errorMessagePipe.transform(err), 'Unable to save message');
          return of(null);
        }),
        tap((message: UserMessage | UserMessage[] | null) => {
          if (message) {
            this.toastService.success('Message saved successfully');
            if ((message as UserMessage[]).length) {
              this.updateState({
                message: (message as UserMessage[])[0],
                editMode: false,
              });
              this.messageSaveEvent.next((message as UserMessage[])[0]);
            } else {
              this.updateState({
                message: message as UserMessage,
                editMode: false,
              });
              this.messageSaveEvent.next(message as UserMessage);
            }
          }
        }),
      ),
  );

  toggleEditMode(enabled: boolean) {
    this.updateState({ editMode: enabled });
  }

  closeDrawer() {
    console.log('store-closeDrawer');
    this.drawerCloseEvent.next(true);
  }

  homeDataSet = (options: PaginatedTableOptions) => {
    return this.api.getUserMessages(options);
  };

  homeSentDataSet = (options: PaginatedTableOptions) => {
    return this.api.getUserSentMessages(options);
  };
}
