var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { TrailMapType } from '../../trail-maps/models';
const recordTypeId = 'Witness';
let Witness = class Witness {
    name;
    email;
    phone;
    address;
};
__decorate([
    FieldData({ recordTypeId })
], Witness.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'email' })
], Witness.prototype, "email", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'phone-number' })
], Witness.prototype, "phone", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address' })
], Witness.prototype, "address", void 0);
Witness = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        displayNameSingular: 'Witness',
        displayNamePlural: 'Witnesses',
        dataModelCustomization: {
            allowCustomFields: true
        },
        lookupProperties: { resultProperties: ['name'] },
        trailMapTypes: [TrailMapType.QuickAdd],
    })
], Witness);
export { Witness };
