import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { OperatorFunction, tap } from 'rxjs';
import { format, parse } from 'date-fns';

type FormSimpleType = string | number | boolean | Date;
// Wrap every "Type" property with FormControl or FormArray to implement typed form interface with no data model duplication
// Take into account that string[] or number[] could be valid form fields
// eslint-disable-next-line @typescript-eslint/ban-types
export type FormBy<Type = {}> = FormGroup<{
  [Property in keyof Type]: Type[Property] extends any[]
  ? Type[Property] extends FormSimpleType[]
  ? FormControl<Type[Property]>
  : FormArray<FormControl<Type[Property][number]>>
  : FormControl<Type[Property]>;
}>;

const dateStringFormat = 'yyyy-MM-dd';

export function getDateOnlyString(value: string | Date | undefined | null) {
  if (value === undefined || value === null) {
    return null;
  }
  if (typeof value === 'string') {
    if (value.length > 10) {
      value = value.substring(0, 10);
    }
    return parse(value, dateStringFormat, new Date());
  }
  return format(value, dateStringFormat);
}

export function setFormDateValue(value: string | Date | undefined | null) {
  if (value === undefined || value === null) {
    return null;
  }
  if (typeof value === 'string') {
    if (value.length > 10) {
      value = value.substring(0, 10);
    }
    return parse(value, dateStringFormat, new Date());
  }
  return value;
}

export function setFormDate(
  form: FormGroup,
  name: string,
  value: string | Date | undefined | null,
) {
  if (value === undefined || value === null) {
    return;
  }
  if (form.get(name) === null) {
    return;
  }
  if (typeof value === 'string') {
    if (value.length > 10) {
      value = value.substring(0, 10);
    }
    form.get(name)?.setValue(parse(value, dateStringFormat, new Date()));
  } else {
    form.get(name)?.setValue(value);
  }
}

export function getFormDateAsString(form: FormGroup, name: string): string | undefined {
  if (form.get(name) === null) {
    return undefined;
  }
  const formVal = form.get(name)?.value as string | Date;
  if (!formVal) {
    return undefined;
  }
  if (typeof formVal === 'string') {
    if (formVal.length > 10) {
      return formVal.substring(0, 10);
    }
    return formVal;
  }
  return format(formVal, dateStringFormat);
}

export function getDateValueAsString(val: string | Date | undefined): string | undefined {
  if (val === null) { return undefined; }
  if (val === undefined) { return undefined; }
  if (typeof val === 'string') {
    if (val.length > 10) {
      return val.substring(0, 10);
    }
    return val;
  }
  return format(val, dateStringFormat);
}

export function setFormDateTime(
  form: FormGroup,
  name: string,
  value: string | Date | undefined | null,
) {
  if (value === undefined || value === null) {
    return;
  }
  if (form.get(name) === null) {
    return;
  }
  if (typeof value === 'string') {
    form.get(name)?.setValue(new Date(value));
  } else {
    form.get(name)?.setValue(value);
  }
}

export function getFormDateTimeAsString(form: FormGroup, name: string): string | undefined {
  if (form.get(name) === null) {
    return undefined;
  }
  const formVal = form.get(name)?.value as string | Date;
  if (!formVal) {
    return undefined;
  }
  if (typeof formVal === 'string') {
    return formVal;
  }
  return formVal.toISOString();
}

export function setFormControlValidationDependency(
  form: FormGroup,
  destroyOp: OperatorFunction<any, unknown>,
  controlName: string,
  dependentControl: string,
) {
  form
    .get(controlName)
    ?.valueChanges.pipe(
      destroyOp,
      tap(() => {
        form.get(dependentControl)?.updateValueAndValidity();
      }),
    )
    .subscribe();
}

export function ensureFormError(form: FormGroup | FormArray | FormControl | AbstractControl, errorTag: string) {
  if (form.hasError(errorTag)) { return; }
  const newErrors: ValidationErrors | null | undefined = { ...form.errors };
  newErrors[errorTag] = true;
  form.setErrors(newErrors);
  console.log('form-set-errors', form, form.errors);
}

export function removeFormError(form: FormGroup | FormArray | FormControl | AbstractControl, errorTag: string) {
  if (!form.hasError(errorTag)) { return; }
  let newErrors: ValidationErrors | null | undefined = { ...form.errors };
  if (form.hasError(errorTag)) {
    delete newErrors[errorTag];
  }
  if (Object.keys(newErrors).length === 0) { newErrors = null; }
  form.setErrors(newErrors);
}