import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { shareReplay } from 'rxjs';
import { RecordHistory } from 'portal-commons/dist/data-model/record-types/record-history';
import { FilterCondition } from 'portal-commons/dist/data-filters/models';
import { PaginatedTableOptions } from 'portal-commons/dist/data-table/models';
import { FileDownloadResult } from 'portal-commons/dist/shared/file-download-result';

@Injectable({
  providedIn: 'root',
})
export class RecordHistoryApiService {
  httpClient = inject(HttpClient);

  getRecordHistory(recordType: string, id: string, filters: FilterCondition[] | undefined) {
    if (!filters) {
      return this.httpClient
        .get<RecordHistory[]>(`/api/record-history/${recordType}/${id}`)
        .pipe(shareReplay(1));
    }

    const options: PaginatedTableOptions = {
      countOnly: false,
      filterConditions: filters,
    };

    return this.httpClient
      .get<RecordHistory[]>(`/api/record-history/${recordType}/${id}`, {
        params: {
          options: JSON.stringify(options),
        },
      })
      .pipe(shareReplay(1));
  }

  export(recordType: string, id: string, filters: FilterCondition[] | undefined) {
    if (!filters) {
      return this.httpClient
        .get<FileDownloadResult>(`/api/record-history/export/${recordType}/${id}`)
        .pipe(shareReplay(1));
    }

    const options: PaginatedTableOptions = {
      countOnly: false,
      filterConditions: filters,
    };

    return this.httpClient
      .get<FileDownloadResult>(`/api/record-history/export/${recordType}/${id}`, {
        params: {
          options: JSON.stringify(options),
        },
      })
      .pipe(shareReplay(1));
  }
}
