var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'Integration';
let Integration = class Integration {
    id;
    createUserId;
    createUser;
    createdUTC;
    modifiedUserId;
    modifiedUser;
    modifiedUTC;
    deleteUserId;
    deleteUser;
    deletedUTC;
    name;
    description;
    url;
    requestVerb;
    disabled;
    requestParams;
    responseTransformScript;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Integration.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], Integration.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'User' })
], Integration.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime' })
], Integration.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId })
], Integration.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'User' })
], Integration.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime' })
], Integration.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId })
], Integration.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'User' })
], Integration.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime' })
], Integration.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], Integration.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], Integration.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], Integration.prototype, "url", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], Integration.prototype, "requestVerb", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], Integration.prototype, "disabled", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb' })
], Integration.prototype, "requestParams", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], Integration.prototype, "responseTransformScript", void 0);
Integration = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        displayNameSingular: 'Integration',
        displayNamePlural: 'Integrations',
        hidden: ['All'],
        usesNewDataModel: true,
    })
], Integration);
export { Integration };
