import { Injectable, inject } from '@angular/core';

import { DataFormatService } from 'app/core/services/data-format.service';
import { DataModelStoreService } from 'app/core/data-model/services/data-model.store';
import { tableColumn } from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class TableColumnService {
  private dataModelService = inject(DataModelStoreService);
  private dataFormatService = inject(DataFormatService);
  getDataModelColumn(
    recordType: string,
    path: string,
    overrides?: Partial<tableColumn>,
  ): tableColumn {
    const field = this.dataModelService.getFieldFromPath(recordType, path);
    if (!field) {
      return {
        propertyName: path,
        displayName: path,
      };
    }
    const format = this.dataFormatService.getFormattingForField(field);
    const col: tableColumn = {
      propertyName: path,
      displayName: field.label,
      format: format,
      displayFunc: (column: tableColumn, row: any, value: any) => {
        return this.dataFormatService.formatFieldValue(value, field);
      },
      ...overrides,
    };
    if (this.dataFormatService.isNumericFormat(format)) {
      col.alignment = 'right';
    }
    return col;
  }
}
