import { Router, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/auth/guards/auth.guard';
import { FourOhFourComponent } from './shared/components/four-oh-four/four-oh-four.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from './layout/layout.component';
import { NgModule } from '@angular/core';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
import { RoleCategories } from 'portal-commons/dist/roleEnums';
import { TenantGuard } from './core/auth/guards/tenant.guard';

const defaultHomeRoute = 'home';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: defaultHomeRoute },

  // Redirect signed in user to the '/home'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: defaultHomeRoute,
  },

  // Auth routes for guests
  {
    path: '',
    canActivate: [TenantGuard, NoAuthGuard],
    canActivateChild: [TenantGuard, NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import('app/modules/auth/confirmation-required/confirmation-required.module').then(
            (m) => m.AuthConfirmationRequiredModule,
          ),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('app/modules/auth/forgot-password/forgot-password.module').then(
            (m) => m.AuthForgotPasswordModule,
          ),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            (m) => m.AuthResetPasswordModule,
          ),
      },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule),
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('app/modules/auth/sign-up/sign-up.module').then((m) => m.AuthSignUpModule),
      },
    ],
  },
  {
    path: 'set-tenant',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    loadChildren: () =>
      import('app/modules/auth/set-tenant/set-tenant.module').then((m) => m.AuthSetTenantModule),
  },
  // Auth routes for OAuthFlow
  {
    path: '',
    // canActivate: [NoAuthGuard],
    // canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'oauth',
        loadChildren: () =>
          import('app/modules/auth/oauth/oauth.module').then((m) => m.OAuthModule),
      },
    ],
  },
  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [TenantGuard, AuthGuard],
    canActivateChild: [TenantGuard, AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule),
      },
      // {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
    ],
  },

  {
    path: '',
    canActivate: [TenantGuard, AuthGuard],
    canActivateChild: [TenantGuard, AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver, //FUSE - This is where initial data hooks/subscriptions are set before rendering like navigation, notifications, etc...
    },
    children: [
      {
        path: 'home',
        pathMatch: 'full',
        children: [],
      },
      {
        path: 'dashboards',
        data: { category: RoleCategories.Dashboard },
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      { path: '404', component: FourOhFourComponent },
      {
        path: 'endorsements',
        data: {
          category: RoleCategories.Policies,
        },
        loadChildren: () =>
          import('./modules/endorsements/endorsements.module').then((m) => m.EndorsementsModule),
      },
      {
        path: 'policies',
        data: {
          category: RoleCategories.Policies,
        },
        loadChildren: () =>
          import('./modules/policies/policies.module').then((m) => m.PoliciesModule),
      },
      {
        path: 'policy-audits',
        data: {
          category: RoleCategories.PolicyAudits,
        },
        loadChildren: () =>
          import('./modules/policy-audits/policy-audits.module').then((m) => m.PolicyAuditsModule),
      },
      {
        path: 'carriers',
        data: {
          category: RoleCategories.Carriers,
        },
        loadChildren: () =>
          import('./modules/carriers/carriers.module').then((m) => m.CarriersModule),
      },
      {
        path: 'locations',
        data: {
          category: RoleCategories.Locations,
        },
        loadChildren: () =>
          import('./modules/locations/locations.module').then((m) => m.LocationsModule),
      },
      {
        path: 'employers',
        data: {
          category: RoleCategories.Employers,
        },
        loadChildren: () =>
          import('./modules/employers/employers.module').then((m) => m.EmployersModule),
      },
      {
        path: 'claims-administrators',
        data: {
          category: RoleCategories.ClaimsAdministrators,
        },
        loadChildren: () =>
          import('./modules/claim-administrators/claim-administrators.module').then((m) => m.ClaimAdministratorsModule),
      },
      {
        path: 'contacts',
        data: {
          category: RoleCategories.Contacts,
        },
        loadChildren: () =>
          import('./modules/contacts/contacts.module').then((m) => m.ContactsModule),
      },
      {
        path: 'incidents',
        data: {
          category: RoleCategories.Incidents,
        },
        loadChildren: () =>
          import('./modules/incidents/incidents.module').then((m) => m.IncidentsModule),
      },
      {
        path: 'claims',
        data: {
          category: RoleCategories.Claims,
        },
        loadChildren: () => import('./modules/claims/claims.module').then((m) => m.ClaimsModule),
      },
      {
        path: 'submissions',
        data: {
          category: RoleCategories.Submissions,
        },
        loadChildren: () =>
          import('./modules/submissions/submissions.module').then((m) => m.SubmissionsModule),
      },
      {
        path: 'renewals',
        data: {
          category: RoleCategories.Renewals,
        },
        loadChildren: () =>
          import('./modules/renewals/renewals.module').then((m) => m.RenewalsModule),
      },
      {
        path: 'tenant',
        loadChildren: () =>
          import('./modules/tenant-modules/tenant-modules.module').then(
            (m) => m.TenantModulesModule,
          ),
      },
      {
        path: 'alerts',
        loadChildren: () => import('./modules/alerts/alerts.module').then((m) => m.AlertsModule),
      },
      {
        path: 'messages',
        data: {
          category: RoleCategories.Messages,
        },
        loadChildren: () =>
          import('./modules/messages/messages.module').then((m) => m.MessagesModule),
      },
      {
        path: 'payments',
        loadChildren: () =>
          import('./modules/payments/payments.module').then((m) => m.PaymentsModule),
      },
      {
        path: 'forms',
        data: {
          category: RoleCategories.FormsLibrary,
        },
        loadChildren: () => import('./modules/forms/forms.module').then((m) => m.FormsModule),
      },
      {
        path: 'faqs',
        data: {
          category: RoleCategories.FAQ,
        },
        loadChildren: () => import('./modules/faqs/faqs.module').then((m) => m.FaqsModule),
      },
      {
        path: 'loss-runs',
        data: {
          category: RoleCategories.LossRuns,
        },
        loadChildren: () =>
          import('./modules/loss-runs/loss-runs.module').then((m) => m.LossRunsModule),
      },
      {
        path: 'policyholders',
        data: {
          category: RoleCategories.Policyholders,
        },
        loadChildren: () =>
          import('./modules/policyholders/policyholders.module').then((m) => m.PolicyholdersModule),
      },
      {
        path: 'quotes',
        data: {},
        loadChildren: () => import('./modules/quotes/quotes.module').then((m) => m.QuotesModule),
      },
      {
        path: 'agencies',
        data: {
          category: RoleCategories.AgencyManagement,
        },
        loadChildren: () =>
          import('./modules/agencies/agencies.module').then((m) => m.AgenciesModule),
      },
      {
        path: 'invoices',
        data: {
          category: RoleCategories.Invoices,
        },
        loadChildren: () =>
          import('./modules/invoices/invoices.module').then((m) => m.InvoicesModule),
      },
      {
        path: 'line-item-types',
        data: {
          category: RoleCategories.LineItemTypes,
        },
        loadChildren: () =>
          import('./modules/line-item-types/line-item-types.module').then(
            (m) => m.LineItemTypesModule,
          ),
      },
      {
        path: 'accounting',
        data: {
          category: RoleCategories.Accounting,
        },
        loadChildren: () =>
          import('./modules/accounting/accounting.module').then((m) => m.AccountingModule),
      },
      {
        path: 'autopay-plans',
        data: {
          category: RoleCategories.AutopayPlans,
        },
        loadChildren: () =>
          import('./modules/autopay-plans/autopay-plans.module').then((m) => m.AutopayPlansModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./modules/search-results/search-results.module').then(
            (m) => m.SearchResultsModule,
          ),
      },
      {
        path: 'views',
        data: {
          category: RoleCategories.Views,
        },
        loadChildren: () =>
          import('./modules/user-views/user-views.module').then((m) => m.ViewsModule),
        // USE WITH ROUTE GUARD,   canLoad could be a future option to also add which would prevent the module js loading completely for more 'stage gate' checking of general module access //
        // canActivate: [AuthGuard],
        // THIS COULD BE A POSSIBLE PATTERN TO ALSO PASS STATIC DATA TO THE ROUTE GUARD TO INSPECT BASED ON USER ROLE PERMISSIONS with a pattern of 'AREA.ACTION' like 'POLICY.EDIT' //
        // data: { roles: 'AREA.ACTION'}
        // Note that any data or canActivate set here would be the default for all child routes in this module.  Within the child module itself, individual routes could also add their own canActivate or data
      },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: '**', redirectTo: 'home' },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.errorHandler = async (error: any) => {
      console.error('error occurred!', error);
      await this.router.navigate(['404']);
    };
  }
}
