var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { TrailMapType } from '../../trail-maps/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'Applicant';
let Applicant = class Applicant {
    legalName;
    federalEin;
    insuredAddress;
    contactName;
    contactEmail;
    contactAddress;
    displayName;
    tin;
    billingAddress;
    mailingAddress;
    physicalAddress;
    primaryContact;
};
__decorate([
    FieldData({ recordTypeId, label: 'Legal Name' })
], Applicant.prototype, "legalName", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'FEIN' })
], Applicant.prototype, "federalEin", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Insured Address' })
], Applicant.prototype, "insuredAddress", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Contact Name' })
], Applicant.prototype, "contactName", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Contact Email' })
], Applicant.prototype, "contactEmail", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Contact Address' })
], Applicant.prototype, "contactAddress", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Display Name' })
], Applicant.prototype, "displayName", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'TIN' })
], Applicant.prototype, "tin", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Billing Address', fieldType: 'Address' })
], Applicant.prototype, "billingAddress", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Mailing Address', fieldType: 'Address' })
], Applicant.prototype, "mailingAddress", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Physical Address', fieldType: 'Address' })
], Applicant.prototype, "physicalAddress", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Primary Contact', fieldType: 'Contact' })
], Applicant.prototype, "primaryContact", void 0);
Applicant = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        displayNameSingular: 'Applicant',
        displayNamePlural: 'Applicants',
        dataModelCustomization: {
            allowCustomFields: true,
        },
        trailMapTypes: [TrailMapType.QuickAdd],
        lookupProperties: { resultProperties: ['name'] },
        supports: {
            views: false,
        },
    })
], Applicant);
export { Applicant };
