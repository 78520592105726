import {
  AccountingPermissions,
  LedgerAccountsPermissions,
  PolicyPermissions,
  RoleCategories,
} from 'portal-commons/dist/roleEnums';

import { FuseNavigationItem } from '@fuse/components/navigation';
import { Icons } from '../icons/models';

export const groupedNavigation: FuseNavigationItem[] = [
  {
    id: 'home-group',
    title: 'My Stuff',
    type: 'aside',
    icon: Icons.heroicons.outline.Home,
    children: [
      {
        id: 'dashboards',
        title: 'My Dashboards',
        featureCategory: RoleCategories.Dashboard,
        type: 'basic',
        icon: Icons.heroicons.outline.ChartPie,
        link: '/dashboards/home',
      },
      {
        id: 'views',
        title: 'Views',
        featureCategory: RoleCategories.Views,
        type: 'basic',
        subtitle: 'Create cool data views',
        icon: Icons.custom.DatabaseFilter,
        link: '/views',
      },
      {
        id: 'messages',
        title: 'Messages',
        featureCategory: RoleCategories.Messages,
        subtitle: 'View and Send Messages',
        type: 'basic',
        icon: Icons.heroicons.outline.Envelope,
        link: '/messages',
      },
    ],
  },
  {
    id: 'policy-group',
    title: 'Policy Management',
    type: 'aside',
    icon: Icons.custom.Policy,
    children: [
      {
        id: 'policies',
        title: 'Policies',
        featureCategory: RoleCategories.Policies,
        subtitle: 'View Policy Information',
        type: 'basic',
        icon: Icons.custom.Policy,
        link: '/policies',
      },
      {
        id: 'submissions',
        title: 'Submissions',
        featureCategory: RoleCategories.Submissions,
        subtitle: 'View and Submit Applications',
        type: 'basic',
        icon: Icons.material.outline.FileUpload,
        link: '/submissions',
      },
      {
        id: 'renewals',
        title: 'Renewals',
        featureCategory: RoleCategories.Renewals,
        subtitle: 'View Renewal Information',
        type: 'basic',
        icon: Icons.material.outline.Autorenew,
        link: '/renewals',
      },
      {
        id: 'quotes',
        title: 'Quotes',
        featureCategory: RoleCategories.Quotes,
        subtitle: 'View Generated Quotes',
        type: 'basic',
        icon: Icons.heroicons.outline.CurrencyDollar,
        link: '/quotes',
      },
      {
        id: 'policy-audits',
        title: 'Audits',
        featureCategory: RoleCategories.PolicyAudits,
        subtitle: 'Create and Submit Policy Audits',
        type: 'basic',
        icon: Icons.material.outline.ChecklistRtl,
        link: '/policy-audits',
      },
    ],
  },
  {
    id: 'billing-group',
    title: 'Billing & Payments',
    type: 'aside',
    icon: 'mat_outline:attach_money',
    children: [
      {
        id: 'invoices',
        title: 'Invoices',
        featureCategory: RoleCategories.Invoices,
        subtitle: 'Create and View Invoices',
        type: 'basic',
        icon: Icons.material.outline.AttachMoney,
        link: '/invoices/home',
      },
      {
        id: 'payments',
        title: 'Payments',
        featureCategory: RoleCategories.PolicyPayments,
        subtitle: 'View and make payments',
        type: 'basic',
        icon: Icons.material.outline.Payment,
        link: '/payments/home',
      },
      {
        id: 'worksheets',
        title: 'Worksheets',
        featureCategory: RoleCategories.Payroll,
        subtitle: 'Create and View Payroll Reports',
        type: 'basic',
        icon: Icons.heroicons.outline.ClipboardDocumentList,
        link: '/payments/payroll-worksheet/home',
      },
      {
        id: 'line-item-types',
        title: 'Line Item Types',
        featureCategory: RoleCategories.LineItemTypes,
        subtitle: 'Types of line items for invoices',
        type: 'basic',
        icon: Icons.material.outline.GroupWork,
        link: '/line-item-types/home',
      },
      {
        id: 'autopay-plans',
        title: 'Autopay Plans',
        featureCategory: RoleCategories.AutopayPlans,
        subtitle: 'Automatically pay invoices',
        type: 'basic',
        icon: Icons.material.outline.Autorenew,
        link: '/autopay-plans/home',
      },
      {
        id: 'policy-billing-schedule-types',
        title: 'Billing Schedule Types',
        featureCategory: RoleCategories.Policies,
        featurePermissionOr: [PolicyPermissions.Admin],
        type: 'basic',
        icon: Icons.heroicons.outline.Calendar,
        link: '/policies/billing-schedule-types',
      },
    ],
  },
  {
    id: 'accouting-group',
    title: 'Accounting',
    type: 'aside',
    icon: Icons.custom.Accounting,
    meta: {
      showGroup: true,
    },
    children: [
      {
        id: 'ledger-entries',
        title: 'Ledger',
        featureCategory: RoleCategories.Accounting,
        subtitle: 'Policyholder Ledger Entries',
        type: 'basic',
        icon: Icons.heroicons.outline.BookOpen,
        link: '/accounting/ledger',
      },
      {
        id: 'ledger-deposits',
        title: 'Deposits',
        featureCategory: RoleCategories.Accounting,
        featurePermissionOr: [AccountingPermissions.ViewDeposits],
        subtitle: 'Policyholder Deposits',
        type: 'basic',
        icon: Icons.heroicons.outline.Banknotes,
        link: '/accounting/deposits',
      },
      {
        id: 'ledger-account-admin',
        title: 'Accounts',
        featureCategory: RoleCategories.LedgerAccounts,
        featurePermissionOr: [LedgerAccountsPermissions.AdminLedgerCodes],
        subtitle: 'Setup Ledger Account Groups and Accounts',
        type: 'basic',
        icon: Icons.feather.List,
        link: '/accounting/admin',
      },
      {
        id: 'surplus-calculations',
        title: 'Surplus Calculations',
        featureCategory: RoleCategories.SurplusCalculations,
        subtitle: 'Calculate and disburse Policy Surpluses',
        type: 'basic',
        icon: Icons.heroicons.outline.ReceiptPercent,
        link: '/accounting/surplus-calculations',
      },
    ],
  },
  {
    id: 'crm-group',
    title: 'CRM',
    type: 'aside',
    icon: Icons.material.outline.Contacts,
    children: [
      {
        id: 'policyholders',
        title: 'Policyholders',
        featureCategory: RoleCategories.Policyholders,
        type: 'basic',
        icon: Icons.heroicons.outline.Briefcase,
        link: '/policyholders',
      },
      {
        id: 'agencies',
        title: 'Agencies',
        featureCategory: RoleCategories.AgencyManagement,
        type: 'basic',
        icon: Icons.heroicons.outline.BuildingOffice,
        link: '/agencies/home',
      },
      {
        id: 'contacts',
        title: 'Contacts',
        featureCategory: RoleCategories.Contacts,
        type: 'basic',
        icon: Icons.phosphor.regular.AddressBook,
        link: '/contacts',
      },
      {
        id: 'locations',
        title: 'Locations',
        featureCategory: RoleCategories.Locations,
        type: 'basic',
        icon: Icons.feather.MapPin,
        link: '/locations',
      },
      {
        id: 'carriers',
        title: 'Carriers',
        featureCategory: RoleCategories.Carriers,
        type: 'basic',
        icon: Icons.phosphor.regular.UmbrellaSimple,
        link: '/carriers',
      },
      {
        id: 'employers',
        title: 'Employers',
        featureCategory: RoleCategories.Employers,
        type: 'basic',
        icon: Icons.phosphor.regular.BuildingOffice,
        link: '/employers',
      },
      {
        id: 'claims-admins',
        title: 'Claims Administrators',
        featureCategory: RoleCategories.ClaimsAdministrators,
        type: 'basic',
        icon: Icons.phosphor.regular.ShieldCheck,
        link: '/claims-administrators',
      },
    ],
  },
  {
    id: 'claims-group',
    title: 'Claims & Loss Runs',
    type: 'aside',
    icon: Icons.custom.Claim,
    children: [
      {
        id: 'claims',
        title: 'Claims',
        featureCategory: RoleCategories.Claims,
        subtitle: 'View Claim Information',
        type: 'basic',
        icon: Icons.custom.Claim,
        link: '/claims',
      },
      {
        id: 'loss-runs',
        title: 'Loss Runs',
        featureCategory: RoleCategories.LossRuns,
        subtitle: 'Run Claim Loss Run Reports',
        type: 'basic',
        icon: Icons.heroicons.outline.DocumentChartBar,
        link: '/loss-runs/home',
      },
    ],
  },
  {
    id: 'incidents',
    title: 'Incidents',
    subtitle: 'Manage First Notices of Loss',
    featureCategory: RoleCategories.Incidents,
    type: 'basic',
    icon: Icons.material.outline.PersonalInjury,
    link: '/incidents',
  },
  {
    id: 'help-center-group',
    title: 'Help Center',
    type: 'aside',
    icon: Icons.material.outline.Support,
    children: [
      {
        id: 'forms',
        title: 'Forms',
        featureCategory: RoleCategories.FormsLibrary,
        subtitle: 'Download Common Forms',
        type: 'basic',
        icon: Icons.feather.FileText,
        link: '/forms/home',
      },

      {
        id: 'faqs',
        title: 'FAQs',
        featureCategory: RoleCategories.FAQ,
        subtitle: 'Frequently Asked Questions',
        type: 'basic',
        icon: Icons.material.outline.LiveHelp,
        link: '/faqs/home',
      },
    ],
  },
  {
    id: 'user-mgmt-group',
    title: 'User Management',
    type: 'aside',
    icon: Icons.material.outline.ManageAccounts,
    children: [
      {
        id: 'admin.users',
        title: 'Users',
        type: 'basic',
        featureCategory: RoleCategories.Users,
        icon: Icons.heroicons.outline.UserCircle,
        link: '/admin/users',
      },
      {
        id: 'admin.roles',
        title: 'Roles',
        type: 'basic',
        featureCategory: RoleCategories.Roles,
        icon: Icons.material.outline.LockOpen,
        link: '/admin/roles',
      },
      {
        id: 'admin.accessGroups',
        title: 'Access Groups',
        type: 'basic',
        featureCategory: RoleCategories.AccessGroups,
        icon: Icons.material.outline.FilterAlt,
        link: '/admin/access-groups',
      },
    ],
  },
  {
    id: 'admin-group',
    title: 'Admin',
    type: 'aside',
    icon: Icons.material.outline.Security,
    children: [
      {
        id: 'admin.datamodel',
        title: 'Data Model',
        type: 'basic',
        featureCategory: RoleCategories.DataModel,
        icon: Icons.feather.Database,
        link: '/admin/datamodel',
      },
      {
        id: 'admin.codesets',
        title: 'Code Sets',
        type: 'basic',
        featureCategory: RoleCategories.Codes,
        icon: Icons.feather.List,
        link: '/admin/codesets',
      },
      {
        id: 'rates.tables',
        title: 'Rate Tables',
        type: 'basic',
        featureCategory: RoleCategories.Rates,
        icon: Icons.heroicons.outline.TableCells,
        link: '/admin/ratetables',
      },
      {
        id: 'admin.rating-formulas',
        title: 'Rating Formulas',
        type: 'basic',
        featureCategory: RoleCategories.Rating,
        icon: Icons.heroicons.outline.Calculator,
        link: '/admin/rating-formulas',
      },
      {
        id: 'alerts',
        title: 'Scheduled Alerts',
        type: 'basic',
        featureCategory: RoleCategories.Alerts,
        icon: Icons.material.outline.Campaign,
        link: '/alerts',
      },
      {
        id: 'admin.trailmaps',
        title: 'Trail Maps',
        type: 'basic',
        featureCategory: RoleCategories.TrailMaps,
        icon: Icons.material.outline.Map,
        link: '/admin/trailmaps',
      },
      {
        id: 'admin.trail-map-fills',
        title: 'Trail Map Fills',
        type: 'basic',
        featureCategory: RoleCategories.TrailMaps,
        icon: Icons.material.outline.DynamicForm,
        link: '/admin/trail-map-fills',
      },
      {
        id: 'admin.document-templates',
        title: 'Document Templates',
        type: 'basic',
        featureCategory: RoleCategories.DocumentTemplates,
        icon: Icons.feather.FileText,
        link: '/admin/document-templates',
      },
      {
        id: 'admin.workflows',
        title: 'Workflows',
        type: 'basic',
        featureCategory: RoleCategories.Workflows,
        icon: Icons.phosphor.regular.TreeStructure,
        link: '/admin/workflows',
      },
      {
        id: 'admin.integrations',
        title: 'Integrations',
        type: 'basic',
        featureCategory: RoleCategories.Integrations,
        icon: Icons.heroicons.outline.ArrowsRightLeft,
        link: '/admin/integrations',
      },
    ],
  },
];
