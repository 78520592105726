import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { RecordHistoryDetailComponent } from './components/record-history-detail/record-history-detail.component';
import { LayoutModule } from 'app/layout/layout.module';
import { MatIconModule } from '@angular/material/icon';
import { FuseAlertModule } from '@fuse/components/alert';
import { RecordHistoryItemComponent } from './components/record-history-item/record-history-item.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DataModelModule } from '../data-model/data-model.module';

@NgModule({
  declarations: [RecordHistoryDetailComponent, RecordHistoryItemComponent],
  imports: [
    CommonModule,
    SharedModule,
    LayoutModule,
    MatIconModule,
    FuseAlertModule,
    MatTooltipModule,
    DataModelModule,
  ],
  exports: [RecordHistoryDetailComponent],
})
export class RecordHistoryModule {}
