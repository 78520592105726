import { Injectable, inject } from '@angular/core';
import { WebsocketService } from '../auth/web-socket.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, shareReplay, tap } from 'rxjs';
import { WsMessageTypes } from 'portal-commons/dist/ws/model';
import { IsLoadingService } from '@service-work/is-loading';
import { WsFileDownloadMessage } from 'portal-commons/dist/ws/model';
import { ToastNotificationService } from '../notifications/toasts/toast-notification.service';
import { downloadFileResult } from '../utils/file-save-as-helper';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class FileDownloadListenerService {
  websocketService = inject(WebsocketService);
  loadingService = inject(IsLoadingService);
  toastService = inject(ToastNotificationService);

  constructor() {
    console.log('filedownload service init');
    this.websocketService.incomingMessages$
      .pipe(
        untilDestroyed(this),
        filter((f: WsFileDownloadMessage) => f._type === WsMessageTypes.fileDownloadResult),
        tap((payload) => {
          console.log('fileDownloadWsMessage', payload);
          if (payload.loadingId) {
            this.loadingService.clear({ key: payload.loadingId });
          }
          if (payload.error) {
            this.toastService.error(payload.error.message, payload.error.title);
          }
          if (payload.fileResult) {
            downloadFileResult(payload.fileResult);
          }
        }),
        shareReplay(1),
      )
      .subscribe();
  }
}
