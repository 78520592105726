<div
  class="group hover:cursor-pointer relative mb-1 py-0 px-1 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
  [ngClass]="{
    'mb-2': last,
    'bg-gray-100': this._isActive
  }">
  <div (click)="selectResult(result)" class="flex flex-row w-full items-center" #itemElement>
    <div class="flex flex-col px-4">
      <div class="truncate leading-normal" [innerHTML]="result.title"></div>
      <div class="w-[440px]">
        <div
          class="truncate overflow-hidden leading-normal text-sm text-secondary"
          *ngIf="result.subtitle"
          [innerHTML]="result.subtitle"></div>
        <div
          class="truncate overflow-hidden leading-normal text-sm text-secondary"
          *ngIf="!result.subtitle">
          {{ result.link }}
        </div>
      </div>
    </div>
    <div class="ml-auto text-secondary mr-2" *ngIf="this._isActive">
      <mat-icon class="icon-size-4" [svgIcon]="Icons.material.outline.KeyboardReturn"></mat-icon>
    </div>
  </div>
</div>
