var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
export var PhoneType;
(function (PhoneType) {
    PhoneType["phone"] = "phone";
    PhoneType["mobile"] = "mobile";
    PhoneType["fax"] = "fax";
})(PhoneType || (PhoneType = {}));
const recordTypeId = 'Phone';
let Phone = class Phone {
    number;
    extension;
    phonetype;
};
__decorate([
    FieldData({ recordTypeId })
], Phone.prototype, "number", void 0);
__decorate([
    FieldData({ recordTypeId })
], Phone.prototype, "extension", void 0);
__decorate([
    FieldData({ recordTypeId })
], Phone.prototype, "phonetype", void 0);
Phone = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        displayNameSingular: 'Phone',
        displayNamePlural: 'Phones',
        lookupProperties: { resultProperties: ['number'] },
    })
], Phone);
export { Phone };
