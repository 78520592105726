import { Observable, lastValueFrom, shareReplay } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TableSchema } from 'portal-commons/dist/views/models';

@Injectable({
  providedIn: 'root',
})
export class TableSchemaService {
  private schemas: Observable<TableSchema[]> | undefined;
  private httpClient: HttpClient;
  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }
  getSchemas(forceRefresh = false) {
    if (!this.schemas || forceRefresh) {
      this.schemas = this.httpClient.get<TableSchema[]>(`/api/viewSchema`).pipe(shareReplay(1));
    }
    return this.schemas;
  }

  getSchemasAsPromise(forceRefresh = false) {
    return lastValueFrom(this.getSchemas(forceRefresh));
  }

  getSchemaById(id: string) {
    return lastValueFrom(this.getSchemas()).then((schemas) => {
      return schemas.find((f) => f.id === id);
    });
  }
}
