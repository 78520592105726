var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'SurplusCalculationLineItem';
let SurplusCalculationLineItem = class SurplusCalculationLineItem {
    id;
    surplusCalculationId;
    surplusCalculation;
    calculationDate;
    // @FieldData({ recordTypeId, label: 'Policy ID', fieldType: 'uuid' })
    // policyId?: string;
    // @FieldData({ recordTypeId, label: 'Policy', fieldType: 'Policy', jsonStorage: true })
    // policy?: Policy;
    policyholderId;
    policyholder;
    cancelledYear;
    claimsFund;
    actualLoss;
    //could be calculated
    fundNet;
    prevDistributed;
    distributionPercent;
    surplusDistributed;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], SurplusCalculationLineItem.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], SurplusCalculationLineItem.prototype, "surplusCalculationId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'SurplusCalculation' })
], SurplusCalculationLineItem.prototype, "surplusCalculation", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Date Calculated', fieldType: 'date' })
], SurplusCalculationLineItem.prototype, "calculationDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Policyholder ID', fieldType: 'uuid' })
], SurplusCalculationLineItem.prototype, "policyholderId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Policyholder', fieldType: 'PolicyHolder', jsonStorage: true })
], SurplusCalculationLineItem.prototype, "policyholder", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Cancelled Year', fieldType: 'integer' })
], SurplusCalculationLineItem.prototype, "cancelledYear", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Claims Fund', fieldType: 'currency' })
], SurplusCalculationLineItem.prototype, "claimsFund", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Actual Loss', fieldType: 'currency' })
], SurplusCalculationLineItem.prototype, "actualLoss", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Fund Net', fieldType: 'currency' })
], SurplusCalculationLineItem.prototype, "fundNet", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Previously Distributed', fieldType: 'currency' })
], SurplusCalculationLineItem.prototype, "prevDistributed", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Percent of Distribution', fieldType: 'decimal' })
], SurplusCalculationLineItem.prototype, "distributionPercent", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Surplus Distributed', fieldType: 'currency' })
], SurplusCalculationLineItem.prototype, "surplusDistributed", void 0);
SurplusCalculationLineItem = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        supports: {
            views: true,
        },
        dataModelCustomization: {
            allowCustomFields: true,
        },
        hasBackingTable: true,
        displayNameSingular: 'Surplus Calculation Line Item',
        displayNamePlural: 'Surplus Calculation Line Items',
    })
], SurplusCalculationLineItem);
export { SurplusCalculationLineItem };
