<ng-container *ngIf="this.displayOption === 'date'" [ngTemplateOutlet]="dateDetails"></ng-container>
<ng-container
  *ngIf="this.displayOption === 'format'"
  [ngTemplateOutlet]="formatDetails"></ng-container>

<div class="flex flex-col gap-2" *ngIf="this.displayOption === 'both'">
  <div>
    You can use date tags in the filename to generate formatted date/time strings within the final
    file name
  </div>
  <div>
    Use the following format for tagging
    <code>{{ '{DATE:[date-val]:[format-string]}' }}></code>
  </div>
  <div>
    For example, for the current ISO date/time <code>{{ '{DATE:now:iso}' }}</code>
  </div>
  <div>
    <mat-tab-group class="mat-tab-native overflow-y-scroll">
      <mat-tab label="Date Tag Details">
        <ng-container [ngTemplateOutlet]="dateDetails"></ng-container>
      </mat-tab>
      <mat-tab label="Date String Format Details">
        <ng-container [ngTemplateOutlet]="formatDetails"></ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #dateDetails>
  <div class="w-full flex flex-col max-h-80 overflow-y-scroll">
    <table class="p-datatable p-datatable-sm">
      <thead class="p-datatable-thead w-full sticky top-0">
        <tr class="w-full">
          <th>Token</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody class="p-datatable-tbody w-full">
        <tr *ngFor="let item of RelativeDateTokens | keyvalue">
          <td class="min-w-20">{{ item.key }}</td>
          <td class="text-secondary">{{ item.value }}</td>
        </tr>
        <tr>
          <td class="min-w-20">&lt; +/- &gt;&lt;number&gt;&lt;d|day|days&gt;</td>
          <td class="text-secondary">
            Plus/minus number of days from today. ex:
            <pre>+7d</pre>
          </td>
        </tr>
        <tr>
          <td class="min-w-20">&lt; +/- &gt;&lt;number&gt;&lt;m|month|months&gt;</td>
          <td class="text-secondary">
            Plus/minus number of months from today ex:
            <pre>-3months</pre>
          </td>
        </tr>
        <tr>
          <td class="min-w-20">&lt; +/- &gt;&lt;number&gt;&lt;w|week|weeks&gt;</td>
          <td class="text-secondary">
            Plus/minus number of weeks from today ex:
            <pre>+24w</pre>
          </td>
        </tr>
        <tr>
          <td class="min-w-20">&lt; +/- &gt;&lt;number&gt;&lt;y|year|years&gt;</td>
          <td class="text-secondary">
            Plus/minus number of years from today ex:
            <pre>+1year</pre>
          </td>
        </tr>
        <tr>
          <td class="min-w-20">&lt; +/- &gt;&lt;number&gt;&lt;q|quarter|quarters&gt;</td>
          <td class="text-secondary">
            Plus/minus number of quarters from today ex:
            <pre>+4quarters</pre>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #formatDetails>
  <div class="w-full flex flex-col max-h-80 overflow-y-scroll">
    <table class="p-datatable p-datatable-sm">
      <thead class="p-datatable-thead w-full sticky top-0">
        <tr>
          <th>Unit</th>
          <th>Token</th>
          <th>Result examples</th>
        </tr>
      </thead>
      <tbody class="p-datatable-tbody w-full">
        <tr>
          <td>ISO Date/Time</td>
          <td>iso</td>
          <td>Full ISO Date/Time</td>
        </tr>
        <tr>
          <td></td>
          <td>iso-date</td>
          <td>ISO Date Only</td>
        </tr>
        <tr>
          <td>Month</td>
          <td>M</td>
          <td>1, 2, ..., 12</td>
        </tr>
        <tr>
          <td></td>
          <td>Mo</td>
          <td>1st, 2nd, ..., 12th</td>
        </tr>
        <tr>
          <td></td>
          <td>MM</td>
          <td>01, 02, ..., 12</td>
        </tr>
        <tr>
          <td></td>
          <td>MMM</td>
          <td>Jan, Feb, ..., Dec</td>
        </tr>
        <tr>
          <td></td>
          <td>MMMM</td>
          <td>January, February, ..., December</td>
        </tr>
        <tr>
          <td>Quarter</td>
          <td>Q</td>
          <td>1, 2, 3, 4</td>
        </tr>
        <tr>
          <td></td>
          <td>Qo</td>
          <td>1st, 2nd, 3rd, 4th</td>
        </tr>
        <tr>
          <td>Day of month</td>
          <td>D</td>
          <td>1, 2, ..., 31</td>
        </tr>
        <tr>
          <td></td>
          <td>Do</td>
          <td>1st, 2nd, ..., 31st</td>
        </tr>
        <tr>
          <td></td>
          <td>DD</td>
          <td>01, 02, ..., 31</td>
        </tr>
        <tr>
          <td>Day of year</td>
          <td>DDD</td>
          <td>1, 2, ..., 366</td>
        </tr>
        <tr>
          <td></td>
          <td>DDDo</td>
          <td>1st, 2nd, ..., 366th</td>
        </tr>
        <tr>
          <td></td>
          <td>DDDD</td>
          <td>001, 002, ..., 366</td>
        </tr>
        <tr>
          <td>Day of week</td>
          <td>d</td>
          <td>0, 1, ..., 6</td>
        </tr>
        <tr>
          <td></td>
          <td>do</td>
          <td>0th, 1st, ..., 6th</td>
        </tr>
        <tr>
          <td></td>
          <td>dd</td>
          <td>Su, Mo, ..., Sa</td>
        </tr>
        <tr>
          <td></td>
          <td>ddd</td>
          <td>Sun, Mon, ..., Sat</td>
        </tr>
        <tr>
          <td></td>
          <td>dddd</td>
          <td>Sunday, Monday, ..., Saturday</td>
        </tr>
        <tr>
          <td>Day of ISO week</td>
          <td>E</td>
          <td>1, 2, ..., 7</td>
        </tr>
        <tr>
          <td>ISO week</td>
          <td>W</td>
          <td>1, 2, ..., 53</td>
        </tr>
        <tr>
          <td></td>
          <td>Wo</td>
          <td>1st, 2nd, ..., 53rd</td>
        </tr>
        <tr>
          <td></td>
          <td>WW</td>
          <td>01, 02, ..., 53</td>
        </tr>
        <tr>
          <td>Year</td>
          <td>YY</td>
          <td>00, 01, ..., 99</td>
        </tr>
        <tr>
          <td></td>
          <td>YYYY</td>
          <td>1900, 1901, ..., 2099</td>
        </tr>
        <tr>
          <td>ISO week-numbering year</td>
          <td>GG</td>
          <td>00, 01, ..., 99</td>
        </tr>
        <tr>
          <td></td>
          <td>GGGG</td>
          <td>1900, 1901, ..., 2099</td>
        </tr>
        <tr>
          <td>AM/PM</td>
          <td>A</td>
          <td>AM, PM</td>
        </tr>
        <tr>
          <td></td>
          <td>a</td>
          <td>am, pm</td>
        </tr>
        <tr>
          <td></td>
          <td>aa</td>
          <td>a.m., p.m.</td>
        </tr>
        <tr>
          <td>Hour</td>
          <td>H</td>
          <td>0, 1, ... 23</td>
        </tr>
        <tr>
          <td></td>
          <td>HH</td>
          <td>00, 01, ... 23</td>
        </tr>
        <tr>
          <td></td>
          <td>h</td>
          <td>1, 2, ..., 12</td>
        </tr>
        <tr>
          <td></td>
          <td>hh</td>
          <td>01, 02, ..., 12</td>
        </tr>
        <tr>
          <td>Minute</td>
          <td>m</td>
          <td>0, 1, ..., 59</td>
        </tr>
        <tr>
          <td></td>
          <td>mm</td>
          <td>00, 01, ..., 59</td>
        </tr>
        <tr>
          <td>Second</td>
          <td>s</td>
          <td>0, 1, ..., 59</td>
        </tr>
        <tr>
          <td></td>
          <td>ss</td>
          <td>00, 01, ..., 59</td>
        </tr>
        <tr>
          <td>1/10 of second</td>
          <td>S</td>
          <td>0, 1, ..., 9</td>
        </tr>
        <tr>
          <td>1/100 of second</td>
          <td>SS</td>
          <td>00, 01, ..., 99</td>
        </tr>
        <tr>
          <td>Millisecond</td>
          <td>SSS</td>
          <td>000, 001, ..., 999</td>
        </tr>
        <tr>
          <td>Timezone</td>
          <td>Z</td>
          <td>-01:00, +00:00, ... +12:00</td>
        </tr>
        <tr>
          <td></td>
          <td>ZZ</td>
          <td>-0100, +0000, ..., +1200</td>
        </tr>
        <tr>
          <td>Seconds timestamp</td>
          <td>X</td>
          <td>512969520</td>
        </tr>
        <tr>
          <td>Milliseconds timestamp</td>
          <td>x</td>
          <td>512969520900</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
