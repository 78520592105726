import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { DataFilterComponent } from '../data-filter/data-filter.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataFilterStore } from '../../services/data-filter.store';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RecordTypeFieldMetadata } from 'app/core/data-model/models/models';
import { DataModelStoreService } from 'app/core/data-model/services/data-model.store';
import { FormBuilder } from '@angular/forms';
import { FilterCondition } from 'portal-commons/dist/data-filters/models';

@UntilDestroy()
@Component({
  selector: 'tb-data-filter-condition',
  templateUrl: './data-filter-condition.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataFilterConditionComponent {
  dataModelStore = inject(DataModelStoreService);
  builder = inject(FormBuilder);
  index?: number;
  existingField$: Observable<RecordTypeFieldMetadata>;
  public filterStore: DataFilterStore;
  filterCondition: FilterCondition | undefined;

  form = this.builder.group({});

  constructor(
    public matDialogRef: MatDialogRef<DataFilterComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.index = data.index;
    this.filterStore = data.filterStore;
    this.filterCondition = data.filter;
  }
  close() {
    this.matDialogRef.close();
  }

  apply() {
    const field = this.form.get('field')!.value as RecordTypeFieldMetadata;
    const filter: FilterCondition = {
      fieldRecordType: field.recordTypeId,
      fieldId: field.id,
      fieldPath: field.fieldRefName ?? field.refName,
      filterType: this.form.get('filterType')!.value,
      searchParameter1: this.form.get('searchParameter1')!.value,
      searchParameter2: this.form.get('searchParameter2')!.value,
      searchParameter1Period: this.form.get('searchParameter1Period')!.value,
    };
    this.matDialogRef.close({
      success: true,
      filter: filter,
      index: this.index,
    });
  }
}
