<div
  id="user-profile-form"
  class="flex flex-col max-w-360 md:min-w-128 sm:min-w-100 max-h-screen -m-6">
  <!-- Header -->
  <div
    class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
    <div class="text-lg font-medium">Update User Profile</div>
    <div class="ml-auto">
      <button mat-icon-button aria-label="Close" matTooltip="Close" tabindex="-1" (click)="close()">
        <mat-icon class="text-on-primary" svgIcon="mat_outline:close"></mat-icon>
      </button>
    </div>
  </div>
  <mat-tab-group class="pt-3">
    <mat-tab label="My Profile">
      <div [swIsLoading]="this.profileLoadingKey" class="min-h-120">
        <form
          [formGroup]="profileForm"
          *ngIf="this.user"
          autocomplete="off"
          class="flex flex-col flex-auto p-3 sm:p-5 overflow-y-auto">
          <mat-form-field class="w-full fuse-mat-dense" hintLabel="Read-only">
            <mat-label class="w-full">Role</mat-label>
            <input matInput [readonly]="true" [value]="this.user.role.name" />
          </mat-form-field>
          <mat-form-field
            class="w-full fuse-mat-dense"
            *ngIf="this.agencyName"
            hintLabel="Read-only">
            <mat-label class="w-full">Agency</mat-label>
            <input matInput [readonly]="true" [value]="this.agencyName" />
          </mat-form-field>
          <mat-form-field
            class="w-full fuse-mat-dense"
            *ngIf="!this.lockEmail"
            hintLabel="Read-only">
            <mat-label class="w-full">User Name</mat-label>
            <input matInput [readonly]="true" [value]="this.user.userId" />
          </mat-form-field>
          <mat-form-field class="w-full fuse-mat-dense">
            <mat-label>Email</mat-label>
            <input
              matInput
              formControlName="email"
              autocomplete="new-email"
              [readonly]="this.lockEmail" />
            <mat-hint *ngIf="this.lockEmail"
              >Email cannot be changed as it is also the user name.</mat-hint
            >
            <mat-error *ngIf="profileForm.get('email').hasError('required')"
              >This field is required</mat-error
            >
            <mat-error *ngIf="profileForm.get('email').hasError('email')"
              >Email must be valid</mat-error
            >
          </mat-form-field>
          <mat-form-field class="w-full fuse-mat-dense">
            <mat-label>Full Name</mat-label>
            <input matInput formControlName="fullName" autocomplete="new-fullName" />
          </mat-form-field>
          <mat-form-field class="w-full fuse-mat-dense">
            <mat-label>Timezone</mat-label>
            <mat-select formControlName="timezone">
              <mat-option *ngFor="let timezone of timezones" [value]="timezone.value">
                {{ timezone.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="mt-2">
            <button
              mat-flat-button
              color="primary"
              [disabled]="
                !profileForm.valid ||
                profileForm.pristine ||
                (this.profileLoadingKey | swIsLoading | async)
              "
              [swIsLoading]="this.profileLoadingKey"
              class="mat-stroked-button mb-3"
              (click)="updateUser()">
              Update Profile
            </button>
          </div>
        </form>
      </div>
    </mat-tab>
    <mat-tab label="Change Password">
      <div class="min-h-120">
        <form
          [formGroup]="changePasswordForm"
          [swIsLoading]="this.passwordChangeKey"
          autocomplete="off"
          class="flex flex-col flex-auto p-3 sm:p-5 overflow-y-auto">
          <mat-form-field class="w-full fuse-mat-dense">
            <mat-label>Current Password</mat-label>
            <input
              id="oldPassword"
              matInput
              type="password"
              [formControlName]="'oldPassword'"
              #passwordField />
            <button
              tabindex="-1"
              mat-icon-button
              type="button"
              (click)="
                passwordField.type === 'password'
                  ? (passwordField.type = 'text')
                  : (passwordField.type = 'password')
              "
              matSuffix>
              <mat-icon
                class="icon-size-5"
                *ngIf="passwordField.type === 'password'"
                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
              <mat-icon
                class="icon-size-5"
                *ngIf="passwordField.type === 'text'"
                [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
            </button>
            <mat-error>Current Password is required</mat-error>
          </mat-form-field>

          <mat-form-field class="w-full fuse-mat-dense">
            <mat-label>New Password</mat-label>
            <input
              id="newPassword"
              matInput
              type="password"
              [formControlName]="'newPassword'"
              #newPasswordField />
            <button
              tabindex="-1"
              mat-icon-button
              type="button"
              (click)="
                newPasswordField.type === 'password'
                  ? (newPasswordField.type = 'text')
                  : (newPasswordField.type = 'password')
              "
              matSuffix>
              <mat-icon
                class="icon-size-5"
                *ngIf="passwordField.type === 'password'"
                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
              <mat-icon
                class="icon-size-5"
                *ngIf="passwordField.type === 'text'"
                [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
            </button>
            <mat-error
              class="mb-1"
              *ngIf="changePasswordForm.get('newPassword').hasError('required')"
              >New Password is required</mat-error
            >
            <mat-error
              class="mb-1"
              *ngIf="changePasswordForm.get('newPassword').hasError('minlength')"
              >Must be at least {{ MIN_PASSWORD_LENGTH }} characters</mat-error
            >
            <mat-error
              class="mb-1"
              *ngIf="changePasswordForm.get('newPassword').hasError('hasNumber')"
              >Must contain at least one number character</mat-error
            >
            <mat-error
              class="mb-1"
              *ngIf="changePasswordForm.get('newPassword').hasError('hasCapitalCase')"
              >Must contain at least one uppercase character</mat-error
            >
            <mat-error
              class="mb-1"
              *ngIf="changePasswordForm.get('newPassword').hasError('hasSmallCase')"
              >Must contain at least one lowercase character</mat-error
            >
            <mat-error
              class="mb-1"
              *ngIf="changePasswordForm.get('newPassword').hasError('hasSpecialCharacters')">
              Must contain at least one special character
              <mat-icon
                class="-mt-2 ml-1 icon-size-4"
                matTooltip="Acceptable special characters are a space or one of the following ^ $ * . [ ] { } ( ) ? &#39; ! @ # % & / \ , > < ' : ; | _ ~ ` = + -"
                svgIcon="mat_outline:info"></mat-icon>
            </mat-error>
          </mat-form-field>

          <!-- Password confirm field -->
          <mat-form-field class="w-full fuse-mat-dense">
            <mat-label>New Password (Confirm)</mat-label>
            <input
              id="newPasswordConfirm"
              matInput
              type="password"
              [formControlName]="'newPasswordConfirm'"
              #passwordConfirmField />
            <button
              tabindex="-1"
              mat-icon-button
              type="button"
              (click)="
                passwordConfirmField.type === 'password'
                  ? (passwordConfirmField.type = 'text')
                  : (passwordConfirmField.type = 'password')
              "
              matSuffix>
              <mat-icon
                class="icon-size-5"
                *ngIf="passwordConfirmField.type === 'password'"
                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
              <mat-icon
                class="icon-size-5"
                *ngIf="passwordConfirmField.type === 'text'"
                [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
            </button>
            <mat-error *ngIf="changePasswordForm.get('newPasswordConfirm').hasError('required')">
              New Password confirmation is required
            </mat-error>
            <mat-error *ngIf="changePasswordForm.get('newPasswordConfirm').hasError('mustMatch')">
              Passwords must match
            </mat-error>
          </mat-form-field>

          <div>
            <button
              mat-flat-button
              color="primary"
              [disabled]="
                !changePasswordForm.valid || (this.passwordChangeKey | swIsLoading | async)
              "
              [swIsLoading]="this.passwordChangeKey"
              class="mat-stroked-button mb-6"
              (click)="changePassword()">
              Update Password
            </button>
          </div>
        </form>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
