var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'User';
let User = class User {
    id;
    email;
    fullName;
    agencyId;
    agency;
};
__decorate([
    FieldData({ recordTypeId, label: 'User ID' })
], User.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], User.prototype, "email", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Name' })
], User.prototype, "fullName", void 0);
__decorate([
    FieldData({ recordTypeId })
], User.prototype, "agencyId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Agency' })
], User.prototype, "agency", void 0);
User = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        idIsString: true,
        displayNameSingular: 'User',
        displayNamePlural: 'Users',
        lookupProperties: { resultProperties: ['fullName', 'email'] },
        workflow: { enabled: true, generalEvents: true }
    })
], User);
export { User };
