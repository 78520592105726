import { CommonModule } from '@angular/common';
import { DataModelModule } from '../../modules/data-model/data-model.module';
import { FuseAlertModule } from '@fuse/components/alert';
import { IsLoadingModule } from '@service-work/is-loading';
import { LetModule } from '@ngrx/component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { TBBulkEditFieldComponent } from './tb-bulk-edit-field/tb-bulk-edit-field.component';
import { TableModule } from 'primeng/table';
import { TbBulkEditDatatableComponent } from './tb-bulk-edit-datatable/tb-bulk-edit-datatable.component';
import { TbPagedDatatableComponent } from './tb-paged-datatable/tb-paged-datatable.component';
import { TbPrimeDatatableComponent } from './tb-prime-datatable/tb-prime-datatable.component';
import { TbPrimeRowSortDatatableComponent } from './tb-prime-rowsort-datatable/tb-prime-rowsort-datatable.component';
@NgModule({
  declarations: [
    TbPrimeDatatableComponent,
    TbPagedDatatableComponent,
    TbPrimeRowSortDatatableComponent,
    TbBulkEditDatatableComponent,
    TBBulkEditFieldComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    IsLoadingModule,
    MatTooltipModule,
    TableModule,
    FuseAlertModule,
    LetModule,
    DataModelModule,
  ],
  exports: [
    TbPrimeDatatableComponent,
    TbPagedDatatableComponent,
    TbPrimeRowSortDatatableComponent,
    TbBulkEditDatatableComponent,
    TBBulkEditFieldComponent,
  ],
})
export class TbDatatablesModule {}
