import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { SideNavOverlayComponent } from './components/side-nav-overlay/side-nav-overlay.component';
import { ComponentHostDirective } from './directives/component-host.directive';
import { FuseDrawerModule } from '@fuse/components/drawer';

@NgModule({
  declarations: [SideNavOverlayComponent, ComponentHostDirective],
  imports: [CommonModule, FuseDrawerModule, SharedModule],
})
export class DynamicSideNavModule {}
