import { Pipe, PipeTransform } from '@angular/core';

import { formatDate } from '@angular/common';

@Pipe({
  name: 'utcDate',
})
export class UtcDatePipe implements PipeTransform {
  transform(value: string | Date, format = 'MMMM - y'): string {
    if (!value) {
      return '';
    }
    // Ensure the value is treated as a Date object
    const date = typeof value === 'string' ? new Date(value) : value;
    // Use Angular's formatDate function to format the date in UTC
    return formatDate(date, format, 'en-US', 'UTC');
  }
}
