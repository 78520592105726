import { CompactLayoutModule } from 'app/layout/layouts/vertical/compact/compact.module';
import { DenseLayoutModule } from 'app/layout/layouts/vertical/dense/dense.module';
import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';
import { LayoutComponent } from 'app/layout/layout.component';
import { NgModule } from '@angular/core';
import { SettingsModule } from 'app/layout/common/settings/settings.module';
import { SharedModule } from 'app/shared/shared.module';
import { ThinLayoutModule } from 'app/layout/layouts/vertical/thin/thin.module';
import { BreadcrumbsComponent } from './common/breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';

const layoutModules = [
  // Empty
  EmptyLayoutModule,

  // Vertical navigation
  CompactLayoutModule,
  DenseLayoutModule,
  ThinLayoutModule,
];

@NgModule({
  declarations: [LayoutComponent, BreadcrumbsComponent],
  imports: [SharedModule, RouterModule, SettingsModule, ...layoutModules],
  exports: [LayoutComponent, BreadcrumbsComponent, ...layoutModules],
})
export class LayoutModule {}
