import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild, forwardRef, inject } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormGroup, FormGroupDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { MaskitoOptions } from '@maskito/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getDateOnlyString } from 'app/core/utils/form-helper';
import maskDate from '../../masks/datemask';
import { BehaviorSubject, tap } from 'rxjs';
import { parse } from 'date-fns';
import { Icons } from 'app/core/icons/models';
import { isValidRelativeDateString } from 'portal-commons/dist/relative-dates/models';
import { CustomValidators } from 'app/core/validators/custom-validators';

@UntilDestroy()
@Component({
  selector: 'tb-relative-date-picker',
  templateUrl: './tb-relative-date-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TbRelativeDatePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TbRelativeDatePickerComponent),
      multi: true,
    },
  ],
})
export class TbRelativeDatePickerComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {
  private readonly defaultClasses = 'fuse-mat-dense no-margin-bottom w-full';
  cd = inject(ChangeDetectorRef);

  @Input() formControlName!: string;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() hint = '';
  @Input() formFieldClasses: string | undefined;
  @Output() readonly dateChange: EventEmitter<Date | null> = new EventEmitter<Date | null>();

  @ViewChild('relativeInput') relativeInput: ElementRef | undefined;
  @ViewChild('dateInput') dateInput: ElementRef | undefined;

  form!: FormGroup;
  readonly options: MaskitoOptions = maskDate;
  value: string | Date | null = null;
  Icons = Icons;
  isDisabled = false;
  _showFunction = new BehaviorSubject(false);
  readonly showFunction$ = this._showFunction.asObservable();
  readonly relativeDateValidatorFn = CustomValidators.conditionalValidator(
    () => {
      if (!!!this.form.get(this.formControlName)?.value) { return false; }
      const isValid = isValidRelativeDateString(this.form.get(this.formControlName)?.value);
      if (!isValid) { this.form.get(this.formControlName)?.markAllAsTouched(); }
      this.cd.markForCheck();
      return !isValid;
    },
    CustomValidators.alwaysThrowValidator({ 'invalid-relative-date': true }));

  @HostBinding('className') extras = 'flex-grow';

  constructor(private _parentForm: FormGroupDirective) {
    this.form = this._parentForm.form;
    if (!!!this.formFieldClasses) {
      this.formFieldClasses = this.defaultClasses;
    }
  }
  private onErrorStatusChange: () => void = () => { console.log() };
  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    const val = control.value;
    if (val !== undefined && val !== null && typeof val === 'string') {
      const dt = parse(val, 'yyyy-MM-dd', new Date());
      if (dt.getFullYear() < 1900) {
        return { 'invalid-date': true };
      }
    }
    return null;
  }
  registerOnValidatorChange?(fn: () => void): void {
    this.onErrorStatusChange = fn;
  }
  ngOnDestroy(): void {
    console.log('tb-date-picker-destroyed');
  }
  ngOnInit(): void {
    if (typeof this.form.get(this.formControlName)?.value === 'string' && isValidRelativeDateString(this.form.get(this.formControlName)?.value)) {
      this._showFunction.next(true);
    }

    this.form.get(this.formControlName)?.valueChanges.pipe(
      untilDestroyed(this),
      tap((val) => {
        console.log('val-change', val, this.form.get(this.formControlName)?.errors);
      })
    ).subscribe();

    this.showFunction$.pipe(
      untilDestroyed(this),
      tap((val) => {
        setTimeout(() => {
          if (val === true) {
            this.form.get(this.formControlName)!.addValidators(this.relativeDateValidatorFn);
          }
          else {
            this.form.get(this.formControlName)!.removeValidators(this.relativeDateValidatorFn);
          }
          this.form.get(this.formControlName)?.updateValueAndValidity();
          this.cd.markForCheck();
          if (val && this.relativeInput) {
            this.relativeInput.nativeElement.focus();
          }
          if (!val && this.dateInput) {
            this.dateInput.nativeElement.focus();
          }

        }, 0);
      })
    ).subscribe();
  }

  toggleShowFunction() {
    this.form.get(this.formControlName)?.setValue(null);
    this._showFunction.next(!this._showFunction.getValue());
  }

  onDateChange(event: any) {
    this.form.get(this.formControlName)?.setValue(getDateOnlyString(event.value));
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (value: Date | null) => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => { };

  writeValue(value: string | Date | null): void {
    console.log('writeValue called with value:', value);
    this.value = value;
  }

  registerOnChange(fn: any): void {
    console.log('registerOnChange called');
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    console.log('registerOnTouched called');
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    console.log('setDisabledState called with isDisabled:', isDisabled);
    this.isDisabled = isDisabled;
  }
}
