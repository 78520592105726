var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'AccountLedgerDeposit';
let AccountLedgerDeposit = class AccountLedgerDeposit {
    //pk
    id;
    //fk
    policyHolderId;
    policyHolder;
    //jdata
    comments;
    status;
    refNo;
    itemDate;
    depositType;
    depositAmount;
    depositItems;
    sourceId;
    payrollReportId;
};
__decorate([
    FieldData({ recordTypeId })
], AccountLedgerDeposit.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'PolicyHolder' })
], AccountLedgerDeposit.prototype, "policyHolderId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyHolder', label: 'Policyholder', jsonStorage: true })
], AccountLedgerDeposit.prototype, "policyHolder", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], AccountLedgerDeposit.prototype, "comments", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], AccountLedgerDeposit.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], AccountLedgerDeposit.prototype, "refNo", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Item Date', fieldType: 'datetime', jsonStorage: true })
], AccountLedgerDeposit.prototype, "itemDate", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], AccountLedgerDeposit.prototype, "depositType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], AccountLedgerDeposit.prototype, "depositAmount", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'AccountLedgerDepositItem',
        isList: true,
        jsonStorage: true,
    })
], AccountLedgerDeposit.prototype, "depositItems", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], AccountLedgerDeposit.prototype, "sourceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], AccountLedgerDeposit.prototype, "payrollReportId", void 0);
AccountLedgerDeposit = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        displayNameSingular: 'Accounting Ledger Deposit',
        displayNamePlural: 'Accounting Ledger Deposits',
    })
], AccountLedgerDeposit);
export { AccountLedgerDeposit };
