import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  map,
  of,
  shareReplay,
  tap,
} from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebsocketService } from 'app/core/auth/web-socket.service';
import { getRoleCategoryByValue } from 'portal-commons/dist/roleEnums';
import { Alert, WsAlertListMessage } from 'portal-commons/dist/alerts/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastNotificationService } from 'app/core/notifications/toasts/toast-notification.service';
import { ErrorMessagePipe } from 'app/shared/pipes/error-message.pipe';
import { RouterService } from 'app/core/services/router-data.service';
import { WsMessageTypes } from 'portal-commons/dist/ws/model';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  private _activeAlerts = new BehaviorSubject<Alert[]>([]);
  readonly activeAlerts$ = this._activeAlerts.asObservable();
  private _cachedAlerts = new BehaviorSubject<Alert[]>([]);
  readonly cachedAlerts$ = this._cachedAlerts.asObservable();

  constructor(
    private websocketService: WebsocketService,
    private toastNotificationService: ToastNotificationService,
    private httpClient: HttpClient,
    private errorPipe: ErrorMessagePipe,
    private routerService: RouterService,
  ) {
    combineLatest([this.routerService.currentRouteData$, this.cachedAlerts$])
      .pipe(
        untilDestroyed(this),
        map(([data, alerts]) => {
          //console.log('route-data:alerts', data, alerts);
          if (!data) {
            this._activeAlerts.next([]);
          } else {
            const filteredAlerts = alerts.filter((e) => {
              const filtered = e.displayLocations.find((x: any) => {
                return x === 'system-wide' || this.dataCategoryApplies(data, x) === true;
              });

              //console.log('filtered', filtered);

              if (filtered && (!e.expirationDate || new Date(e.expirationDate) >= new Date())) {
                return true;
              }

              return false;
            });
            this._activeAlerts.next([...filteredAlerts]);
          }
        }),
        catchError((err) => {
          console.error('Failure evaluating alerts and routes', err);
          this._activeAlerts.next([]);
          return of(null);
        }),
      )
      .subscribe();

    this.websocketService.incomingMessages$
      .pipe(
        untilDestroyed(this),
        filter((f: WsAlertListMessage) => f._type === WsMessageTypes.alertList),
        tap((payload) => {
          if (payload && payload.alerts.length > 0) {
            this._cachedAlerts.next(payload.alerts);
          }
        }),
        shareReplay(1),
      )
      .subscribe();
  }

  dataCategoryApplies(data: any, locationReference: string) {
    if (!data) {
      return false;
    }
    if (data.category && getRoleCategoryByValue(locationReference) === data.category) {
      return true;
    }
    if (data.check?.category && getRoleCategoryByValue(locationReference) === data.check.category) {
      return true;
    }
    if (data.category && locationReference === data.category) {
      return true;
    }
    if (data.check?.category && locationReference === data.check.category) {
      return true;
    }
    return false;
  }

  acknowledgeAlert(alertId: string) {
    this.httpClient
      .post(`/api/logging/save-alert-acknowledgement?alertId=${alertId}`, {
        alertId: alertId,
      })
      .pipe(
        untilDestroyed(this),
        catchError((err) => {
          this.toastNotificationService.error(
            'Failure acknowledging alert',
            this.errorPipe.transform(err),
          );
          return of(null);
        }),
      )
      .subscribe();
  }
}
