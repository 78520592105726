export var UserStatus;
(function (UserStatus) {
    UserStatus["active"] = "active";
    UserStatus["pending"] = "pending";
    UserStatus["deactivated"] = "deactivated";
})(UserStatus || (UserStatus = {}));
export var UserDetailsPersona;
(function (UserDetailsPersona) {
    UserDetailsPersona["AgencyUser"] = "AgencyUser";
    UserDetailsPersona["PolicyHolderUser"] = "PolicyHolderUser";
    UserDetailsPersona["TenantUser"] = "TenantUser";
    UserDetailsPersona["TrailblazerUser"] = "TrailblazerUser";
    UserDetailsPersona["SystemProcessUser"] = "SystemProcessUser";
})(UserDetailsPersona || (UserDetailsPersona = {}));
export const SYSTEM_USER_ID = 'SYSTEM_PROCESS';
