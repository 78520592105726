<div class="relative flex flex-grow w-full">
  <!-- Icon -->
  <ng-container *ngIf="item.changeDetails">
    <button
      mat-icon-button
      class="flex shrink-0 items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-400"
      matTooltip="Show details"
      (click)="toggleExpanded()">
      <mat-icon class="icon-size-5 text-white" [svgIcon]="getIcon()"></mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="!item.changeDetails">
    <div class="flex shrink-0 items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-400">
      <mat-icon class="icon-size-5 text-white" [svgIcon]="getIcon()"></mat-icon>
    </div>
  </ng-container>

  <!-- Content -->
  <div class="flex flex-col flex-grow items-start w-full">
    <!-- Description -->
    <div [innerHTML]="getDescription(item)"></div>
    <div
      class="flex flex-col sm:flex-row sm:items-center mt-2 sm:mt-1 sm:space-x-2 text-md leading-5">
      <!-- Date -->
      <div class="text-secondary">
        {{ item.ts | date: 'short' }}
      </div>
    </div>
    <!-- Extra content -->
    <div class="extraDetail w-full">
      <div class="mt-4 py-4 px-5 w-full rounded-lg bg-gray-100 dark:bg-gray-800 overflow-x-scroll">
        <div *ngFor="let detail of this.getDetail()" [innerHTML]="detail"></div>
      </div>
    </div>
  </div>
</div>
