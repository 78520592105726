var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { TrailMapType } from '../../trail-maps/models';
const recordTypeId = 'ClaimHistory';
let ClaimHistory = class ClaimHistory {
    policyPeriod;
    carrierName;
    policyNumber;
    annualPremium;
    xMod;
    claimCount;
    totalPaid;
    totalReserve;
};
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, label: 'Policy Period' })
], ClaimHistory.prototype, "policyPeriod", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, label: 'Carrier' })
], ClaimHistory.prototype, "carrierName", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, label: 'Policy Number' })
], ClaimHistory.prototype, "policyNumber", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, label: 'Annual Premium', fieldType: 'currency' })
], ClaimHistory.prototype, "annualPremium", void 0);
__decorate([
    FieldData({
        recordTypeId,
        jsonStorage: true,
        label: 'Experience Modifier',
        fieldType: 'decimal',
    })
], ClaimHistory.prototype, "xMod", void 0);
__decorate([
    FieldData({
        recordTypeId,
        jsonStorage: true,
        label: 'Number of Claims',
        fieldType: 'integer',
    })
], ClaimHistory.prototype, "claimCount", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, label: 'Amount Paid', fieldType: 'currency' })
], ClaimHistory.prototype, "totalPaid", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, label: 'Reserve Amount', fieldType: 'currency' })
], ClaimHistory.prototype, "totalReserve", void 0);
ClaimHistory = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        displayNameSingular: 'Claim History',
        displayNamePlural: 'Claim Histories',
        supports: {
            views: true
        },
        dataModelCustomization: {
            allowCustomFields: false
        },
        trailMapTypes: [TrailMapType.QuickAdd],
        usesNewDataModel: true,
    })
], ClaimHistory);
export { ClaimHistory };
