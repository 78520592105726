import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';
import { timer } from 'rxjs';

@Component({
  selector: 'fuse-confirmation-dialog',
  templateUrl: './dialog.component.html',
  styles: [
    /* language=SCSS */
    `
      .fuse-confirmation-dialog-panel {
        @screen md {
          @apply w-128;
        }

        .mat-dialog-container {
          padding: 0 !important;
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FuseConfirmationDialogComponent implements OnInit {
  currentAskNumber: number = 1;
  disableConfirm: boolean = false;
  origConfirmLabel: string;
  inputText: string = '';
  /**
   * Constructor
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FuseConfirmationConfig,
    public matDialogRef: MatDialogRef<FuseConfirmationDialogComponent>
  ) {
    this.origConfirmLabel = data.actions.confirm.label;
  }

  markConfirmed() {
    if (this.currentAskNumber >= (this.data.numberOfTimesToAsk ?? 1)) {
      this.matDialogRef.close('confirmed');
      return;
    }

    this.disableConfirm = true;
    this.currentAskNumber++;

    timer(1000).subscribe((val) => {
      this.data.actions.confirm.label = `${this.origConfirmLabel} (${this.currentAskNumber})`;
      this.disableConfirm = false;
    });
  }

  onTextChange(): void {
    this.data.inputTextMatch === this.inputText
      ? (this.disableConfirm = false)
      : (this.disableConfirm = true);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    if (this.data.inputTextMatch) {
      this.disableConfirm = true;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
}
