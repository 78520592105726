import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { ISideNavComponent } from 'app/layout/common/dynamic-side-nav/models/side-nav-config';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  distinctUntilChanged,
  first,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { RecordHistory } from 'portal-commons/dist/data-model/record-types/record-history';
import { RecordHistoryApiService } from '../../services/record-history-api.service';
import { formatDistance, parseISO } from 'date-fns';
import { ErrorMessagePipe } from 'app/shared/pipes/error-message.pipe';
import { IsLoadingService } from '@service-work/is-loading';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastNotificationService } from 'app/core/notifications/toasts/toast-notification.service';
import { DataFilterStore } from 'app/modules/data-model/services/data-filter.store';
import { downloadFileResult } from 'app/core/utils/file-save-as-helper';
import { FileDownloadResult } from 'portal-commons/dist/shared/file-download-result';

@UntilDestroy()
@Component({
  selector: 'tb-record-history-detail',
  templateUrl: './record-history-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DataFilterStore],
})
export class RecordHistoryDetailComponent implements ISideNavComponent, OnInit {
  dataFilterStore = inject(DataFilterStore);
  recordHistoryApi = inject(RecordHistoryApiService);
  errorPipe = inject(ErrorMessagePipe);
  loadingService = inject(IsLoadingService);
  toastService = inject(ToastNotificationService);

  EXPORT_KEY = crypto.randomUUID();
  LOADING_KEY = crypto.randomUUID();

  closeEvent: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() recordType: string | undefined;
  @Input() id: string | undefined;
  @Input() hideButton: boolean | undefined;

  loaded$ = new BehaviorSubject<boolean | undefined>(undefined);
  error$ = new BehaviorSubject<string | undefined>(undefined);
  expandAll$ = new Subject<boolean>();
  recordHistory$: Observable<RecordHistory[]> | undefined;
  anyDetails$: Observable<boolean> | undefined;

  expandAll!: boolean;

  close() {
    this.closeEvent.next(true);
  }

  ngOnInit(): void {
    if (!this.recordType || !this.id) {
      return;
    }
    this.recordHistory$ = this.dataFilterStore.quickSearchFilters$.pipe(
      untilDestroyed(this),
      startWith(undefined),
      distinctUntilChanged(),
      switchMap((filters) => {
        return this.loadingService.add(
          this.recordHistoryApi.getRecordHistory(this.recordType!, this.id!, filters).pipe(
            catchError((err) => {
              this.error$.next(this.errorPipe.transform(err));
              return of([]);
            }),
            tap(() => {
              this.loaded$.next(true);
            }),
          ),
          { key: this.LOADING_KEY },
        );
      }),
      shareReplay(1),
    );

    this.anyDetails$ = this.recordHistory$.pipe(
      map((history) => {
        return history.find((f) => f.changeDetails) !== undefined;
      }),
    );
  }

  isSameDay(current: string, compare: string): boolean {
    return current.substring(0, 10) === compare.substring(0, 10);
  }

  getFormatDistance(ts: string) {
    return `${formatDistance(parseISO(ts), new Date())} ago`;
  }

  toggleExpanded() {
    this.expandAll = !this.expandAll;
    this.expandAll$.next(this.expandAll);
  }

  export() {
    if (!this.recordType || !this.id) {
      return;
    }
    this.loadingService
      .add(
        this.dataFilterStore.quickSearchFilters$.pipe(
          first(),
          untilDestroyed(this),
          switchMap((filters) => {
            return this.recordHistoryApi.export(this.recordType!, this.id!, filters).pipe(
              untilDestroyed(this),
              catchError((err) => {
                console.error(err);
                this.toastService.error('Unable to export history');
                return of(undefined);
              }),
              map((response: FileDownloadResult | undefined) => {
                if (!response) {
                  return;
                }
                downloadFileResult(response);
              }),
            );
          }),
        ),
        { key: this.EXPORT_KEY },
      )
      .subscribe();
  }
}
