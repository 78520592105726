var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'RatingFormulaVersion';
let RatingFormulaVersion = class RatingFormulaVersion {
    id;
    ratingFormulaId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deletedUser;
    deletedUTC;
    effectiveDate;
    expirationDate;
    source;
    script;
    states;
    filter;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], RatingFormulaVersion.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], RatingFormulaVersion.prototype, "ratingFormulaId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], RatingFormulaVersion.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], RatingFormulaVersion.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], RatingFormulaVersion.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], RatingFormulaVersion.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], RatingFormulaVersion.prototype, "deletedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], RatingFormulaVersion.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Effective Date', fieldType: 'date' })
], RatingFormulaVersion.prototype, "effectiveDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Expiration Date', fieldType: 'date' })
], RatingFormulaVersion.prototype, "expirationDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Source' })
], RatingFormulaVersion.prototype, "source", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Script' })
], RatingFormulaVersion.prototype, "script", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'States' })
], RatingFormulaVersion.prototype, "states", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Filter', hasColumn: true })
], RatingFormulaVersion.prototype, "filter", void 0);
RatingFormulaVersion = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Rate Formula Version',
        displayNamePlural: 'Rate Formula Versions',
    })
], RatingFormulaVersion);
export { RatingFormulaVersion };
