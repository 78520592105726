<div class="flex flex-col min-w-100">
  <div class="text-accent pb-1">Filter Field</div>
  <tb-datamodel-field-filter
    [form]="this.form"
    [recordType]="this.filterStore.recordType$ | async"
    [condition]="this.filterCondition"
    [rootFieldsOnly]="this.filterStore.rootFieldsOnly$ | async">
  </tb-datamodel-field-filter>
  <div class="flex flex-row justify-end gap-2">
    <button
      mat-flat-button
      [color]="'primary'"
      class="mt-4"
      [disabled]="!this.form.valid"
      (click)="apply()">
      Apply
    </button>
    <button mat-button class="mt-4" (click)="close()">Cancel</button>
  </div>
</div>
