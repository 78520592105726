var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'Message';
let Message = class Message {
    id;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    parentRecordType;
    parentRecordId;
    body;
    subject;
    from;
    to;
    //todo: move to separate table
    toUsers;
    sendDate;
    messageGroupId;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Message.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], Message.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Message.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], Message.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Message.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId })
], Message.prototype, "parentRecordType", void 0);
__decorate([
    FieldData({ recordTypeId })
], Message.prototype, "parentRecordId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Body' })
], Message.prototype, "body", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Subject' })
], Message.prototype, "subject", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'From', fieldType: 'User' })
], Message.prototype, "from", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'To' })
], Message.prototype, "to", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'To Users' })
], Message.prototype, "toUsers", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Sent On', fieldType: 'datetime' })
], Message.prototype, "sendDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Message.prototype, "messageGroupId", void 0);
Message = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Message',
        displayNamePlural: 'Messages',
    })
], Message);
export { Message };
