import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts.component';
import { SharedModule } from 'app/shared/shared.module';
import { FuseAlertModule } from '@fuse/components/alert';

@NgModule({
  declarations: [AlertsComponent],
  imports: [CommonModule, FuseAlertModule, SharedModule],
  exports: [AlertsComponent],
})
export class AlertsModule {}
