var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../../data-filters/models';
import { RoleCategories } from '../../../roleEnums';
import { FieldData, RecordTypeData } from '../../meta-data-decorators';
const recordTypeId = 'Workflow';
let Workflow = class Workflow {
    id;
    name;
    description;
    isDisabled;
    runOncePerRecord;
    recordType;
    filterGroup;
    triggers;
    manualTriggerRoles;
    manualApplyFilter;
    manualTriggerType;
    manualTriggerText;
    manualTriggerPostMessage;
    scheduleDoNotApplyFilterCondition;
    schedule;
    actions;
    fieldChangeSet;
    createUser;
    createUserId;
    createdUTC;
    modifiedUser;
    modifiedUserId;
    modifiedUTC;
    deleteUser;
    deleteUserId;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Workflow.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], Workflow.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId })
], Workflow.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], Workflow.prototype, "isDisabled", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], Workflow.prototype, "runOncePerRecord", void 0);
__decorate([
    FieldData({ recordTypeId })
], Workflow.prototype, "recordType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', label: 'Filter', jsonStorage: true })
], Workflow.prototype, "filterGroup", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', label: 'Triggers', jsonStorage: true })
], Workflow.prototype, "triggers", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Workflow.prototype, "manualTriggerRoles", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Workflow.prototype, "manualApplyFilter", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Workflow.prototype, "manualTriggerType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Workflow.prototype, "manualTriggerText", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Workflow.prototype, "manualTriggerPostMessage", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Workflow.prototype, "scheduleDoNotApplyFilterCondition", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Workflow.prototype, "schedule", void 0);
__decorate([
    FieldData({ recordTypeId, isList: true, fieldType: 'Action', disableField: true })
], Workflow.prototype, "actions", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Workflow.prototype, "fieldChangeSet", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], Workflow.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], Workflow.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Workflow.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], Workflow.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], Workflow.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Workflow.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], Workflow.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], Workflow.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], Workflow.prototype, "deletedUTC", void 0);
Workflow = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        dataModelCustomization: {
            hideFromAdmin: true
        },
        hasBackingTable: true,
        displayNameSingular: 'Workflow',
        displayNamePlural: 'Workflows',
        roleCategory: RoleCategories.Workflows,
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'name',
                filterType: FilterType.Contains,
            },
        ],
    })
], Workflow);
export { Workflow };
