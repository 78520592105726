export var PeriodSize;
(function (PeriodSize) {
    PeriodSize["Day"] = "d";
    PeriodSize["Week"] = "w";
    PeriodSize["Month"] = "m";
    PeriodSize["Quarter"] = "q";
    PeriodSize["Year"] = "y";
})(PeriodSize || (PeriodSize = {}));
export const SingularTimePeriods = [
    { size: PeriodSize.Day, label: 'Day' },
    { size: PeriodSize.Week, label: 'Week' },
    { size: PeriodSize.Month, label: 'Month' },
    { size: PeriodSize.Year, label: 'Year' },
];
export const PluralTimePeriods = SingularTimePeriods.map((x) => {
    x.label += 's';
    return x;
});
export const ParentheticalTimePeriods = [
    { size: PeriodSize.Day, label: 'Day(s)' },
    { size: PeriodSize.Month, label: 'Month(s)' },
    { size: PeriodSize.Year, label: 'Year(s)' },
];
