import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { ActiveToast, GlobalConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastNotificationService {
  private lastInserted: number[] = [];
  options: GlobalConfig;
  constructor(private toastrService: ToastrService) {
    this.options = this.toastrService.toastrConfig;
  }

  clearLastNotification() {
    if (this.lastInserted.length > 0) {
      this.toastrService.clear(this.lastInserted.pop());
    }
  }

  clearAllNotifications() {
    this.toastrService.clear();
  }

  getDefaultOptions(isErrorWarning: boolean, isHtml?: boolean): GlobalConfig {
    const opt = cloneDeep(this.options);
    opt.enableHtml = isHtml ?? false;
    opt.resetTimeoutOnDuplicate = true;
    opt.preventDuplicates = true;
    opt.countDuplicates = true;

    if (isErrorWarning) {
      opt.autoDismiss = false;
      opt.disableTimeOut = true;
      opt.closeButton = true;
    } else {
      opt.autoDismiss = true;
      opt.progressBar = true;
      opt.disableTimeOut = false;
      opt.closeButton = true;
      opt.timeOut = 3000;
    }

    return opt;
  }

  markLastMsg(msg: ActiveToast<any>) {
    if (msg && msg.toastId) {
      this.lastInserted.push(msg.toastId);
    }
  }

  success(message?: string, title?: string, isHtml?: boolean) {
    this.markLastMsg(
      this.toastrService.success(message, title, this.getDefaultOptions(false, isHtml)),
    );
  }

  error(message?: string, title?: string, isHtml?: boolean) {
    this.markLastMsg(
      this.toastrService.error(message, title, this.getDefaultOptions(true, isHtml)),
    );
  }

  warning(message?: string, title?: string, isHtml?: boolean) {
    this.markLastMsg(
      this.toastrService.warning(message, title, this.getDefaultOptions(true, isHtml)),
    );
  }

  info(message?: string, title?: string, isHtml?: boolean) {
    this.markLastMsg(
      this.toastrService.info(message, title, this.getDefaultOptions(false, isHtml)),
    );
  }

  custom(
    style: string,
    message?: string,
    title?: string,
    isHtml?: boolean,
    icon?: string,
    isError?: boolean,
  ) {
    const opts = this.getDefaultOptions(isError ?? false, isHtml);
    if (icon) {
      opts.titleClass = icon;
    }
    this.toastrService.show(message, title, opts, style);
  }
}
