import { Injectable, inject } from '@angular/core';
import { filterTypeOptions } from '../models/models';
import { FilterCondition, convertToFilterConditions } from 'portal-commons/dist/data-filters/models';
import { filterDescriptionPart } from 'portal-commons/dist/data-filters/models';
import { FilterGroup } from 'portal-commons/dist/views/models';
import { PrettyLogicalOperator } from 'portal-commons/dist/data-filters/models';
import { DataModelStoreService } from 'app/core/data-model/services/data-model.store';
import { DataFormatService } from 'app/core/services/data-format.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  dataModel = inject(DataModelStoreService);
  dataFormat = inject(DataFormatService);

  translateFilterGroup(filter: FilterGroup): string {
    if (!filter) {
      return '';
    }
    return (
      '( ' +
      convertToFilterConditions(filter.conditions ?? [])
        .map(
          (c) =>
            `( ${this.getFilterDescription(c)
              ?.map((x) => `<span class="${x.classes}">${x.text}</span>`)
              .join(' ')}) `,
        )
        .concat(filter.groups.map((g) => this.translateFilterGroup(g)))
        .join(filter.operator === PrettyLogicalOperator.All ? ' and ' : ' or ') +
      ' )'
    );
  }

  getFilterConditionsDescription(filters: FilterCondition[], operator: PrettyLogicalOperator): string | undefined {
    return filters.map((c) => {
      return this.getFilterDescription(c)
        ?.map((x) => `<span class="${x.classes}">${x.text}</span>`)
        .join(' ');
    }).join(operator === PrettyLogicalOperator.All ? ' and ' : ' or ');
  }

  getFilterDescription(filter: FilterCondition): filterDescriptionPart[] | undefined {
    const filterTypeDesc = filterTypeOptions.find((f) => f.value === filter.filterType)?.text;
    const dmField = this.dataModel.getFieldFromRecordTypeByFieldId(
      filter.fieldRecordType!,
      (filter.fieldPath ?? filter.fieldId)!,
    );
    if (!dmField) {
      return undefined;
    }
    const parts: filterDescriptionPart[] = [];
    parts.push({ text: dmField.label!, classes: ['font-medium'] });
    parts.push({ text: filterTypeDesc!, classes: ['lowercase'] });
    if (filter.searchParameter1) {
      parts.push({
        text: this.dataFormat.formatFieldValue(filter.searchParameter1, dmField),
        classes: ['font-medium'],
      });
    }
    if (filter.searchParameter1Period) {
      parts.push({
        text: filter.searchParameter1Period,
        classes: ['font-medium'],
      });
    }
    if (filter.searchParameter2) {
      parts.push({ text: 'and' });
      parts.push({
        text: this.dataFormat.formatFieldValue(filter.searchParameter2, dmField),
        classes: ['font-medium'],
      });
    }
    return parts;
  }

}
