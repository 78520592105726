import { Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, Observable } from 'rxjs';
import { DataModelStoreService } from '../../../core/data-model/services/data-model.store';

@UntilDestroy()
@Pipe({ name: 'getLabel' })
export class GetLabelPipe implements PipeTransform {
  constructor(private store: DataModelStoreService) {}

  transform(fieldId: string, recordType: string): Observable<string | null> {
    return this.store.recordTypesLoaded$.pipe(
      untilDestroyed(this),
      filter((f) => f === true),
      map(() => {
        return this.store.getFieldFromRecordTypeByFieldId(recordType, fieldId)?.label ?? null;
      }),
    );
  }
}
