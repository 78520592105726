import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { FuseAlertModule } from '@fuse/components/alert';
import { UserComponent } from 'app/layout/common/user/user.component';
import { SharedModule } from 'app/shared/shared.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { IsLoadingModule } from '@service-work/is-loading';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [UserComponent, UserProfileComponent],
  imports: [
    FuseDrawerModule,
    FuseAlertModule,
    ReactiveFormsModule,
    IsLoadingModule,
    MatButtonModule,
    MatDividerModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    SharedModule,
  ],
  exports: [UserComponent, UserProfileComponent],
})
export class UserModule {}
