<ng-container *ngIf="hasCustomIcon">
  <fuse-alert
    [type]="toastType"
    [dismissible]="isDismissable"
    [dismissed]="false"
    [appearance]="'border'">
    <mat-icon class="icon-size-5" fuseAlertIcon [svgIcon]="customIcon"> </mat-icon>
    <span *ngIf="title" fuseAlertTitle>{{ title }}</span>
    <ng-container *ngIf="toastPackage.config.enableHtml">
      <span [innerHtml]="message"></span>
    </ng-container>
    <ng-container *ngIf="!toastPackage.config.enableHtml">
      {{ message }}
    </ng-container>
  </fuse-alert>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
</ng-container>

<ng-container *ngIf="!hasCustomIcon">
  <fuse-alert
    [type]="toastType"
    [dismissible]="isDismissable"
    [dismissed]="false"
    [appearance]="'border'">
    <span *ngIf="title" fuseAlertTitle>{{ title }}</span>
    <ng-container *ngIf="toastPackage.config.enableHtml">
      <span [innerHtml]="message"></span>
    </ng-container>
    <ng-container *ngIf="!toastPackage.config.enableHtml">
      {{ message }}
    </ng-container>
  </fuse-alert>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
</ng-container>
