export var PolicyStatusType;
(function (PolicyStatusType) {
    PolicyStatusType["submitted"] = "submitted";
    PolicyStatusType["quoted"] = "quoted";
    PolicyStatusType["declined"] = "declined";
    PolicyStatusType["withdrawn"] = "withdrawn";
    PolicyStatusType["bound"] = "bound";
    PolicyStatusType["active"] = "active";
    PolicyStatusType["lost"] = "lost";
    PolicyStatusType["grace"] = "grace";
    PolicyStatusType["deleted"] = "deleted";
    PolicyStatusType["cancelled"] = "cancelled";
    PolicyStatusType["reinstated"] = "reinstated";
    PolicyStatusType["prePendingCancellation"] = "prePendingCancellation";
    PolicyStatusType["pendingCancellation"] = "pendingCancellation";
    PolicyStatusType["pendingdeposit"] = "pendingdeposit";
})(PolicyStatusType || (PolicyStatusType = {}));
export const InactiveStatuses = ['cancelled', 'draft', 'pending'];
export var PolicyTemplateSegmentType;
(function (PolicyTemplateSegmentType) {
    PolicyTemplateSegmentType["text"] = "text";
    PolicyTemplateSegmentType["policynumber"] = "policynumber";
})(PolicyTemplateSegmentType || (PolicyTemplateSegmentType = {}));
export const PolicyBoundStatuses = [
    PolicyStatusType.bound,
    PolicyStatusType.prePendingCancellation,
    PolicyStatusType.pendingCancellation,
    PolicyStatusType.reinstated,
    PolicyStatusType.grace,
    PolicyStatusType.pendingdeposit,
    PolicyStatusType.cancelled,
];
export const PolicyActiveBoundStatuses = [
    PolicyStatusType.bound,
    PolicyStatusType.prePendingCancellation,
    PolicyStatusType.pendingCancellation,
    PolicyStatusType.reinstated,
    PolicyStatusType.grace,
];
