import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RelativeDateTokens } from 'portal-commons/dist/relative-dates/models';

@Component({
  selector: 'tb-relative-date-helper',
  templateUrl: './relative-date-helper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelativeDateHelperComponent {
  @Input() displayOption: 'both' | 'date' | 'format' = 'both';
  RelativeDateTokens = RelativeDateTokens;
}
