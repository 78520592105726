import { Injectable } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, distinctUntilChanged, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { appConfig } from '../config/app.config';

interface tenantThemeConfig {
  logos: {
    signIn: string;
    headerSmall: string;
    headerLarge: string;
  };
  themeName: string;
  useDefaultColors?: boolean;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TenantThemeService {
  #SIGN_IN_LOGO = '--sign-in-logo';
  #HEADER_LOGO_SM = '--nav-header-logo-small';
  #HEADER_LOGO_LG = '--nav-header-logo-large';
  #DEFAULT_THEME_NAME = 'theme-trailblazer';

  constructor(private configService: FuseConfigService) { }

  private $theme = new BehaviorSubject<tenantThemeConfig | undefined>(undefined);
  readonly theme$ = this.$theme.asObservable();

  getThemePrimaryColor(numericRange?: number) {
    return this.getThemeColor(false, numericRange);
  }
  getThemeAccentColor(numericRange?: number) {
    return this.getThemeColor(true, numericRange);
  }

  private getThemeColor(accent: boolean, numericRange?: number) {
    const bodyElem = document.querySelector('body.light');
    if (!bodyElem) {
      return undefined;
    }
    if (bodyElem) {
      let varKey = `--fuse-${accent ? 'accent' : 'primary'}`;
      if (numericRange) {
        varKey += `-${numericRange}`;
      }
      const styleColor = getComputedStyle(bodyElem).getPropertyValue(varKey);
      if (!styleColor) {
        return undefined;
      }
      if (!!styleColor.trimStart().trimEnd()) {
        return styleColor.trimStart().trimEnd();
      }
      return undefined;
    }
  }

  setTenant(tenant: string) {
    const themeMatch = appConfig.themes.find((f) => f.id === `theme-${tenant}`);
    if (!themeMatch) {
      this.resetDefaults();
    } else {
      this.setTheme({
        themeName: tenant,
        logos: {
          signIn: `${environment.assetsUrl}/tenants/${tenant}/${tenant}-logo.${themeMatch.imageExtension ?? 'svg'}`,
          headerLarge: `${environment.assetsUrl}/tenants/${tenant}/${tenant}-header-large.${themeMatch.imageExtension ?? 'svg'}`,
          headerSmall: `${environment.assetsUrl}/tenants/${tenant}/${tenant}-header-small.${themeMatch.imageExtension ?? 'svg'}`,
        },
        useDefaultColors: themeMatch.useDefaultColors ?? false,
      });
    }
  }

  clearTenant() {
    this.resetDefaults();
  }

  private setTheme(themeConfig: tenantThemeConfig | undefined) {
    this.$theme.next(themeConfig);
  }

  private themeSwitch$ = this.theme$
    .pipe(
      untilDestroyed(this),
      distinctUntilChanged((prev, curr) => prev?.themeName === curr?.themeName),
      map((theme) => {
        if (!theme) {
          this.resetDefaults();
        } else {
          if (
            !appConfig.themes.find((f) => f.id === `theme-${theme.themeName}`) ||
            appConfig.theme === `theme-${theme.themeName}`
          ) {
            return;
          }
          if (theme.useDefaultColors) {
            this.resetDefaults();
          } else {
            this.configService.config = { theme: `theme-${theme.themeName}` };
          }
          this.setThemeLogos(theme);
        }
      }),
    )
    .subscribe();

  private setThemeLogos(themeConfig: tenantThemeConfig) {
    if (!!themeConfig.logos.signIn) {
      document.documentElement.style.setProperty(
        this.#SIGN_IN_LOGO,
        `url("${themeConfig.logos.signIn}")`,
      );
    }
    if (!!themeConfig.logos.headerLarge) {
      document.documentElement.style.setProperty(
        this.#HEADER_LOGO_LG,
        `url("${themeConfig.logos.headerLarge}")`,
      );
    }
    if (!!themeConfig.logos.headerSmall) {
      document.documentElement.style.setProperty(
        this.#HEADER_LOGO_SM,
        `url("${themeConfig.logos.headerSmall}")`,
      );
    }
  }

  private resetDefaults() {
    this.configService.config = { theme: this.#DEFAULT_THEME_NAME };
    document.documentElement.style.setProperty(
      this.#SIGN_IN_LOGO,
      `var(${this.#SIGN_IN_LOGO}-dflt)`,
    );
    document.documentElement.style.setProperty(
      this.#HEADER_LOGO_LG,
      `var(${this.#HEADER_LOGO_LG}-dflt)`,
    );
    document.documentElement.style.setProperty(
      this.#HEADER_LOGO_SM,
      `var(${this.#HEADER_LOGO_SM}-dflt)`,
    );
  }
}
