import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, shareReplay, catchError, of, BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class RouterService {
  private _currentRouteData = new BehaviorSubject<any | undefined>(undefined);
  currentRouteData$ = this._currentRouteData.asObservable();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    router.events
      .pipe(
        untilDestroyed(this),
        map((event) => {
          if (event instanceof NavigationEnd) {
            const node = this.findLastFirstChild(this.route.snapshot);
            if (node && node.data) {
              this._currentRouteData.next(node.data);
            } else {
              this._currentRouteData.next(undefined);
            }
          }
        }),
        catchError((err) => {
          console.error('Error in Router Data Service', err);
          return of(null);
        }),
        shareReplay(1),
      )
      .subscribe();
  }

  private findLastFirstChild(snapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    return snapshot.firstChild ? this.findLastFirstChild(snapshot.firstChild) : snapshot;
  }
}
