var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'PayrollReportRate';
let PayrollReportRate = class PayrollReportRate {
    id;
    state;
    payroll;
    rate;
    premiumAmount;
    classCode;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    sourceId;
    numberOfEmployees;
};
__decorate([
    FieldData({ recordTypeId })
], PayrollReportRate.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], PayrollReportRate.prototype, "state", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], PayrollReportRate.prototype, "payroll", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'percentage', jsonStorage: true })
], PayrollReportRate.prototype, "rate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], PayrollReportRate.prototype, "premiumAmount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'ClassCode', jsonStorage: true })
], PayrollReportRate.prototype, "classCode", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User', jsonStorage: true })
], PayrollReportRate.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime', jsonStorage: true })
], PayrollReportRate.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User', jsonStorage: true })
], PayrollReportRate.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime', jsonStorage: true })
], PayrollReportRate.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], PayrollReportRate.prototype, "sourceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer', jsonStorage: true, disableField: true })
], PayrollReportRate.prototype, "numberOfEmployees", void 0);
PayrollReportRate = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        displayNameSingular: 'Payroll Report Rate',
        displayNamePlural: 'Payroll Report Rates',
    })
], PayrollReportRate);
export { PayrollReportRate };
