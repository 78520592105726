var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../data-filters/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { mimeTypeList } from '../../mime-types/mime-type-helper';
import { TrailMapType } from '../../trail-maps/models';
export const FileStates = {
    PendingUpload: 'PendingUpload',
    Uploaded: 'Uploaded',
    UploadFailed: 'UploadFailed'
};
const recordTypeId = 'File';
let File = class File {
    id;
    createUser;
    createUserId;
    createdUTC;
    modifiedUser;
    modifiedUserId;
    modifiedUTC;
    deleteUser;
    deleteUserId;
    deletedUTC;
    parentRecordType;
    parentRecordId;
    state;
    uploadDate;
    fileName;
    description;
    contentType;
    size;
    documentCategoryId;
    documentCategory;
    documentTemplateId;
    documentTemplate;
};
__decorate([
    FieldData({ recordTypeId, label: 'File ID', fieldType: 'uuid' })
], File.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], File.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], File.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], File.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], File.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], File.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], File.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], File.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], File.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], File.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, disableField: true })
], File.prototype, "parentRecordType", void 0);
__decorate([
    FieldData({ recordTypeId, disableField: true })
], File.prototype, "parentRecordId", void 0);
__decorate([
    FieldData({ recordTypeId, codeEnum: FileStates })
], File.prototype, "state", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime' })
], File.prototype, "uploadDate", void 0);
__decorate([
    FieldData({ recordTypeId })
], File.prototype, "fileName", void 0);
__decorate([
    FieldData({ recordTypeId })
], File.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, codeEnum: mimeTypeList })
], File.prototype, "contentType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'filesize' })
], File.prototype, "size", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', disableField: true })
], File.prototype, "documentCategoryId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Document Category',
        fieldType: 'Code',
        codeSet: 'documentCategories',
    })
], File.prototype, "documentCategory", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'DocumentTemplate' })
], File.prototype, "documentTemplateId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'DocumentTemplate' })
], File.prototype, "documentTemplate", void 0);
File = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'File',
        displayNamePlural: 'Files',
        usesNewDataModel: true,
        dataModelCustomization: {
            allowCustomFields: true
        },
        lookupProperties: { resultProperties: ['fileName', 'description', 'documentCategory.description'], filterProperties: ['fileName', 'description'] },
        trailMapTypes: [TrailMapType.HomePage, TrailMapType.Detail, TrailMapType.DataEntry],
        supports: {
            views: true
        },
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'fileName',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'contentType',
                filterType: FilterType.Equals,
            },
        ],
    })
], File);
export { File };
