import { NgModule, Optional, SkipSelf } from '@angular/core';
import { IconsModule } from './icons/icons.module';
import { DataViewsModule } from './dataView/data-views.module';
import { DataViewGridComponent } from './dataView/components/data-view-grid/data-view-grid.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { cloneDeep } from 'lodash-es';

@NgModule({
  imports: [
    IconsModule,
    DataViewsModule,
    HttpClientModule,
    HttpCacheInterceptorModule.forRoot({
      responseSerializer(body) {
        return cloneDeep(body);
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DatePipe,
    CurrencyPipe,
    PercentPipe,
    //useHttpCacheLocalStorage,
  ],
  exports: [DataViewGridComponent],
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. Import this module in the AppModule only.',
      );
    }
  }
}
