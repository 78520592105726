import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';

import { CommonModule } from '@angular/common';
import { DataVisualizationsService } from './data-visualizations.service';
import { FuseAlertModule } from '@fuse/components/alert';
import { IsLoadingModule } from '@service-work/is-loading';
import { MatMenuModule } from '@angular/material/menu';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    FuseAlertModule,
    MatNativeDateModule,
    MatRippleModule,
    IsLoadingModule,
    CommonModule,
    MatMenuModule,
  ],
  providers: [DataVisualizationsService],
})
export class DataVisualizationsModule {}
