import { AbstractControl, ValidatorFn } from '@angular/forms';

const DATE_REGEX = new RegExp(/^(\d{2})\/(\d{2})\/\d{4}$/);
const invalidObj = { 'invalid-date': true };

export function dateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const dateVal = control.value;

        // if (typeof dateVal === 'string') {
        //     if (!DATE_REGEX.test(dateVal) && dateVal.length <= 10) {
        //         return invalidObj;
        //     }

        //     dateVal = parse(dateVal, 'MM/dd/yyyy', new Date());
        // }

        if (dateVal !== undefined && dateVal !== null && typeof dateVal === 'object') {
            const dt: Date = dateVal;
            if (dt.getFullYear() < 1900) { return invalidObj; }
        }

        return null;
    };
}