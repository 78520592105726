import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, first, of, switchMap, timeout, withLatestFrom } from 'rxjs';

import { AuthService } from '../auth.service';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Injectable } from '@angular/core';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { RoleCategories } from 'portal-commons/dist/roleEnums';
import { ToastNotificationService } from 'app/core/notifications/toasts/toast-notification.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private navService: NavigationService,
    private toastService: ToastNotificationService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(state.url, redirectUrl, route.data ? route.data : undefined);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(state.url, redirectUrl, childRoute.parent.data ? childRoute.parent.data : undefined);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this._check(route.path ?? '', '/', route.data);
  }

  private getFirstNavLink(items: FuseNavigationItem[]): string | undefined {
    for (const item of items) {
      if (item.link) {
        return item.link;
      }
      if (item.children) {
        const childLink = this.getFirstNavLink(item.children);
        if (childLink) {
          return childLink;
        }
      }
    }
    return undefined;
  }

  private checkSecurityAccess(routeCategory: RoleCategories): Observable<boolean> {
    return this._authService.hasFeatureAccess$(routeCategory).pipe(
      withLatestFrom(this.navService.get()),
      switchMap(([hasAccess, nav]) => {
        if (hasAccess) {
          return of(hasAccess);
        }
        const firstLink = this.getFirstNavLink(nav.default);
        if (firstLink) {
          void this._router.navigate([firstLink]);
          return of(true);
        }
        this.toastService.error('User does not have access to any modules');
        void this._router.navigate(['sign-out']);
        return of(false);
      }),
    );
  }

  private _check(currentUrl: string, redirectURL: string, routeData: any): Observable<boolean> {
    return this._authService.isInitialized().pipe(
      first((v) => v),
      switchMap(() => {
        return this._authService.check().pipe(
          switchMap((authenticated) => {
            if (!authenticated) {
              void this._router.navigate(['sign-in'], { queryParams: { redirectURL } }).then();
              return of(false);
            }

            if (currentUrl.toLowerCase() === '/home') {
              return this.navService.get().pipe(
                switchMap((nav) => {
                  for (const item of nav.default) {
                    if (item.link && item.defaultPage) {
                      void this._router.navigate([item.link]);
                      return of(true);
                    }
                    if (item.children) {
                      for (const child of item.children) {
                        if (child.link && child.defaultPage) {
                          void this._router.navigate([child.link]);
                          return of(true);
                        }
                      }
                    }
                  }
                  void this._router.navigate(['dashboards']);
                  return of(true);
                })
              );
            }

            if (routeData && routeData.category) {
              return this.checkSecurityAccess(routeData.category);
            }
            return of(true);
          }),
        );
      }),
      timeout({
        each: 10000,
        with: () => {
          void this._router.navigate(['sign-in'], { queryParams: { redirectURL } }).then();
          return of(false);
        },
      }),
    );
  }
}
