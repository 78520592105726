var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { RoleCategories } from '../../roleEnums';
import { TrailMapType } from '../../trail-maps/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'PolicyHolder';
let PolicyHolder = class PolicyHolder {
    //todo: make required again
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    legalName;
    displayName;
    tenantNumber;
    contacts;
    status;
    policyholderType;
    address;
    physicalAddress;
    mailingAddress;
    billingAddress;
    joinDate;
    inactiveDate;
    federalEin;
    tin;
    organizationType;
    sourceId;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyHolder.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyHolder.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyHolder.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyHolder.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created By',
        fieldType: 'User',
        jsonStorage: true,
    })
], PolicyHolder.prototype, "createUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], PolicyHolder.prototype, "createdUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified By',
        fieldType: 'User',
        jsonStorage: true,
    })
], PolicyHolder.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], PolicyHolder.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted By',
        fieldType: 'User',
        jsonStorage: true,
    })
], PolicyHolder.prototype, "deleteUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], PolicyHolder.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Legal Name', jsonStorage: true })
], PolicyHolder.prototype, "legalName", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Name', jsonStorage: true })
], PolicyHolder.prototype, "displayName", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Reference Number', jsonStorage: true })
], PolicyHolder.prototype, "tenantNumber", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'Contact',
        isList: true,
        jsonStorage: true,
    })
], PolicyHolder.prototype, "contacts", void 0);
__decorate([
    FieldData({
        recordTypeId,
        jsonStorage: true,
        codeList: [
            { code: 'active', description: 'Active' },
            { code: 'inactive', description: 'Inactive' },
        ],
    })
], PolicyHolder.prototype, "status", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Policyholder Type',
        codeSet: 'policyholderTypes',
        fieldType: 'codelist',
        jsonStorage: true,
    })
], PolicyHolder.prototype, "policyholderType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address', jsonStorage: true })
], PolicyHolder.prototype, "address", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address', jsonStorage: true })
], PolicyHolder.prototype, "physicalAddress", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address', jsonStorage: true })
], PolicyHolder.prototype, "mailingAddress", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address', jsonStorage: true })
], PolicyHolder.prototype, "billingAddress", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Join Date', fieldType: 'date', jsonStorage: true })
], PolicyHolder.prototype, "joinDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Inactive Date', fieldType: 'date', jsonStorage: true })
], PolicyHolder.prototype, "inactiveDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'FEIN', jsonStorage: true })
], PolicyHolder.prototype, "federalEin", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'TIN', jsonStorage: true })
], PolicyHolder.prototype, "tin", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Organization Type',
        codeSet: 'organizationTypes',
        fieldType: 'codelist',
        jsonStorage: true,
    })
], PolicyHolder.prototype, "organizationType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], PolicyHolder.prototype, "sourceId", void 0);
PolicyHolder = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Policyholder',
        displayNamePlural: 'Policyholders',
        dataModelCustomization: {
            allowCustomFields: true,
        },
        lookupProperties: { resultProperties: ['displayName', 'tenantNumber', 'status'], filterProperties: ['displayName', 'tenantNumber'] },
        supports: {
            views: true,
            files: true,
            globalSearch: true,
            docTemplates: true
        },
        trailMapTypes: [
            TrailMapType.HomePage,
            TrailMapType.DataEntry,
            TrailMapType.Detail,
            TrailMapType.QuickAdd,
        ],
        usesNewDataModel: true,
        roleCategory: RoleCategories.Policyholders,
        workflow: { enabled: true, generalEvents: true },
    })
], PolicyHolder);
export { PolicyHolder };
