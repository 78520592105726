var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { TrailMapType } from '../../trail-maps/models';
const recordTypeId = 'Claimant';
let Claimant = class Claimant {
    firstName;
    lastName;
    address;
    phoneNumber;
    email;
};
__decorate([
    FieldData({ recordTypeId })
], Claimant.prototype, "firstName", void 0);
__decorate([
    FieldData({ recordTypeId })
], Claimant.prototype, "lastName", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address' })
], Claimant.prototype, "address", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Phone #', fieldType: 'phone-number' })
], Claimant.prototype, "phoneNumber", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'email' })
], Claimant.prototype, "email", void 0);
Claimant = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        displayNameSingular: 'Claimant',
        displayNamePlural: 'Claimants',
        lookupProperties: { resultProperties: ['firstName', 'lastName'] },
        trailMapTypes: [TrailMapType.QuickAdd],
        dataModelCustomization: {
            allowCustomFields: true,
        },
    })
], Claimant);
export { Claimant };
