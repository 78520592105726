export var ScheduledJobTypeEnum;
(function (ScheduledJobTypeEnum) {
    ScheduledJobTypeEnum["ViewSend"] = "VIEW-SEND";
    ScheduledJobTypeEnum["WorkflowRun"] = "WORKFLOW-SCHEDULE-RUN";
})(ScheduledJobTypeEnum || (ScheduledJobTypeEnum = {}));
export const ScheduleJobTypes = {
    [ScheduledJobTypeEnum.ViewSend]: 'Send View',
    [ScheduledJobTypeEnum.WorkflowRun]: 'Run Workflow',
};
export function schedulesDiff(prior, current) {
    if (!prior && current) {
        return true;
    }
    if (prior && !current) {
        return true;
    }
    if (prior && current) {
        if (prior.effectiveUTC !== current.effectiveUTC) {
            return true;
        }
        if (prior.cron !== current.cron) {
            return true;
        }
    }
    return false;
}
