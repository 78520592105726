import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class SessionUrlService {
  urlTenantValid(authService: AuthService) {
    const urlTenant = this.getTenantFromCurrentUrl();
    // console.log('tenant from current url', urlTenant);
    if (!urlTenant || urlTenant.length === 0) {
      return false;
    }

    const authTenant = authService.getTenant();
    // console.log('tenant from auth', authTenant);
    return !(authTenant && authTenant.toLocaleLowerCase() !== urlTenant.toLocaleLowerCase());
  }

  getTenantFromCurrentUrl() {
    // console.log('evaluating getTenantFromCurrentUrl', window.location.hostname.toLocaleLowerCase(),
    //   environment.baseHostName.toLocaleLowerCase()
    // );
    const strippedHost = window.location.hostname
      .toLocaleLowerCase()
      .replace(environment.baseHostName.toLocaleLowerCase(), '');
    if (!strippedHost || strippedHost.length === 0) {
      return null;
    }
    const tenantFromHost = strippedHost.split('.')[0];
    // console.log('result from getTenantFromCurrentUrl', tenantFromHost);
    return tenantFromHost;
  }

  buildTenantUrl(authService: AuthService, urlTree: UrlTree) {
    const protocol = window.location.protocol;
    const tenant = authService.getTenant();
    let newRoute = `${protocol}//${tenant}.${environment.baseHostName}`;
    if (urlTree) {
      newRoute += `${urlTree.toString()}`;
    }
    // console.log('buildTenantUrl', newRoute);
    return newRoute;
  }

  private getCurrentRouteTree() {
    try {
      return new DefaultUrlSerializer().parse(window.location.pathname + window.location.search);
    } catch (e) {
      console.error('Could not determine getCurrentRouteTree', e);
      return new DefaultUrlSerializer().parse('');
    }
  }

  buildTenantSpecificCurrentUrl(authService: AuthService) {
    return this.buildTenantUrl(authService, this.getCurrentRouteTree());
  }

  urlIsBaseHost() {
    const sessionUrl = window.location.hostname;
    return environment.baseHostName.toLocaleLowerCase() === sessionUrl.toLocaleLowerCase();
  }

  buildBaseHostUrl(urlTree: UrlTree) {
    const protocol = window.location.protocol;
    let newRoute = `${protocol}//${environment.baseHostName}`;
    if (urlTree) {
      newRoute += `${urlTree.toString()}`;
    }
    return newRoute;
  }
}
