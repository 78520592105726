import { Observable, shareReplay } from 'rxjs';
import { UserDetails } from 'portal-commons/dist/users/models';

import { ChangePasswordRequest } from 'src/app/core/models/change-password-request';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MyUserDetailsInput, UserDetailsInput } from 'portal-commons/dist/users/models';
import { UserPreview } from 'portal-commons/dist/identity/models/userPreview';

export interface selectGroup {
  id: string;
  label: string;
}
@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  private httpClient: HttpClient;

  private _userPreviews: Observable<UserPreview[]>;
  private users: Observable<UserDetails[]>;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  getUsers(forceRefresh: boolean): Observable<UserDetails[]> {
    if (!this.users || forceRefresh) {
      this.users = this.httpClient.get<UserDetails[]>(`/api/users`).pipe(shareReplay(1));
    }
    return this.users;
  }

  saveProfile(input: MyUserDetailsInput) {
    return this.httpClient.post<UserDetails>('/api/myprofile', input).pipe(shareReplay(1));
  }

  saveUser(input: UserDetailsInput) {
    return this.httpClient.post<UserDetails>('/api/users', input).pipe(shareReplay(1));
  }

  deleteUser(id: string) {
    return this.httpClient
      .post<UserDetails>(`/api/users/delete`, { userId: id })
      .pipe(shareReplay(1));
  }

  reactivateUser(id: string) {
    return this.httpClient
      .post<UserDetails>(`/api/users/reactivate`, { userId: id })
      .pipe(shareReplay(1));
  }

  changePassword(token: any, passwordBody: ChangePasswordRequest): Observable<any> {
    const path = '/api/users/password';

    return this.httpClient.put(path, passwordBody, {
      headers: { Authorization: token },
    });
  }
  getUserPreviews(forceRefresh: boolean): Observable<UserPreview[]> {
    if (!this._userPreviews || forceRefresh) {
      this._userPreviews = this.httpClient
        .get<UserPreview[]>('api/userpreviews')
        .pipe(shareReplay(1));
    }
    return this._userPreviews;
  }

  distinctGroups(userPreviews: UserPreview[]): selectGroup[] {
    const groups: selectGroup[] = [];
    if (userPreviews) {
      for (const user of userPreviews) {
        const group: selectGroup = {
          id: '',
          label: '',
        };
        if (user.agencyId && user.agencyPreview) {
          group.id = user.agencyId;
          group.label = user.agencyPreview.name;
        }
        const existing = groups.find((e) => e.id === group.id);
        if (!existing) {
          groups.push(group);
        }
      }
    }

    return groups;
  }
}
