var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../data-filters/models';
import { TrailMapScopeType, TrailMapType, } from '../../trail-maps/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'TrailMap';
let TrailMap = class TrailMap {
    id;
    createUserId;
    createUser;
    createdUTC;
    modifiedUserId;
    modifiedUser;
    modifiedUTC;
    deleteUserId;
    deleteUser;
    deletedUTC;
    name;
    displayName;
    subtitle;
    mapArea;
    mapType;
    mapScope;
    roles;
    filter;
    tabs;
    //@FieldData({ recordTypeId, label: 'Sections', disableField: true, jsonStorage: true, fieldType: 'jsonb' }) sections?: Array<TrailMapSection>;
    integrations;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], TrailMap.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], TrailMap.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], TrailMap.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], TrailMap.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], TrailMap.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], TrailMap.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], TrailMap.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], TrailMap.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], TrailMap.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], TrailMap.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId })
], TrailMap.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId })
], TrailMap.prototype, "displayName", void 0);
__decorate([
    FieldData({ recordTypeId })
], TrailMap.prototype, "subtitle", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Area', fieldType: 'mapArea' })
], TrailMap.prototype, "mapArea", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Type', codeEnum: TrailMapType })
], TrailMap.prototype, "mapType", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Scope', codeEnum: TrailMapScopeType })
], TrailMap.prototype, "mapScope", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Roles',
        disableField: true,
        jsonStorage: true,
        fieldType: 'jsonb',
    })
], TrailMap.prototype, "roles", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Filter',
        disableField: true,
        jsonStorage: true,
        fieldType: 'jsonb',
    })
], TrailMap.prototype, "filter", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Tabs',
        disableField: true,
        jsonStorage: true,
        fieldType: 'jsonb',
    })
], TrailMap.prototype, "tabs", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Integrations',
        disableField: true,
        jsonStorage: true,
        fieldType: 'jsonb',
    })
], TrailMap.prototype, "integrations", void 0);
TrailMap = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        hasBackingTable: true,
        displayNameSingular: 'Trail Map',
        displayNamePlural: 'Trail Maps',
        dataModelCustomization: {
            hideFromAdmin: true,
        },
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'mapArea',
                filterType: FilterType.Equals,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'mapType',
                filterType: FilterType.Equals,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'name',
                filterType: FilterType.Contains,
            },
        ],
    })
], TrailMap);
export { TrailMap };
