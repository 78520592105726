import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenerateButtonComponent } from './components/generate-button/generate-button.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseAlertModule } from '@fuse/components/alert';
import { DataModelModule } from '../data-model/data-model.module';
import { MatMenuModule } from '@angular/material/menu';
import { IsLoadingModule } from '@service-work/is-loading';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  declarations: [GenerateButtonComponent],
  imports: [
    CommonModule,
    SharedModule,
    LayoutModule,
    MatIconModule,
    FuseAlertModule,
    MatTooltipModule,
    MatMenuModule,
    DataModelModule,
    IsLoadingModule,
  ],
  exports: [GenerateButtonComponent],
})
export class DocumentTemplatesModule {}
