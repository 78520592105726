import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ComponentStore } from '@ngrx/component-store';
import { DataModelStoreService } from 'app/core/data-model/services/data-model.store';
import { DataFormatService } from 'app/core/services/data-format.service';
import { FilterService } from 'app/modules/filters/services/filter.service';
import {
  FilterCondition,
  FilterType,
  PrettyLogicalOperator,
  QuickSearchField,
} from 'portal-commons/dist/data-filters/models';
import { RecordTypesType } from 'portal-commons/dist/data-model/record-types';
import {
  Observable,
  Subject,
  combineLatest,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { LookupService } from 'app/core/services/lookup.service';

export interface DataFilterState {
  recordType: RecordTypesType | string | undefined;
  expanded: boolean;
  isCollapsible: boolean;
  rootFieldsOnly: boolean;
  filters: FilterCondition[] | undefined;
  quickSearchFields?: QuickSearchField[] | undefined;
  quickSearchFilters?: FilterCondition[] | undefined;
}

const DEFAULT_STATE: DataFilterState = {
  recordType: undefined,
  expanded: false,
  isCollapsible: true,
  rootFieldsOnly: true,
  filters: undefined,
  quickSearchFields: undefined,
  quickSearchFilters: undefined,
};

@UntilDestroy()
@Injectable()
export class DataFilterStore extends ComponentStore<DataFilterState> {
  httpClient = inject(HttpClient);
  dataModelStore = inject(DataModelStoreService);
  dataFormatService = inject(DataFormatService);
  filterService = inject(FilterService);
  lookupService = inject(LookupService);

  constructor() {
    super(DEFAULT_STATE);
  }

  private _clearQuickFilterFields = new Subject<boolean>();
  readonly clearQuickFilterFields$ = this._clearQuickFilterFields.asObservable();

  readonly recordType$ = this.select(({ recordType }) => recordType);
  readonly expanded$ = this.select(({ expanded }) => expanded);
  readonly isCollapsible$ = this.select(({ isCollapsible }) => isCollapsible);
  readonly filters$ = this.select(({ filters }) => filters);
  readonly rootFieldsOnly$ = this.select(({ rootFieldsOnly }) => rootFieldsOnly);
  readonly quickSearchFields$ = this.select(({ quickSearchFields }) => quickSearchFields);
  readonly quickSearchFilters$ = this.select(({ quickSearchFilters }) => quickSearchFilters);

  readonly quickSearchFilterDescription$ = this.quickSearchFilters$.pipe(
    map((filters) => {
      if (!filters || filters.length === 0) { return undefined; }
      try {
        return this.filterService.getFilterConditionsDescription(filters, PrettyLogicalOperator.All);
      }
      catch (err) {
        console.error('unable to translate search filters', err);
        return undefined;
      }
    }),
    shareReplay(1)
  );

  readonly filterConditions$ = combineLatest([
    this.filters$.pipe(startWith([])),
    this.quickSearchFilters$.pipe(startWith([])),
  ]).pipe(
    map(([filters, quickFilters]) => {
      return [...(filters ?? []), ...(quickFilters ?? [])];
    }),
    shareReplay(1),
  );

  initFilterState(state: DataFilterState) {
    this.patchState({
      ...state,
    });
  }

  clearQuickFilterFields() {
    this._clearQuickFilterFields.next(true);
    this.updateState({ quickSearchFilters: [] });
  }

  applyQuickSearch(form: FormGroup) {
    const formVals = form.getRawValue();
    const filters: FilterCondition[] = [];
    const searchFields = this.get().quickSearchFields;
    if (!searchFields) {
      return this.updateState({ quickSearchFilters: [] });
    }
    for (const searchField of searchFields) {
      const usesBetween =
        searchField.filterType === FilterType.Between &&
        Object.keys(formVals).filter((f) => f === `${searchField.fieldPath}.param1`).length > 0;
      if (usesBetween) {
        let formVal1 = formVals[`${searchField.fieldPath}.param1`];
        let formVal2 = formVals[`${searchField.fieldPath}.param2`];
        if (!!!formVal1 && !!!formVal2) {
          continue;
        }
        if (formVal1 instanceof Date) {
          formVal1 = formVal1.toISOString().substring(0, 10);
        }
        if (formVal2 instanceof Date) {
          formVal2 = formVal2.toISOString().substring(0, 10);
        }
        filters.push({
          fieldRecordType: searchField.recordType,
          fieldId: '',
          fieldPath: searchField.fieldPath,
          filterType: searchField.filterType,
          searchParameter1: formVal1 as string,
          searchParameter2: formVal2 as string,
        });
      } else {
        const formVal = formVals[searchField.fieldPath];
        if (!!!formVal) {
          continue;
        }
        filters.push({
          fieldRecordType: searchField.recordType,
          fieldId: '',
          fieldPath: searchField.fieldPath,
          filterType: searchField.filterType,
          searchParameter1: formVal as string,
        });
      }
    }
    console.log('applyQuickSearch', filters);
    return this.updateState({ quickSearchFilters: filters });
  }

  getCurrentFilters() {
    return this.get().quickSearchFilters;
  }

  readonly updateState = this.updater((state, updates: Partial<DataFilterState>) => ({
    ...state,
    ...updates,
  }));

  readonly toggleExpanded = this.effect((origin$: Observable<void>) =>
    origin$.pipe(
      withLatestFrom(this.expanded$),
      switchMap(([_, expanded]) => {
        return of(!expanded);
      }),
      tap((newVal: boolean) => {
        this.patchState({ expanded: newVal });
      }),
    ),
  );

  readonly applyFilter = this.effect(
    (origin$: Observable<{ filter: FilterCondition; index?: number }>) =>
      origin$.pipe(
        withLatestFrom(this.filters$),
        tap(([input, filters]) => {
          if (!filters) {
            filters = [];
          }
          const { filter, index } = input;

          const parts = this.filterService.getFilterDescription(filter);
          if (parts && parts.length > 0) {
            filter.descriptionParts = parts;
          }

          if (index !== undefined && filters.length > index) {
            filters[index] = filter;
          } else {
            filters.push(filter);
          }

          this.patchState({ filters: [...filters] });
        }),
      ),
  );

  readonly removeFilter = this.effect((origin$: Observable<number>) =>
    origin$.pipe(
      withLatestFrom(this.filters$),
      map(([index, filters]) => {
        if (!filters) {
          return;
        }
        const newFilters = [...filters];
        newFilters.splice(index, 1);
        this.patchState({ filters: newFilters });
      }),
    ),
  );

  getSearchFieldLookup(recordType: string, filter: string) {
    return this.lookupService.recordTypeLookup({ q: filter, recordTypeId: recordType });
  }
}