export var PayrollReportStatus;
(function (PayrollReportStatus) {
    PayrollReportStatus["submitted"] = "submitted";
    PayrollReportStatus["open"] = "open";
    PayrollReportStatus["deleted"] = "deleted";
})(PayrollReportStatus || (PayrollReportStatus = {}));
export var LateFeeType;
(function (LateFeeType) {
    LateFeeType["Invoice"] = "invoice";
    LateFeeType["LineItem"] = "lineitem";
})(LateFeeType || (LateFeeType = {}));
export var LateFeeAmountType;
(function (LateFeeAmountType) {
    LateFeeAmountType["FlatFee"] = "flatfee";
    LateFeeAmountType["Percentage"] = "percentage";
})(LateFeeAmountType || (LateFeeAmountType = {}));
export const payrollReportAdminSettingFilter = {
    category: 'Payments',
    name: 'payrollReportSettings',
};
