import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'errorMessage' })
export class ErrorMessagePipe implements PipeTransform {
  transform(error: any): string {
    if (error && typeof error === 'string') {
      return error;
    }
    if (error && error.error && error.error.errorMessage) {
      return error.error.errorMessage as string;
    }
    if (error && error.error && error.error.message) {
      return error.error.message as string;
    }
    if (error.message) {
      return error.message as string;
    }
    return 'An unexpected error occurred';
  }
}
