import { Observable, shareReplay } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessagePreview } from 'portal-commons/dist/messages/messagePreview';
import { UserMessage } from 'portal-commons/dist/messages/userMessage';
import { PaginatedTableOptions } from 'portal-commons/dist/data-table/models';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  constructor(private httpClient: HttpClient) {}

  getRecentUserMessagePreviews(): Observable<MessagePreview[]> {
    return this.httpClient.get<MessagePreview[]>(`/api/messages/previews`).pipe(shareReplay(1));
  }

  deleteUserMessage(id: string) {
    return this.httpClient.delete<UserMessage>(`/api/messages/${id}`).pipe(shareReplay(1));
  }

  getUserMessages(options: PaginatedTableOptions): Observable<UserMessage[]> {
    return this.httpClient
      .get<UserMessage[]>('/api/messages', {
        params: {
          options: JSON.stringify(options),
        },
      })
      .pipe(shareReplay(1));
  }

  getUserSentMessages(options: PaginatedTableOptions): Observable<UserMessage[]> {
    return this.httpClient
      .get<UserMessage[]>('/api/messages-sent', {
        params: {
          options: JSON.stringify(options),
        },
      })
      .pipe(shareReplay(1));
  }

  getUserMessage(messageId: string): Observable<UserMessage> {
    return this.httpClient.get<UserMessage>(`/api/messages/${messageId}`).pipe(shareReplay(1));
  }
  createMessage(
    message: Partial<UserMessage>,
    parentRecordType?: string,
    parentRecordId?: string,
  ): Observable<UserMessage> {
    let url = '/api/messages';
    if (parentRecordType && parentRecordId) {
      url += `?parentRecordType=${parentRecordType}`;
      url += `&parentRecordId=${parentRecordId}`;
    }
    return this.httpClient.post<UserMessage>(url, message).pipe(shareReplay(1));
  }
  updateMessage(message: Partial<UserMessage>): Observable<UserMessage> {
    return this.httpClient.put<UserMessage>('/api/messages', message).pipe(shareReplay(1));
  }

  updateMessages(messages: Partial<UserMessage>[]): Observable<UserMessage[]> {
    return this.httpClient
      .post<UserMessage[]>('/api/messages/updates', messages)
      .pipe(shareReplay(1));
  }
}
