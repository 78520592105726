<div class="w-full flex flex-row flex-grow">
  <mat-form-field [class]="this.formFieldClasses">
    <label *ngIf="!!this.label">{{ this.label }}</label>
    <div class="flex flex-row w-full">
      <ng-container *ngIf="(this.showFunction$ | async) === false">
        <input
          matInput
          #dateInput
          class="flex-grow"
          [placeholder]="this.placeholder"
          floatLabel="always"
          [matDatepicker]="dateVal"
          [formControlName]="this.formControlName"
          [maskito]="options"
          (dateChange)="onDateChange($event)" />
        <mat-datepicker-toggle matSuffix [for]="dateVal"></mat-datepicker-toggle>
        <mat-datepicker #dateVal></mat-datepicker>
      </ng-container>
      <ng-container *ngIf="(this.showFunction$ | async) === true">
        <input
          matInput
          #relativeInput
          class="flex-grow"
          floatLabel="always"
          [placeholder]="this.placeholder"
          [formControlName]="this.formControlName" />
        <div matSuffix class="text-secondary text-right py-3 italic text-sm uppercase min-w-40">
          Relative date
        </div>
        <button mat-icon-button matSuffix [matMenuTriggerFor]="menu" type="button">
          <mat-icon [svgIcon]="Icons.material.outline.Info"></mat-icon>
        </button>
        <mat-menu
          [yPosition]="'below'"
          [overlapTrigger]="true"
          #menu="matMenu"
          [class]="'max-w-200 max-h-400 overflow-y-scroll rounded-2xl'">
          <div class="m-2 mx-4" (click)="$event.stopPropagation()">
            <tb-relative-date-helper displayOption="date"></tb-relative-date-helper>
          </div>
        </mat-menu>
      </ng-container>
    </div>
    <mat-hint *ngIf="!!this.hint">{{ this.hint }}</mat-hint>
    <mat-error *ngIf="this.form.get(this.formControlName)?.hasError('invalid-relative-date')">
      Invalid relative date expression
    </mat-error>
    <mat-error *ngIf="this.form.get(this.formControlName)?.hasError('invalid-date')">
      Invalid date
    </mat-error>
    <mat-error *ngIf="this.form.get(this.formControlName)?.hasError('required')">
      Valid date required
    </mat-error>
  </mat-form-field>
  <button
    mat-icon-button
    [matTooltip]="
      (this.showFunction$ | async) === false
        ? 'Toggle to relative date expression'
        : 'Toggle to enter date value'
    "
    (click)="toggleShowFunction()"
    [color]="(this.showFunction$ | async) === true ? 'primary' : undefined">
    <mat-icon [svgIcon]="Icons.phosphor.regular.Function"></mat-icon>
  </button>
</div>
