import { FileDownloadResult } from "portal-commons/dist/shared/file-download-result";
import { Buffer } from 'buffer';
import { saveAs } from 'file-saver';

/**
 * 
 * Note that if not using a downloadUrl that contentType is required.  Pass in empty string for content type if using buffer or base64.
 * @returns 
 */
export function downloadFileResult(response: FileDownloadResult | undefined): boolean {
  if (!response) {
    console.log('download result undefined');
    return false;
  }

  if (response.downloadUrl) {
    saveAs(response.downloadUrl, response.fileName);
    return true;
  }

  if (response.base64) {
    if (!!!response.contentType) {
      throw Error('content type is missing');
    }
    const buffer = Buffer.from(response.base64, 'base64');
    const filename = response.fileName;
    const blob = new Blob([buffer], { type: response.contentType });
    saveAs(blob, filename);
    return true;
  }
  return false;
}
