var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
export const NotificationStates = {
    success: 'SUCCESS',
    warning: 'WARNING',
    errors: 'ERRORS',
    running: 'RUNNING',
    cancelled: 'CANCELLED',
};
const recordTypeId = 'Notification';
let Notification = class Notification {
    id;
    title;
    message;
    createdUTC;
    modifiedUTC;
    recordType;
    recordId;
    expiresUTC;
    audience;
    state;
    icon;
    read;
};
__decorate([
    FieldData({ recordTypeId, label: 'ID', fieldType: 'uuid' })
], Notification.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], Notification.prototype, "title", void 0);
__decorate([
    FieldData({ recordTypeId })
], Notification.prototype, "message", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Notification.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Notification.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId })
], Notification.prototype, "recordType", void 0);
__decorate([
    FieldData({ recordTypeId })
], Notification.prototype, "recordId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime' })
], Notification.prototype, "expiresUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true })
], Notification.prototype, "audience", void 0);
__decorate([
    FieldData({ recordTypeId, codeEnum: NotificationStates })
], Notification.prototype, "state", void 0);
__decorate([
    FieldData({ recordTypeId })
], Notification.prototype, "icon", void 0);
Notification = __decorate([
    RecordTypeData({
        usesNewDataModel: true,
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Notification',
        displayNamePlural: 'Notifications',
        dataModelCustomization: {
            hideFromAdmin: true
        }
    })
], Notification);
export { Notification };
