import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, FormControl } from '@angular/forms';
import { dateValidator } from '../validators/date-validator';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[matDatepicker]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ValidateDateDirective, multi: true }
  ]
})
export class ValidateDateDirective implements Validator {
  private _valFn: ValidatorFn;

  constructor() {
    this._valFn = dateValidator();
  }

  validate(control: FormControl) {
    return this._valFn(control);
  }
}
