var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { TrailMapType } from '../../trail-maps/models';
export var AddressType;
(function (AddressType) {
    AddressType["billing"] = "billing";
    AddressType["mailing"] = "mailing";
    AddressType["shipping"] = "shipping";
})(AddressType || (AddressType = {}));
const recordTypeId = 'Address';
let Address = class Address {
    address1;
    address2;
    city;
    state;
    postalCode;
    addressType;
};
__decorate([
    FieldData({ recordTypeId })
], Address.prototype, "address1", void 0);
__decorate([
    FieldData({ recordTypeId })
], Address.prototype, "address2", void 0);
__decorate([
    FieldData({ recordTypeId })
], Address.prototype, "city", void 0);
__decorate([
    FieldData({ recordTypeId, codeSet: 'states', fieldType: 'codelist' })
], Address.prototype, "state", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Postal' })
], Address.prototype, "postalCode", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Address Type' })
], Address.prototype, "addressType", void 0);
Address = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        displayNameSingular: 'Address',
        displayNamePlural: 'Addresses',
        lookupProperties: { resultProperties: ['address1', 'address2', 'city', 'state', 'postalCode'] },
        trailMapTypes: [TrailMapType.QuickAdd],
    })
], Address);
export { Address };
