import {
  DataModelRecordType,
  DataModelRecordTypeField,
} from 'portal-commons/dist/data-model/record-types';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedTableOptions } from 'portal-commons/dist/data-table/models';
import { shareReplay } from 'rxjs';
import { FileDownloadResult } from 'portal-commons/dist/shared/file-download-result';

@Injectable({
  providedIn: 'root',
})
export class DataModelApiService {
  constructor(private httpClient: HttpClient) { }

  getTenantDataModel() {
    return this.httpClient
      .get<DataModelRecordType[]>('api/recordtypes/tenant-model')
      .pipe(shareReplay(1));
  }

  getPaginatedRecordTypes(options?: PaginatedTableOptions) {
    if (!options) {
      options = {
        countOnly: false,
        limit: 1000,
      };
    }
    return this.httpClient
      .get<DataModelRecordType[]>('/api/recordtypes-paginated', {
        params: {
          options: JSON.stringify(options),
        },
      })
      .pipe(shareReplay(1));
  }

  saveRecordTypeField(recordTypeField: DataModelRecordTypeField) {
    console.log('saveRecordTypeField', recordTypeField);
    return this.httpClient
      .post<DataModelRecordTypeField>(
        `/api/recordtypes/${recordTypeField.recordTypeId}/fields/save`,
        recordTypeField,
      )
      .pipe(shareReplay(1));
  }

  removeRecordType(recordType: DataModelRecordType) {
    return this.httpClient
      .post<DataModelRecordType>(`/api/recordtypes/delete`, recordType)
      .pipe(shareReplay(1));
  }

  removeRecordTypeField(recordTypeField: DataModelRecordTypeField) {
    return this.httpClient
      .post<DataModelRecordTypeField>(
        `/api/recordtypes/${recordTypeField.recordTypeId}/fields/delete`,
        recordTypeField,
      )
      .pipe(shareReplay(1));
  }

  exportFields(recordType: string) {
    return this.httpClient
      .post<FileDownloadResult>(`/api/recordtypes/${recordType}/fields/export`, {})
      .pipe(shareReplay(1));
  }

  saveRecordType(recordType: Partial<DataModelRecordType>) {
    return this.httpClient
      .post<DataModelRecordType>('/api/recordtypes/save', recordType)
      .pipe(shareReplay(1));
  }
}
