//https://stackoverflow.com/questions/71932351/type-and-sub-type-dependent-properties
export var ActionTypeEnum;
(function (ActionTypeEnum) {
    ActionTypeEnum["BulkPrint"] = "bulk-print";
    ActionTypeEnum["DocumentTemplate"] = "document-template";
    ActionTypeEnum["Script"] = "script";
})(ActionTypeEnum || (ActionTypeEnum = {}));
export const ActionTypes = {
    [ActionTypeEnum.BulkPrint]: 'Bulk Print',
    [ActionTypeEnum.DocumentTemplate]: 'Document Template',
    [ActionTypeEnum.Script]: 'Script',
};
export const BulkActionTypes = [ActionTypeEnum.BulkPrint];
export var WorkflowStatusEnum;
(function (WorkflowStatusEnum) {
    WorkflowStatusEnum["Pending"] = "pending";
    WorkflowStatusEnum["Queued"] = "queued";
    WorkflowStatusEnum["Running"] = "running";
    WorkflowStatusEnum["Failed"] = "failed";
    WorkflowStatusEnum["Completed"] = "completed";
})(WorkflowStatusEnum || (WorkflowStatusEnum = {}));
