var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { RoleCategories } from '../../roleEnums';
const recordTypeId = 'AccountingLedgerEntry';
let AccountingLedgerEntry = class AccountingLedgerEntry {
    //pk
    id;
    //fk
    policyHolderId;
    policyHolder;
    //jdata
    comments;
    itemDate;
    dateReceived;
    invoiceId;
    debitAmount;
    creditAmount;
    policyPeriod;
    accounting_deposit_id;
    description;
    payMethod;
    referenceNote;
    accountNumber;
    invoiceNumber;
    subAccount;
    commissionable;
    policyHolderSourceId;
    lineItemId;
    sourceId;
    depositSourceId;
};
__decorate([
    FieldData({ recordTypeId, label: 'Ledger ID', fieldType: 'uuid' })
], AccountingLedgerEntry.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, referenceType: 'PolicyHolder' })
], AccountingLedgerEntry.prototype, "policyHolderId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyHolder', label: 'Policyholder', jsonStorage: true })
], AccountingLedgerEntry.prototype, "policyHolder", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], AccountingLedgerEntry.prototype, "comments", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Item Date', fieldType: 'datetime', jsonStorage: true })
], AccountingLedgerEntry.prototype, "itemDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Date Received', fieldType: 'datetime', jsonStorage: true })
], AccountingLedgerEntry.prototype, "dateReceived", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], AccountingLedgerEntry.prototype, "invoiceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], AccountingLedgerEntry.prototype, "debitAmount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], AccountingLedgerEntry.prototype, "creditAmount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer', jsonStorage: true })
], AccountingLedgerEntry.prototype, "policyPeriod", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], AccountingLedgerEntry.prototype, "accounting_deposit_id", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], AccountingLedgerEntry.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], AccountingLedgerEntry.prototype, "payMethod", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], AccountingLedgerEntry.prototype, "referenceNote", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], AccountingLedgerEntry.prototype, "accountNumber", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], AccountingLedgerEntry.prototype, "invoiceNumber", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], AccountingLedgerEntry.prototype, "subAccount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true, disableField: true })
], AccountingLedgerEntry.prototype, "commissionable", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], AccountingLedgerEntry.prototype, "policyHolderSourceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], AccountingLedgerEntry.prototype, "lineItemId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], AccountingLedgerEntry.prototype, "sourceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer', jsonStorage: true, disableField: true })
], AccountingLedgerEntry.prototype, "depositSourceId", void 0);
AccountingLedgerEntry = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Accounting Ledger Entry',
        displayNamePlural: 'Accounting Ledger Entries',
        // supports: {
        //   views: true,
        //   docTemplates: true,
        // },
        usesNewDataModel: true,
        dataModelCustomization: {
            allowCustomFields: true,
        },
        //trailMapTypes: [TrailMapType.HomePage, TrailMapType.Detail],
        lookupProperties: { resultProperties: ['invoiceId', 'effectiveDate', 'expirationDate'], filterProperties: ['invoiceId'] },
        roleCategory: RoleCategories.Accounting,
    })
], AccountingLedgerEntry);
export { AccountingLedgerEntry };
