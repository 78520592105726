var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { TrailMapType } from '../../trail-maps/models';
import { RoleCategories } from '../../roleEnums';
import { FilterType } from '../../data-filters/models';
export var claimStatusEnum;
(function (claimStatusEnum) {
    claimStatusEnum["Open"] = "open";
    claimStatusEnum["Reopened"] = "reopened";
    claimStatusEnum["Closed"] = "closed";
})(claimStatusEnum || (claimStatusEnum = {}));
;
export const claimStatusList = [
    { code: claimStatusEnum.Open, description: 'Open' },
    { code: claimStatusEnum.Reopened, description: 'Reopened' },
    { code: claimStatusEnum.Closed, description: 'Closed' }
];
const recordTypeId = 'Claim';
let Claim = class Claim {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    claimNumber;
    status;
    claimant;
    lossDate;
    reportDate;
    dateOpened;
    bodyPart;
    adjuster;
    cause;
    nature;
    claimDescription;
    claimType;
    closeDate;
    reserves;
    payments;
    policyId;
    policy;
    totalIncurred;
    totalPaid;
};
__decorate([
    FieldData({ recordTypeId, label: 'Claim ID', fieldType: 'uuid' })
], Claim.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Claim.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Claim.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Claim.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], Claim.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Claim.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], Claim.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Claim.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], Claim.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], Claim.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Claim.prototype, "claimNumber", void 0);
__decorate([
    FieldData({
        recordTypeId,
        codeList: claimStatusList
    })
], Claim.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Claimant', jsonStorage: true })
], Claim.prototype, "claimant", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], Claim.prototype, "lossDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], Claim.prototype, "reportDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], Claim.prototype, "dateOpened", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'bodyParts', jsonStorage: true })
], Claim.prototype, "bodyPart", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Claim.prototype, "adjuster", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'causes', jsonStorage: true })
], Claim.prototype, "cause", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'codelist',
        codeSet: 'naturesOfInjury',
        jsonStorage: true,
    })
], Claim.prototype, "nature", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Claim.prototype, "claimDescription", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Claim.prototype, "claimType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], Claim.prototype, "closeDate", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'ClaimReserve',
        isList: true,
        jsonStorage: true,
    })
], Claim.prototype, "reserves", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'ClaimPayment',
        isList: true,
        jsonStorage: true,
    })
], Claim.prototype, "payments", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Claim.prototype, "policyId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Policy', jsonStorage: true })
], Claim.prototype, "policy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Claim.prototype, "totalIncurred", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Claim.prototype, "totalPaid", void 0);
Claim = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Claim',
        displayNamePlural: 'Claims',
        supports: {
            views: true,
            files: true,
            globalSearch: true,
        },
        dataModelCustomization: {
            allowCustomFields: true,
        },
        trailMapTypes: [TrailMapType.HomePage, TrailMapType.Detail, TrailMapType.DataEntry],
        lookupProperties: { resultProperties: ['claimNumber'] },
        roleCategory: RoleCategories.Claims,
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'claimNumber',
                filterType: FilterType.Equals,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'claimant.lastName',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'policy.policyNumber',
                filterType: FilterType.Equals,
            },
        ],
        usesNewDataModel: true,
    })
], Claim);
export { Claim };
