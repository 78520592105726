<div class="flex flex-col w-full h-screen px-4">
  <!-- Header -->
  <div class="relative w-full h-auto">
    <div class="flex items-center w-full pt-6 pb-4">
      <div class="flex flex-grow text-xl font-semibold tracking-tight leading-8 pl-2">
        Record History
      </div>
      <div class="flex flex-grow justify-end gap-1">
        <button
          *ngIf="this.loaded$ | async"
          mat-icon-button
          aria-label="Export results"
          matTooltip="Export results"
          [swIsLoading]="this.EXPORT_KEY"
          [disabled]="this.EXPORT_KEY | swIsLoading | async"
          (click)="export()">
          <mat-icon svgIcon="feather:download"></mat-icon>
        </button>
        <button *ngIf="!hideButton" mat-icon-button [matTooltip]="'Close'" (click)="close()">
          <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- Body -->
  <div class="overflow-y-scroll">
    <div class="m-2" *ngIf="this.error$ | async as error; else body">
      <fuse-alert [type]="'error'">
        <span fuseAlertTitle>An Error Occurred</span>
        {{ this.error }}
      </fuse-alert>
    </div>
  </div>
</div>

<ng-template #body>
  <div class="w-full flex flex-col mb-4 px-4" [swIsLoading]="this.LOADING_KEY" swLg>
    <ng-container *ngIf="this.recordHistory$ | async as history">
      <tb-data-filter
        [recordType]="'RecordHistory'"
        [isCollapsible]="true"
        [rootFieldsOnly]="true"
        [disableAddFilter]="true"
        [expanded]="false"></tb-data-filter>
      <ng-container *ngIf="history.length; else noActivity">
        <div>
          <button
            *ngIf="this.anyDetails$ | async"
            mat-button
            [matTooltip]="(this.expandAll ? 'Collapse' : 'Expand') + ' All Change Details'"
            (click)="toggleExpanded()">
            <span class="text-secondary uppercase text-sm"
              >{{ this.expandAll ? 'Collapse' : 'Expand' }} All</span
            >
          </button>
        </div>
        <ol>
          <ng-container
            *ngFor="let historyItem of history; let i = index; let first = first; let last = last"
            ngForTrackById>
            <!-- Date separator -->
            <ng-container *ngIf="first || !isSameDay(historyItem.ts!, history[i - 1].ts!)">
              <li class="relative flex py-4">
                <div
                  class="relative py-2 px-8 text-md font-medium leading-5 rounded-full bg-primary text-on-primary">
                  {{ getFormatDistance(historyItem.ts!) | titlecase }}
                </div>
              </li>
            </ng-container>
            <!-- Activity -->
            <li class="relative flex py-4">
              <!-- Line -->
              <ng-container *ngIf="!last && isSameDay(historyItem.ts!, history[i + 1].ts!)">
                <div
                  class="absolute top-7 left-5 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600"></div>
              </ng-container>
              <tb-record-history-item [item]="historyItem"></tb-record-history-item>
            </li>
          </ng-container>
        </ol>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #noActivity> No record history available... </ng-template>
