var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { WorkflowStatusEnum, } from '../../../actions/models';
import { FilterType } from '../../../data-filters/models';
import { RecordTypeData, FieldData } from '../../meta-data-decorators';
const recordTypeId = 'WorkflowRunHistory';
export var WorkflowHistoryTriggerMode;
(function (WorkflowHistoryTriggerMode) {
    WorkflowHistoryTriggerMode["event"] = "event";
    WorkflowHistoryTriggerMode["test"] = "test";
    WorkflowHistoryTriggerMode["manual"] = "manual";
    WorkflowHistoryTriggerMode["schedule"] = "schedule";
})(WorkflowHistoryTriggerMode || (WorkflowHistoryTriggerMode = {}));
let WorkflowRunHistory = class WorkflowRunHistory {
    id;
    workflowId;
    workflow;
    initialUTC;
    status;
    lastStatusUTC;
    changeDetails;
    statusDetail;
    recordType;
    recordId;
    triggerMode;
};
__decorate([
    FieldData({ recordTypeId, label: 'ID', fieldType: 'uuid' })
], WorkflowRunHistory.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Workflow Id', fieldType: 'uuid', referenceType: 'Workflow' })
], WorkflowRunHistory.prototype, "workflowId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Workflow', fieldType: 'Workflow' })
], WorkflowRunHistory.prototype, "workflow", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Initial Timestamp', fieldType: 'datetime' })
], WorkflowRunHistory.prototype, "initialUTC", void 0);
__decorate([
    FieldData({
        recordTypeId, codeList: [
            { code: WorkflowStatusEnum.Pending, description: 'Pending' },
            { code: WorkflowStatusEnum.Queued, description: 'Queued' },
            { code: WorkflowStatusEnum.Running, description: 'Running' },
            { code: WorkflowStatusEnum.Failed, description: 'Failed' },
            { code: WorkflowStatusEnum.Completed, description: 'Completed' }
        ]
    })
], WorkflowRunHistory.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Status Timestamp', fieldType: 'datetime' })
], WorkflowRunHistory.prototype, "lastStatusUTC", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, disableField: true })
], WorkflowRunHistory.prototype, "changeDetails", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, disableField: true })
], WorkflowRunHistory.prototype, "statusDetail", void 0);
__decorate([
    FieldData({ recordTypeId })
], WorkflowRunHistory.prototype, "recordType", void 0);
__decorate([
    FieldData({ recordTypeId })
], WorkflowRunHistory.prototype, "recordId", void 0);
__decorate([
    FieldData({ recordTypeId, codeEnum: WorkflowHistoryTriggerMode, label: 'Triggered By' })
], WorkflowRunHistory.prototype, "triggerMode", void 0);
WorkflowRunHistory = __decorate([
    RecordTypeData({
        usesNewDataModel: true,
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Worfklow Run History',
        displayNamePlural: 'Workflow Run History',
        dataModelCustomization: {
            hideFromAdmin: true
        },
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'status',
                filterType: FilterType.Equals,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'triggerMode',
                filterType: FilterType.Equals
            }
        ],
    })
], WorkflowRunHistory);
export { WorkflowRunHistory };
