export function toNumber(input: string | number | null | undefined): number | null | undefined {
  if (input === undefined) {
    return undefined;
  }
  if (input === null) {
    return null;
  }
  if (typeof input === 'number') {
    return input;
  }
  return Number(input.replace(/[^0-9.-]+/g, ''));
}
