var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'ClaimPayment';
let ClaimPayment = class ClaimPayment {
    id;
    paymentDate;
    amount;
    paymentType;
    payee;
    reserveCode;
    paymentCode;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ClaimPayment.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], ClaimPayment.prototype, "paymentDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], ClaimPayment.prototype, "amount", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimPayment.prototype, "paymentType", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimPayment.prototype, "payee", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimPayment.prototype, "reserveCode", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimPayment.prototype, "paymentCode", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], ClaimPayment.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], ClaimPayment.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], ClaimPayment.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], ClaimPayment.prototype, "modifiedUTC", void 0);
ClaimPayment = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        displayNameSingular: 'Claim Payment',
        displayNamePlural: 'Claim Payments',
        dataModelCustomization: {
            allowCustomFields: true
        },
    })
], ClaimPayment);
export { ClaimPayment };
