<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative">
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>Signed in as</span>
      <span class="mt-1.5 text-md font-medium break-words">{{
        user?.fullName ?? user?.email ?? user?.userId
      }}</span>
    </span>
    <span class="hidden">{{ user?.userId }}</span>
  </button>
  <mat-divider class="my-1"></mat-divider>
  <button mat-menu-item (click)="editProfile()">
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span>Profile</span>
  </button>
  <mat-divider class="my-1"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>
