var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ScheduleJobTypes } from '../../scheduled-jobs/models';
import { RecordTypeData, FieldData } from '../meta-data-decorators';
const recordTypeId = 'ScheduledJob';
let ScheduledJob = class ScheduledJob {
    id;
    jobType;
    parentId;
    runOnce;
    effectiveUTC;
    runUntil;
    cron;
    lastTriggeredUTC;
    tenant;
    config;
    createUser;
    createUserId;
    createdUTC;
    modifiedUser;
    modifiedUserId;
    modifiedUTC;
    deleteUser;
    deleteUserId;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ScheduledJob.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, codeEnum: ScheduleJobTypes })
], ScheduledJob.prototype, "jobType", void 0);
__decorate([
    FieldData({ recordTypeId })
], ScheduledJob.prototype, "parentId", void 0);
__decorate([
    FieldData({ recordTypeId })
], ScheduledJob.prototype, "runOnce", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Effective Timestamp', fieldType: 'datetime' })
], ScheduledJob.prototype, "effectiveUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], ScheduledJob.prototype, "runUntil", void 0);
__decorate([
    FieldData({ recordTypeId })
], ScheduledJob.prototype, "cron", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Last Triggered', fieldType: 'datetime' })
], ScheduledJob.prototype, "lastTriggeredUTC", void 0);
__decorate([
    FieldData({ recordTypeId, disableField: true })
], ScheduledJob.prototype, "tenant", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], ScheduledJob.prototype, "config", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], ScheduledJob.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], ScheduledJob.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], ScheduledJob.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], ScheduledJob.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], ScheduledJob.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], ScheduledJob.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], ScheduledJob.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], ScheduledJob.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], ScheduledJob.prototype, "deletedUTC", void 0);
ScheduledJob = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        dataModelCustomization: {
            hideFromAdmin: true
        },
        hasBackingTable: true,
        displayNameSingular: 'Scheduled Job',
        displayNamePlural: 'Scheduled Jobs',
    })
], ScheduledJob);
export { ScheduledJob };
