var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'PolicyCommissionSetting';
let PolicyCommissionSetting = class PolicyCommissionSetting {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    agentId;
    agent;
    agencyId;
    agency;
    policyId;
    policy;
    defaultRenewalCommission;
    defaultNewBusinessCommission;
    effectiveDate;
    expirationDate;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyCommissionSetting.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyCommissionSetting.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyCommissionSetting.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyCommissionSetting.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created By',
        fieldType: 'User',
        jsonStorage: true,
    })
], PolicyCommissionSetting.prototype, "createUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created On',
        fieldType: 'datetime',
    })
], PolicyCommissionSetting.prototype, "createdUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified By',
        fieldType: 'User',
        jsonStorage: true,
    })
], PolicyCommissionSetting.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified On',
        fieldType: 'datetime',
    })
], PolicyCommissionSetting.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted By',
        fieldType: 'User',
        jsonStorage: true,
    })
], PolicyCommissionSetting.prototype, "deleteUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], PolicyCommissionSetting.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: false, referenceType: 'Agent' })
], PolicyCommissionSetting.prototype, "agentId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Agent', jsonStorage: false })
], PolicyCommissionSetting.prototype, "agent", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: false, referenceType: 'Agency' })
], PolicyCommissionSetting.prototype, "agencyId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Agent', jsonStorage: false })
], PolicyCommissionSetting.prototype, "agency", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: false, referenceType: 'Policy' })
], PolicyCommissionSetting.prototype, "policyId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Policy', jsonStorage: false })
], PolicyCommissionSetting.prototype, "policy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', jsonStorage: false })
], PolicyCommissionSetting.prototype, "defaultRenewalCommission", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', jsonStorage: false })
], PolicyCommissionSetting.prototype, "defaultNewBusinessCommission", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Effective Date',
        fieldType: 'datetime',
    })
], PolicyCommissionSetting.prototype, "effectiveDate", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Expiration Date',
        fieldType: 'datetime',
    })
], PolicyCommissionSetting.prototype, "expirationDate", void 0);
PolicyCommissionSetting = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Policy Commission Setting',
        displayNamePlural: 'Policy Commission Settings',
        dataModelCustomization: {
            allowCustomFields: true,
        },
        supports: {
            views: true,
        },
        usesNewDataModel: true,
        workflow: { enabled: true, generalEvents: true },
    })
], PolicyCommissionSetting);
export { PolicyCommissionSetting };
