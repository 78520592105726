import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'notNullorEmpty', pure: true })
export class NotNullorEmptyPipe implements PipeTransform {
  transform(input: any): boolean {
    if (typeof input === 'string') {
      return !(input === undefined || input === null || input.trim() === '');
    }

    return !(input === undefined || input === null);
  }
}

@Pipe({ name: 'nullorEmpty', pure: true })
export class NullorEmptyPipe implements PipeTransform {
  transform(input: any): boolean {
    if (typeof input === 'string') {
      return input === undefined || input === null || input.trim() === '';
    }

    return input === undefined || input === null;
  }
}
