import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavButtonComponent } from './components/side-nav-button/side-nav-button.component';
import { SideNavMenuComponent } from './components/side-nav-menu/side-nav-menu.component';
import { SharedModule } from 'app/shared/shared.module';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [SideNavButtonComponent, SideNavMenuComponent],
  imports: [CommonModule, SharedModule, FuseNavigationModule, MatIconModule],
  exports: [SideNavButtonComponent],
})
export class SideNavMenuModule {}
