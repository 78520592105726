import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataFilterStore } from '../../services/data-filter.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'tb-data-filter-quick-search',
  templateUrl: './data-filter-quick-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataFilterQuickSearchComponent implements OnInit {
  @Input() formGroup: FormGroup;

  public store = inject(DataFilterStore);

  disableBtns$ = new BehaviorSubject(true);

  @HostBinding('className') classBinding = 'w-full flex flex-row items-center mt-1 ml-1';

  ngOnInit(): void {
    this.store.clearQuickFilterFields$
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.formGroup.reset();
        }),
      )
      .subscribe();


    combineLatest([
      this.store.quickSearchFilters$,
      this.formGroup.valueChanges
    ]).pipe(
      untilDestroyed(this),
      tap(([filters, values]) => {
        const searchInputs = filters && filters.length > 0;
        const formInputs = Object.keys(values).find((f) => !!values[f]);
        this.disableBtns$.next(!searchInputs && !formInputs);
      }),
    )
      .subscribe();
  }

  doSearch() {
    this.store.applyQuickSearch(this.formGroup);
  }
}
