var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'InvoiceAppliedPayment';
let InvoiceAppliedPayment = class InvoiceAppliedPayment {
    //pk
    id;
    invoiceId;
    invoice;
    sourcePaymentId;
    sourcePayment;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    dateApplied;
    amount;
    invoiceLineItemId;
    invoiceLineItem;
    scenarioFlag;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], InvoiceAppliedPayment.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Invoice' })
], InvoiceAppliedPayment.prototype, "invoiceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Invoice' })
], InvoiceAppliedPayment.prototype, "invoice", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'PolicyPayment' })
], InvoiceAppliedPayment.prototype, "sourcePaymentId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyPayment' })
], InvoiceAppliedPayment.prototype, "sourcePayment", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], InvoiceAppliedPayment.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], InvoiceAppliedPayment.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], InvoiceAppliedPayment.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], InvoiceAppliedPayment.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], InvoiceAppliedPayment.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], InvoiceAppliedPayment.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], InvoiceAppliedPayment.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], InvoiceAppliedPayment.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], InvoiceAppliedPayment.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Date Applied', fieldType: 'datetime' })
], InvoiceAppliedPayment.prototype, "dateApplied", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], InvoiceAppliedPayment.prototype, "amount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'InvoiceLine' })
], InvoiceAppliedPayment.prototype, "invoiceLineItemId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'InvoiceLine' })
], InvoiceAppliedPayment.prototype, "invoiceLineItem", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], InvoiceAppliedPayment.prototype, "scenarioFlag", void 0);
InvoiceAppliedPayment = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        supports: {
            views: true
        },
        displayNameSingular: 'Invoice Applied Payment',
        displayNamePlural: 'Invoice Applied Payments'
    })
], InvoiceAppliedPayment);
export { InvoiceAppliedPayment };
