var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
export const DataImportStates = {
    pending: 'PENDING',
    running: 'RUNNING',
    success: 'SUCCESS',
    warning: 'WARNING',
    errors: 'ERRORS',
    cancelled: 'CANCELLED',
};
const recordTypeId = 'DataImportHistory';
let DataImportHistory = class DataImportHistory {
    id;
    createUserId;
    createUser;
    createdUTC;
    modifiedUTC;
    recordType;
    status;
    total;
    processed;
    success;
    failed;
    inserted;
    updated;
    fileName;
    errors;
};
__decorate([
    FieldData({ recordTypeId, label: 'ID', fieldType: 'uuid' })
], DataImportHistory.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], DataImportHistory.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], DataImportHistory.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], DataImportHistory.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], DataImportHistory.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId })
], DataImportHistory.prototype, "recordType", void 0);
__decorate([
    FieldData({ recordTypeId, codeEnum: DataImportStates })
], DataImportHistory.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], DataImportHistory.prototype, "total", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], DataImportHistory.prototype, "processed", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], DataImportHistory.prototype, "success", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], DataImportHistory.prototype, "failed", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], DataImportHistory.prototype, "inserted", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], DataImportHistory.prototype, "updated", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], DataImportHistory.prototype, "fileName", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true })
], DataImportHistory.prototype, "errors", void 0);
DataImportHistory = __decorate([
    RecordTypeData({
        usesNewDataModel: true,
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Data Import History',
        displayNamePlural: 'Data Import History',
        dataModelCustomization: {
            hideFromAdmin: true
        }
    })
], DataImportHistory);
export { DataImportHistory };
const chunkRecordTypeId = 'DataImportHistoryPart';
let DataImportHistoryPart = class DataImportHistoryPart {
    id;
    dataImportHistoryId;
    createdUTC;
    modifiedUTC;
    status;
    total;
    processed;
    success;
    failed;
    errors;
};
__decorate([
    FieldData({ recordTypeId, label: 'ID', fieldType: 'uuid' })
], DataImportHistoryPart.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, referenceType: 'DataImportHistory', fieldType: 'uuid' })
], DataImportHistoryPart.prototype, "dataImportHistoryId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], DataImportHistoryPart.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], DataImportHistoryPart.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, codeEnum: DataImportStates })
], DataImportHistoryPart.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], DataImportHistoryPart.prototype, "total", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], DataImportHistoryPart.prototype, "processed", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], DataImportHistoryPart.prototype, "success", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], DataImportHistoryPart.prototype, "failed", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true })
], DataImportHistoryPart.prototype, "errors", void 0);
DataImportHistoryPart = __decorate([
    RecordTypeData({
        usesNewDataModel: true,
        id: chunkRecordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Data Import History Part',
        displayNamePlural: 'Data Import History Parts',
        dataModelCustomization: {
            hideFromAdmin: true
        }
    })
], DataImportHistoryPart);
export { DataImportHistoryPart };
