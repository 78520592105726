var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'PolicyAuditLineItem';
let PolicyAuditLineItem = class PolicyAuditLineItem {
    classCode;
    state;
    rate;
    payroll;
    effectiveDate;
    // @FieldData({ recordTypeId, fieldType: 'date' }) expirationDate?: string;
    worksheetFactor;
    employees;
    location;
    actualPayroll;
    total;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'ClassCode' })
], PolicyAuditLineItem.prototype, "classCode", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'State',
        fieldType: 'codelist',
        codeSet: 'states',
    })
], PolicyAuditLineItem.prototype, "state", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Rate', fieldType: 'decimal' })
], PolicyAuditLineItem.prototype, "rate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Payroll', fieldType: 'currency' })
], PolicyAuditLineItem.prototype, "payroll", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], PolicyAuditLineItem.prototype, "effectiveDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal' })
], PolicyAuditLineItem.prototype, "worksheetFactor", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], PolicyAuditLineItem.prototype, "employees", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Location' })
], PolicyAuditLineItem.prototype, "location", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], PolicyAuditLineItem.prototype, "actualPayroll", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], PolicyAuditLineItem.prototype, "total", void 0);
PolicyAuditLineItem = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        dataModelCustomization: {
            hideFromAdmin: true,
            allowCustomFields: false,
            preventAddCustomFields: true,
        },
        displayNameSingular: 'Policy Audit Line Item',
        displayNamePlural: 'Policy Audit Line Items',
    })
], PolicyAuditLineItem);
export { PolicyAuditLineItem };
