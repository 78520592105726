import { AuthService } from '../core/auth/auth.service';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private defaultTitle = 'Trailblazer Portal';
  private titleSetExplicitly = false;

  constructor(private titleService: Title, private authService: AuthService) {}

  public setTitle(newTitle?: string): void {
    this.titleSetExplicitly = true;
    const title = newTitle
      ? `${
          this.defaultTitle
        } - ${newTitle} - ${this.authService.getTenant()!} ${this.environmentSuffix()}`
      : this.defaultTitle;
    this.titleService.setTitle(title);
  }

  public resetTitleFlag(): void {
    this.titleSetExplicitly = false;
  }
  public environmentSuffix(): string {
    if (!environment.production) {
      return ' - nonProd';
    }
    return '';
  }

  public ensureTitleAfterNavigation(): void {
    setTimeout(() => {
      if (!this.titleSetExplicitly) {
        this.titleService.setTitle(
          `${this.defaultTitle} - ${this.authService.getTenant()!} ${this.environmentSuffix()}`,
        );
      }
    }, 0); // Minimal delay to allow synchronous title settings to occur first
  }
}
