var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { TrailMapType } from '../../trail-maps/models';
const recordTypeId = 'IncludedExcludedIndividual';
let IncludedExcludedIndividual = class IncludedExcludedIndividual {
    state;
    locationNumber;
    name;
    birthDate;
    title;
    ownerPercent;
    duties;
    includedExcluded;
    classCode;
    earnings;
};
__decorate([
    FieldData({
        recordTypeId,
        label: 'State',
        fieldType: 'codelist',
        codeSet: 'states',
    })
], IncludedExcludedIndividual.prototype, "state", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, label: 'Location Number' })
], IncludedExcludedIndividual.prototype, "locationNumber", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], IncludedExcludedIndividual.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, label: 'Date of Birth', fieldType: 'date' })
], IncludedExcludedIndividual.prototype, "birthDate", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, label: 'Title or Relationship' })
], IncludedExcludedIndividual.prototype, "title", void 0);
__decorate([
    FieldData({
        recordTypeId,
        jsonStorage: true,
        label: 'Ownership Percentage',
        fieldType: 'percentage-whole-number',
    })
], IncludedExcludedIndividual.prototype, "ownerPercent", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], IncludedExcludedIndividual.prototype, "duties", void 0);
__decorate([
    FieldData({
        recordTypeId,
        jsonStorage: true,
        label: 'Included or Excluded',
        fieldType: 'codelist',
        codeList: [
            { code: 'included', description: 'Included' },
            { code: 'excluded', description: 'Excluded' },
        ],
    })
], IncludedExcludedIndividual.prototype, "includedExcluded", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, label: 'Class Code', fieldType: 'ClassCode' })
], IncludedExcludedIndividual.prototype, "classCode", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'currency' })
], IncludedExcludedIndividual.prototype, "earnings", void 0);
IncludedExcludedIndividual = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        displayNameSingular: 'Included/Excluded Individual',
        displayNamePlural: 'Included/Excluded Individuals',
        supports: {
            views: false
        },
        dataModelCustomization: {
            allowCustomFields: false
        },
        trailMapTypes: [TrailMapType.QuickAdd],
        usesNewDataModel: true,
    })
], IncludedExcludedIndividual);
export { IncludedExcludedIndividual };
