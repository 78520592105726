var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { CommissionBasisType } from '../../commissions/models';
const recordTypeId = 'AgencyCommissionSetting';
let AgencyCommissionSetting = class AgencyCommissionSetting {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    agencyId;
    agency;
    defaultRenewalCommission;
    defaultNewBusinessCommission;
    commissionBasisType;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], AgencyCommissionSetting.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], AgencyCommissionSetting.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], AgencyCommissionSetting.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], AgencyCommissionSetting.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created By',
        fieldType: 'User',
        jsonStorage: true,
    })
], AgencyCommissionSetting.prototype, "createUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created On',
        fieldType: 'datetime',
    })
], AgencyCommissionSetting.prototype, "createdUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified By',
        fieldType: 'User',
        jsonStorage: true,
    })
], AgencyCommissionSetting.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified On',
        fieldType: 'datetime',
    })
], AgencyCommissionSetting.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted By',
        fieldType: 'User',
        jsonStorage: true,
    })
], AgencyCommissionSetting.prototype, "deleteUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], AgencyCommissionSetting.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: false, referenceType: 'Agency' })
], AgencyCommissionSetting.prototype, "agencyId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Agency', jsonStorage: false })
], AgencyCommissionSetting.prototype, "agency", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal' })
], AgencyCommissionSetting.prototype, "defaultRenewalCommission", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal' })
], AgencyCommissionSetting.prototype, "defaultNewBusinessCommission", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: false, codeEnum: CommissionBasisType })
], AgencyCommissionSetting.prototype, "commissionBasisType", void 0);
AgencyCommissionSetting = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Agency Commission Setting',
        displayNamePlural: 'Agency Commission Settings',
        dataModelCustomization: {
            allowCustomFields: true,
        },
        supports: {
            views: true,
        },
        usesNewDataModel: true,
        workflow: { enabled: true, generalEvents: true },
    })
], AgencyCommissionSetting);
export { AgencyCommissionSetting };
