import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Alert } from 'portal-commons/dist/alerts/models';
import { AlertsService } from './alerts.service';
import { FuseAlertService } from '@fuse/components/alert';

@Component({
  selector: 'tb-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsComponent implements OnInit {
  activeAlerts$: Observable<any[]>;

  constructor(private alertsService: AlertsService, private _fuseAlertService: FuseAlertService) {}

  deleteSubject = new Subject();
  filteredAlerts$: Observable<any[]>;

  ngOnInit(): void {
    this.activeAlerts$ = this.alertsService.activeAlerts$;
  }

  SaveAcknowledgement(event: any, alert: Alert) {
    // console.log('saveAcknowledgement', event, alert);
    alert.requiresManualDismissal = false;
    (alert as any).dismissed = true;
    this.alertsService.acknowledgeAlert(alert.id!);
  }

  dismissAction(dismissed: boolean, id: string) {
    // console.log('dismissAction', dismissed, id);
  }
}
