export var KpiType;
(function (KpiType) {
    KpiType["timeSeriesLineSummary"] = "timeSeriesLineSummary";
    KpiType["ratio"] = "ratio";
    KpiType["comparativeRatio"] = "comparativeRatio";
    KpiType["aggregateFigure"] = "aggregateFigure";
})(KpiType || (KpiType = {}));
export var DateRange;
(function (DateRange) {
    DateRange["QTD"] = "qtd";
    DateRange["MTD"] = "mtd";
    DateRange["YTD"] = "ytd";
    DateRange["LM"] = "lm";
    DateRange["LQ"] = "lq";
    DateRange["LY"] = "ly";
    DateRange["CUSTOM"] = "custom";
})(DateRange || (DateRange = {}));
export var FilterType;
(function (FilterType) {
    FilterType["agency"] = "agency";
})(FilterType || (FilterType = {}));
