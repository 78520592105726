import { sortBy, startCase } from 'lodash-es';

export function convertEnumToSet(enumType: object): { id: string; displayName: string }[] {
  const set: { id: string; displayName: string }[] = [];

  for (const item of Object.values(enumType)) {
    if (!item) {
      continue;
    }
    const useVal: string = typeof item === 'string' ? item : item.toString();
    set.push({
      id: useVal,
      displayName:
        useVal === useVal.toUpperCase() ? startCase(useVal.toLowerCase()) : startCase(useVal),
    });
  }
  return sortBy(set, 'displayName');
}

export function getEnumDisplayValue(enumType: object, input: string | undefined | null) {
  if (!!!input) { return input; }
  for (const item of Object.values(enumType)) {
    if (!item) {
      continue;
    }
    const useVal: string = typeof item === 'string' ? item : item.toString();
    if (useVal !== input) { continue; }
    return useVal === useVal.toUpperCase() ? startCase(useVal.toLowerCase()) : startCase(useVal);
  }
}
