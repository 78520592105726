import { Observable, shareReplay, tap } from 'rxjs';

import { Agency } from 'portal-commons/dist/data-model/record-types/agency';
import { AgencyPreview } from 'portal-commons/dist/agencies/models';
import { Agent } from 'portal-commons/dist/data-model/record-types/agent';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedTableOptions } from 'portal-commons/dist/data-table/models';

@Injectable({
  providedIn: 'root',
})
export class AgencyService {
  private agency: Observable<Agency>;
  private agents: Observable<Agent[]>;
  private agencyPreviews: Observable<AgencyPreview[]>;
  constructor(private httpClient: HttpClient) {}

  getAgency(id: string, forceRefresh: boolean): Observable<Agency> {
    if (!this.agency || forceRefresh) {
      this.agency = this.httpClient.get<Agency>(`api/agencies/${id}`).pipe(
        tap((result) => {
          console.log('getAgency', result);
        }),
        shareReplay(1),
      );
    }
    return this.agency;
  }

  getAgencies(options: PaginatedTableOptions): Observable<Agency[]> {
    return this.httpClient
      .get<Agency[]>('api/agencies', {
        params: {
          options: JSON.stringify(options),
        },
      })
      .pipe(shareReplay(1));
  }

  getAgents(agencyId: string, forceRefresh: boolean): Observable<Agent[]> {
    if (!this.agents || forceRefresh) {
      this.agents = this.httpClient.get<Agent[]>(`api/agencies/${agencyId}/agents`).pipe(
        tap((result) => {
          console.log('getAgents', result);
        }),
        shareReplay(1),
      );
    }
    return this.agents;
  }

  getAgencyPreviews(forceRefresh: boolean): Observable<AgencyPreview[]> {
    if (!this.agencyPreviews || forceRefresh) {
      this.agencyPreviews = this.httpClient
        .get<AgencyPreview[]>('/api/agencypreviews')
        .pipe(shareReplay(1));
    }
    return this.agencyPreviews;
  }

  lookup = (filter: string): Observable<AgencyPreview[]> => {
    return this.httpClient
      .get<AgencyPreview[]>(`/api/agencies/lookup`, {
        params: {
          filter: filter,
        },
      })
      .pipe(shareReplay(1));
  };

  saveAgency(agencyInput: Partial<Agency>) {
    return this.httpClient.post<Agency>('/api/agencies', agencyInput).pipe(shareReplay(1));
  }

  stubAgency(name: string, agencyNumber: string) {
    const input: Partial<Agency> = {
      name: name,
      status: 'active',
      agencyNumber: agencyNumber,
      addresses: [],
      phones: [],
    };

    return this.httpClient.post<Agency>('/api/agencies', input).pipe(shareReplay(1));
  }
}
