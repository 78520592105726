var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ActionTypes } from '../../../actions/models';
import { FilterType } from '../../../data-filters/models';
import { RoleCategories } from '../../../roleEnums';
import { FieldData, RecordTypeData } from '../../meta-data-decorators';
const recordTypeId = 'Action';
let Action = class Action {
    id;
    name;
    description;
    isDisabled;
    sequence;
    actionType;
    filterConditionGroup;
    config;
    workflowId;
    workflow;
    createUser;
    createUserId;
    createdUTC;
    modifiedUser;
    modifiedUserId;
    modifiedUTC;
    deleteUser;
    deleteUserId;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Action.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], Action.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId })
], Action.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], Action.prototype, "isDisabled", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], Action.prototype, "sequence", void 0);
__decorate([
    FieldData({ recordTypeId, codeEnum: ActionTypes, label: 'Type' })
], Action.prototype, "actionType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Action.prototype, "filterConditionGroup", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Action.prototype, "config", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', label: 'Workflow Id', referenceType: 'Workflow' })
], Action.prototype, "workflowId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Workflow', label: 'Workflow' })
], Action.prototype, "workflow", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], Action.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], Action.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Action.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], Action.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], Action.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Action.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], Action.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], Action.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], Action.prototype, "deletedUTC", void 0);
Action = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        dataModelCustomization: {
            hideFromAdmin: true
        },
        hasBackingTable: true,
        displayNameSingular: 'Action',
        displayNamePlural: 'Actions',
        roleCategory: RoleCategories.Workflows,
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'name',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'actionType',
                filterType: FilterType.Equals,
            },
        ],
    })
], Action);
export { Action };
