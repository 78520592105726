import {
  ISWIsLoadingDirectiveConfig,
  IsLoadingModule,
  SW_IS_LOADING_DIRECTIVE_CONFIG,
} from '@service-work/is-loading';
import { RouterModule } from '@angular/router';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { DataVisualizationsModule } from './core/dataVisualizations/data-visualizations.module';
import { DragulaModule } from 'ng2-dragula';
import { FourOhFourComponent } from './shared/components/four-oh-four/four-oh-four.component';
import { FuseAlertModule } from '@fuse/components/alert';
import { FuseConfigModule } from '@fuse/services/config/config.module';
import { FuseModule } from '@fuse/fuse.module';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { InputMaskModule } from '@ngneat/input-mask';
import { LayoutModule } from './layout/layout.module';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgModule } from '@angular/core';
import { NgxCrumbsModule } from 'ngx-crumbs';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from './shared/shared.module';
import { TbToastComponent } from './core/notifications/toasts/tb-toast.component';
import { ToastrModule } from 'ngx-toastr';
import { appConfig } from './core/config/app.config';
import { environment } from '../environments/environment';
import { RecordHistoryModule } from './modules/record-history/record-history.module';
import { DocumentTemplatesModule } from './modules/document-templates/document-templates.module';
import { SideNavMenuModule } from './layout/common/side-nav-menu/side-nav-menu.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';


const myConfig: ISWIsLoadingDirectiveConfig = {
  // disable element while loading (default: true)
  disableEl: true,
  // the class used to indicate loading (default: "sw-is-loading")
  loadingClass: 'sw-is-loading',
  // should a spinner element be added to the dom
  // (default: varies --> true for button/anchor elements, false otherwise)
  addSpinnerEl: true,
};

@NgModule({
  declarations: [AppComponent, FourOhFourComponent, TbToastComponent],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    RouterModule,

    AppRoutingModule,

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    FuseAlertModule,

    LayoutModule,

    MatIconModule,
    MatProgressSpinnerModule,
    SharedModule,

    IsLoadingModule,
    NgxCrumbsModule,

    /* configure app with AmplifyUIAngularModule */
    AmplifyAuthenticatorModule,
    DataVisualizationsModule,
    DragulaModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.inAws,
      registrationStrategy: 'registerImmediately',
    }),
    GoogleTagManagerModule.forRoot({
      id: environment.firebase.measurementId,
      gtm_auth: environment.firebase.GTM_EnvironmentAuth,
      gtm_preview: environment.firebase.GTM_Preview,
    }),
    ToastrModule.forRoot({
      toastComponent: TbToastComponent,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      toastClass: 'tb-toastr',
      iconClasses: {
        error: 'tb-toast-error',
        info: 'tb-toast-info',
        success: 'tb-toast-success',
        warning: 'tb-toast-warning',
      },
    }),
    InputMaskModule,
    RecordHistoryModule,
    DocumentTemplatesModule,
    SideNavMenuModule,
    RecaptchaV3Module
  ],
  entryComponents: [TbToastComponent],
  providers: [
    { provide: SW_IS_LOADING_DIRECTIVE_CONFIG, useValue: myConfig },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.captcha }
  ],

  bootstrap: [AppComponent],
})
export class AppModule {
  /// ONLY UNCOMMENT LOCALLY, DO NOT PUT IN AWS
  // constructor(
  //   private readonly router: Router,
  // ) {
  //   if (environment.inAws === false) {
  //     router.events
  //       .subscribe(console.log);
  //   }
  // }
}
