var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../data-filters/models';
import { RoleCategories } from '../../roleEnums';
import { TrailMapType } from '../../trail-maps/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'Employer';
let Employer = class Employer {
    id;
    name;
    policyholderId;
    policyholder;
    address;
    phoneNumber;
    federalEin;
    email;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Employer.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], Employer.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'PolicyHolder' })
], Employer.prototype, "policyholderId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyHolder' })
], Employer.prototype, "policyholder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address', jsonStorage: true })
], Employer.prototype, "address", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Phone #', fieldType: 'phone-number' })
], Employer.prototype, "phoneNumber", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'FEIN' })
], Employer.prototype, "federalEin", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'email' })
], Employer.prototype, "email", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Employer.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Employer.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Employer.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], Employer.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Employer.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], Employer.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Employer.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], Employer.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], Employer.prototype, "deletedUTC", void 0);
Employer = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        roleCategory: RoleCategories.Employers,
        displayNameSingular: 'Employer',
        displayNamePlural: 'Employers',
        security: {
            useDefaultPersonaSecurity: true,
            allowCrossPolicyholderRecords: false
        },
        supports: {
            files: true,
            notes: true,
            views: true,
            docTemplates: true
        },
        trailMapTypes: [TrailMapType.Detail, TrailMapType.HomePage, TrailMapType.DataEntry, TrailMapType.QuickAdd],
        dataModelCustomization: {
            allowCustomFields: true
        },
        quickFilterFields: [
            { recordType: recordTypeId, fieldPath: 'name', filterType: FilterType.Contains },
            { recordType: recordTypeId, fieldPath: 'federalEin', filterType: FilterType.Equals },
        ],
        lookupProperties: { resultProperties: ['name'] },
    })
], Employer);
export { Employer };
