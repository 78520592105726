export const WsMessageTypes = {
    connected: 'CONNECTED',
    refreshAll: 'REFRESH_ALL',
    refreshRecord: 'REFRESH_RECORD',
    alertList: 'ALERT_LIST',
    bulkActionStatus: 'BULK_ACTION_STATUS',
    fileDownloadResult: 'FILE_DOWNLOAD_RESULT',
    messageList: 'MESSAGE_LIST',
    messageDelete: 'MESSAGE_DELETE',
    messageAdd: 'MESSAGE_ADD',
    messageUpdate: 'MESSAGE_UPDATE',
    notificationList: 'NOTIFICATION_LIST',
    notificationAdd: 'NOTIFICATION_ADD',
    notificationUpdate: 'NOTIFICATION_UPDATE',
    notificationDelete: 'NOTIFICATION_DELETE',
    refreshDataModel: 'REFRESH_DATAMODEL'
};
