import { Observable, shareReplay } from 'rxjs';
import { Kpi, KpiSummary, KpiType } from 'portal-commons/dist/analytics/models';
import { DateRange } from './models/dateRange';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KPIResult } from './models/kpiResult';
import { dataFilters } from '../../shared/models/data-filter';

@Injectable({
  providedIn: 'root',
})
export class DataVisualizationsService {
  constructor(private httpClient: HttpClient) {}

  getKpis(area: string, type: string) {
    return this.httpClient
      .get<KpiSummary[]>('/api/analytics/kpis', {
        params: {
          area: area,
          type: type,
        },
      })
      .pipe(shareReplay(1));
  }

  getKpi(id: string) {
    return this.httpClient.get<Kpi>(`/api/analytics/kpis/${id}`).pipe(shareReplay(1));
  }

  hasData(data: KPIResult): boolean {
    if (!data) {
      return false;
    }
    switch (data.kpiType) {
      case KpiType.timeSeriesLineSummary:
        return data.currentResults?.dataPoints?.yData?.length > 0;
      case KpiType.ratio:
        return data.currentResults?.dataPoints.numerator;
      case KpiType.comparativeRatio:
        return (
          data.currentResults?.dataPoints.numerator && data.previousResults?.dataPoints.numerator
        );
      case KpiType.aggregateFigure:
        return data.currentResults?.dataPoints.aggregateValue;
    }
    return false;
  }

  getKpiResult(
    kpiId: string,
    dateRange: DateRange,
    dataFilters: dataFilters[],
    customStartDate?: string,
    customEndDate?: string,
    model?: any,
  ): Observable<KPIResult> {
    return this.httpClient
      .post<KPIResult>('/api/analytics/kpiResult', {
        dateRange: dateRange,
        customStartDate: customStartDate,
        customEndDate: customEndDate,
        kpiId: kpiId,
        filters: dataFilters,
        modelId: model && model.id ? model.id : undefined,
      })
      .pipe(shareReplay(1));
  }
}
