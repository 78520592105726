var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../../data-filters/models';
import { RoleCategories } from '../../../roleEnums';
import { FieldData, RecordTypeData } from '../../meta-data-decorators';
const recordTypeId = 'LedgerAccountGroup';
let LedgerAccountGroup = class LedgerAccountGroup {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    code;
    description;
};
__decorate([
    FieldData({ recordTypeId, label: 'Ledger Account Group ID', fieldType: 'uuid' })
], LedgerAccountGroup.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], LedgerAccountGroup.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], LedgerAccountGroup.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], LedgerAccountGroup.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], LedgerAccountGroup.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], LedgerAccountGroup.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], LedgerAccountGroup.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], LedgerAccountGroup.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], LedgerAccountGroup.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], LedgerAccountGroup.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId })
], LedgerAccountGroup.prototype, "code", void 0);
__decorate([
    FieldData({ recordTypeId })
], LedgerAccountGroup.prototype, "description", void 0);
LedgerAccountGroup = __decorate([
    RecordTypeData({
        usesNewDataModel: true,
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Ledger Account Group',
        displayNamePlural: 'Ledger Account Groups',
        dataModelCustomization: {
            hideFromAdmin: true
        },
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'code',
                filterType: FilterType.StartsWith,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'description',
                filterType: FilterType.Contains,
            },
        ],
        roleCategory: RoleCategories.Accounting,
    })
], LedgerAccountGroup);
export { LedgerAccountGroup };
