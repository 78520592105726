import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  SecurityContext,
  inject,
} from '@angular/core';
import { DataModelStoreService } from 'app/core/data-model/services/data-model.store';
import { DataFormatService } from 'app/core/services/data-format.service';
import { RecordHistory } from 'portal-commons/dist/data-model/record-types/record-history';
import {
  RecordHistoryDetail,
  recordHistoryTypeDescription,
} from 'portal-commons/dist/record-history/models';
import { RecordHistoryDetailComponent } from '../record-history-detail/record-history-detail.component';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'tb-record-history-item',
  templateUrl: './record-history-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TitleCasePipe],
  styleUrls: ['./record-history-item.component.scss'],
})
export class RecordHistoryItemComponent implements OnInit {
  cdRef = inject(ChangeDetectorRef);
  titleCasePipe = inject(TitleCasePipe);
  dataModelStore = inject(DataModelStoreService);
  formatService = inject(DataFormatService);
  sanitizer = inject(DomSanitizer);

  @Input() item!: RecordHistory;
  expanded = false;

  constructor(private parent: RecordHistoryDetailComponent) {}

  ngOnInit(): void {
    if (this.parent) {
      this.parent.expandAll$
        .pipe(
          untilDestroyed(this),
          tap((val) => {
            if (!this.item.changeDetails) {
              return;
            }
            this.expanded = val;
            this.cdRef.markForCheck();
          }),
        )
        .subscribe();
    }
  }

  @HostBinding('class') get classList(): any {
    return {
      'w-full': true,
      expanded: this.expanded,
    };
  }

  getDescription(item: RecordHistory) {
    if (item.relatedRecordId && item.relatedRecordType) {
      const relatedName = this.dataModelStore.getRecordType(item.recordType)?.displayNameSingular;
      return `${this.getBoldHtmlText(
        `${relatedName} ${this.titleCasePipe.transform(item.changeType)!}`,
      )} by ${item.initiatedByUser?.fullName ?? 'System'}`;
    }

    return `${this.getBoldHtmlText(this.titleCasePipe.transform(item.changeType)!)} by ${
      item.initiatedByUser?.fullName ?? 'System'
    }`;
  }

  isSameDay(current: string, compare: string): boolean {
    return current.substring(0, 10) === compare.substring(0, 10);
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
    this.cdRef.markForCheck();
  }

  getValue(recordType: string, detail: RecordHistoryDetail): string | undefined {
    if (typeof detail.value === 'object') {
      return undefined;
    }
    const field = this.dataModelStore.getFieldFromPath(recordType, detail.field);
    if (!field) {
      return undefined;
    }
    if (detail.type === 'add') {
      if (detail.valueDescription) {
        return ` as ${this.getBoldHtmlText(detail.valueDescription)}`;
      }
      return ` as ${this.getBoldHtmlText(
        this.formatService.formatFieldValue(detail.value, field),
      )}`;
    }
    if (detail.type === 'update') {
      let updateDesc = '';
      if (detail.priorDescription) {
        updateDesc += ` from ${this.getBoldHtmlText(detail.priorDescription)}`;
      } else {
        updateDesc += ` from ${this.getBoldHtmlText(
          this.formatService.formatFieldValue(detail.prior, field),
        )}`;
      }
      if (detail.valueDescription) {
        updateDesc += ` to ${this.getBoldHtmlText(detail.valueDescription)}`;
      } else {
        updateDesc += ` to ${this.getBoldHtmlText(
          this.formatService.formatFieldValue(detail.value, field),
        )}`;
      }
      return updateDesc;
    }
    return undefined;
  }

  getBoldHtmlText(input: string) {
    return `<span class='font-semibold'>${this.sanitizer.sanitize(
      SecurityContext.HTML,
      input,
    )}</span>`;
  }

  getIcon() {
    if (this.item.typeIcon) {
      return this.item.typeIcon;
    }

    switch (this.item.changeType) {
      case 'CREATED':
        return 'feather:star';
      case 'DELETED':
        return 'mat_outline:delete';
    }
    return 'feather:refresh-cw';
  }

  getDetail() {
    if (
      !this.parent.recordType ||
      !this.item.changeDetails ||
      this.item.changeDetails.length === 0
    ) {
      return undefined;
    }
    const details: string[] = [];
    let itemChanges = this.item.changeDetails as any;
    if (typeof itemChanges === 'string') {
      itemChanges = JSON.parse(itemChanges) as RecordHistoryDetail[];
    }

    for (const detail of itemChanges as RecordHistoryDetail[]) {
      const verb = recordHistoryTypeDescription[detail.type];
      if (!verb) {
        continue;
      }
      const label = this.dataModelStore.getLabelFromPath(this.parent.recordType, detail.field);
      if (!label) {
        continue;
      }
      let desc = `${this.getBoldHtmlText(label)} ${verb}`;
      const val = this.getValue(this.parent.recordType, detail);
      if (val) {
        desc += `${val}`;
      }
      details.push(desc);
    }
    return details;
  }
}
