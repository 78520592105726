import {
  ControlValueAccessor,
  FormControlDirective,
  FormControlName,
  NgControl,
  NgModel,
} from '@angular/forms';

import { inject } from '@angular/core';

class NoopValueAccessor implements ControlValueAccessor {
  writeValue() {
    // Intentionally left empty
  }
  registerOnChange() {
    // Intentionally left empty
  }
  registerOnTouched() {
    // Intentionally left empty
  }
}

export function injectNgControl() {
  const ngControl = inject(NgControl, { self: true, optional: true });

  if (!ngControl) {
    throw new Error('...');
  }

  if (
    ngControl instanceof FormControlDirective ||
    ngControl instanceof FormControlName ||
    ngControl instanceof NgModel
  ) {
    // 👇👇👇
    ngControl.valueAccessor = new NoopValueAccessor();

    return ngControl;
  }

  throw new Error(`...`);
}
