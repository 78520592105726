var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../../data-filters/models';
import { RoleCategories } from '../../../roleEnums';
import { FieldData, RecordTypeData } from '../../meta-data-decorators';
const recordTypeId = 'LedgerAccount';
let LedgerAccount = class LedgerAccount {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    ledgerAccountGroupId;
    ledgerAccountGroup;
    code;
    description;
};
__decorate([
    FieldData({ recordTypeId, label: 'Ledger Account ID', fieldType: 'uuid' })
], LedgerAccount.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], LedgerAccount.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], LedgerAccount.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], LedgerAccount.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], LedgerAccount.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], LedgerAccount.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], LedgerAccount.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], LedgerAccount.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], LedgerAccount.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], LedgerAccount.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Ledger Account Group Id',
        referenceType: 'LedgerAccountGroup',
    })
], LedgerAccount.prototype, "ledgerAccountGroupId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'LedgerAccountGroup' })
], LedgerAccount.prototype, "ledgerAccountGroup", void 0);
__decorate([
    FieldData({ recordTypeId })
], LedgerAccount.prototype, "code", void 0);
__decorate([
    FieldData({ recordTypeId })
], LedgerAccount.prototype, "description", void 0);
LedgerAccount = __decorate([
    RecordTypeData({
        usesNewDataModel: true,
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Ledger Account',
        displayNamePlural: 'Ledger Accounts',
        dataModelCustomization: {
            hideFromAdmin: true
        },
        roleCategory: RoleCategories.Accounting,
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'code',
                filterType: FilterType.StartsWith,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'description',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'ledgerAccountGroup.description',
                filterType: FilterType.Contains,
            },
        ],
    })
], LedgerAccount);
export { LedgerAccount };
