var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { FilterType } from '../../data-filters/models';
const recordTypeId = 'DataModelRecordType';
export const DataModelScopes = {
    system: 'system',
    tenant: 'tenant'
};
let DataModelAdminRecordType = class DataModelAdminRecordType {
    id;
    alias;
    displayNameSingular;
    displayNamePlural;
    hasBackingTable;
    pendingDeployment;
    scope;
    modifiedUTC;
};
__decorate([
    FieldData({ recordTypeId, label: 'ID', fieldType: 'uuid' })
], DataModelAdminRecordType.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], DataModelAdminRecordType.prototype, "alias", void 0);
__decorate([
    FieldData({ recordTypeId })
], DataModelAdminRecordType.prototype, "displayNameSingular", void 0);
__decorate([
    FieldData({ recordTypeId })
], DataModelAdminRecordType.prototype, "displayNamePlural", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', label: 'Standalone Storage' })
], DataModelAdminRecordType.prototype, "hasBackingTable", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], DataModelAdminRecordType.prototype, "pendingDeployment", void 0);
__decorate([
    FieldData({ recordTypeId, codeEnum: DataModelScopes })
], DataModelAdminRecordType.prototype, "scope", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], DataModelAdminRecordType.prototype, "modifiedUTC", void 0);
DataModelAdminRecordType = __decorate([
    RecordTypeData({
        usesNewDataModel: false,
        id: recordTypeId,
        hasBackingTable: true,
        dataModelCustomization: {
            hideFromAdmin: true
        },
        displayNameSingular: 'Data Model Record Type',
        displayNamePlural: 'Data Model Record Types',
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'displayNameSingular',
                label: 'Display Name',
                filterType: FilterType.Contains
            },
            {
                recordType: recordTypeId,
                fieldPath: 'scope',
                filterType: FilterType.Equals
            },
            {
                recordType: recordTypeId,
                fieldPath: 'hasBackingTable',
                filterType: FilterType.Equals
            }
        ]
    })
], DataModelAdminRecordType);
export { DataModelAdminRecordType };
