import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, tap } from 'rxjs';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { DataModelStoreService } from './core/data-model/services/data-model.store';
import { CodesStoreService } from './core/codes/codes.store';

@Injectable({
  providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
  constructor(
    private _navigationService: NavigationService,
    private dataModelStore: DataModelStoreService,
    private codesStore: CodesStoreService,
  ) {
    console.log('InitialDataResolver.constructor()');
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    //PLACE ANY OBSERVABLES HERE THAT MUST COMPLETE BEFORE THE APP LOADS
    return combineLatest([
      this._navigationService.get().pipe(tap(() => console.log('RequiredResolvers-Nav completed'))),
      this.dataModelStore
        .loadRecordTypesAndFields()
        .pipe(tap(() => console.log('RequiredResolvers-DataModel completed'))),
    ]);
  }
}
