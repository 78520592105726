var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../data-filters/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
export var DocumentTemplateType;
(function (DocumentTemplateType) {
    DocumentTemplateType["docx"] = "docx";
    DocumentTemplateType["pdf"] = "pdf";
    DocumentTemplateType["packet"] = "packet";
})(DocumentTemplateType || (DocumentTemplateType = {}));
const recordTypeId = 'DocumentTemplate';
let DocumentTemplate = class DocumentTemplate {
    id;
    name;
    recordType;
    fileName;
    documentCategoryId;
    documentCategory;
    createUser;
    createUserId;
    createdUTC;
    modifiedUser;
    modifiedUserId;
    modifiedUTC;
    deleteUser;
    deleteUserId;
    deletedUTC;
    templateType;
    templateFields;
    script;
    sequence;
    testMode;
    filter;
    packetItems;
    doNotFlatten;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], DocumentTemplate.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], DocumentTemplate.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'recordTypeLookup' })
], DocumentTemplate.prototype, "recordType", void 0);
__decorate([
    FieldData({ recordTypeId })
], DocumentTemplate.prototype, "fileName", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', disableField: true })
], DocumentTemplate.prototype, "documentCategoryId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Document Category',
        fieldType: 'Code',
        codeSet: 'documentCategories',
    })
], DocumentTemplate.prototype, "documentCategory", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], DocumentTemplate.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], DocumentTemplate.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], DocumentTemplate.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], DocumentTemplate.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], DocumentTemplate.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], DocumentTemplate.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], DocumentTemplate.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], DocumentTemplate.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], DocumentTemplate.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId, codeList: [
            { code: DocumentTemplateType.docx, description: 'Docx' },
            { code: DocumentTemplateType.pdf, description: 'Pdf' },
            { code: DocumentTemplateType.packet, description: 'Packet' },
        ]
    })
], DocumentTemplate.prototype, "templateType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true })
], DocumentTemplate.prototype, "templateFields", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], DocumentTemplate.prototype, "script", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], DocumentTemplate.prototype, "sequence", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], DocumentTemplate.prototype, "testMode", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], DocumentTemplate.prototype, "filter", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], DocumentTemplate.prototype, "packetItems", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true })
], DocumentTemplate.prototype, "doNotFlatten", void 0);
DocumentTemplate = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        dataModelCustomization: {
            hideFromAdmin: true
        },
        hasBackingTable: true,
        displayNameSingular: 'Document Template',
        displayNamePlural: 'Document Templates',
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'name',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'templateType',
                filterType: FilterType.Equals,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'recordType',
                filterType: FilterType.Equals,
            },
        ],
    })
], DocumentTemplate);
export { DocumentTemplate };
