export var LineCalculationType;
(function (LineCalculationType) {
    LineCalculationType["Manual_Entry"] = "Manual Entry";
    LineCalculationType["Formula_Calculation"] = "Formula Calculation";
})(LineCalculationType || (LineCalculationType = {}));
export var InvoiceStatusType;
(function (InvoiceStatusType) {
    InvoiceStatusType["submitted"] = "submitted";
    InvoiceStatusType["active"] = "active";
    InvoiceStatusType["inActive"] = "inactive";
    InvoiceStatusType["deleted"] = "deleted";
    InvoiceStatusType["draft"] = "draft";
    InvoiceStatusType["future"] = "future";
    InvoiceStatusType["paid"] = "paid";
    InvoiceStatusType["paymentPending"] = "paymentPending";
    InvoiceStatusType["paymentFailed"] = "paymentFailed";
    InvoiceStatusType["paymentReversed"] = "paymentReversed";
    InvoiceStatusType["inGrace"] = "inGrace";
    InvoiceStatusType["overDue"] = "overdue";
    InvoiceStatusType["void"] = "void";
    InvoiceStatusType["installmentPlan"] = "installment-plan";
})(InvoiceStatusType || (InvoiceStatusType = {}));
export const eligibleForPaymentStatuses = [
    InvoiceStatusType.active,
    InvoiceStatusType.inGrace,
    InvoiceStatusType.paymentFailed,
    InvoiceStatusType.overDue,
];
export const editableStatuses = [InvoiceStatusType.draft];
export var InvoiceTemplateSegmentType;
(function (InvoiceTemplateSegmentType) {
    InvoiceTemplateSegmentType["invoiceIdIncrement"] = "invoiceIdIncrement";
    InvoiceTemplateSegmentType["yearIncrement"] = "yearIncrement";
    InvoiceTemplateSegmentType["monthlyIncrement"] = "monthlyIncrement";
    InvoiceTemplateSegmentType["invoice"] = "invoice";
    InvoiceTemplateSegmentType["text"] = "text";
})(InvoiceTemplateSegmentType || (InvoiceTemplateSegmentType = {}));
export var InvoiceAppSettings;
(function (InvoiceAppSettings) {
    InvoiceAppSettings["PDFTemplateIdPath"] = "PDFTemplateIdPath";
})(InvoiceAppSettings || (InvoiceAppSettings = {}));
export const InvoiceAppSettingsCategory = 'invoices';
export const invoiceAdminSettingFilter = {
    category: 'Invoices',
    name: 'invoiceGeneration',
    subcategory: 'invoiceId',
};
export const BillingScheduleDueDateOptions = [
    { key: 'eom', value: 'End of month from Issue Date' },
    { key: '+10d', value: '10 days from Issue Date' },
    { key: '+15d', value: '15 days from Issue Date' },
    { key: '+30d', value: '30 days from Issue Date' },
    { key: '+45d', value: '45 days from Issue Date' },
    { key: '+60d', value: '60 days from Issue Date' },
    { key: '+90d', value: '90 days from Issue Date' },
    { key: '+1w', value: '1 week from Issue Date' },
    { key: '+2w', value: '1 weeks from Issue Date' },
    { key: '+1m', value: '1 month from Issue Date' },
    { key: 'day-1-month-after-issue', value: '1st day of month following Issue Date' },
    { key: 'day-2-month-after-issue', value: '2nd day of month following Issue Date' },
    { key: 'day-3-month-after-issue', value: '3rd day of month following Issue Date' },
    { key: 'day-4-month-after-issue', value: '4th day of month following Issue Date' },
    { key: 'day-5-month-after-issue', value: '5th day of month following Issue Date' },
    { key: 'day-6-month-after-issue', value: '6th day of month following Issue Date' },
    { key: 'day-7-month-after-issue', value: '7th day of month following Issue Date' },
    { key: 'day-8-month-after-issue', value: '8th day of month following Issue Date' },
    { key: 'day-9-month-after-issue', value: '9th day of month following Issue Date' },
    { key: 'day-10-month-after-issue', value: '10th day of month following Issue Date' },
    { key: 'day-11-month-after-issue', value: '11th day of month following Issue Date' },
    { key: 'day-12-month-after-issue', value: '12th day of month following Issue Date' },
    { key: 'day-13-month-after-issue', value: '13th day of month following Issue Date' },
    { key: 'day-14-month-after-issue', value: '14th day of month following Issue Date' },
    { key: 'day-15-month-after-issue', value: '15th day of month following Issue Date' },
    { key: 'day-16-month-after-issue', value: '16th day of month following Issue Date' },
    { key: 'day-17-month-after-issue', value: '17th day of month following Issue Date' },
    { key: 'day-18-month-after-issue', value: '18th day of month following Issue Date' },
    { key: 'day-19-month-after-issue', value: '19th day of month following Issue Date' },
    { key: 'day-20-month-after-issue', value: '20th day of month following Issue Date' },
    { key: 'day-21-month-after-issue', value: '21st day of month following Issue Date' },
    { key: 'day-22-month-after-issue', value: '22nd day of month following Issue Date' },
    { key: 'day-23-month-after-issue', value: '23rd day of month following Issue Date' },
    { key: 'day-24-month-after-issue', value: '24th day of month following Issue Date' },
    { key: 'day-25-month-after-issue', value: '25th day of month following Issue Date' },
    { key: 'day-26-month-after-issue', value: '26th day of month following Issue Date' },
    { key: 'day-27-month-after-issue', value: '27th day of month following Issue Date' },
    { key: 'day-28-month-after-issue', value: '28th day of month following Issue Date' },
    { key: 'day-last-month-after-issue', value: 'Last day of month following Issue Date' },
];
