const defaultMIMEType = 'application/octet-stream';
//Sourced from http://www.iana.org/assignments/media-types/media-types.xhtml on 12/6/23
export const mimeTypeList = {
    'application/1d-interleaved-parityfec': '1d-interleaved-parityfec',
    'application/3gpdash-qoe-report+xml': '3gpdash-qoe-report+xml',
    'application/3gppHal+json': '3gppHal+json',
    'application/3gppHalForms+json': '3gppHalForms+json',
    'application/3gpp-ims+xml': '3gpp-ims+xml',
    'application/A2L': 'A2L',
    'application/ace+cbor': 'ace+cbor',
    'application/ace+json': 'ace+json',
    'application/activemessage': 'activemessage',
    'application/activity+json': 'activity+json',
    'application/aif+cbor': 'aif+cbor',
    'application/aif+json': 'aif+json',
    'application/alto-cdni+json': 'alto-cdni+json',
    'application/alto-cdnifilter+json': 'alto-cdnifilter+json',
    'application/alto-costmap+json': 'alto-costmap+json',
    'application/alto-costmapfilter+json': 'alto-costmapfilter+json',
    'application/alto-directory+json': 'alto-directory+json',
    'application/alto-endpointprop+json': 'alto-endpointprop+json',
    'application/alto-endpointpropparams+json': 'alto-endpointpropparams+json',
    'application/alto-endpointcost+json': 'alto-endpointcost+json',
    'application/alto-endpointcostparams+json': 'alto-endpointcostparams+json',
    'application/alto-error+json': 'alto-error+json',
    'application/alto-networkmapfilter+json': 'alto-networkmapfilter+json',
    'application/alto-networkmap+json': 'alto-networkmap+json',
    'application/alto-propmap+json': 'alto-propmap+json',
    'application/alto-propmapparams+json': 'alto-propmapparams+json',
    'application/alto-updatestreamcontrol+json': 'alto-updatestreamcontrol+json',
    'application/alto-updatestreamparams+json': 'alto-updatestreamparams+json',
    'application/AML': 'AML',
    'application/andrew-inset': 'andrew-inset',
    'application/applefile': 'applefile',
    'application/at+jwt': 'at+jwt',
    'application/ATF': 'ATF',
    'application/ATFX': 'ATFX',
    'application/atom+xml': 'atom+xml',
    'application/atomcat+xml': 'atomcat+xml',
    'application/atomdeleted+xml': 'atomdeleted+xml',
    'application/atomicmail': 'atomicmail',
    'application/atomsvc+xml': 'atomsvc+xml',
    'application/atsc-dwd+xml': 'atsc-dwd+xml',
    'application/atsc-dynamic-event-message': 'atsc-dynamic-event-message',
    'application/atsc-held+xml': 'atsc-held+xml',
    'application/atsc-rdt+json': 'atsc-rdt+json',
    'application/atsc-rsat+xml': 'atsc-rsat+xml',
    'application/ATXML': 'ATXML',
    'application/auth-policy+xml': 'auth-policy+xml',
    'application/automationml-aml+xml': 'automationml-aml+xml',
    'application/automationml-amlx+zip': 'automationml-amlx+zip',
    'application/bacnet-xdd+zip': 'bacnet-xdd+zip',
    'application/batch-SMTP': 'batch-SMTP',
    'application/beep+xml': 'beep+xml',
    'application/c2pa': 'c2pa',
    'application/calendar+json': 'calendar+json',
    'application/calendar+xml': 'calendar+xml',
    'application/call-completion': 'call-completion',
    'application/CALS-1840': 'CALS-1840',
    'application/captive+json': 'captive+json',
    'application/cbor': 'cbor',
    'application/cbor-seq': 'cbor-seq',
    'application/cccex': 'cccex',
    'application/ccmp+xml': 'ccmp+xml',
    'application/ccxml+xml': 'ccxml+xml',
    'application/cda+xml': 'cda+xml',
    'application/CDFX+XML': 'CDFX+XML',
    'application/cdmi-capability': 'cdmi-capability',
    'application/cdmi-container': 'cdmi-container',
    'application/cdmi-domain': 'cdmi-domain',
    'application/cdmi-object': 'cdmi-object',
    'application/cdmi-queue': 'cdmi-queue',
    'application/cdni': 'cdni',
    'application/CEA': 'CEA',
    'application/cea-2018+xml': 'cea-2018+xml',
    'application/cellml+xml': 'cellml+xml',
    'application/cfw': 'cfw',
    'application/cid-edhoc+cbor-seq': 'cid-edhoc+cbor-seq',
    'application/city+json': 'city+json',
    'application/clr': 'clr',
    'application/clue_info+xml': 'clue_info+xml',
    'application/clue+xml': 'clue+xml',
    'application/cms': 'cms',
    'application/cnrp+xml': 'cnrp+xml',
    'application/coap-group+json': 'coap-group+json',
    'application/coap-payload': 'coap-payload',
    'application/commonground': 'commonground',
    'application/concise-problem-details+cbor': 'concise-problem-details+cbor',
    'application/conference-info+xml': 'conference-info+xml',
    'application/cpl+xml': 'cpl+xml',
    'application/cose': 'cose',
    'application/cose-key': 'cose-key',
    'application/cose-key-set': 'cose-key-set',
    'application/cose-x509': 'cose-x509',
    'application/csrattrs': 'csrattrs',
    'application/csta+xml': 'csta+xml',
    'application/CSTAdata+xml': 'CSTAdata+xml',
    'application/csvm+json': 'csvm+json',
    'application/cwl': 'cwl',
    'application/cwl+json': 'cwl+json',
    'application/cwt': 'cwt',
    'application/cybercash': 'cybercash',
    'application/dash+xml': 'dash+xml',
    'application/dash-patch+xml': 'dash-patch+xml',
    'application/dashdelta': 'dashdelta',
    'application/davmount+xml': 'davmount+xml',
    'application/dca-rft': 'dca-rft',
    'application/DCD': 'DCD',
    'application/dec-dx': 'dec-dx',
    'application/dialog-info+xml': 'dialog-info+xml',
    'application/dicom': 'dicom',
    'application/dicom+json': 'dicom+json',
    'application/dicom+xml': 'dicom+xml',
    'application/DII': 'DII',
    'application/DIT': 'DIT',
    'application/dns': 'dns',
    'application/dns+json': 'dns+json',
    'application/dns-message': 'dns-message',
    'application/dots+cbor': 'dots+cbor',
    'application/dpop+jwt': 'dpop+jwt',
    'application/dskpp+xml': 'dskpp+xml',
    'application/dssc+der': 'dssc+der',
    'application/dssc+xml': 'dssc+xml',
    'application/dvcs': 'dvcs',
    'application/ecmascript': 'ecmascript (OBSOLETED in favor of text/javascript)',
    'application/edhoc+cbor-seq': 'edhoc+cbor-seq',
    'application/EDI-consent': 'EDI-consent',
    'application/EDIFACT': 'EDIFACT',
    'application/EDI-X12': 'EDI-X12',
    'application/efi': 'efi',
    'application/elm+json': 'elm+json',
    'application/elm+xml': 'elm+xml',
    'application/EmergencyCallData.cap+xml': 'EmergencyCallData.cap+xml',
    'application/EmergencyCallData.Comment+xml': 'EmergencyCallData.Comment+xml',
    'application/EmergencyCallData.Control+xml': 'EmergencyCallData.Control+xml',
    'application/EmergencyCallData.DeviceInfo+xml': 'EmergencyCallData.DeviceInfo+xml',
    'application/EmergencyCallData.eCall.MSD': 'EmergencyCallData.eCall.MSD',
    'application/EmergencyCallData.LegacyESN+json': 'EmergencyCallData.LegacyESN+json',
    'application/EmergencyCallData.ProviderInfo+xml': 'EmergencyCallData.ProviderInfo+xml',
    'application/EmergencyCallData.ServiceInfo+xml': 'EmergencyCallData.ServiceInfo+xml',
    'application/EmergencyCallData.SubscriberInfo+xml': 'EmergencyCallData.SubscriberInfo+xml',
    'application/EmergencyCallData.VEDS+xml': 'EmergencyCallData.VEDS+xml',
    'application/emma+xml': 'emma+xml',
    'application/emotionml+xml': 'emotionml+xml',
    'application/encaprtp': 'encaprtp',
    'application/epp+xml': 'epp+xml',
    'application/epub+zip': 'epub+zip',
    'application/eshop': 'eshop',
    'application/example': 'example',
    'application/exi': 'exi',
    'application/expect-ct-report+json': 'expect-ct-report+json',
    'application/express': 'express',
    'application/fastinfoset': 'fastinfoset',
    'application/fastsoap': 'fastsoap',
    'application/fdf': 'fdf',
    'application/fdt+xml': 'fdt+xml',
    'application/fhir+json': 'fhir+json',
    'application/fhir+xml': 'fhir+xml',
    'application/fits': 'fits',
    'application/flexfec': 'flexfec',
    'application/font-sfnt': 'font-sfnt - DEPRECATED in favor of font/sfnt',
    'application/font-tdpfr': 'font-tdpfr',
    'application/font-woff': 'font-woff - DEPRECATED in favor of font/woff',
    'application/framework-attributes+xml': 'framework-attributes+xml',
    'application/geo+json': 'geo+json',
    'application/geo+json-seq': 'geo+json-seq',
    'application/geopackage+sqlite3': 'geopackage+sqlite3',
    'application/geoxacml+json': 'geoxacml+json',
    'application/geoxacml+xml': 'geoxacml+xml',
    'application/gltf-buffer': 'gltf-buffer',
    'application/gml+xml': 'gml+xml',
    'application/gzip': 'gzip',
    'application/H224': 'H224',
    'application/held+xml': 'held+xml',
    'application/hl7v2+xml': 'hl7v2+xml',
    'application/http': 'http',
    'application/hyperstudio': 'hyperstudio',
    'application/ibe-key-request+xml': 'ibe-key-request+xml',
    'application/ibe-pkg-reply+xml': 'ibe-pkg-reply+xml',
    'application/ibe-pp-data': 'ibe-pp-data',
    'application/iges': 'iges',
    'application/im-iscomposing+xml': 'im-iscomposing+xml',
    'application/index': 'index',
    'application/index.cmd': 'index.cmd',
    'application/index.obj': 'index.obj',
    'application/index.response': 'index.response',
    'application/index.vnd': 'index.vnd',
    'application/inkml+xml': 'inkml+xml',
    'application/IOTP': 'IOTP',
    'application/ipfix': 'ipfix',
    'application/ipp': 'ipp',
    'application/ISUP': 'ISUP',
    'application/its+xml': 'its+xml',
    'application/java-archive': 'java-archive',
    'application/javascript': 'javascript (OBSOLETED in favor of text/javascript)',
    'application/jf2feed+json': 'jf2feed+json',
    'application/jose': 'jose',
    'application/jose+json': 'jose+json',
    'application/jrd+json': 'jrd+json',
    'application/jscalendar+json': 'jscalendar+json',
    'application/jscontact+json': 'jscontact+json',
    'application/json': 'json',
    'application/json-patch+json': 'json-patch+json',
    'application/json-seq': 'json-seq',
    'application/jsonpath': 'jsonpath',
    'application/jwk+json': 'jwk+json',
    'application/jwk-set+json': 'jwk-set+json',
    'application/jwt': 'jwt',
    'application/kpml-request+xml': 'kpml-request+xml',
    'application/kpml-response+xml': 'kpml-response+xml',
    'application/ld+json': 'ld+json',
    'application/lgr+xml': 'lgr+xml',
    'application/link-format': 'link-format',
    'application/linkset': 'linkset',
    'application/linkset+json': 'linkset+json',
    'application/load-control+xml': 'load-control+xml',
    'application/logout+jwt': 'logout+jwt',
    'application/lost+xml': 'lost+xml',
    'application/lostsync+xml': 'lostsync+xml',
    'application/lpf+zip': 'lpf+zip',
    'application/LXF': 'LXF',
    'application/mac-binhex40': 'mac-binhex40',
    'application/macwriteii': 'macwriteii',
    'application/mads+xml': 'mads+xml',
    'application/manifest+json': 'manifest+json',
    'application/marc': 'marc',
    'application/marcxml+xml': 'marcxml+xml',
    'application/mathematica': 'mathematica',
    'application/mathml+xml': 'mathml+xml',
    'application/mathml-content+xml': 'mathml-content+xml',
    'application/mathml-presentation+xml': 'mathml-presentation+xml',
    'application/mbms-associated-procedure-description+xml': 'mbms-associated-procedure-description+xml',
    'application/mbms-deregister+xml': 'mbms-deregister+xml',
    'application/mbms-envelope+xml': 'mbms-envelope+xml',
    'application/mbms-msk-response+xml': 'mbms-msk-response+xml',
    'application/mbms-msk+xml': 'mbms-msk+xml',
    'application/mbms-protection-description+xml': 'mbms-protection-description+xml',
    'application/mbms-reception-report+xml': 'mbms-reception-report+xml',
    'application/mbms-register-response+xml': 'mbms-register-response+xml',
    'application/mbms-register+xml': 'mbms-register+xml',
    'application/mbms-schedule+xml': 'mbms-schedule+xml',
    'application/mbms-user-service-description+xml': 'mbms-user-service-description+xml',
    'application/mbox': 'mbox',
    'application/media_control+xml': 'media_control+xml',
    'application/media-policy-dataset+xml': 'media-policy-dataset+xml',
    'application/mediaservercontrol+xml': 'mediaservercontrol+xml',
    'application/merge-patch+json': 'merge-patch+json',
    'application/metalink4+xml': 'metalink4+xml',
    'application/mets+xml': 'mets+xml',
    'application/MF4': 'MF4',
    'application/mikey': 'mikey',
    'application/mipc': 'mipc',
    'application/missing-blocks+cbor-seq': 'missing-blocks+cbor-seq',
    'application/mmt-aei+xml': 'mmt-aei+xml',
    'application/mmt-usd+xml': 'mmt-usd+xml',
    'application/mods+xml': 'mods+xml',
    'application/moss-keys': 'moss-keys',
    'application/moss-signature': 'moss-signature',
    'application/mosskey-data': 'mosskey-data',
    'application/mosskey-request': 'mosskey-request',
    'application/mp21': 'mp21',
    'application/mp4': 'mp4',
    'application/mpeg4-generic': 'mpeg4-generic',
    'application/mpeg4-iod': 'mpeg4-iod',
    'application/mpeg4-iod-xmt': 'mpeg4-iod-xmt',
    'application/mrb-consumer+xml': 'mrb-consumer+xml',
    'application/mrb-publish+xml': 'mrb-publish+xml',
    'application/msc-ivr+xml': 'msc-ivr+xml',
    'application/msc-mixer+xml': 'msc-mixer+xml',
    'application/msword': 'msword',
    'application/mud+json': 'mud+json',
    'application/multipart-core': 'multipart-core',
    'application/mxf': 'mxf',
    'application/n-quads': 'n-quads',
    'application/n-triples': 'n-triples',
    'application/nasdata': 'nasdata',
    'application/news-checkgroups': 'news-checkgroups',
    'application/news-groupinfo': 'news-groupinfo',
    'application/news-transmission': 'news-transmission',
    'application/nlsml+xml': 'nlsml+xml',
    'application/node': 'node',
    'application/nss': 'nss',
    'application/oauth-authz-req+jwt': 'oauth-authz-req+jwt',
    'application/oblivious-dns-message': 'oblivious-dns-message',
    'application/ocsp-request': 'ocsp-request',
    'application/ocsp-response': 'ocsp-response',
    'application/octet-stream': 'octet-stream',
    'application/ODA': 'ODA',
    'application/odm+xml': 'odm+xml',
    'application/ODX': 'ODX',
    'application/oebps-package+xml': 'oebps-package+xml',
    'application/ogg': 'ogg',
    'application/ohttp-keys': 'ohttp-keys',
    'application/opc-nodeset+xml': 'opc-nodeset+xml',
    'application/oscore': 'oscore',
    'application/oxps': 'oxps',
    'application/p21': 'p21',
    'application/p21+zip': 'p21+zip',
    'application/p2p-overlay+xml': 'p2p-overlay+xml',
    'application/parityfec': 'parityfec',
    'application/passport': 'passport',
    'application/patch-ops-error+xml': 'patch-ops-error+xml',
    'application/pdf': 'pdf',
    'application/PDX': 'PDX',
    'application/pem-certificate-chain': 'pem-certificate-chain',
    'application/pgp-encrypted': 'pgp-encrypted',
    'application/pgp-keys': 'pgp-keys',
    'application/pgp-signature': 'pgp-signature',
    'application/pidf-diff+xml': 'pidf-diff+xml',
    'application/pidf+xml': 'pidf+xml',
    'application/pkcs10': 'pkcs10',
    'application/pkcs7-mime': 'pkcs7-mime',
    'application/pkcs7-signature': 'pkcs7-signature',
    'application/pkcs8': 'pkcs8',
    'application/pkcs8-encrypted': 'pkcs8-encrypted',
    'application/pkcs12': 'pkcs12',
    'application/pkix-attr-cert': 'pkix-attr-cert',
    'application/pkix-cert': 'pkix-cert',
    'application/pkix-crl': 'pkix-crl',
    'application/pkix-pkipath': 'pkix-pkipath',
    'application/pkixcmp': 'pkixcmp',
    'application/pls+xml': 'pls+xml',
    'application/poc-settings+xml': 'poc-settings+xml',
    'application/postscript': 'postscript',
    'application/ppsp-tracker+json': 'ppsp-tracker+json',
    'application/private-token-issuer-directory': 'private-token-issuer-directory',
    'application/private-token-request': 'private-token-request',
    'application/private-token-response': 'private-token-response',
    'application/problem+json': 'problem+json',
    'application/problem+xml': 'problem+xml',
    'application/provenance+xml': 'provenance+xml',
    'application/prs.alvestrand.titrax-sheet': 'prs.alvestrand.titrax-sheet',
    'application/prs.cww': 'prs.cww',
    'application/prs.cyn': 'prs.cyn',
    'application/prs.hpub+zip': 'prs.hpub+zip',
    'application/prs.implied-document+xml': 'prs.implied-document+xml',
    'application/prs.implied-executable': 'prs.implied-executable',
    'application/prs.implied-structure': 'prs.implied-structure',
    'application/prs.nprend': 'prs.nprend',
    'application/prs.plucker': 'prs.plucker',
    'application/prs.rdf-xml-crypt': 'prs.rdf-xml-crypt',
    'application/prs.vcfbzip2': 'prs.vcfbzip2',
    'application/prs.xsf+xml': 'prs.xsf+xml',
    'application/pskc+xml': 'pskc+xml',
    'application/pvd+json': 'pvd+json',
    'application/rdf+xml': 'rdf+xml',
    'application/route-apd+xml': 'route-apd+xml',
    'application/route-s-tsid+xml': 'route-s-tsid+xml',
    'application/route-usd+xml': 'route-usd+xml',
    'application/QSIG': 'QSIG',
    'application/raptorfec': 'raptorfec',
    'application/rdap+json': 'rdap+json',
    'application/reginfo+xml': 'reginfo+xml',
    'application/relax-ng-compact-syntax': 'relax-ng-compact-syntax',
    'application/remote-printing': 'remote-printing (OBSOLETE)',
    'application/reputon+json': 'reputon+json',
    'application/resource-lists-diff+xml': 'resource-lists-diff+xml',
    'application/resource-lists+xml': 'resource-lists+xml',
    'application/rfc+xml': 'rfc+xml',
    'application/riscos': 'riscos',
    'application/rlmi+xml': 'rlmi+xml',
    'application/rls-services+xml': 'rls-services+xml',
    'application/rpki-checklist': 'rpki-checklist',
    'application/rpki-ghostbusters': 'rpki-ghostbusters',
    'application/rpki-manifest': 'rpki-manifest',
    'application/rpki-publication': 'rpki-publication',
    'application/rpki-roa': 'rpki-roa',
    'application/rpki-updown': 'rpki-updown',
    'application/rtf': 'rtf',
    'application/rtploopback': 'rtploopback',
    'application/rtx': 'rtx',
    'application/samlassertion+xml': 'samlassertion+xml',
    'application/samlmetadata+xml': 'samlmetadata+xml',
    'application/sarif-external-properties+json': 'sarif-external-properties+json',
    'application/sarif+json': 'sarif+json',
    'application/sbe': 'sbe',
    'application/sbml+xml': 'sbml+xml',
    'application/scaip+xml': 'scaip+xml',
    'application/scim+json': 'scim+json',
    'application/scvp-cv-request': 'scvp-cv-request',
    'application/scvp-cv-response': 'scvp-cv-response',
    'application/scvp-vp-request': 'scvp-vp-request',
    'application/scvp-vp-response': 'scvp-vp-response',
    'application/sdp': 'sdp',
    'application/secevent+jwt': 'secevent+jwt',
    'application/senml-etch+cbor': 'senml-etch+cbor',
    'application/senml-etch+json': 'senml-etch+json',
    'application/senml-exi': 'senml-exi',
    'application/senml+cbor': 'senml+cbor',
    'application/senml+json': 'senml+json',
    'application/senml+xml': 'senml+xml',
    'application/sensml-exi': 'sensml-exi',
    'application/sensml+cbor': 'sensml+cbor',
    'application/sensml+json': 'sensml+json',
    'application/sensml+xml': 'sensml+xml',
    'application/sep-exi': 'sep-exi',
    'application/sep+xml': 'sep+xml',
    'application/session-info': 'session-info',
    'application/set-payment': 'set-payment',
    'application/set-payment-initiation': 'set-payment-initiation',
    'application/set-registration': 'set-registration',
    'application/set-registration-initiation': 'set-registration-initiation',
    'application/SGML': 'SGML',
    'application/sgml-open-catalog': 'sgml-open-catalog',
    'application/shf+xml': 'shf+xml',
    'application/sieve': 'sieve',
    'application/simple-filter+xml': 'simple-filter+xml',
    'application/simple-message-summary': 'simple-message-summary',
    'application/simpleSymbolContainer': 'simpleSymbolContainer',
    'application/sipc': 'sipc',
    'application/slate': 'slate',
    'application/smil': 'smil (OBSOLETED in favor of application/smil+xml)',
    'application/smil+xml': 'smil+xml',
    'application/smpte336m': 'smpte336m',
    'application/soap+fastinfoset': 'soap+fastinfoset',
    'application/soap+xml': 'soap+xml',
    'application/sparql-query': 'sparql-query',
    'application/spdx+json': 'spdx+json',
    'application/sparql-results+xml': 'sparql-results+xml',
    'application/spirits-event+xml': 'spirits-event+xml',
    'application/sql': 'sql',
    'application/srgs': 'srgs',
    'application/srgs+xml': 'srgs+xml',
    'application/sru+xml': 'sru+xml',
    'application/ssml+xml': 'ssml+xml',
    'application/stix+json': 'stix+json',
    'application/swid+cbor': 'swid+cbor',
    'application/swid+xml': 'swid+xml',
    'application/tamp-apex-update': 'tamp-apex-update',
    'application/tamp-apex-update-confirm': 'tamp-apex-update-confirm',
    'application/tamp-community-update': 'tamp-community-update',
    'application/tamp-community-update-confirm': 'tamp-community-update-confirm',
    'application/tamp-error': 'tamp-error',
    'application/tamp-sequence-adjust': 'tamp-sequence-adjust',
    'application/tamp-sequence-adjust-confirm': 'tamp-sequence-adjust-confirm',
    'application/tamp-status-query': 'tamp-status-query',
    'application/tamp-status-response': 'tamp-status-response',
    'application/tamp-update': 'tamp-update',
    'application/tamp-update-confirm': 'tamp-update-confirm',
    'application/taxii+json': 'taxii+json',
    'application/td+json': 'td+json',
    'application/tei+xml': 'tei+xml',
    'application/TETRA_ISI': 'TETRA_ISI',
    'application/thraud+xml': 'thraud+xml',
    'application/timestamp-query': 'timestamp-query',
    'application/timestamp-reply': 'timestamp-reply',
    'application/timestamped-data': 'timestamped-data',
    'application/tlsrpt+gzip': 'tlsrpt+gzip',
    'application/tlsrpt+json': 'tlsrpt+json',
    'application/tm+json': 'tm+json',
    'application/tnauthlist': 'tnauthlist',
    'application/token-introspection+jwt': 'token-introspection+jwt',
    'application/trickle-ice-sdpfrag': 'trickle-ice-sdpfrag',
    'application/trig': 'trig',
    'application/ttml+xml': 'ttml+xml',
    'application/tve-trigger': 'tve-trigger',
    'application/tzif': 'tzif',
    'application/tzif-leap': 'tzif-leap',
    'application/ulpfec': 'ulpfec',
    'application/urc-grpsheet+xml': 'urc-grpsheet+xml',
    'application/urc-ressheet+xml': 'urc-ressheet+xml',
    'application/urc-targetdesc+xml': 'urc-targetdesc+xml',
    'application/urc-uisocketdesc+xml': 'urc-uisocketdesc+xml',
    'application/vcard+json': 'vcard+json',
    'application/vcard+xml': 'vcard+xml',
    'application/vemmi': 'vemmi',
    'application/vnd.1000minds.decision-model+xml': 'vnd.1000minds.decision-model+xml',
    'application/vnd.1ob': 'vnd.1ob',
    'application/vnd.3gpp.5gnas': 'vnd.3gpp.5gnas',
    'application/vnd.3gpp.access-transfer-events+xml': 'vnd.3gpp.access-transfer-events+xml',
    'application/vnd.3gpp.bsf+xml': 'vnd.3gpp.bsf+xml',
    'application/vnd.3gpp.crs+xml': 'vnd.3gpp.crs+xml',
    'application/vnd.3gpp.current-location-discovery+xml': 'vnd.3gpp.current-location-discovery+xml',
    'application/vnd.3gpp.GMOP+xml': 'vnd.3gpp.GMOP+xml',
    'application/vnd.3gpp.gtpc': 'vnd.3gpp.gtpc',
    'application/vnd.3gpp.interworking-data': 'vnd.3gpp.interworking-data',
    'application/vnd.3gpp.lpp': 'vnd.3gpp.lpp',
    'application/vnd.3gpp.mc-signalling-ear': 'vnd.3gpp.mc-signalling-ear',
    'application/vnd.3gpp.mcdata-affiliation-command+xml': 'vnd.3gpp.mcdata-affiliation-command+xml',
    'application/vnd.3gpp.mcdata-info+xml': 'vnd.3gpp.mcdata-info+xml',
    'application/vnd.3gpp.mcdata-msgstore-ctrl-request+xml': 'vnd.3gpp.mcdata-msgstore-ctrl-request+xml',
    'application/vnd.3gpp.mcdata-payload': 'vnd.3gpp.mcdata-payload',
    'application/vnd.3gpp.mcdata-regroup+xml': 'vnd.3gpp.mcdata-regroup+xml',
    'application/vnd.3gpp.mcdata-service-config+xml': 'vnd.3gpp.mcdata-service-config+xml',
    'application/vnd.3gpp.mcdata-signalling': 'vnd.3gpp.mcdata-signalling',
    'application/vnd.3gpp.mcdata-ue-config+xml': 'vnd.3gpp.mcdata-ue-config+xml',
    'application/vnd.3gpp.mcdata-user-profile+xml': 'vnd.3gpp.mcdata-user-profile+xml',
    'application/vnd.3gpp.mcptt-affiliation-command+xml': 'vnd.3gpp.mcptt-affiliation-command+xml',
    'application/vnd.3gpp.mcptt-floor-request+xml': 'vnd.3gpp.mcptt-floor-request+xml',
    'application/vnd.3gpp.mcptt-info+xml': 'vnd.3gpp.mcptt-info+xml',
    'application/vnd.3gpp.mcptt-location-info+xml': 'vnd.3gpp.mcptt-location-info+xml',
    'application/vnd.3gpp.mcptt-mbms-usage-info+xml': 'vnd.3gpp.mcptt-mbms-usage-info+xml',
    'application/vnd.3gpp.mcptt-regroup+xml': 'vnd.3gpp.mcptt-regroup+xml',
    'application/vnd.3gpp.mcptt-service-config+xml': 'vnd.3gpp.mcptt-service-config+xml',
    'application/vnd.3gpp.mcptt-signed+xml': 'vnd.3gpp.mcptt-signed+xml',
    'application/vnd.3gpp.mcptt-ue-config+xml': 'vnd.3gpp.mcptt-ue-config+xml',
    'application/vnd.3gpp.mcptt-ue-init-config+xml': 'vnd.3gpp.mcptt-ue-init-config+xml',
    'application/vnd.3gpp.mcptt-user-profile+xml': 'vnd.3gpp.mcptt-user-profile+xml',
    'application/vnd.3gpp.mcvideo-affiliation-command+xml': 'vnd.3gpp.mcvideo-affiliation-command+xml',
    'application/vnd.3gpp.mcvideo-affiliation-info+xml': 'vnd.3gpp.mcvideo-affiliation-info+xml (OBSOLETED in favor of application/vnd.3gpp.mcvideo-info+xml)',
    'application/vnd.3gpp.mcvideo-info+xml': 'vnd.3gpp.mcvideo-info+xml',
    'application/vnd.3gpp.mcvideo-location-info+xml': 'vnd.3gpp.mcvideo-location-info+xml',
    'application/vnd.3gpp.mcvideo-mbms-usage-info+xml': 'vnd.3gpp.mcvideo-mbms-usage-info+xml',
    'application/vnd.3gpp.mcvideo-regroup+xml': 'vnd.3gpp.mcvideo-regroup+xml',
    'application/vnd.3gpp.mcvideo-service-config+xml': 'vnd.3gpp.mcvideo-service-config+xml',
    'application/vnd.3gpp.mcvideo-transmission-request+xml': 'vnd.3gpp.mcvideo-transmission-request+xml',
    'application/vnd.3gpp.mcvideo-ue-config+xml': 'vnd.3gpp.mcvideo-ue-config+xml',
    'application/vnd.3gpp.mcvideo-user-profile+xml': 'vnd.3gpp.mcvideo-user-profile+xml',
    'application/vnd.3gpp.mid-call+xml': 'vnd.3gpp.mid-call+xml',
    'application/vnd.3gpp.ngap': 'vnd.3gpp.ngap',
    'application/vnd.3gpp.pfcp': 'vnd.3gpp.pfcp',
    'application/vnd.3gpp.pic-bw-large': 'vnd.3gpp.pic-bw-large',
    'application/vnd.3gpp.pic-bw-small': 'vnd.3gpp.pic-bw-small',
    'application/vnd.3gpp.pic-bw-var': 'vnd.3gpp.pic-bw-var',
    'application/vnd.3gpp-prose-pc3a+xml': 'vnd.3gpp-prose-pc3a+xml',
    'application/vnd.3gpp-prose-pc3ach+xml': 'vnd.3gpp-prose-pc3ach+xml',
    'application/vnd.3gpp-prose-pc3ch+xml': 'vnd.3gpp-prose-pc3ch+xml',
    'application/vnd.3gpp-prose-pc8+xml': 'vnd.3gpp-prose-pc8+xml',
    'application/vnd.3gpp-prose+xml': 'vnd.3gpp-prose+xml',
    'application/vnd.3gpp.s1ap': 'vnd.3gpp.s1ap',
    'application/vnd.3gpp.seal-group-doc+xml': 'vnd.3gpp.seal-group-doc+xml',
    'application/vnd.3gpp.seal-info+xml': 'vnd.3gpp.seal-info+xml',
    'application/vnd.3gpp.seal-location-info+xml': 'vnd.3gpp.seal-location-info+xml',
    'application/vnd.3gpp.seal-mbms-usage-info+xml': 'vnd.3gpp.seal-mbms-usage-info+xml',
    'application/vnd.3gpp.seal-network-QoS-management-info+xml': 'vnd.3gpp.seal-network-QoS-management-info+xml',
    'application/vnd.3gpp.seal-ue-config-info+xml': 'vnd.3gpp.seal-ue-config-info+xml',
    'application/vnd.3gpp.seal-unicast-info+xml': 'vnd.3gpp.seal-unicast-info+xml',
    'application/vnd.3gpp.seal-user-profile-info+xml': 'vnd.3gpp.seal-user-profile-info+xml',
    'application/vnd.3gpp.sms': 'vnd.3gpp.sms',
    'application/vnd.3gpp.sms+xml': 'vnd.3gpp.sms+xml',
    'application/vnd.3gpp.srvcc-ext+xml': 'vnd.3gpp.srvcc-ext+xml',
    'application/vnd.3gpp.SRVCC-info+xml': 'vnd.3gpp.SRVCC-info+xml',
    'application/vnd.3gpp.state-and-event-info+xml': 'vnd.3gpp.state-and-event-info+xml',
    'application/vnd.3gpp.ussd+xml': 'vnd.3gpp.ussd+xml',
    'application/vnd.3gpp.vae-info+xml': 'vnd.3gpp.vae-info+xml',
    'application/vnd.3gpp-v2x-local-service-information': 'vnd.3gpp-v2x-local-service-information',
    'application/vnd.3gpp2.bcmcsinfo+xml': 'vnd.3gpp2.bcmcsinfo+xml',
    'application/vnd.3gpp2.sms': 'vnd.3gpp2.sms',
    'application/vnd.3gpp2.tcap': 'vnd.3gpp2.tcap',
    'application/vnd.3gpp.v2x': 'vnd.3gpp.v2x',
    'application/vnd.3lightssoftware.imagescal': 'vnd.3lightssoftware.imagescal',
    'application/vnd.3M.Post-it-Notes': 'vnd.3M.Post-it-Notes',
    'application/vnd.accpac.simply.aso': 'vnd.accpac.simply.aso',
    'application/vnd.accpac.simply.imp': 'vnd.accpac.simply.imp',
    'application/vnd.acm.addressxfer+json': 'vnd.acm.addressxfer+json',
    'application/vnd.acm.chatbot+json': 'vnd.acm.chatbot+json',
    'application/vnd.acucobol': 'vnd.acucobol',
    'application/vnd.acucorp': 'vnd.acucorp',
    'application/vnd.adobe.flash.movie': 'vnd.adobe.flash.movie',
    'application/vnd.adobe.formscentral.fcdt': 'vnd.adobe.formscentral.fcdt',
    'application/vnd.adobe.fxp': 'vnd.adobe.fxp',
    'application/vnd.adobe.partial-upload': 'vnd.adobe.partial-upload',
    'application/vnd.adobe.xdp+xml': 'vnd.adobe.xdp+xml',
    'application/vnd.aether.imp': 'vnd.aether.imp',
    'application/vnd.afpc.afplinedata': 'vnd.afpc.afplinedata',
    'application/vnd.afpc.afplinedata-pagedef': 'vnd.afpc.afplinedata-pagedef',
    'application/vnd.afpc.cmoca-cmresource': 'vnd.afpc.cmoca-cmresource',
    'application/vnd.afpc.foca-charset': 'vnd.afpc.foca-charset',
    'application/vnd.afpc.foca-codedfont': 'vnd.afpc.foca-codedfont',
    'application/vnd.afpc.foca-codepage': 'vnd.afpc.foca-codepage',
    'application/vnd.afpc.modca': 'vnd.afpc.modca',
    'application/vnd.afpc.modca-cmtable': 'vnd.afpc.modca-cmtable',
    'application/vnd.afpc.modca-formdef': 'vnd.afpc.modca-formdef',
    'application/vnd.afpc.modca-mediummap': 'vnd.afpc.modca-mediummap',
    'application/vnd.afpc.modca-objectcontainer': 'vnd.afpc.modca-objectcontainer',
    'application/vnd.afpc.modca-overlay': 'vnd.afpc.modca-overlay',
    'application/vnd.afpc.modca-pagesegment': 'vnd.afpc.modca-pagesegment',
    'application/vnd.age': 'vnd.age',
    'application/vnd.ah-barcode': 'vnd.ah-barcode',
    'application/vnd.ahead.space': 'vnd.ahead.space',
    'application/vnd.airzip.filesecure.azf': 'vnd.airzip.filesecure.azf',
    'application/vnd.airzip.filesecure.azs': 'vnd.airzip.filesecure.azs',
    'application/vnd.amadeus+json': 'vnd.amadeus+json',
    'application/vnd.amazon.mobi8-ebook': 'vnd.amazon.mobi8-ebook',
    'application/vnd.americandynamics.acc': 'vnd.americandynamics.acc',
    'application/vnd.amiga.ami': 'vnd.amiga.ami',
    'application/vnd.amundsen.maze+xml': 'vnd.amundsen.maze+xml',
    'application/vnd.android.ota': 'vnd.android.ota',
    'application/vnd.anki': 'vnd.anki',
    'application/vnd.anser-web-certificate-issue-initiation': 'vnd.anser-web-certificate-issue-initiation',
    'application/vnd.antix.game-component': 'vnd.antix.game-component',
    'application/vnd.apache.arrow.file': 'vnd.apache.arrow.file',
    'application/vnd.apache.arrow.stream': 'vnd.apache.arrow.stream',
    'application/vnd.apache.thrift.binary': 'vnd.apache.thrift.binary',
    'application/vnd.apache.thrift.compact': 'vnd.apache.thrift.compact',
    'application/vnd.apache.thrift.json': 'vnd.apache.thrift.json',
    'application/vnd.apexlang': 'vnd.apexlang',
    'application/vnd.api+json': 'vnd.api+json',
    'application/vnd.aplextor.warrp+json': 'vnd.aplextor.warrp+json',
    'application/vnd.apothekende.reservation+json': 'vnd.apothekende.reservation+json',
    'application/vnd.apple.installer+xml': 'vnd.apple.installer+xml',
    'application/vnd.apple.keynote': 'vnd.apple.keynote',
    'application/vnd.apple.mpegurl': 'vnd.apple.mpegurl',
    'application/vnd.apple.numbers': 'vnd.apple.numbers',
    'application/vnd.apple.pages': 'vnd.apple.pages',
    'application/vnd.arastra.swi': 'vnd.arastra.swi (OBSOLETED in favor of application/vnd.aristanetworks.swi)',
    'application/vnd.aristanetworks.swi': 'vnd.aristanetworks.swi',
    'application/vnd.artisan+json': 'vnd.artisan+json',
    'application/vnd.artsquare': 'vnd.artsquare',
    'application/vnd.astraea-software.iota': 'vnd.astraea-software.iota',
    'application/vnd.audiograph': 'vnd.audiograph',
    'application/vnd.autopackage': 'vnd.autopackage',
    'application/vnd.avalon+json': 'vnd.avalon+json',
    'application/vnd.avistar+xml': 'vnd.avistar+xml',
    'application/vnd.balsamiq.bmml+xml': 'vnd.balsamiq.bmml+xml',
    'application/vnd.banana-accounting': 'vnd.banana-accounting',
    'application/vnd.bbf.usp.error': 'vnd.bbf.usp.error',
    'application/vnd.bbf.usp.msg': 'vnd.bbf.usp.msg',
    'application/vnd.bbf.usp.msg+json': 'vnd.bbf.usp.msg+json',
    'application/vnd.balsamiq.bmpr': 'vnd.balsamiq.bmpr',
    'application/vnd.bekitzur-stech+json': 'vnd.bekitzur-stech+json',
    'application/vnd.belightsoft.lhzd+zip': 'vnd.belightsoft.lhzd+zip',
    'application/vnd.belightsoft.lhzl+zip': 'vnd.belightsoft.lhzl+zip',
    'application/vnd.bint.med-content': 'vnd.bint.med-content',
    'application/vnd.biopax.rdf+xml': 'vnd.biopax.rdf+xml',
    'application/vnd.blink-idb-value-wrapper': 'vnd.blink-idb-value-wrapper',
    'application/vnd.blueice.multipass': 'vnd.blueice.multipass',
    'application/vnd.bluetooth.ep.oob': 'vnd.bluetooth.ep.oob',
    'application/vnd.bluetooth.le.oob': 'vnd.bluetooth.le.oob',
    'application/vnd.bmi': 'vnd.bmi',
    'application/vnd.bpf': 'vnd.bpf',
    'application/vnd.bpf3': 'vnd.bpf3',
    'application/vnd.businessobjects': 'vnd.businessobjects',
    'application/vnd.byu.uapi+json': 'vnd.byu.uapi+json',
    'application/vnd.bzip3': 'vnd.bzip3',
    'application/vnd.cab-jscript': 'vnd.cab-jscript',
    'application/vnd.canon-cpdl': 'vnd.canon-cpdl',
    'application/vnd.canon-lips': 'vnd.canon-lips',
    'application/vnd.capasystems-pg+json': 'vnd.capasystems-pg+json',
    'application/vnd.cendio.thinlinc.clientconf': 'vnd.cendio.thinlinc.clientconf',
    'application/vnd.century-systems.tcp_stream': 'vnd.century-systems.tcp_stream',
    'application/vnd.chemdraw+xml': 'vnd.chemdraw+xml',
    'application/vnd.chess-pgn': 'vnd.chess-pgn',
    'application/vnd.chipnuts.karaoke-mmd': 'vnd.chipnuts.karaoke-mmd',
    'application/vnd.ciedi': 'vnd.ciedi',
    'application/vnd.cinderella': 'vnd.cinderella',
    'application/vnd.cirpack.isdn-ext': 'vnd.cirpack.isdn-ext',
    'application/vnd.citationstyles.style+xml': 'vnd.citationstyles.style+xml',
    'application/vnd.claymore': 'vnd.claymore',
    'application/vnd.cloanto.rp9': 'vnd.cloanto.rp9',
    'application/vnd.clonk.c4group': 'vnd.clonk.c4group',
    'application/vnd.cluetrust.cartomobile-config': 'vnd.cluetrust.cartomobile-config',
    'application/vnd.cluetrust.cartomobile-config-pkg': 'vnd.cluetrust.cartomobile-config-pkg',
    'application/vnd.cncf.helm.chart.content.v1.tar+gzip': 'vnd.cncf.helm.chart.content.v1.tar+gzip',
    'application/vnd.cncf.helm.chart.provenance.v1.prov': 'vnd.cncf.helm.chart.provenance.v1.prov',
    'application/vnd.cncf.helm.config.v1+json': 'vnd.cncf.helm.config.v1+json',
    'application/vnd.coffeescript': 'vnd.coffeescript',
    'application/vnd.collabio.xodocuments.document': 'vnd.collabio.xodocuments.document',
    'application/vnd.collabio.xodocuments.document-template': 'vnd.collabio.xodocuments.document-template',
    'application/vnd.collabio.xodocuments.presentation': 'vnd.collabio.xodocuments.presentation',
    'application/vnd.collabio.xodocuments.presentation-template': 'vnd.collabio.xodocuments.presentation-template',
    'application/vnd.collabio.xodocuments.spreadsheet': 'vnd.collabio.xodocuments.spreadsheet',
    'application/vnd.collabio.xodocuments.spreadsheet-template': 'vnd.collabio.xodocuments.spreadsheet-template',
    'application/vnd.collection.doc+json': 'vnd.collection.doc+json',
    'application/vnd.collection+json': 'vnd.collection+json',
    'application/vnd.collection.next+json': 'vnd.collection.next+json',
    'application/vnd.comicbook-rar': 'vnd.comicbook-rar',
    'application/vnd.comicbook+zip': 'vnd.comicbook+zip',
    'application/vnd.commerce-battelle': 'vnd.commerce-battelle',
    'application/vnd.commonspace': 'vnd.commonspace',
    'application/vnd.coreos.ignition+json': 'vnd.coreos.ignition+json',
    'application/vnd.cosmocaller': 'vnd.cosmocaller',
    'application/vnd.contact.cmsg': 'vnd.contact.cmsg',
    'application/vnd.crick.clicker': 'vnd.crick.clicker',
    'application/vnd.crick.clicker.keyboard': 'vnd.crick.clicker.keyboard',
    'application/vnd.crick.clicker.palette': 'vnd.crick.clicker.palette',
    'application/vnd.crick.clicker.template': 'vnd.crick.clicker.template',
    'application/vnd.crick.clicker.wordbank': 'vnd.crick.clicker.wordbank',
    'application/vnd.criticaltools.wbs+xml': 'vnd.criticaltools.wbs+xml',
    'application/vnd.cryptii.pipe+json': 'vnd.cryptii.pipe+json',
    'application/vnd.crypto-shade-file': 'vnd.crypto-shade-file',
    'application/vnd.cryptomator.encrypted': 'vnd.cryptomator.encrypted',
    'application/vnd.cryptomator.vault': 'vnd.cryptomator.vault',
    'application/vnd.ctc-posml': 'vnd.ctc-posml',
    'application/vnd.ctct.ws+xml': 'vnd.ctct.ws+xml',
    'application/vnd.cups-pdf': 'vnd.cups-pdf',
    'application/vnd.cups-postscript': 'vnd.cups-postscript',
    'application/vnd.cups-ppd': 'vnd.cups-ppd',
    'application/vnd.cups-raster': 'vnd.cups-raster',
    'application/vnd.cups-raw': 'vnd.cups-raw',
    'application/vnd.curl': 'vnd.curl',
    'application/vnd.cyan.dean.root+xml': 'vnd.cyan.dean.root+xml',
    'application/vnd.cybank': 'vnd.cybank',
    'application/vnd.cyclonedx+json': 'vnd.cyclonedx+json',
    'application/vnd.cyclonedx+xml': 'vnd.cyclonedx+xml',
    'application/vnd.d2l.coursepackage1p0+zip': 'vnd.d2l.coursepackage1p0+zip',
    'application/vnd.d3m-dataset': 'vnd.d3m-dataset',
    'application/vnd.d3m-problem': 'vnd.d3m-problem',
    'application/vnd.dart': 'vnd.dart',
    'application/vnd.data-vision.rdz': 'vnd.data-vision.rdz',
    'application/vnd.datalog': 'vnd.datalog',
    'application/vnd.datapackage+json': 'vnd.datapackage+json',
    'application/vnd.dataresource+json': 'vnd.dataresource+json',
    'application/vnd.dbf': 'vnd.dbf',
    'application/vnd.debian.binary-package': 'vnd.debian.binary-package',
    'application/vnd.dece.data': 'vnd.dece.data',
    'application/vnd.dece.ttml+xml': 'vnd.dece.ttml+xml',
    'application/vnd.dece.unspecified': 'vnd.dece.unspecified',
    'application/vnd.dece.zip': 'vnd.dece.zip',
    'application/vnd.denovo.fcselayout-link': 'vnd.denovo.fcselayout-link',
    'application/vnd.desmume.movie': 'vnd.desmume.movie',
    'application/vnd.dir-bi.plate-dl-nosuffix': 'vnd.dir-bi.plate-dl-nosuffix',
    'application/vnd.dm.delegation+xml': 'vnd.dm.delegation+xml',
    'application/vnd.dna': 'vnd.dna',
    'application/vnd.document+json': 'vnd.document+json',
    'application/vnd.dolby.mobile.1': 'vnd.dolby.mobile.1',
    'application/vnd.dolby.mobile.2': 'vnd.dolby.mobile.2',
    'application/vnd.doremir.scorecloud-binary-document': 'vnd.doremir.scorecloud-binary-document',
    'application/vnd.dpgraph': 'vnd.dpgraph',
    'application/vnd.dreamfactory': 'vnd.dreamfactory',
    'application/vnd.drive+json': 'vnd.drive+json',
    'application/vnd.dtg.local': 'vnd.dtg.local',
    'application/vnd.dtg.local.flash': 'vnd.dtg.local.flash',
    'application/vnd.dtg.local.html': 'vnd.dtg.local.html',
    'application/vnd.dvb.ait': 'vnd.dvb.ait',
    'application/vnd.dvb.dvbisl+xml': 'vnd.dvb.dvbisl+xml',
    'application/vnd.dvb.dvbj': 'vnd.dvb.dvbj',
    'application/vnd.dvb.esgcontainer': 'vnd.dvb.esgcontainer',
    'application/vnd.dvb.ipdcdftnotifaccess': 'vnd.dvb.ipdcdftnotifaccess',
    'application/vnd.dvb.ipdcesgaccess': 'vnd.dvb.ipdcesgaccess',
    'application/vnd.dvb.ipdcesgaccess2': 'vnd.dvb.ipdcesgaccess2',
    'application/vnd.dvb.ipdcesgpdd': 'vnd.dvb.ipdcesgpdd',
    'application/vnd.dvb.ipdcroaming': 'vnd.dvb.ipdcroaming',
    'application/vnd.dvb.iptv.alfec-base': 'vnd.dvb.iptv.alfec-base',
    'application/vnd.dvb.iptv.alfec-enhancement': 'vnd.dvb.iptv.alfec-enhancement',
    'application/vnd.dvb.notif-aggregate-root+xml': 'vnd.dvb.notif-aggregate-root+xml',
    'application/vnd.dvb.notif-container+xml': 'vnd.dvb.notif-container+xml',
    'application/vnd.dvb.notif-generic+xml': 'vnd.dvb.notif-generic+xml',
    'application/vnd.dvb.notif-ia-msglist+xml': 'vnd.dvb.notif-ia-msglist+xml',
    'application/vnd.dvb.notif-ia-registration-request+xml': 'vnd.dvb.notif-ia-registration-request+xml',
    'application/vnd.dvb.notif-ia-registration-response+xml': 'vnd.dvb.notif-ia-registration-response+xml',
    'application/vnd.dvb.notif-init+xml': 'vnd.dvb.notif-init+xml',
    'application/vnd.dvb.pfr': 'vnd.dvb.pfr',
    'application/vnd.dvb.service': 'vnd.dvb.service',
    'application/vnd.dxr': 'vnd.dxr',
    'application/vnd.dynageo': 'vnd.dynageo',
    'application/vnd.dzr': 'vnd.dzr',
    'application/vnd.easykaraoke.cdgdownload': 'vnd.easykaraoke.cdgdownload',
    'application/vnd.ecip.rlp': 'vnd.ecip.rlp',
    'application/vnd.ecdis-update': 'vnd.ecdis-update',
    'application/vnd.eclipse.ditto+json': 'vnd.eclipse.ditto+json',
    'application/vnd.ecowin.chart': 'vnd.ecowin.chart',
    'application/vnd.ecowin.filerequest': 'vnd.ecowin.filerequest',
    'application/vnd.ecowin.fileupdate': 'vnd.ecowin.fileupdate',
    'application/vnd.ecowin.series': 'vnd.ecowin.series',
    'application/vnd.ecowin.seriesrequest': 'vnd.ecowin.seriesrequest',
    'application/vnd.ecowin.seriesupdate': 'vnd.ecowin.seriesupdate',
    'application/vnd.efi.img': 'vnd.efi.img',
    'application/vnd.efi.iso': 'vnd.efi.iso',
    'application/vnd.eln+zip': 'vnd.eln+zip',
    'application/vnd.emclient.accessrequest+xml': 'vnd.emclient.accessrequest+xml',
    'application/vnd.enliven': 'vnd.enliven',
    'application/vnd.enphase.envoy': 'vnd.enphase.envoy',
    'application/vnd.eprints.data+xml': 'vnd.eprints.data+xml',
    'application/vnd.epson.esf': 'vnd.epson.esf',
    'application/vnd.epson.msf': 'vnd.epson.msf',
    'application/vnd.epson.quickanime': 'vnd.epson.quickanime',
    'application/vnd.epson.salt': 'vnd.epson.salt',
    'application/vnd.epson.ssf': 'vnd.epson.ssf',
    'application/vnd.ericsson.quickcall': 'vnd.ericsson.quickcall',
    'application/vnd.espass-espass+zip': 'vnd.espass-espass+zip',
    'application/vnd.eszigno3+xml': 'vnd.eszigno3+xml',
    'application/vnd.etsi.aoc+xml': 'vnd.etsi.aoc+xml',
    'application/vnd.etsi.asic-s+zip': 'vnd.etsi.asic-s+zip',
    'application/vnd.etsi.asic-e+zip': 'vnd.etsi.asic-e+zip',
    'application/vnd.etsi.cug+xml': 'vnd.etsi.cug+xml',
    'application/vnd.etsi.iptvcommand+xml': 'vnd.etsi.iptvcommand+xml',
    'application/vnd.etsi.iptvdiscovery+xml': 'vnd.etsi.iptvdiscovery+xml',
    'application/vnd.etsi.iptvprofile+xml': 'vnd.etsi.iptvprofile+xml',
    'application/vnd.etsi.iptvsad-bc+xml': 'vnd.etsi.iptvsad-bc+xml',
    'application/vnd.etsi.iptvsad-cod+xml': 'vnd.etsi.iptvsad-cod+xml',
    'application/vnd.etsi.iptvsad-npvr+xml': 'vnd.etsi.iptvsad-npvr+xml',
    'application/vnd.etsi.iptvservice+xml': 'vnd.etsi.iptvservice+xml',
    'application/vnd.etsi.iptvsync+xml': 'vnd.etsi.iptvsync+xml',
    'application/vnd.etsi.iptvueprofile+xml': 'vnd.etsi.iptvueprofile+xml',
    'application/vnd.etsi.mcid+xml': 'vnd.etsi.mcid+xml',
    'application/vnd.etsi.mheg5': 'vnd.etsi.mheg5',
    'application/vnd.etsi.overload-control-policy-dataset+xml': 'vnd.etsi.overload-control-policy-dataset+xml',
    'application/vnd.etsi.pstn+xml': 'vnd.etsi.pstn+xml',
    'application/vnd.etsi.sci+xml': 'vnd.etsi.sci+xml',
    'application/vnd.etsi.simservs+xml': 'vnd.etsi.simservs+xml',
    'application/vnd.etsi.timestamp-token': 'vnd.etsi.timestamp-token',
    'application/vnd.etsi.tsl+xml': 'vnd.etsi.tsl+xml',
    'application/vnd.etsi.tsl.der': 'vnd.etsi.tsl.der',
    'application/vnd.eu.kasparian.car+json': 'vnd.eu.kasparian.car+json',
    'application/vnd.eudora.data': 'vnd.eudora.data',
    'application/vnd.evolv.ecig.profile': 'vnd.evolv.ecig.profile',
    'application/vnd.evolv.ecig.settings': 'vnd.evolv.ecig.settings',
    'application/vnd.evolv.ecig.theme': 'vnd.evolv.ecig.theme',
    'application/vnd.exstream-empower+zip': 'vnd.exstream-empower+zip',
    'application/vnd.exstream-package': 'vnd.exstream-package',
    'application/vnd.ezpix-album': 'vnd.ezpix-album',
    'application/vnd.ezpix-package': 'vnd.ezpix-package',
    'application/vnd.f-secure.mobile': 'vnd.f-secure.mobile',
    'application/vnd.fastcopy-disk-image': 'vnd.fastcopy-disk-image',
    'application/vnd.familysearch.gedcom+zip': 'vnd.familysearch.gedcom+zip',
    'application/vnd.fdsn.mseed': 'vnd.fdsn.mseed',
    'application/vnd.fdsn.seed': 'vnd.fdsn.seed',
    'application/vnd.ffsns': 'vnd.ffsns',
    'application/vnd.ficlab.flb+zip': 'vnd.ficlab.flb+zip',
    'application/vnd.filmit.zfc': 'vnd.filmit.zfc',
    'application/vnd.fints': 'vnd.fints',
    'application/vnd.firemonkeys.cloudcell': 'vnd.firemonkeys.cloudcell',
    'application/vnd.FloGraphIt': 'vnd.FloGraphIt',
    'application/vnd.fluxtime.clip': 'vnd.fluxtime.clip',
    'application/vnd.font-fontforge-sfd': 'vnd.font-fontforge-sfd',
    'application/vnd.framemaker': 'vnd.framemaker',
    'application/vnd.freelog.comic': 'vnd.freelog.comic',
    'application/vnd.frogans.fnc': 'vnd.frogans.fnc (OBSOLETE)',
    'application/vnd.frogans.ltf': 'vnd.frogans.ltf (OBSOLETE)',
    'application/vnd.fsc.weblaunch': 'vnd.fsc.weblaunch',
    'application/vnd.fujifilm.fb.docuworks': 'vnd.fujifilm.fb.docuworks',
    'application/vnd.fujifilm.fb.docuworks.binder': 'vnd.fujifilm.fb.docuworks.binder',
    'application/vnd.fujifilm.fb.docuworks.container': 'vnd.fujifilm.fb.docuworks.container',
    'application/vnd.fujifilm.fb.jfi+xml': 'vnd.fujifilm.fb.jfi+xml',
    'application/vnd.fujitsu.oasys': 'vnd.fujitsu.oasys',
    'application/vnd.fujitsu.oasys2': 'vnd.fujitsu.oasys2',
    'application/vnd.fujitsu.oasys3': 'vnd.fujitsu.oasys3',
    'application/vnd.fujitsu.oasysgp': 'vnd.fujitsu.oasysgp',
    'application/vnd.fujitsu.oasysprs': 'vnd.fujitsu.oasysprs',
    'application/vnd.fujixerox.ART4': 'vnd.fujixerox.ART4',
    'application/vnd.fujixerox.ART-EX': 'vnd.fujixerox.ART-EX',
    'application/vnd.fujixerox.ddd': 'vnd.fujixerox.ddd',
    'application/vnd.fujixerox.docuworks': 'vnd.fujixerox.docuworks',
    'application/vnd.fujixerox.docuworks.binder': 'vnd.fujixerox.docuworks.binder',
    'application/vnd.fujixerox.docuworks.container': 'vnd.fujixerox.docuworks.container',
    'application/vnd.fujixerox.HBPL': 'vnd.fujixerox.HBPL',
    'application/vnd.fut-misnet': 'vnd.fut-misnet',
    'application/vnd.futoin+cbor': 'vnd.futoin+cbor',
    'application/vnd.futoin+json': 'vnd.futoin+json',
    'application/vnd.fuzzysheet': 'vnd.fuzzysheet',
    'application/vnd.genomatix.tuxedo': 'vnd.genomatix.tuxedo',
    'application/vnd.genozip': 'vnd.genozip',
    'application/vnd.gentics.grd+json': 'vnd.gentics.grd+json',
    'application/vnd.gentoo.catmetadata+xml': 'vnd.gentoo.catmetadata+xml',
    'application/vnd.gentoo.ebuild': 'vnd.gentoo.ebuild',
    'application/vnd.gentoo.eclass': 'vnd.gentoo.eclass',
    'application/vnd.gentoo.gpkg': 'vnd.gentoo.gpkg',
    'application/vnd.gentoo.manifest': 'vnd.gentoo.manifest',
    'application/vnd.gentoo.xpak': 'vnd.gentoo.xpak',
    'application/vnd.gentoo.pkgmetadata+xml': 'vnd.gentoo.pkgmetadata+xml',
    'application/vnd.geo+json': 'vnd.geo+json (OBSOLETED by [RFC7946] in favor of application/geo+json)',
    'application/vnd.geocube+xml': 'vnd.geocube+xml (OBSOLETED by request)',
    'application/vnd.geogebra.file': 'vnd.geogebra.file',
    'application/vnd.geogebra.slides': 'vnd.geogebra.slides',
    'application/vnd.geogebra.tool': 'vnd.geogebra.tool',
    'application/vnd.geometry-explorer': 'vnd.geometry-explorer',
    'application/vnd.geonext': 'vnd.geonext',
    'application/vnd.geoplan': 'vnd.geoplan',
    'application/vnd.geospace': 'vnd.geospace',
    'application/vnd.gerber': 'vnd.gerber',
    'application/vnd.globalplatform.card-content-mgt': 'vnd.globalplatform.card-content-mgt',
    'application/vnd.globalplatform.card-content-mgt-response': 'vnd.globalplatform.card-content-mgt-response',
    'application/vnd.gmx': 'vnd.gmx - DEPRECATED',
    'application/vnd.gnu.taler.exchange+json': 'vnd.gnu.taler.exchange+json',
    'application/vnd.gnu.taler.merchant+json': 'vnd.gnu.taler.merchant+json',
    'application/vnd.google-earth.kml+xml': 'vnd.google-earth.kml+xml',
    'application/vnd.google-earth.kmz': 'vnd.google-earth.kmz',
    'application/vnd.gov.sk.e-form+xml': 'vnd.gov.sk.e-form+xml',
    'application/vnd.gov.sk.e-form+zip': 'vnd.gov.sk.e-form+zip',
    'application/vnd.gov.sk.xmldatacontainer+xml': 'vnd.gov.sk.xmldatacontainer+xml',
    'application/vnd.gpxsee.map+xml': 'vnd.gpxsee.map+xml',
    'application/vnd.grafeq': 'vnd.grafeq',
    'application/vnd.gridmp': 'vnd.gridmp',
    'application/vnd.groove-account': 'vnd.groove-account',
    'application/vnd.groove-help': 'vnd.groove-help',
    'application/vnd.groove-identity-message': 'vnd.groove-identity-message',
    'application/vnd.groove-injector': 'vnd.groove-injector',
    'application/vnd.groove-tool-message': 'vnd.groove-tool-message',
    'application/vnd.groove-tool-template': 'vnd.groove-tool-template',
    'application/vnd.groove-vcard': 'vnd.groove-vcard',
    'application/vnd.hal+json': 'vnd.hal+json',
    'application/vnd.hal+xml': 'vnd.hal+xml',
    'application/vnd.HandHeld-Entertainment+xml': 'vnd.HandHeld-Entertainment+xml',
    'application/vnd.hbci': 'vnd.hbci',
    'application/vnd.hc+json': 'vnd.hc+json',
    'application/vnd.hcl-bireports': 'vnd.hcl-bireports',
    'application/vnd.hdt': 'vnd.hdt',
    'application/vnd.heroku+json': 'vnd.heroku+json',
    'application/vnd.hhe.lesson-player': 'vnd.hhe.lesson-player',
    'application/vnd.hp-HPGL': 'vnd.hp-HPGL',
    'application/vnd.hp-hpid': 'vnd.hp-hpid',
    'application/vnd.hp-hps': 'vnd.hp-hps',
    'application/vnd.hp-jlyt': 'vnd.hp-jlyt',
    'application/vnd.hp-PCL': 'vnd.hp-PCL',
    'application/vnd.hp-PCLXL': 'vnd.hp-PCLXL',
    'application/vnd.hsl': 'vnd.hsl',
    'application/vnd.httphone': 'vnd.httphone',
    'application/vnd.hydrostatix.sof-data': 'vnd.hydrostatix.sof-data',
    'application/vnd.hyper-item+json': 'vnd.hyper-item+json',
    'application/vnd.hyper+json': 'vnd.hyper+json',
    'application/vnd.hyperdrive+json': 'vnd.hyperdrive+json',
    'application/vnd.hzn-3d-crossword': 'vnd.hzn-3d-crossword',
    'application/vnd.ibm.afplinedata': 'vnd.ibm.afplinedata (OBSOLETED in favor of vnd.afpc.afplinedata)',
    'application/vnd.ibm.electronic-media': 'vnd.ibm.electronic-media',
    'application/vnd.ibm.MiniPay': 'vnd.ibm.MiniPay',
    'application/vnd.ibm.modcap': 'vnd.ibm.modcap (OBSOLETED in favor of application/vnd.afpc.modca)',
    'application/vnd.ibm.rights-management': 'vnd.ibm.rights-management',
    'application/vnd.ibm.secure-container': 'vnd.ibm.secure-container',
    'application/vnd.iccprofile': 'vnd.iccprofile',
    'application/vnd.ieee.1905': 'vnd.ieee.1905',
    'application/vnd.igloader': 'vnd.igloader',
    'application/vnd.imagemeter.folder+zip': 'vnd.imagemeter.folder+zip',
    'application/vnd.imagemeter.image+zip': 'vnd.imagemeter.image+zip',
    'application/vnd.immervision-ivp': 'vnd.immervision-ivp',
    'application/vnd.immervision-ivu': 'vnd.immervision-ivu',
    'application/vnd.ims.imsccv1p1': 'vnd.ims.imsccv1p1',
    'application/vnd.ims.imsccv1p2': 'vnd.ims.imsccv1p2',
    'application/vnd.ims.imsccv1p3': 'vnd.ims.imsccv1p3',
    'application/vnd.ims.lis.v2.result+json': 'vnd.ims.lis.v2.result+json',
    'application/vnd.ims.lti.v2.toolconsumerprofile+json': 'vnd.ims.lti.v2.toolconsumerprofile+json',
    'application/vnd.ims.lti.v2.toolproxy.id+json': 'vnd.ims.lti.v2.toolproxy.id+json',
    'application/vnd.ims.lti.v2.toolproxy+json': 'vnd.ims.lti.v2.toolproxy+json',
    'application/vnd.ims.lti.v2.toolsettings+json': 'vnd.ims.lti.v2.toolsettings+json',
    'application/vnd.ims.lti.v2.toolsettings.simple+json': 'vnd.ims.lti.v2.toolsettings.simple+json',
    'application/vnd.informedcontrol.rms+xml': 'vnd.informedcontrol.rms+xml',
    'application/vnd.infotech.project': 'vnd.infotech.project',
    'application/vnd.infotech.project+xml': 'vnd.infotech.project+xml',
    'application/vnd.informix-visionary': 'vnd.informix-visionary (OBSOLETED in favor of application/vnd.visionary)',
    'application/vnd.innopath.wamp.notification': 'vnd.innopath.wamp.notification',
    'application/vnd.insors.igm': 'vnd.insors.igm',
    'application/vnd.intercon.formnet': 'vnd.intercon.formnet',
    'application/vnd.intergeo': 'vnd.intergeo',
    'application/vnd.intertrust.digibox': 'vnd.intertrust.digibox',
    'application/vnd.intertrust.nncp': 'vnd.intertrust.nncp',
    'application/vnd.intu.qbo': 'vnd.intu.qbo',
    'application/vnd.intu.qfx': 'vnd.intu.qfx',
    'application/vnd.ipfs.ipns-record': 'vnd.ipfs.ipns-record',
    'application/vnd.ipld.car': 'vnd.ipld.car',
    'application/vnd.ipld.dag-cbor': 'vnd.ipld.dag-cbor',
    'application/vnd.ipld.dag-json': 'vnd.ipld.dag-json',
    'application/vnd.ipld.raw': 'vnd.ipld.raw',
    'application/vnd.iptc.g2.catalogitem+xml': 'vnd.iptc.g2.catalogitem+xml',
    'application/vnd.iptc.g2.conceptitem+xml': 'vnd.iptc.g2.conceptitem+xml',
    'application/vnd.iptc.g2.knowledgeitem+xml': 'vnd.iptc.g2.knowledgeitem+xml',
    'application/vnd.iptc.g2.newsitem+xml': 'vnd.iptc.g2.newsitem+xml',
    'application/vnd.iptc.g2.newsmessage+xml': 'vnd.iptc.g2.newsmessage+xml',
    'application/vnd.iptc.g2.packageitem+xml': 'vnd.iptc.g2.packageitem+xml',
    'application/vnd.iptc.g2.planningitem+xml': 'vnd.iptc.g2.planningitem+xml',
    'application/vnd.ipunplugged.rcprofile': 'vnd.ipunplugged.rcprofile',
    'application/vnd.irepository.package+xml': 'vnd.irepository.package+xml',
    'application/vnd.is-xpr': 'vnd.is-xpr',
    'application/vnd.isac.fcs': 'vnd.isac.fcs',
    'application/vnd.jam': 'vnd.jam',
    'application/vnd.iso11783-10+zip': 'vnd.iso11783-10+zip',
    'application/vnd.japannet-directory-service': 'vnd.japannet-directory-service',
    'application/vnd.japannet-jpnstore-wakeup': 'vnd.japannet-jpnstore-wakeup',
    'application/vnd.japannet-payment-wakeup': 'vnd.japannet-payment-wakeup',
    'application/vnd.japannet-registration': 'vnd.japannet-registration',
    'application/vnd.japannet-registration-wakeup': 'vnd.japannet-registration-wakeup',
    'application/vnd.japannet-setstore-wakeup': 'vnd.japannet-setstore-wakeup',
    'application/vnd.japannet-verification': 'vnd.japannet-verification',
    'application/vnd.japannet-verification-wakeup': 'vnd.japannet-verification-wakeup',
    'application/vnd.jcp.javame.midlet-rms': 'vnd.jcp.javame.midlet-rms',
    'application/vnd.jisp': 'vnd.jisp',
    'application/vnd.joost.joda-archive': 'vnd.joost.joda-archive',
    'application/vnd.jsk.isdn-ngn': 'vnd.jsk.isdn-ngn',
    'application/vnd.kahootz': 'vnd.kahootz',
    'application/vnd.kde.karbon': 'vnd.kde.karbon',
    'application/vnd.kde.kchart': 'vnd.kde.kchart',
    'application/vnd.kde.kformula': 'vnd.kde.kformula',
    'application/vnd.kde.kivio': 'vnd.kde.kivio',
    'application/vnd.kde.kontour': 'vnd.kde.kontour',
    'application/vnd.kde.kpresenter': 'vnd.kde.kpresenter',
    'application/vnd.kde.kspread': 'vnd.kde.kspread',
    'application/vnd.kde.kword': 'vnd.kde.kword',
    'application/vnd.kenameaapp': 'vnd.kenameaapp',
    'application/vnd.kidspiration': 'vnd.kidspiration',
    'application/vnd.Kinar': 'vnd.Kinar',
    'application/vnd.koan': 'vnd.koan',
    'application/vnd.kodak-descriptor': 'vnd.kodak-descriptor',
    'application/vnd.las': 'vnd.las',
    'application/vnd.las.las+json': 'vnd.las.las+json',
    'application/vnd.las.las+xml': 'vnd.las.las+xml',
    'application/vnd.laszip': 'vnd.laszip',
    'application/vnd.leap+json': 'vnd.leap+json',
    'application/vnd.liberty-request+xml': 'vnd.liberty-request+xml',
    'application/vnd.llamagraphics.life-balance.desktop': 'vnd.llamagraphics.life-balance.desktop',
    'application/vnd.llamagraphics.life-balance.exchange+xml': 'vnd.llamagraphics.life-balance.exchange+xml',
    'application/vnd.logipipe.circuit+zip': 'vnd.logipipe.circuit+zip',
    'application/vnd.loom': 'vnd.loom',
    'application/vnd.lotus-1-2-3': 'vnd.lotus-1-2-3',
    'application/vnd.lotus-approach': 'vnd.lotus-approach',
    'application/vnd.lotus-freelance': 'vnd.lotus-freelance',
    'application/vnd.lotus-notes': 'vnd.lotus-notes',
    'application/vnd.lotus-organizer': 'vnd.lotus-organizer',
    'application/vnd.lotus-screencam': 'vnd.lotus-screencam',
    'application/vnd.lotus-wordpro': 'vnd.lotus-wordpro',
    'application/vnd.macports.portpkg': 'vnd.macports.portpkg',
    'application/vnd.mapbox-vector-tile': 'vnd.mapbox-vector-tile',
    'application/vnd.marlin.drm.actiontoken+xml': 'vnd.marlin.drm.actiontoken+xml',
    'application/vnd.marlin.drm.conftoken+xml': 'vnd.marlin.drm.conftoken+xml',
    'application/vnd.marlin.drm.license+xml': 'vnd.marlin.drm.license+xml',
    'application/vnd.marlin.drm.mdcf': 'vnd.marlin.drm.mdcf',
    'application/vnd.mason+json': 'vnd.mason+json',
    'application/vnd.maxar.archive.3tz+zip': 'vnd.maxar.archive.3tz+zip',
    'application/vnd.maxmind.maxmind-db': 'vnd.maxmind.maxmind-db',
    'application/vnd.mcd': 'vnd.mcd',
    'application/vnd.mdl': 'vnd.mdl',
    'application/vnd.mdl-mbsdf': 'vnd.mdl-mbsdf',
    'application/vnd.medcalcdata': 'vnd.medcalcdata',
    'application/vnd.mediastation.cdkey': 'vnd.mediastation.cdkey',
    'application/vnd.medicalholodeck.recordxr': 'vnd.medicalholodeck.recordxr',
    'application/vnd.meridian-slingshot': 'vnd.meridian-slingshot',
    'application/vnd.mermaid': 'vnd.mermaid',
    'application/vnd.MFER': 'vnd.MFER',
    'application/vnd.mfmp': 'vnd.mfmp',
    'application/vnd.micro+json': 'vnd.micro+json',
    'application/vnd.micrografx.flo': 'vnd.micrografx.flo',
    'application/vnd.micrografx.igx': 'vnd.micrografx.igx',
    'application/vnd.microsoft.portable-executable': 'vnd.microsoft.portable-executable',
    'application/vnd.microsoft.windows.thumbnail-cache': 'vnd.microsoft.windows.thumbnail-cache',
    'application/vnd.miele+json': 'vnd.miele+json',
    'application/vnd.mif': 'vnd.mif',
    'application/vnd.minisoft-hp3000-save': 'vnd.minisoft-hp3000-save',
    'application/vnd.mitsubishi.misty-guard.trustweb': 'vnd.mitsubishi.misty-guard.trustweb',
    'application/vnd.Mobius.DAF': 'vnd.Mobius.DAF',
    'application/vnd.Mobius.DIS': 'vnd.Mobius.DIS',
    'application/vnd.Mobius.MBK': 'vnd.Mobius.MBK',
    'application/vnd.Mobius.MQY': 'vnd.Mobius.MQY',
    'application/vnd.Mobius.MSL': 'vnd.Mobius.MSL',
    'application/vnd.Mobius.PLC': 'vnd.Mobius.PLC',
    'application/vnd.Mobius.TXF': 'vnd.Mobius.TXF',
    'application/vnd.modl': 'vnd.modl',
    'application/vnd.mophun.application': 'vnd.mophun.application',
    'application/vnd.mophun.certificate': 'vnd.mophun.certificate',
    'application/vnd.motorola.flexsuite': 'vnd.motorola.flexsuite',
    'application/vnd.motorola.flexsuite.adsi': 'vnd.motorola.flexsuite.adsi',
    'application/vnd.motorola.flexsuite.fis': 'vnd.motorola.flexsuite.fis',
    'application/vnd.motorola.flexsuite.gotap': 'vnd.motorola.flexsuite.gotap',
    'application/vnd.motorola.flexsuite.kmr': 'vnd.motorola.flexsuite.kmr',
    'application/vnd.motorola.flexsuite.ttc': 'vnd.motorola.flexsuite.ttc',
    'application/vnd.motorola.flexsuite.wem': 'vnd.motorola.flexsuite.wem',
    'application/vnd.motorola.iprm': 'vnd.motorola.iprm',
    'application/vnd.mozilla.xul+xml': 'vnd.mozilla.xul+xml',
    'application/vnd.ms-artgalry': 'vnd.ms-artgalry',
    'application/vnd.ms-asf': 'vnd.ms-asf',
    'application/vnd.ms-cab-compressed': 'vnd.ms-cab-compressed',
    'application/vnd.ms-3mfdocument': 'vnd.ms-3mfdocument',
    'application/vnd.ms-excel': 'vnd.ms-excel',
    'application/vnd.ms-excel.addin.macroEnabled.12': 'vnd.ms-excel.addin.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12': 'vnd.ms-excel.sheet.binary.macroEnabled.12',
    'application/vnd.ms-excel.sheet.macroEnabled.12': 'vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.template.macroEnabled.12': 'vnd.ms-excel.template.macroEnabled.12',
    'application/vnd.ms-fontobject': 'vnd.ms-fontobject',
    'application/vnd.ms-htmlhelp': 'vnd.ms-htmlhelp',
    'application/vnd.ms-ims': 'vnd.ms-ims',
    'application/vnd.ms-lrm': 'vnd.ms-lrm',
    'application/vnd.ms-office.activeX+xml': 'vnd.ms-office.activeX+xml',
    'application/vnd.ms-officetheme': 'vnd.ms-officetheme',
    'application/vnd.ms-playready.initiator+xml': 'vnd.ms-playready.initiator+xml',
    'application/vnd.ms-powerpoint': 'vnd.ms-powerpoint',
    'application/vnd.ms-powerpoint.addin.macroEnabled.12': 'vnd.ms-powerpoint.addin.macroEnabled.12',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12': 'vnd.ms-powerpoint.presentation.macroEnabled.12',
    'application/vnd.ms-powerpoint.slide.macroEnabled.12': 'vnd.ms-powerpoint.slide.macroEnabled.12',
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': 'vnd.ms-powerpoint.slideshow.macroEnabled.12',
    'application/vnd.ms-powerpoint.template.macroEnabled.12': 'vnd.ms-powerpoint.template.macroEnabled.12',
    'application/vnd.ms-PrintDeviceCapabilities+xml': 'vnd.ms-PrintDeviceCapabilities+xml',
    'application/vnd.ms-PrintSchemaTicket+xml': 'vnd.ms-PrintSchemaTicket+xml',
    'application/vnd.ms-project': 'vnd.ms-project',
    'application/vnd.ms-tnef': 'vnd.ms-tnef',
    'application/vnd.ms-windows.devicepairing': 'vnd.ms-windows.devicepairing',
    'application/vnd.ms-windows.nwprinting.oob': 'vnd.ms-windows.nwprinting.oob',
    'application/vnd.ms-windows.printerpairing': 'vnd.ms-windows.printerpairing',
    'application/vnd.ms-windows.wsd.oob': 'vnd.ms-windows.wsd.oob',
    'application/vnd.ms-wmdrm.lic-chlg-req': 'vnd.ms-wmdrm.lic-chlg-req',
    'application/vnd.ms-wmdrm.lic-resp': 'vnd.ms-wmdrm.lic-resp',
    'application/vnd.ms-wmdrm.meter-chlg-req': 'vnd.ms-wmdrm.meter-chlg-req',
    'application/vnd.ms-wmdrm.meter-resp': 'vnd.ms-wmdrm.meter-resp',
    'application/vnd.ms-word.document.macroEnabled.12': 'vnd.ms-word.document.macroEnabled.12',
    'application/vnd.ms-word.template.macroEnabled.12': 'vnd.ms-word.template.macroEnabled.12',
    'application/vnd.ms-works': 'vnd.ms-works',
    'application/vnd.ms-wpl': 'vnd.ms-wpl',
    'application/vnd.ms-xpsdocument': 'vnd.ms-xpsdocument',
    'application/vnd.msa-disk-image': 'vnd.msa-disk-image',
    'application/vnd.mseq': 'vnd.mseq',
    'application/vnd.msign': 'vnd.msign',
    'application/vnd.multiad.creator': 'vnd.multiad.creator',
    'application/vnd.multiad.creator.cif': 'vnd.multiad.creator.cif',
    'application/vnd.musician': 'vnd.musician',
    'application/vnd.music-niff': 'vnd.music-niff',
    'application/vnd.muvee.style': 'vnd.muvee.style',
    'application/vnd.mynfc': 'vnd.mynfc',
    'application/vnd.nacamar.ybrid+json': 'vnd.nacamar.ybrid+json',
    'application/vnd.nato.bindingdataobject+cbor': 'vnd.nato.bindingdataobject+cbor',
    'application/vnd.nato.bindingdataobject+json': 'vnd.nato.bindingdataobject+json',
    'application/vnd.nato.bindingdataobject+xml': 'vnd.nato.bindingdataobject+xml',
    'application/vnd.nato.openxmlformats-package.iepd+zip': 'vnd.nato.openxmlformats-package.iepd+zip',
    'application/vnd.ncd.control': 'vnd.ncd.control',
    'application/vnd.ncd.reference': 'vnd.ncd.reference',
    'application/vnd.nearst.inv+json': 'vnd.nearst.inv+json',
    'application/vnd.nebumind.line': 'vnd.nebumind.line',
    'application/vnd.nervana': 'vnd.nervana',
    'application/vnd.netfpx': 'vnd.netfpx',
    'application/vnd.neurolanguage.nlu': 'vnd.neurolanguage.nlu',
    'application/vnd.nimn': 'vnd.nimn',
    'application/vnd.nintendo.snes.rom': 'vnd.nintendo.snes.rom',
    'application/vnd.nintendo.nitro.rom': 'vnd.nintendo.nitro.rom',
    'application/vnd.nitf': 'vnd.nitf',
    'application/vnd.noblenet-directory': 'vnd.noblenet-directory',
    'application/vnd.noblenet-sealer': 'vnd.noblenet-sealer',
    'application/vnd.noblenet-web': 'vnd.noblenet-web',
    'application/vnd.nokia.catalogs': 'vnd.nokia.catalogs',
    'application/vnd.nokia.conml+wbxml': 'vnd.nokia.conml+wbxml',
    'application/vnd.nokia.conml+xml': 'vnd.nokia.conml+xml',
    'application/vnd.nokia.iptv.config+xml': 'vnd.nokia.iptv.config+xml',
    'application/vnd.nokia.iSDS-radio-presets': 'vnd.nokia.iSDS-radio-presets',
    'application/vnd.nokia.landmark+wbxml': 'vnd.nokia.landmark+wbxml',
    'application/vnd.nokia.landmark+xml': 'vnd.nokia.landmark+xml',
    'application/vnd.nokia.landmarkcollection+xml': 'vnd.nokia.landmarkcollection+xml',
    'application/vnd.nokia.ncd': 'vnd.nokia.ncd',
    'application/vnd.nokia.n-gage.ac+xml': 'vnd.nokia.n-gage.ac+xml',
    'application/vnd.nokia.n-gage.data': 'vnd.nokia.n-gage.data',
    'application/vnd.nokia.n-gage.symbian.install': 'vnd.nokia.n-gage.symbian.install (OBSOLETE; no replacement given)',
    'application/vnd.nokia.pcd+wbxml': 'vnd.nokia.pcd+wbxml',
    'application/vnd.nokia.pcd+xml': 'vnd.nokia.pcd+xml',
    'application/vnd.nokia.radio-preset': 'vnd.nokia.radio-preset',
    'application/vnd.nokia.radio-presets': 'vnd.nokia.radio-presets',
    'application/vnd.novadigm.EDM': 'vnd.novadigm.EDM',
    'application/vnd.novadigm.EDX': 'vnd.novadigm.EDX',
    'application/vnd.novadigm.EXT': 'vnd.novadigm.EXT',
    'application/vnd.ntt-local.content-share': 'vnd.ntt-local.content-share',
    'application/vnd.ntt-local.file-transfer': 'vnd.ntt-local.file-transfer',
    'application/vnd.ntt-local.ogw_remote-access': 'vnd.ntt-local.ogw_remote-access',
    'application/vnd.ntt-local.sip-ta_remote': 'vnd.ntt-local.sip-ta_remote',
    'application/vnd.ntt-local.sip-ta_tcp_stream': 'vnd.ntt-local.sip-ta_tcp_stream',
    'application/vnd.oai.workflows': 'vnd.oai.workflows',
    'application/vnd.oai.workflows+json': 'vnd.oai.workflows+json',
    'application/vnd.oai.workflows+yaml': 'vnd.oai.workflows+yaml',
    'application/vnd.oasis.opendocument.base': 'vnd.oasis.opendocument.base',
    'application/vnd.oasis.opendocument.chart': 'vnd.oasis.opendocument.chart',
    'application/vnd.oasis.opendocument.chart-template': 'vnd.oasis.opendocument.chart-template',
    'application/vnd.oasis.opendocument.database': 'vnd.oasis.opendocument.database (OBSOLETED in favor of application/vnd.oasis.opendocument.base)',
    'application/vnd.oasis.opendocument.formula': 'vnd.oasis.opendocument.formula',
    'application/vnd.oasis.opendocument.formula-template': 'vnd.oasis.opendocument.formula-template',
    'application/vnd.oasis.opendocument.graphics': 'vnd.oasis.opendocument.graphics',
    'application/vnd.oasis.opendocument.graphics-template': 'vnd.oasis.opendocument.graphics-template',
    'application/vnd.oasis.opendocument.image': 'vnd.oasis.opendocument.image',
    'application/vnd.oasis.opendocument.image-template': 'vnd.oasis.opendocument.image-template',
    'application/vnd.oasis.opendocument.presentation': 'vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.presentation-template': 'vnd.oasis.opendocument.presentation-template',
    'application/vnd.oasis.opendocument.spreadsheet': 'vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.spreadsheet-template': 'vnd.oasis.opendocument.spreadsheet-template',
    'application/vnd.oasis.opendocument.text': 'vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.text-master': 'vnd.oasis.opendocument.text-master',
    'application/vnd.oasis.opendocument.text-master-template': 'vnd.oasis.opendocument.text-master-template',
    'application/vnd.oasis.opendocument.text-template': 'vnd.oasis.opendocument.text-template',
    'application/vnd.oasis.opendocument.text-web': 'vnd.oasis.opendocument.text-web',
    'application/vnd.obn': 'vnd.obn',
    'application/vnd.ocf+cbor': 'vnd.ocf+cbor',
    'application/vnd.oci.image.manifest.v1+json': 'vnd.oci.image.manifest.v1+json',
    'application/vnd.oftn.l10n+json': 'vnd.oftn.l10n+json',
    'application/vnd.oipf.contentaccessdownload+xml': 'vnd.oipf.contentaccessdownload+xml',
    'application/vnd.oipf.contentaccessstreaming+xml': 'vnd.oipf.contentaccessstreaming+xml',
    'application/vnd.oipf.cspg-hexbinary': 'vnd.oipf.cspg-hexbinary',
    'application/vnd.oipf.dae.svg+xml': 'vnd.oipf.dae.svg+xml',
    'application/vnd.oipf.dae.xhtml+xml': 'vnd.oipf.dae.xhtml+xml',
    'application/vnd.oipf.mippvcontrolmessage+xml': 'vnd.oipf.mippvcontrolmessage+xml',
    'application/vnd.oipf.pae.gem': 'vnd.oipf.pae.gem',
    'application/vnd.oipf.spdiscovery+xml': 'vnd.oipf.spdiscovery+xml',
    'application/vnd.oipf.spdlist+xml': 'vnd.oipf.spdlist+xml',
    'application/vnd.oipf.ueprofile+xml': 'vnd.oipf.ueprofile+xml',
    'application/vnd.oipf.userprofile+xml': 'vnd.oipf.userprofile+xml',
    'application/vnd.olpc-sugar': 'vnd.olpc-sugar',
    'application/vnd.oma.bcast.associated-procedure-parameter+xml': 'vnd.oma.bcast.associated-procedure-parameter+xml',
    'application/vnd.oma.bcast.drm-trigger+xml': 'vnd.oma.bcast.drm-trigger+xml',
    'application/vnd.oma.bcast.imd+xml': 'vnd.oma.bcast.imd+xml',
    'application/vnd.oma.bcast.ltkm': 'vnd.oma.bcast.ltkm',
    'application/vnd.oma.bcast.notification+xml': 'vnd.oma.bcast.notification+xml',
    'application/vnd.oma.bcast.provisioningtrigger': 'vnd.oma.bcast.provisioningtrigger',
    'application/vnd.oma.bcast.sgboot': 'vnd.oma.bcast.sgboot',
    'application/vnd.oma.bcast.sgdd+xml': 'vnd.oma.bcast.sgdd+xml',
    'application/vnd.oma.bcast.sgdu': 'vnd.oma.bcast.sgdu',
    'application/vnd.oma.bcast.simple-symbol-container': 'vnd.oma.bcast.simple-symbol-container',
    'application/vnd.oma.bcast.smartcard-trigger+xml': 'vnd.oma.bcast.smartcard-trigger+xml',
    'application/vnd.oma.bcast.sprov+xml': 'vnd.oma.bcast.sprov+xml',
    'application/vnd.oma.bcast.stkm': 'vnd.oma.bcast.stkm',
    'application/vnd.oma.cab-address-book+xml': 'vnd.oma.cab-address-book+xml',
    'application/vnd.oma.cab-feature-handler+xml': 'vnd.oma.cab-feature-handler+xml',
    'application/vnd.oma.cab-pcc+xml': 'vnd.oma.cab-pcc+xml',
    'application/vnd.oma.cab-subs-invite+xml': 'vnd.oma.cab-subs-invite+xml',
    'application/vnd.oma.cab-user-prefs+xml': 'vnd.oma.cab-user-prefs+xml',
    'application/vnd.oma.dcd': 'vnd.oma.dcd',
    'application/vnd.oma.dcdc': 'vnd.oma.dcdc',
    'application/vnd.oma.dd2+xml': 'vnd.oma.dd2+xml',
    'application/vnd.oma.drm.risd+xml': 'vnd.oma.drm.risd+xml',
    'application/vnd.oma.group-usage-list+xml': 'vnd.oma.group-usage-list+xml',
    'application/vnd.oma.lwm2m+cbor': 'vnd.oma.lwm2m+cbor',
    'application/vnd.oma.lwm2m+json': 'vnd.oma.lwm2m+json',
    'application/vnd.oma.lwm2m+tlv': 'vnd.oma.lwm2m+tlv',
    'application/vnd.oma.pal+xml': 'vnd.oma.pal+xml',
    'application/vnd.oma.poc.detailed-progress-report+xml': 'vnd.oma.poc.detailed-progress-report+xml',
    'application/vnd.oma.poc.final-report+xml': 'vnd.oma.poc.final-report+xml',
    'application/vnd.oma.poc.groups+xml': 'vnd.oma.poc.groups+xml',
    'application/vnd.oma.poc.invocation-descriptor+xml': 'vnd.oma.poc.invocation-descriptor+xml',
    'application/vnd.oma.poc.optimized-progress-report+xml': 'vnd.oma.poc.optimized-progress-report+xml',
    'application/vnd.oma.push': 'vnd.oma.push',
    'application/vnd.oma.scidm.messages+xml': 'vnd.oma.scidm.messages+xml',
    'application/vnd.oma.xcap-directory+xml': 'vnd.oma.xcap-directory+xml',
    'application/vnd.omads-email+xml': 'vnd.omads-email+xml',
    'application/vnd.omads-file+xml': 'vnd.omads-file+xml',
    'application/vnd.omads-folder+xml': 'vnd.omads-folder+xml',
    'application/vnd.omaloc-supl-init': 'vnd.omaloc-supl-init',
    'application/vnd.oma-scws-config': 'vnd.oma-scws-config',
    'application/vnd.oma-scws-http-request': 'vnd.oma-scws-http-request',
    'application/vnd.oma-scws-http-response': 'vnd.oma-scws-http-response',
    'application/vnd.onepager': 'vnd.onepager',
    'application/vnd.onepagertamp': 'vnd.onepagertamp',
    'application/vnd.onepagertamx': 'vnd.onepagertamx',
    'application/vnd.onepagertat': 'vnd.onepagertat',
    'application/vnd.onepagertatp': 'vnd.onepagertatp',
    'application/vnd.onepagertatx': 'vnd.onepagertatx',
    'application/vnd.onvif.metadata': 'vnd.onvif.metadata',
    'application/vnd.openblox.game-binary': 'vnd.openblox.game-binary',
    'application/vnd.openblox.game+xml': 'vnd.openblox.game+xml',
    'application/vnd.openeye.oeb': 'vnd.openeye.oeb',
    'application/vnd.openstreetmap.data+xml': 'vnd.openstreetmap.data+xml',
    'application/vnd.opentimestamps.ots': 'vnd.opentimestamps.ots',
    'application/vnd.openxmlformats-officedocument.custom-properties+xml': 'vnd.openxmlformats-officedocument.custom-properties+xml',
    'application/vnd.openxmlformats-officedocument.customXmlProperties+xml': 'vnd.openxmlformats-officedocument.customXmlProperties+xml',
    'application/vnd.openxmlformats-officedocument.drawing+xml': 'vnd.openxmlformats-officedocument.drawing+xml',
    'application/vnd.openxmlformats-officedocument.drawingml.chart+xml': 'vnd.openxmlformats-officedocument.drawingml.chart+xml',
    'application/vnd.openxmlformats-officedocument.drawingml.chartshapes+xml': 'vnd.openxmlformats-officedocument.drawingml.chartshapes+xml',
    'application/vnd.openxmlformats-officedocument.drawingml.diagramColors+xml': 'vnd.openxmlformats-officedocument.drawingml.diagramColors+xml',
    'application/vnd.openxmlformats-officedocument.drawingml.diagramData+xml': 'vnd.openxmlformats-officedocument.drawingml.diagramData+xml',
    'application/vnd.openxmlformats-officedocument.drawingml.diagramLayout+xml': 'vnd.openxmlformats-officedocument.drawingml.diagramLayout+xml',
    'application/vnd.openxmlformats-officedocument.drawingml.diagramStyle+xml': 'vnd.openxmlformats-officedocument.drawingml.diagramStyle+xml',
    'application/vnd.openxmlformats-officedocument.extended-properties+xml': 'vnd.openxmlformats-officedocument.extended-properties+xml',
    'application/vnd.openxmlformats-officedocument.presentationml.commentAuthors+xml': 'vnd.openxmlformats-officedocument.presentationml.commentAuthors+xml',
    'application/vnd.openxmlformats-officedocument.presentationml.comments+xml': 'vnd.openxmlformats-officedocument.presentationml.comments+xml',
    'application/vnd.openxmlformats-officedocument.presentationml.handoutMaster+xml': 'vnd.openxmlformats-officedocument.presentationml.handoutMaster+xml',
    'application/vnd.openxmlformats-officedocument.presentationml.notesMaster+xml': 'vnd.openxmlformats-officedocument.presentationml.notesMaster+xml',
    'application/vnd.openxmlformats-officedocument.presentationml.notesSlide+xml': 'vnd.openxmlformats-officedocument.presentationml.notesSlide+xml',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation.main+xml': 'vnd.openxmlformats-officedocument.presentationml.presentation.main+xml',
    'application/vnd.openxmlformats-officedocument.presentationml.presProps+xml': 'vnd.openxmlformats-officedocument.presentationml.presProps+xml',
    'application/vnd.openxmlformats-officedocument.presentationml.slide': 'vnd.openxmlformats-officedocument.presentationml.slide',
    'application/vnd.openxmlformats-officedocument.presentationml.slide+xml': 'vnd.openxmlformats-officedocument.presentationml.slide+xml',
    'application/vnd.openxmlformats-officedocument.presentationml.slideLayout+xml': 'vnd.openxmlformats-officedocument.presentationml.slideLayout+xml',
    'application/vnd.openxmlformats-officedocument.presentationml.slideMaster+xml': 'vnd.openxmlformats-officedocument.presentationml.slideMaster+xml',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': 'vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow.main+xml': 'vnd.openxmlformats-officedocument.presentationml.slideshow.main+xml',
    'application/vnd.openxmlformats-officedocument.presentationml.slideUpdateInfo+xml': 'vnd.openxmlformats-officedocument.presentationml.slideUpdateInfo+xml',
    'application/vnd.openxmlformats-officedocument.presentationml.tableStyles+xml': 'vnd.openxmlformats-officedocument.presentationml.tableStyles+xml',
    'application/vnd.openxmlformats-officedocument.presentationml.tags+xml': 'vnd.openxmlformats-officedocument.presentationml.tags+xml',
    'application/vnd.openxmlformats-officedocument.presentationml.template': 'vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.openxmlformats-officedocument.presentationml.template.main+xml': 'vnd.openxmlformats-officedocument.presentationml.template.main+xml',
    'application/vnd.openxmlformats-officedocument.presentationml.viewProps+xml': 'vnd.openxmlformats-officedocument.presentationml.viewProps+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.calcChain+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.calcChain+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.chartsheet+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.chartsheet+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.comments+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.comments+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.connections+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.connections+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.dialogsheet+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.dialogsheet+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.externalLink+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.externalLink+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.pivotCacheDefinition+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.pivotCacheDefinition+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.pivotCacheRecords+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.pivotCacheRecords+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.pivotTable+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.pivotTable+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.queryTable+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.queryTable+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.revisionHeaders+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.revisionHeaders+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.revisionLog+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.revisionLog+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sharedStrings+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.sharedStrings+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetMetadata+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.sheetMetadata+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.styles+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.styles+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.table+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.table+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.tableSingleCells+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.tableSingleCells+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template': 'vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template.main+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.template.main+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.userNames+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.userNames+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.volatileDependencies+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.volatileDependencies+xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet+xml': 'vnd.openxmlformats-officedocument.spreadsheetml.worksheet+xml',
    'application/vnd.openxmlformats-officedocument.theme+xml': 'vnd.openxmlformats-officedocument.theme+xml',
    'application/vnd.openxmlformats-officedocument.themeOverride+xml': 'vnd.openxmlformats-officedocument.themeOverride+xml',
    'application/vnd.openxmlformats-officedocument.vmlDrawing': 'vnd.openxmlformats-officedocument.vmlDrawing',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.comments+xml': 'vnd.openxmlformats-officedocument.wordprocessingml.comments+xml',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document.glossary+xml': 'vnd.openxmlformats-officedocument.wordprocessingml.document.glossary+xml',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml': 'vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.endnotes+xml': 'vnd.openxmlformats-officedocument.wordprocessingml.endnotes+xml',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.fontTable+xml': 'vnd.openxmlformats-officedocument.wordprocessingml.fontTable+xml',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.footer+xml': 'vnd.openxmlformats-officedocument.wordprocessingml.footer+xml',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.footnotes+xml': 'vnd.openxmlformats-officedocument.wordprocessingml.footnotes+xml',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.numbering+xml': 'vnd.openxmlformats-officedocument.wordprocessingml.numbering+xml',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.settings+xml': 'vnd.openxmlformats-officedocument.wordprocessingml.settings+xml',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.styles+xml': 'vnd.openxmlformats-officedocument.wordprocessingml.styles+xml',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template': 'vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template.main+xml': 'vnd.openxmlformats-officedocument.wordprocessingml.template.main+xml',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.webSettings+xml': 'vnd.openxmlformats-officedocument.wordprocessingml.webSettings+xml',
    'application/vnd.openxmlformats-package.core-properties+xml': 'vnd.openxmlformats-package.core-properties+xml',
    'application/vnd.openxmlformats-package.digital-signature-xmlsignature+xml': 'vnd.openxmlformats-package.digital-signature-xmlsignature+xml',
    'application/vnd.openxmlformats-package.relationships+xml': 'vnd.openxmlformats-package.relationships+xml',
    'application/vnd.oracle.resource+json': 'vnd.oracle.resource+json',
    'application/vnd.orange.indata': 'vnd.orange.indata',
    'application/vnd.osa.netdeploy': 'vnd.osa.netdeploy',
    'application/vnd.osgeo.mapguide.package': 'vnd.osgeo.mapguide.package',
    'application/vnd.osgi.bundle': 'vnd.osgi.bundle',
    'application/vnd.osgi.dp': 'vnd.osgi.dp',
    'application/vnd.osgi.subsystem': 'vnd.osgi.subsystem',
    'application/vnd.otps.ct-kip+xml': 'vnd.otps.ct-kip+xml',
    'application/vnd.oxli.countgraph': 'vnd.oxli.countgraph',
    'application/vnd.pagerduty+json': 'vnd.pagerduty+json',
    'application/vnd.palm': 'vnd.palm',
    'application/vnd.panoply': 'vnd.panoply',
    'application/vnd.paos.xml': 'vnd.paos.xml',
    'application/vnd.patentdive': 'vnd.patentdive',
    'application/vnd.patientecommsdoc': 'vnd.patientecommsdoc',
    'application/vnd.pawaafile': 'vnd.pawaafile',
    'application/vnd.pcos': 'vnd.pcos',
    'application/vnd.pg.format': 'vnd.pg.format',
    'application/vnd.pg.osasli': 'vnd.pg.osasli',
    'application/vnd.piaccess.application-licence': 'vnd.piaccess.application-licence',
    'application/vnd.picsel': 'vnd.picsel',
    'application/vnd.pmi.widget': 'vnd.pmi.widget',
    'application/vnd.poc.group-advertisement+xml': 'vnd.poc.group-advertisement+xml',
    'application/vnd.pocketlearn': 'vnd.pocketlearn',
    'application/vnd.powerbuilder6': 'vnd.powerbuilder6',
    'application/vnd.powerbuilder6-s': 'vnd.powerbuilder6-s',
    'application/vnd.powerbuilder7': 'vnd.powerbuilder7',
    'application/vnd.powerbuilder75': 'vnd.powerbuilder75',
    'application/vnd.powerbuilder75-s': 'vnd.powerbuilder75-s',
    'application/vnd.powerbuilder7-s': 'vnd.powerbuilder7-s',
    'application/vnd.preminet': 'vnd.preminet',
    'application/vnd.previewsystems.box': 'vnd.previewsystems.box',
    'application/vnd.proteus.magazine': 'vnd.proteus.magazine',
    'application/vnd.psfs': 'vnd.psfs',
    'application/vnd.pt.mundusmundi': 'vnd.pt.mundusmundi',
    'application/vnd.publishare-delta-tree': 'vnd.publishare-delta-tree',
    'application/vnd.pvi.ptid1': 'vnd.pvi.ptid1',
    'application/vnd.pwg-multiplexed': 'vnd.pwg-multiplexed',
    'application/vnd.pwg-xhtml-print+xml': 'vnd.pwg-xhtml-print+xml',
    'application/vnd.qualcomm.brew-app-res': 'vnd.qualcomm.brew-app-res',
    'application/vnd.quarantainenet': 'vnd.quarantainenet',
    'application/vnd.Quark.QuarkXPress': 'vnd.Quark.QuarkXPress',
    'application/vnd.quobject-quoxdocument': 'vnd.quobject-quoxdocument',
    'application/vnd.radisys.moml+xml': 'vnd.radisys.moml+xml',
    'application/vnd.radisys.msml-audit-conf+xml': 'vnd.radisys.msml-audit-conf+xml',
    'application/vnd.radisys.msml-audit-conn+xml': 'vnd.radisys.msml-audit-conn+xml',
    'application/vnd.radisys.msml-audit-dialog+xml': 'vnd.radisys.msml-audit-dialog+xml',
    'application/vnd.radisys.msml-audit-stream+xml': 'vnd.radisys.msml-audit-stream+xml',
    'application/vnd.radisys.msml-audit+xml': 'vnd.radisys.msml-audit+xml',
    'application/vnd.radisys.msml-conf+xml': 'vnd.radisys.msml-conf+xml',
    'application/vnd.radisys.msml-dialog-base+xml': 'vnd.radisys.msml-dialog-base+xml',
    'application/vnd.radisys.msml-dialog-fax-detect+xml': 'vnd.radisys.msml-dialog-fax-detect+xml',
    'application/vnd.radisys.msml-dialog-fax-sendrecv+xml': 'vnd.radisys.msml-dialog-fax-sendrecv+xml',
    'application/vnd.radisys.msml-dialog-group+xml': 'vnd.radisys.msml-dialog-group+xml',
    'application/vnd.radisys.msml-dialog-speech+xml': 'vnd.radisys.msml-dialog-speech+xml',
    'application/vnd.radisys.msml-dialog-transform+xml': 'vnd.radisys.msml-dialog-transform+xml',
    'application/vnd.radisys.msml-dialog+xml': 'vnd.radisys.msml-dialog+xml',
    'application/vnd.radisys.msml+xml': 'vnd.radisys.msml+xml',
    'application/vnd.rainstor.data': 'vnd.rainstor.data',
    'application/vnd.rapid': 'vnd.rapid',
    'application/vnd.rar': 'vnd.rar',
    'application/vnd.realvnc.bed': 'vnd.realvnc.bed',
    'application/vnd.recordare.musicxml': 'vnd.recordare.musicxml',
    'application/vnd.recordare.musicxml+xml': 'vnd.recordare.musicxml+xml',
    'application/vnd.relpipe': 'vnd.relpipe',
    'application/vnd.RenLearn.rlprint': 'vnd.RenLearn.rlprint',
    'application/vnd.resilient.logic': 'vnd.resilient.logic',
    'application/vnd.restful+json': 'vnd.restful+json',
    'application/vnd.rig.cryptonote': 'vnd.rig.cryptonote',
    'application/vnd.route66.link66+xml': 'vnd.route66.link66+xml',
    'application/vnd.rs-274x': 'vnd.rs-274x',
    'application/vnd.ruckus.download': 'vnd.ruckus.download',
    'application/vnd.s3sms': 'vnd.s3sms',
    'application/vnd.sailingtracker.track': 'vnd.sailingtracker.track',
    'application/vnd.sar': 'vnd.sar',
    'application/vnd.sbm.cid': 'vnd.sbm.cid',
    'application/vnd.sbm.mid2': 'vnd.sbm.mid2',
    'application/vnd.scribus': 'vnd.scribus',
    'application/vnd.sealed.3df': 'vnd.sealed.3df',
    'application/vnd.sealed.csf': 'vnd.sealed.csf',
    'application/vnd.sealed.doc': 'vnd.sealed.doc',
    'application/vnd.sealed.eml': 'vnd.sealed.eml',
    'application/vnd.sealed.mht': 'vnd.sealed.mht',
    'application/vnd.sealed.net': 'vnd.sealed.net',
    'application/vnd.sealed.ppt': 'vnd.sealed.ppt',
    'application/vnd.sealed.tiff': 'vnd.sealed.tiff',
    'application/vnd.sealed.xls': 'vnd.sealed.xls',
    'application/vnd.sealedmedia.softseal.html': 'vnd.sealedmedia.softseal.html',
    'application/vnd.sealedmedia.softseal.pdf': 'vnd.sealedmedia.softseal.pdf',
    'application/vnd.seemail': 'vnd.seemail',
    'application/vnd.seis+json': 'vnd.seis+json',
    'application/vnd.sema': 'vnd.sema',
    'application/vnd.semd': 'vnd.semd',
    'application/vnd.semf': 'vnd.semf',
    'application/vnd.shade-save-file': 'vnd.shade-save-file',
    'application/vnd.shana.informed.formdata': 'vnd.shana.informed.formdata',
    'application/vnd.shana.informed.formtemplate': 'vnd.shana.informed.formtemplate',
    'application/vnd.shana.informed.interchange': 'vnd.shana.informed.interchange',
    'application/vnd.shana.informed.package': 'vnd.shana.informed.package',
    'application/vnd.shootproof+json': 'vnd.shootproof+json',
    'application/vnd.shopkick+json': 'vnd.shopkick+json',
    'application/vnd.shp': 'vnd.shp',
    'application/vnd.shx': 'vnd.shx',
    'application/vnd.sigrok.session': 'vnd.sigrok.session',
    'application/vnd.SimTech-MindMapper': 'vnd.SimTech-MindMapper',
    'application/vnd.siren+json': 'vnd.siren+json',
    'application/vnd.smaf': 'vnd.smaf',
    'application/vnd.smart.notebook': 'vnd.smart.notebook',
    'application/vnd.smart.teacher': 'vnd.smart.teacher',
    'application/vnd.smintio.portals.archive': 'vnd.smintio.portals.archive',
    'application/vnd.snesdev-page-table': 'vnd.snesdev-page-table',
    'application/vnd.software602.filler.form+xml': 'vnd.software602.filler.form+xml',
    'application/vnd.software602.filler.form-xml-zip': 'vnd.software602.filler.form-xml-zip',
    'application/vnd.solent.sdkm+xml': 'vnd.solent.sdkm+xml',
    'application/vnd.spotfire.dxp': 'vnd.spotfire.dxp',
    'application/vnd.spotfire.sfs': 'vnd.spotfire.sfs',
    'application/vnd.sqlite3': 'vnd.sqlite3',
    'application/vnd.sss-cod': 'vnd.sss-cod',
    'application/vnd.sss-dtf': 'vnd.sss-dtf',
    'application/vnd.sss-ntf': 'vnd.sss-ntf',
    'application/vnd.stepmania.package': 'vnd.stepmania.package',
    'application/vnd.stepmania.stepchart': 'vnd.stepmania.stepchart',
    'application/vnd.street-stream': 'vnd.street-stream',
    'application/vnd.sun.wadl+xml': 'vnd.sun.wadl+xml',
    'application/vnd.sus-calendar': 'vnd.sus-calendar',
    'application/vnd.svd': 'vnd.svd',
    'application/vnd.swiftview-ics': 'vnd.swiftview-ics',
    'application/vnd.sybyl.mol2': 'vnd.sybyl.mol2',
    'application/vnd.sycle+xml': 'vnd.sycle+xml',
    'application/vnd.syft+json': 'vnd.syft+json',
    'application/vnd.syncml.dm.notification': 'vnd.syncml.dm.notification',
    'application/vnd.syncml.dmddf+xml': 'vnd.syncml.dmddf+xml',
    'application/vnd.syncml.dmtnds+wbxml': 'vnd.syncml.dmtnds+wbxml',
    'application/vnd.syncml.dmtnds+xml': 'vnd.syncml.dmtnds+xml',
    'application/vnd.syncml.dmddf+wbxml': 'vnd.syncml.dmddf+wbxml',
    'application/vnd.syncml.dm+wbxml': 'vnd.syncml.dm+wbxml',
    'application/vnd.syncml.dm+xml': 'vnd.syncml.dm+xml',
    'application/vnd.syncml.ds.notification': 'vnd.syncml.ds.notification',
    'application/vnd.syncml+xml': 'vnd.syncml+xml',
    'application/vnd.tableschema+json': 'vnd.tableschema+json',
    'application/vnd.tao.intent-module-archive': 'vnd.tao.intent-module-archive',
    'application/vnd.tcpdump.pcap': 'vnd.tcpdump.pcap',
    'application/vnd.think-cell.ppttc+json': 'vnd.think-cell.ppttc+json',
    'application/vnd.tml': 'vnd.tml',
    'application/vnd.tmd.mediaflex.api+xml': 'vnd.tmd.mediaflex.api+xml',
    'application/vnd.tmobile-livetv': 'vnd.tmobile-livetv',
    'application/vnd.tri.onesource': 'vnd.tri.onesource',
    'application/vnd.trid.tpt': 'vnd.trid.tpt',
    'application/vnd.triscape.mxs': 'vnd.triscape.mxs',
    'application/vnd.trueapp': 'vnd.trueapp',
    'application/vnd.truedoc': 'vnd.truedoc',
    'application/vnd.ubisoft.webplayer': 'vnd.ubisoft.webplayer',
    'application/vnd.ufdl': 'vnd.ufdl',
    'application/vnd.uiq.theme': 'vnd.uiq.theme',
    'application/vnd.umajin': 'vnd.umajin',
    'application/vnd.unity': 'vnd.unity',
    'application/vnd.uoml+xml': 'vnd.uoml+xml',
    'application/vnd.uplanet.alert': 'vnd.uplanet.alert',
    'application/vnd.uplanet.alert-wbxml': 'vnd.uplanet.alert-wbxml',
    'application/vnd.uplanet.bearer-choice': 'vnd.uplanet.bearer-choice',
    'application/vnd.uplanet.bearer-choice-wbxml': 'vnd.uplanet.bearer-choice-wbxml',
    'application/vnd.uplanet.cacheop': 'vnd.uplanet.cacheop',
    'application/vnd.uplanet.cacheop-wbxml': 'vnd.uplanet.cacheop-wbxml',
    'application/vnd.uplanet.channel': 'vnd.uplanet.channel',
    'application/vnd.uplanet.channel-wbxml': 'vnd.uplanet.channel-wbxml',
    'application/vnd.uplanet.list': 'vnd.uplanet.list',
    'application/vnd.uplanet.listcmd': 'vnd.uplanet.listcmd',
    'application/vnd.uplanet.listcmd-wbxml': 'vnd.uplanet.listcmd-wbxml',
    'application/vnd.uplanet.list-wbxml': 'vnd.uplanet.list-wbxml',
    'application/vnd.uri-map': 'vnd.uri-map',
    'application/vnd.uplanet.signal': 'vnd.uplanet.signal',
    'application/vnd.valve.source.material': 'vnd.valve.source.material',
    'application/vnd.vcx': 'vnd.vcx',
    'application/vnd.vd-study': 'vnd.vd-study',
    'application/vnd.vectorworks': 'vnd.vectorworks',
    'application/vnd.vel+json': 'vnd.vel+json',
    'application/vnd.verimatrix.vcas': 'vnd.verimatrix.vcas',
    'application/vnd.veritone.aion+json': 'vnd.veritone.aion+json',
    'application/vnd.veryant.thin': 'vnd.veryant.thin',
    'application/vnd.ves.encrypted': 'vnd.ves.encrypted',
    'application/vnd.vidsoft.vidconference': 'vnd.vidsoft.vidconference',
    'application/vnd.visio': 'vnd.visio',
    'application/vnd.visionary': 'vnd.visionary',
    'application/vnd.vividence.scriptfile': 'vnd.vividence.scriptfile',
    'application/vnd.vsf': 'vnd.vsf',
    'application/vnd.wap.sic': 'vnd.wap.sic',
    'application/vnd.wap.slc': 'vnd.wap.slc',
    'application/vnd.wap.wbxml': 'vnd.wap.wbxml',
    'application/vnd.wap.wmlc': 'vnd.wap.wmlc',
    'application/vnd.wap.wmlscriptc': 'vnd.wap.wmlscriptc',
    'application/vnd.wasmflow.wafl': 'vnd.wasmflow.wafl',
    'application/vnd.webturbo': 'vnd.webturbo',
    'application/vnd.wfa.dpp': 'vnd.wfa.dpp',
    'application/vnd.wfa.p2p': 'vnd.wfa.p2p',
    'application/vnd.wfa.wsc': 'vnd.wfa.wsc',
    'application/vnd.windows.devicepairing': 'vnd.windows.devicepairing',
    'application/vnd.wmc': 'vnd.wmc',
    'application/vnd.wmf.bootstrap': 'vnd.wmf.bootstrap',
    'application/vnd.wolfram.mathematica': 'vnd.wolfram.mathematica',
    'application/vnd.wolfram.mathematica.package': 'vnd.wolfram.mathematica.package',
    'application/vnd.wolfram.player': 'vnd.wolfram.player',
    'application/vnd.wordlift': 'vnd.wordlift',
    'application/vnd.wordperfect': 'vnd.wordperfect',
    'application/vnd.wqd': 'vnd.wqd',
    'application/vnd.wrq-hp3000-labelled': 'vnd.wrq-hp3000-labelled',
    'application/vnd.wt.stf': 'vnd.wt.stf',
    'application/vnd.wv.csp+xml': 'vnd.wv.csp+xml',
    'application/vnd.wv.csp+wbxml': 'vnd.wv.csp+wbxml',
    'application/vnd.wv.ssp+xml': 'vnd.wv.ssp+xml',
    'application/vnd.xacml+json': 'vnd.xacml+json',
    'application/vnd.xara': 'vnd.xara',
    'application/vnd.xfdl': 'vnd.xfdl',
    'application/vnd.xfdl.webform': 'vnd.xfdl.webform',
    'application/vnd.xmi+xml': 'vnd.xmi+xml',
    'application/vnd.xmpie.cpkg': 'vnd.xmpie.cpkg',
    'application/vnd.xmpie.dpkg': 'vnd.xmpie.dpkg',
    'application/vnd.xmpie.plan': 'vnd.xmpie.plan',
    'application/vnd.xmpie.ppkg': 'vnd.xmpie.ppkg',
    'application/vnd.xmpie.xlim': 'vnd.xmpie.xlim',
    'application/vnd.yamaha.hv-dic': 'vnd.yamaha.hv-dic',
    'application/vnd.yamaha.hv-script': 'vnd.yamaha.hv-script',
    'application/vnd.yamaha.hv-voice': 'vnd.yamaha.hv-voice',
    'application/vnd.yamaha.openscoreformat.osfpvg+xml': 'vnd.yamaha.openscoreformat.osfpvg+xml',
    'application/vnd.yamaha.openscoreformat': 'vnd.yamaha.openscoreformat',
    'application/vnd.yamaha.remote-setup': 'vnd.yamaha.remote-setup',
    'application/vnd.yamaha.smaf-audio': 'vnd.yamaha.smaf-audio',
    'application/vnd.yamaha.smaf-phrase': 'vnd.yamaha.smaf-phrase',
    'application/vnd.yamaha.through-ngn': 'vnd.yamaha.through-ngn',
    'application/vnd.yamaha.tunnel-udpencap': 'vnd.yamaha.tunnel-udpencap',
    'application/vnd.yaoweme': 'vnd.yaoweme',
    'application/vnd.yellowriver-custom-menu': 'vnd.yellowriver-custom-menu',
    'application/vnd.youtube.yt': 'vnd.youtube.yt (OBSOLETED in favor of video/vnd.youtube.yt)',
    'application/vnd.zul': 'vnd.zul',
    'application/vnd.zzazz.deck+xml': 'vnd.zzazz.deck+xml',
    'application/voicexml+xml': 'voicexml+xml',
    'application/voucher-cms+json': 'voucher-cms+json',
    'application/vq-rtcpxr': 'vq-rtcpxr',
    'application/wasm': 'wasm',
    'application/watcherinfo+xml': 'watcherinfo+xml',
    'application/webpush-options+json': 'webpush-options+json',
    'application/whoispp-query': 'whoispp-query',
    'application/whoispp-response': 'whoispp-response',
    'application/widget': 'widget',
    'application/wita': 'wita',
    'application/wordperfect5.1': 'wordperfect5.1',
    'application/wsdl+xml': 'wsdl+xml',
    'application/wspolicy+xml': 'wspolicy+xml',
    'application/x-pki-message': 'x-pki-message',
    'application/x-www-form-urlencoded': 'x-www-form-urlencoded',
    'application/x-x509-ca-cert': 'x-x509-ca-cert',
    'application/x-x509-ca-ra-cert': 'x-x509-ca-ra-cert',
    'application/x-x509-next-ca-cert': 'x-x509-next-ca-cert',
    'application/x400-bp': 'x400-bp',
    'application/xacml+xml': 'xacml+xml',
    'application/xcap-att+xml': 'xcap-att+xml',
    'application/xcap-caps+xml': 'xcap-caps+xml',
    'application/xcap-diff+xml': 'xcap-diff+xml',
    'application/xcap-el+xml': 'xcap-el+xml',
    'application/xcap-error+xml': 'xcap-error+xml',
    'application/xcap-ns+xml': 'xcap-ns+xml',
    'application/xcon-conference-info-diff+xml': 'xcon-conference-info-diff+xml',
    'application/xcon-conference-info+xml': 'xcon-conference-info+xml',
    'application/xenc+xml': 'xenc+xml',
    'application/xfdf': 'xfdf',
    'application/xhtml+xml': 'xhtml+xml',
    'application/xliff+xml': 'xliff+xml',
    'application/xml': 'xml',
    'application/xml-dtd': 'xml-dtd',
    'application/xml-external-parsed-entity': 'xml-external-parsed-entity',
    'application/xml-patch+xml': 'xml-patch+xml',
    'application/xmpp+xml': 'xmpp+xml',
    'application/xop+xml': 'xop+xml',
    'application/xslt+xml': 'xslt+xml',
    'application/xv+xml': 'xv+xml',
    'application/yaml': 'yaml',
    'application/yang': 'yang',
    'application/yang-data+cbor': 'yang-data+cbor',
    'application/yang-data+json': 'yang-data+json',
    'application/yang-data+xml': 'yang-data+xml',
    'application/yang-patch+json': 'yang-patch+json',
    'application/yang-patch+xml': 'yang-patch+xml',
    'application/yin+xml': 'yin+xml',
    'application/zip': 'zip',
    'application/zlib': 'zlib',
    'application/zstd': 'zstd',
};
const extensionMimeTypeLookup = {
    '.123': 'application/vnd.lotus-1-2-3',
    '.1km': 'application/vnd.1000minds.decision-model+xml',
    '.3dml': 'text/vnd.in3d.3dml',
    '.3ds': 'image/x-3ds',
    '.3g2': 'video/3gpp2',
    '.3gp': 'video/3gpp',
    '.3gpp': 'video/3gpp',
    '.3mf': 'model/3mf',
    '.7z': 'application/x-7z-compressed',
    '.aab': 'application/x-authorware-bin',
    '.aac': 'audio/x-aac',
    '.aam': 'application/x-authorware-map',
    '.aas': 'application/x-authorware-seg',
    '.abw': 'application/x-abiword',
    '.ac': 'application/vnd.nokia.n-gage.ac+xml',
    '.acc': 'application/vnd.americandynamics.acc',
    '.ace': 'application/x-ace-compressed',
    '.acu': 'application/vnd.acucobol',
    '.acutc': 'application/vnd.acucorp',
    '.adp': 'audio/adpcm',
    '.aep': 'application/vnd.audiograph',
    '.afm': 'application/x-font-type1',
    '.afp': 'application/vnd.ibm.modcap',
    '.age': 'application/vnd.age',
    '.ahead': 'application/vnd.ahead.space',
    '.ai': 'application/postscript',
    '.aif': 'audio/x-aiff',
    '.aifc': 'audio/x-aiff',
    '.aiff': 'audio/x-aiff',
    '.air': 'application/vnd.adobe.air-application-installer-package+zip',
    '.ait': 'application/vnd.dvb.ait',
    '.ami': 'application/vnd.amiga.ami',
    '.amr': 'audio/amr',
    '.apk': 'application/vnd.android.package-archive',
    '.apng': 'image/apng',
    '.appcache': 'text/cache-manifest',
    '.application': 'application/x-ms-application',
    '.apr': 'application/vnd.lotus-approach',
    '.arc': 'application/x-freearc',
    '.arj': 'application/x-arj',
    '.asc': 'application/pgp-signature',
    '.asf': 'video/x-ms-asf',
    '.asm': 'text/x-asm',
    '.aso': 'application/vnd.accpac.simply.aso',
    '.asx': 'video/x-ms-asf',
    '.atc': 'application/vnd.acucorp',
    '.atom': 'application/atom+xml',
    '.atomcat': 'application/atomcat+xml',
    '.atomdeleted': 'application/atomdeleted+xml',
    '.atomsvc': 'application/atomsvc+xml',
    '.atx': 'application/vnd.antix.game-component',
    '.au': 'audio/basic',
    '.avci': 'image/avci',
    '.avcs': 'image/avcs',
    '.avi': 'video/x-msvideo',
    '.avif': 'image/avif',
    '.aw': 'application/applixware',
    '.azf': 'application/vnd.airzip.filesecure.azf',
    '.azs': 'application/vnd.airzip.filesecure.azs',
    '.azv': 'image/vnd.airzip.accelerator.azv',
    '.azw': 'application/vnd.amazon.ebook',
    '.b16': 'image/vnd.pco.b16',
    '.bat': 'application/x-msdownload',
    '.bcpio': 'application/x-bcpio',
    '.bdf': 'application/x-font-bdf',
    '.bdm': 'application/vnd.syncml.dm+wbxml',
    '.bdoc': 'application/x-bdoc',
    '.bed': 'application/vnd.realvnc.bed',
    '.bh2': 'application/vnd.fujitsu.oasysprs',
    '.bin': 'application/octet-stream',
    '.blb': 'application/x-blorb',
    '.blorb': 'application/x-blorb',
    '.bmi': 'application/vnd.bmi',
    '.bmml': 'application/vnd.balsamiq.bmml+xml',
    '.bmp': 'image/x-ms-bmp',
    '.book': 'application/vnd.framemaker',
    '.box': 'application/vnd.previewsystems.box',
    '.boz': 'application/x-bzip2',
    '.bpk': 'application/octet-stream',
    '.bsp': 'model/vnd.valve.source.compiled-map',
    '.btif': 'image/prs.btif',
    '.buffer': 'application/octet-stream',
    '.bz': 'application/x-bzip',
    '.bz2': 'application/x-bzip2',
    '.c': 'text/x-c',
    '.c11amc': 'application/vnd.cluetrust.cartomobile-config',
    '.c11amz': 'application/vnd.cluetrust.cartomobile-config-pkg',
    '.c4d': 'application/vnd.clonk.c4group',
    '.c4f': 'application/vnd.clonk.c4group',
    '.c4g': 'application/vnd.clonk.c4group',
    '.c4p': 'application/vnd.clonk.c4group',
    '.c4u': 'application/vnd.clonk.c4group',
    '.cab': 'application/vnd.ms-cab-compressed',
    '.caf': 'audio/x-caf',
    '.cap': 'application/vnd.tcpdump.pcap',
    '.car': 'application/vnd.curl.car',
    '.cat': 'application/vnd.ms-pki.seccat',
    '.cb7': 'application/x-cbr',
    '.cba': 'application/x-cbr',
    '.cbr': 'application/x-cbr',
    '.cbt': 'application/x-cbr',
    '.cbz': 'application/x-cbr',
    '.cc': 'text/x-c',
    '.cco': 'application/x-cocoa',
    '.cct': 'application/x-director',
    '.ccxml': 'application/ccxml+xml',
    '.cdbcmsg': 'application/vnd.contact.cmsg',
    '.cdf': 'application/x-netcdf',
    '.cdfx': 'application/cdfx+xml',
    '.cdkey': 'application/vnd.mediastation.cdkey',
    '.cdmia': 'application/cdmi-capability',
    '.cdmic': 'application/cdmi-container',
    '.cdmid': 'application/cdmi-domain',
    '.cdmio': 'application/cdmi-object',
    '.cdmiq': 'application/cdmi-queue',
    '.cdx': 'chemical/x-cdx',
    '.cdxml': 'application/vnd.chemdraw+xml',
    '.cdy': 'application/vnd.cinderella',
    '.cer': 'application/pkix-cert',
    '.cfs': 'application/x-cfs-compressed',
    '.cgm': 'image/cgm',
    '.chat': 'application/x-chat',
    '.chm': 'application/vnd.ms-htmlhelp',
    '.chrt': 'application/vnd.kde.kchart',
    '.cif': 'chemical/x-cif',
    '.cii': 'application/vnd.anser-web-certificate-issue-initiation',
    '.cil': 'application/vnd.ms-artgalry',
    '.cjs': 'application/node',
    '.cla': 'application/vnd.claymore',
    '.class': 'application/java-vm',
    '.clkk': 'application/vnd.crick.clicker.keyboard',
    '.clkp': 'application/vnd.crick.clicker.palette',
    '.clkt': 'application/vnd.crick.clicker.template',
    '.clkw': 'application/vnd.crick.clicker.wordbank',
    '.clkx': 'application/vnd.crick.clicker',
    '.clp': 'application/x-msclip',
    '.cmc': 'application/vnd.cosmocaller',
    '.cmdf': 'chemical/x-cmdf',
    '.cml': 'chemical/x-cml',
    '.cmp': 'application/vnd.yellowriver-custom-menu',
    '.cmx': 'image/x-cmx',
    '.cod': 'application/vnd.rim.cod',
    '.coffee': 'text/coffeescript',
    '.com': 'application/x-msdownload',
    '.conf': 'text/plain',
    '.cpio': 'application/x-cpio',
    '.cpl': 'application/cpl+xml',
    '.cpp': 'text/x-c',
    '.cpt': 'application/mac-compactpro',
    '.crd': 'application/x-mscardfile',
    '.crl': 'application/pkix-crl',
    '.crt': 'application/x-x509-ca-cert',
    '.crx': 'application/x-chrome-extension',
    '.cryptonote': 'application/vnd.rig.cryptonote',
    '.csh': 'application/x-csh',
    '.csl': 'application/vnd.citationstyles.style+xml',
    '.csml': 'chemical/x-csml',
    '.csp': 'application/vnd.commonspace',
    '.css': 'text/css',
    '.cst': 'application/x-director',
    '.csv': 'text/csv',
    '.cu': 'application/cu-seeme',
    '.curl': 'text/vnd.curl',
    '.cww': 'application/prs.cww',
    '.cxt': 'application/x-director',
    '.cxx': 'text/x-c',
    '.dae': 'model/vnd.collada+xml',
    '.daf': 'application/vnd.mobius.daf',
    '.dart': 'application/vnd.dart',
    '.dataless': 'application/vnd.fdsn.seed',
    '.davmount': 'application/davmount+xml',
    '.dbf': 'application/vnd.dbf',
    '.dbk': 'application/docbook+xml',
    '.dcr': 'application/x-director',
    '.dcurl': 'text/vnd.curl.dcurl',
    '.dd2': 'application/vnd.oma.dd2+xml',
    '.ddd': 'application/vnd.fujixerox.ddd',
    '.ddf': 'application/vnd.syncml.dmddf+xml',
    '.dds': 'image/vnd.ms-dds',
    '.deb': 'application/x-debian-package',
    '.def': 'text/plain',
    '.deploy': 'application/octet-stream',
    '.der': 'application/x-x509-ca-cert',
    '.dfac': 'application/vnd.dreamfactory',
    '.dgc': 'application/x-dgc-compressed',
    '.dic': 'text/x-c',
    '.dir': 'application/x-director',
    '.dis': 'application/vnd.mobius.dis',
    '.disposition-notification': 'message/disposition-notification',
    '.dist': 'application/octet-stream',
    '.distz': 'application/octet-stream',
    '.djv': 'image/vnd.djvu',
    '.djvu': 'image/vnd.djvu',
    '.dll': 'application/x-msdownload',
    '.dmg': 'application/x-apple-diskimage',
    '.dmp': 'application/vnd.tcpdump.pcap',
    '.dms': 'application/octet-stream',
    '.dna': 'application/vnd.dna',
    '.doc': 'application/msword',
    '.docm': 'application/vnd.ms-word.document.macroenabled.12',
    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.dot': 'application/msword',
    '.dotm': 'application/vnd.ms-word.template.macroenabled.12',
    '.dotx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    '.dp': 'application/vnd.osgi.dp',
    '.dpg': 'application/vnd.dpgraph',
    '.dra': 'audio/vnd.dra',
    '.drle': 'image/dicom-rle',
    '.dsc': 'text/prs.lines.tag',
    '.dssc': 'application/dssc+der',
    '.dtb': 'application/x-dtbook+xml',
    '.dtd': 'application/xml-dtd',
    '.dts': 'audio/vnd.dts',
    '.dtshd': 'audio/vnd.dts.hd',
    '.dump': 'application/octet-stream',
    '.dvb': 'video/vnd.dvb.file',
    '.dvi': 'application/x-dvi',
    '.dwd': 'application/atsc-dwd+xml',
    '.dwf': 'model/vnd.dwf',
    '.dwg': 'image/vnd.dwg',
    '.dxf': 'image/vnd.dxf',
    '.dxp': 'application/vnd.spotfire.dxp',
    '.dxr': 'application/x-director',
    '.ear': 'application/java-archive',
    '.ecelp4800': 'audio/vnd.nuera.ecelp4800',
    '.ecelp7470': 'audio/vnd.nuera.ecelp7470',
    '.ecelp9600': 'audio/vnd.nuera.ecelp9600',
    '.ecma': 'application/ecmascript',
    '.edm': 'application/vnd.novadigm.edm',
    '.edx': 'application/vnd.novadigm.edx',
    '.efif': 'application/vnd.picsel',
    '.ei6': 'application/vnd.pg.osasli',
    '.elc': 'application/octet-stream',
    '.emf': 'image/emf',
    '.eml': 'message/rfc822',
    '.emma': 'application/emma+xml',
    '.emotionml': 'application/emotionml+xml',
    '.emz': 'application/x-msmetafile',
    '.eol': 'audio/vnd.digital-winds',
    '.eot': 'application/vnd.ms-fontobject',
    '.eps': 'application/postscript',
    '.epub': 'application/epub+zip',
    '.es': 'application/ecmascript',
    '.es3': 'application/vnd.eszigno3+xml',
    '.esa': 'application/vnd.osgi.subsystem',
    '.esf': 'application/vnd.epson.esf',
    '.et3': 'application/vnd.eszigno3+xml',
    '.etx': 'text/x-setext',
    '.eva': 'application/x-eva',
    '.evy': 'application/x-envoy',
    '.exe': 'application/x-msdownload',
    '.exi': 'application/exi',
    '.exp': 'application/express',
    '.exr': 'image/aces',
    '.ext': 'application/vnd.novadigm.ext',
    '.ez': 'application/andrew-inset',
    '.ez2': 'application/vnd.ezpix-album',
    '.ez3': 'application/vnd.ezpix-package',
    '.f': 'text/x-fortran',
    '.f4v': 'video/x-f4v',
    '.f77': 'text/x-fortran',
    '.f90': 'text/x-fortran',
    '.fbs': 'image/vnd.fastbidsheet',
    '.fcdt': 'application/vnd.adobe.formscentral.fcdt',
    '.fcs': 'application/vnd.isac.fcs',
    '.fdf': 'application/vnd.fdf',
    '.fdt': 'application/fdt+xml',
    '.fe_launch': 'application/vnd.denovo.fcselayout-link',
    '.fg5': 'application/vnd.fujitsu.oasysgp',
    '.fgd': 'application/x-director',
    '.fh': 'image/x-freehand',
    '.fh4': 'image/x-freehand',
    '.fh5': 'image/x-freehand',
    '.fh7': 'image/x-freehand',
    '.fhc': 'image/x-freehand',
    '.fig': 'application/x-xfig',
    '.fits': 'image/fits',
    '.flac': 'audio/x-flac',
    '.fli': 'video/x-fli',
    '.flo': 'application/vnd.micrografx.flo',
    '.flv': 'video/x-flv',
    '.flw': 'application/vnd.kde.kivio',
    '.flx': 'text/vnd.fmi.flexstor',
    '.fly': 'text/vnd.fly',
    '.fm': 'application/vnd.framemaker',
    '.fnc': 'application/vnd.frogans.fnc',
    '.fo': 'application/vnd.software602.filler.form+xml',
    '.for': 'text/x-fortran',
    '.fpx': 'image/vnd.fpx',
    '.frame': 'application/vnd.framemaker',
    '.fsc': 'application/vnd.fsc.weblaunch',
    '.fst': 'image/vnd.fst',
    '.ftc': 'application/vnd.fluxtime.clip',
    '.fti': 'application/vnd.anser-web-funds-transfer-initiation',
    '.fvt': 'video/vnd.fvt',
    '.fxp': 'application/vnd.adobe.fxp',
    '.fxpl': 'application/vnd.adobe.fxp',
    '.fzs': 'application/vnd.fuzzysheet',
    '.g2w': 'application/vnd.geoplan',
    '.g3': 'image/g3fax',
    '.g3w': 'application/vnd.geospace',
    '.gac': 'application/vnd.groove-account',
    '.gam': 'application/x-tads',
    '.gbr': 'application/rpki-ghostbusters',
    '.gca': 'application/x-gca-compressed',
    '.gdl': 'model/vnd.gdl',
    '.gdoc': 'application/vnd.google-apps.document',
    '.ged': 'text/vnd.familysearch.gedcom',
    '.geo': 'application/vnd.dynageo',
    '.geojson': 'application/geo+json',
    '.gex': 'application/vnd.geometry-explorer',
    '.ggb': 'application/vnd.geogebra.file',
    '.ggt': 'application/vnd.geogebra.tool',
    '.ghf': 'application/vnd.groove-help',
    '.gif': 'image/gif',
    '.gim': 'application/vnd.groove-identity-message',
    '.glb': 'model/gltf-binary',
    '.gltf': 'model/gltf+json',
    '.gml': 'application/gml+xml',
    '.gmx': 'application/vnd.gmx',
    '.gnumeric': 'application/x-gnumeric',
    '.gph': 'application/vnd.flographit',
    '.gpx': 'application/gpx+xml',
    '.gqf': 'application/vnd.grafeq',
    '.gqs': 'application/vnd.grafeq',
    '.gram': 'application/srgs',
    '.gramps': 'application/x-gramps-xml',
    '.gre': 'application/vnd.geometry-explorer',
    '.grv': 'application/vnd.groove-injector',
    '.grxml': 'application/srgs+xml',
    '.gsf': 'application/x-font-ghostscript',
    '.gsheet': 'application/vnd.google-apps.spreadsheet',
    '.gslides': 'application/vnd.google-apps.presentation',
    '.gtar': 'application/x-gtar',
    '.gtm': 'application/vnd.groove-tool-message',
    '.gtw': 'model/vnd.gtw',
    '.gv': 'text/vnd.graphviz',
    '.gxf': 'application/gxf',
    '.gxt': 'application/vnd.geonext',
    '.gz': 'application/gzip',
    '.h': 'text/x-c',
    '.h261': 'video/h261',
    '.h263': 'video/h263',
    '.h264': 'video/h264',
    '.hal': 'application/vnd.hal+xml',
    '.hbci': 'application/vnd.hbci',
    '.hbs': 'text/x-handlebars-template',
    '.hdd': 'application/x-virtualbox-hdd',
    '.hdf': 'application/x-hdf',
    '.heic': 'image/heic',
    '.heics': 'image/heic-sequence',
    '.heif': 'image/heif',
    '.heifs': 'image/heif-sequence',
    '.hej2': 'image/hej2k',
    '.held': 'application/atsc-held+xml',
    '.hh': 'text/x-c',
    '.hjson': 'application/hjson',
    '.hlp': 'application/winhlp',
    '.hpgl': 'application/vnd.hp-hpgl',
    '.hpid': 'application/vnd.hp-hpid',
    '.hps': 'application/vnd.hp-hps',
    '.hqx': 'application/mac-binhex40',
    '.hsj2': 'image/hsj2',
    '.htc': 'text/x-component',
    '.htke': 'application/vnd.kenameaapp',
    '.htm': 'text/html',
    '.html': 'text/html',
    '.hvd': 'application/vnd.yamaha.hv-dic',
    '.hvp': 'application/vnd.yamaha.hv-voice',
    '.hvs': 'application/vnd.yamaha.hv-script',
    '.i2g': 'application/vnd.intergeo',
    '.icc': 'application/vnd.iccprofile',
    '.ice': 'x-conference/x-cooltalk',
    '.icm': 'application/vnd.iccprofile',
    '.ico': 'image/x-icon',
    '.ics': 'text/calendar',
    '.ief': 'image/ief',
    '.ifb': 'text/calendar',
    '.ifm': 'application/vnd.shana.informed.formdata',
    '.iges': 'model/iges',
    '.igl': 'application/vnd.igloader',
    '.igm': 'application/vnd.insors.igm',
    '.igs': 'model/iges',
    '.igx': 'application/vnd.micrografx.igx',
    '.iif': 'application/vnd.shana.informed.interchange',
    '.img': 'application/octet-stream',
    '.imp': 'application/vnd.accpac.simply.imp',
    '.ims': 'application/vnd.ms-ims',
    '.in': 'text/plain',
    '.ini': 'text/plain',
    '.ink': 'application/inkml+xml',
    '.inkml': 'application/inkml+xml',
    '.install': 'application/x-install-instructions',
    '.iota': 'application/vnd.astraea-software.iota',
    '.ipfix': 'application/ipfix',
    '.ipk': 'application/vnd.shana.informed.package',
    '.irm': 'application/vnd.ibm.rights-management',
    '.irp': 'application/vnd.irepository.package+xml',
    '.iso': 'application/x-iso9660-image',
    '.itp': 'application/vnd.shana.informed.formtemplate',
    '.its': 'application/its+xml',
    '.ivp': 'application/vnd.immervision-ivp',
    '.ivu': 'application/vnd.immervision-ivu',
    '.jad': 'text/vnd.sun.j2me.app-descriptor',
    '.jade': 'text/jade',
    '.jam': 'application/vnd.jam',
    '.jar': 'application/java-archive',
    '.jardiff': 'application/x-java-archive-diff',
    '.java': 'text/x-java-source',
    '.jhc': 'image/jphc',
    '.jisp': 'application/vnd.jisp',
    '.jls': 'image/jls',
    '.jlt': 'application/vnd.hp-jlyt',
    '.jng': 'image/x-jng',
    '.jnlp': 'application/x-java-jnlp-file',
    '.joda': 'application/vnd.joost.joda-archive',
    '.jp2': 'image/jp2',
    '.jpe': 'image/jpeg',
    '.jpeg': 'image/jpeg',
    '.jpf': 'image/jpx',
    '.jpg': 'image/jpeg',
    '.jpg2': 'image/jp2',
    '.jpgm': 'video/jpm',
    '.jpgv': 'video/jpeg',
    '.jph': 'image/jph',
    '.jpm': 'video/jpm',
    '.jpx': 'image/jpx',
    '.js': 'application/javascript',
    '.json': 'application/json',
    '.json5': 'application/json5',
    '.jsonld': 'application/ld+json',
    '.jsonml': 'application/jsonml+json',
    '.jsx': 'text/jsx',
    '.jxr': 'image/jxr',
    '.jxra': 'image/jxra',
    '.jxrs': 'image/jxrs',
    '.jxs': 'image/jxs',
    '.jxsc': 'image/jxsc',
    '.jxsi': 'image/jxsi',
    '.jxss': 'image/jxss',
    '.kar': 'audio/midi',
    '.karbon': 'application/vnd.kde.karbon',
    '.kdbx': 'application/x-keepass2',
    '.key': 'application/x-iwork-keynote-sffkey',
    '.kfo': 'application/vnd.kde.kformula',
    '.kia': 'application/vnd.kidspiration',
    '.kml': 'application/vnd.google-earth.kml+xml',
    '.kmz': 'application/vnd.google-earth.kmz',
    '.kne': 'application/vnd.kinar',
    '.knp': 'application/vnd.kinar',
    '.kon': 'application/vnd.kde.kontour',
    '.kpr': 'application/vnd.kde.kpresenter',
    '.kpt': 'application/vnd.kde.kpresenter',
    '.kpxx': 'application/vnd.ds-keypoint',
    '.ksp': 'application/vnd.kde.kspread',
    '.ktr': 'application/vnd.kahootz',
    '.ktx': 'image/ktx',
    '.ktx2': 'image/ktx2',
    '.ktz': 'application/vnd.kahootz',
    '.kwd': 'application/vnd.kde.kword',
    '.kwt': 'application/vnd.kde.kword',
    '.lasxml': 'application/vnd.las.las+xml',
    '.latex': 'application/x-latex',
    '.lbd': 'application/vnd.llamagraphics.life-balance.desktop',
    '.lbe': 'application/vnd.llamagraphics.life-balance.exchange+xml',
    '.les': 'application/vnd.hhe.lesson-player',
    '.less': 'text/less',
    '.lgr': 'application/lgr+xml',
    '.lha': 'application/x-lzh-compressed',
    '.link66': 'application/vnd.route66.link66+xml',
    '.list': 'text/plain',
    '.list3820': 'application/vnd.ibm.modcap',
    '.listafp': 'application/vnd.ibm.modcap',
    '.litcoffee': 'text/coffeescript',
    '.lnk': 'application/x-ms-shortcut',
    '.log': 'text/plain',
    '.lostxml': 'application/lost+xml',
    '.lrf': 'application/octet-stream',
    '.lrm': 'application/vnd.ms-lrm',
    '.ltf': 'application/vnd.frogans.ltf',
    '.lua': 'text/x-lua',
    '.luac': 'application/x-lua-bytecode',
    '.lvp': 'audio/vnd.lucent.voice',
    '.lwp': 'application/vnd.lotus-wordpro',
    '.lzh': 'application/x-lzh-compressed',
    '.m13': 'application/x-msmediaview',
    '.m14': 'application/x-msmediaview',
    '.m1v': 'video/mpeg',
    '.m21': 'application/mp21',
    '.m2a': 'audio/mpeg',
    '.m2v': 'video/mpeg',
    '.m3a': 'audio/mpeg',
    '.m3u': 'audio/x-mpegurl',
    '.m3u8': 'application/vnd.apple.mpegurl',
    '.m4a': 'audio/x-m4a',
    '.m4p': 'application/mp4',
    '.m4s': 'video/iso.segment',
    '.m4u': 'video/vnd.mpegurl',
    '.m4v': 'video/x-m4v',
    '.ma': 'application/mathematica',
    '.mads': 'application/mads+xml',
    '.maei': 'application/mmt-aei+xml',
    '.mag': 'application/vnd.ecowin.chart',
    '.maker': 'application/vnd.framemaker',
    '.man': 'text/troff',
    '.manifest': 'text/cache-manifest',
    '.map': 'application/json',
    '.mar': 'application/octet-stream',
    '.markdown': 'text/markdown',
    '.mathml': 'application/mathml+xml',
    '.mb': 'application/mathematica',
    '.mbk': 'application/vnd.mobius.mbk',
    '.mbox': 'application/mbox',
    '.mc1': 'application/vnd.medcalcdata',
    '.mcd': 'application/vnd.mcd',
    '.mcurl': 'text/vnd.curl.mcurl',
    '.md': 'text/markdown',
    '.mdb': 'application/x-msaccess',
    '.mdi': 'image/vnd.ms-modi',
    '.mdx': 'text/mdx',
    '.me': 'text/troff',
    '.mesh': 'model/mesh',
    '.meta4': 'application/metalink4+xml',
    '.metalink': 'application/metalink+xml',
    '.mets': 'application/mets+xml',
    '.mfm': 'application/vnd.mfmp',
    '.mft': 'application/rpki-manifest',
    '.mgp': 'application/vnd.osgeo.mapguide.package',
    '.mgz': 'application/vnd.proteus.magazine',
    '.mid': 'audio/midi',
    '.midi': 'audio/midi',
    '.mie': 'application/x-mie',
    '.mif': 'application/vnd.mif',
    '.mime': 'message/rfc822',
    '.mj2': 'video/mj2',
    '.mjp2': 'video/mj2',
    '.mjs': 'application/javascript',
    '.mk3d': 'video/x-matroska',
    '.mka': 'audio/x-matroska',
    '.mkd': 'text/x-markdown',
    '.mks': 'video/x-matroska',
    '.mkv': 'video/x-matroska',
    '.mlp': 'application/vnd.dolby.mlp',
    '.mmd': 'application/vnd.chipnuts.karaoke-mmd',
    '.mmf': 'application/vnd.smaf',
    '.mml': 'text/mathml',
    '.mmr': 'image/vnd.fujixerox.edmics-mmr',
    '.mng': 'video/x-mng',
    '.mny': 'application/x-msmoney',
    '.mobi': 'application/x-mobipocket-ebook',
    '.mods': 'application/mods+xml',
    '.mov': 'video/quicktime',
    '.movie': 'video/x-sgi-movie',
    '.mp2': 'audio/mpeg',
    '.mp21': 'application/mp21',
    '.mp2a': 'audio/mpeg',
    '.mp3': 'audio/mpeg',
    '.mp4': 'video/mp4',
    '.mp4a': 'audio/mp4',
    '.mp4s': 'application/mp4',
    '.mp4v': 'video/mp4',
    '.mpc': 'application/vnd.mophun.certificate',
    '.mpd': 'application/dash+xml',
    '.mpe': 'video/mpeg',
    '.mpeg': 'video/mpeg',
    '.mpf': 'application/media-policy-dataset+xml',
    '.mpg': 'video/mpeg',
    '.mpg4': 'video/mp4',
    '.mpga': 'audio/mpeg',
    '.mpkg': 'application/vnd.apple.installer+xml',
    '.mpm': 'application/vnd.blueice.multipass',
    '.mpn': 'application/vnd.mophun.application',
    '.mpp': 'application/vnd.ms-project',
    '.mpt': 'application/vnd.ms-project',
    '.mpy': 'application/vnd.ibm.minipay',
    '.mqy': 'application/vnd.mobius.mqy',
    '.mrc': 'application/marc',
    '.mrcx': 'application/marcxml+xml',
    '.ms': 'text/troff',
    '.mscml': 'application/mediaservercontrol+xml',
    '.mseed': 'application/vnd.fdsn.mseed',
    '.mseq': 'application/vnd.mseq',
    '.msf': 'application/vnd.epson.msf',
    '.msg': 'application/vnd.ms-outlook',
    '.msh': 'model/mesh',
    '.msi': 'application/x-msdownload',
    '.msl': 'application/vnd.mobius.msl',
    '.msm': 'application/octet-stream',
    '.msp': 'application/octet-stream',
    '.msty': 'application/vnd.muvee.style',
    '.mtl': 'model/mtl',
    '.mts': 'model/vnd.mts',
    '.mus': 'application/vnd.musician',
    '.musd': 'application/mmt-usd+xml',
    '.musicxml': 'application/vnd.recordare.musicxml+xml',
    '.mvb': 'application/x-msmediaview',
    '.mvt': 'application/vnd.mapbox-vector-tile',
    '.mwf': 'application/vnd.mfer',
    '.mxf': 'application/mxf',
    '.mxl': 'application/vnd.recordare.musicxml',
    '.mxmf': 'audio/mobile-xmf',
    '.mxml': 'application/xv+xml',
    '.mxs': 'application/vnd.triscape.mxs',
    '.mxu': 'video/vnd.mpegurl',
    '.n-gage': 'application/vnd.nokia.n-gage.symbian.install',
    '.n3': 'text/n3',
    '.nb': 'application/mathematica',
    '.nbp': 'application/vnd.wolfram.player',
    '.nc': 'application/x-netcdf',
    '.ncx': 'application/x-dtbncx+xml',
    '.nfo': 'text/x-nfo',
    '.ngdat': 'application/vnd.nokia.n-gage.data',
    '.nitf': 'application/vnd.nitf',
    '.nlu': 'application/vnd.neurolanguage.nlu',
    '.nml': 'application/vnd.enliven',
    '.nnd': 'application/vnd.noblenet-directory',
    '.nns': 'application/vnd.noblenet-sealer',
    '.nnw': 'application/vnd.noblenet-web',
    '.npx': 'image/vnd.net-fpx',
    '.nq': 'application/n-quads',
    '.nsc': 'application/x-conference',
    '.nsf': 'application/vnd.lotus-notes',
    '.nt': 'application/n-triples',
    '.ntf': 'application/vnd.nitf',
    '.numbers': 'application/x-iwork-numbers-sffnumbers',
    '.nzb': 'application/x-nzb',
    '.oa2': 'application/vnd.fujitsu.oasys2',
    '.oa3': 'application/vnd.fujitsu.oasys3',
    '.oas': 'application/vnd.fujitsu.oasys',
    '.obd': 'application/x-msbinder',
    '.obgx': 'application/vnd.openblox.game+xml',
    '.obj': 'model/obj',
    '.oda': 'application/oda',
    '.odb': 'application/vnd.oasis.opendocument.database',
    '.odc': 'application/vnd.oasis.opendocument.chart',
    '.odf': 'application/vnd.oasis.opendocument.formula',
    '.odft': 'application/vnd.oasis.opendocument.formula-template',
    '.odg': 'application/vnd.oasis.opendocument.graphics',
    '.odi': 'application/vnd.oasis.opendocument.image',
    '.odm': 'application/vnd.oasis.opendocument.text-master',
    '.odp': 'application/vnd.oasis.opendocument.presentation',
    '.ods': 'application/vnd.oasis.opendocument.spreadsheet',
    '.odt': 'application/vnd.oasis.opendocument.text',
    '.oga': 'audio/ogg',
    '.ogex': 'model/vnd.opengex',
    '.ogg': 'audio/ogg',
    '.ogv': 'video/ogg',
    '.ogx': 'application/ogg',
    '.omdoc': 'application/omdoc+xml',
    '.onepkg': 'application/onenote',
    '.onetmp': 'application/onenote',
    '.onetoc': 'application/onenote',
    '.onetoc2': 'application/onenote',
    '.opf': 'application/oebps-package+xml',
    '.opml': 'text/x-opml',
    '.oprc': 'application/vnd.palm',
    '.opus': 'audio/ogg',
    '.org': 'text/x-org',
    '.osf': 'application/vnd.yamaha.openscoreformat',
    '.osfpvg': 'application/vnd.yamaha.openscoreformat.osfpvg+xml',
    '.osm': 'application/vnd.openstreetmap.data+xml',
    '.otc': 'application/vnd.oasis.opendocument.chart-template',
    '.otf': 'font/otf',
    '.otg': 'application/vnd.oasis.opendocument.graphics-template',
    '.oth': 'application/vnd.oasis.opendocument.text-web',
    '.oti': 'application/vnd.oasis.opendocument.image-template',
    '.otp': 'application/vnd.oasis.opendocument.presentation-template',
    '.ots': 'application/vnd.oasis.opendocument.spreadsheet-template',
    '.ott': 'application/vnd.oasis.opendocument.text-template',
    '.ova': 'application/x-virtualbox-ova',
    '.ovf': 'application/x-virtualbox-ovf',
    '.owl': 'application/rdf+xml',
    '.oxps': 'application/oxps',
    '.oxt': 'application/vnd.openofficeorg.extension',
    '.p': 'text/x-pascal',
    '.p10': 'application/pkcs10',
    '.p12': 'application/x-pkcs12',
    '.p7b': 'application/x-pkcs7-certificates',
    '.p7c': 'application/pkcs7-mime',
    '.p7m': 'application/pkcs7-mime',
    '.p7r': 'application/x-pkcs7-certreqresp',
    '.p7s': 'application/pkcs7-signature',
    '.p8': 'application/pkcs8',
    '.pac': 'application/x-ns-proxy-autoconfig',
    '.pages': 'application/x-iwork-pages-sffpages',
    '.pas': 'text/x-pascal',
    '.paw': 'application/vnd.pawaafile',
    '.pbd': 'application/vnd.powerbuilder6',
    '.pbm': 'image/x-portable-bitmap',
    '.pcap': 'application/vnd.tcpdump.pcap',
    '.pcf': 'application/x-font-pcf',
    '.pcl': 'application/vnd.hp-pcl',
    '.pclxl': 'application/vnd.hp-pclxl',
    '.pct': 'image/x-pict',
    '.pcurl': 'application/vnd.curl.pcurl',
    '.pcx': 'image/x-pcx',
    '.pdb': 'application/x-pilot',
    '.pde': 'text/x-processing',
    '.pdf': 'application/pdf',
    '.pem': 'application/x-x509-ca-cert',
    '.pfa': 'application/x-font-type1',
    '.pfb': 'application/x-font-type1',
    '.pfm': 'application/x-font-type1',
    '.pfr': 'application/font-tdpfr',
    '.pfx': 'application/x-pkcs12',
    '.pgm': 'image/x-portable-graymap',
    '.pgn': 'application/x-chess-pgn',
    '.pgp': 'application/pgp-encrypted',
    '.php': 'application/x-httpd-php',
    '.pic': 'image/x-pict',
    '.pkg': 'application/octet-stream',
    '.pki': 'application/pkixcmp',
    '.pkipath': 'application/pkix-pkipath',
    '.pkpass': 'application/vnd.apple.pkpass',
    '.pl': 'application/x-perl',
    '.plb': 'application/vnd.3gpp.pic-bw-large',
    '.plc': 'application/vnd.mobius.plc',
    '.plf': 'application/vnd.pocketlearn',
    '.pls': 'application/pls+xml',
    '.pm': 'application/x-perl',
    '.pml': 'application/vnd.ctc-posml',
    '.png': 'image/png',
    '.pnm': 'image/x-portable-anymap',
    '.portpkg': 'application/vnd.macports.portpkg',
    '.pot': 'application/vnd.ms-powerpoint',
    '.potm': 'application/vnd.ms-powerpoint.template.macroenabled.12',
    '.potx': 'application/vnd.openxmlformats-officedocument.presentationml.template',
    '.ppam': 'application/vnd.ms-powerpoint.addin.macroenabled.12',
    '.ppd': 'application/vnd.cups-ppd',
    '.ppm': 'image/x-portable-pixmap',
    '.pps': 'application/vnd.ms-powerpoint',
    '.ppsm': 'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
    '.ppsx': 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    '.ppt': 'application/vnd.ms-powerpoint',
    '.pptm': 'application/vnd.ms-powerpoint.presentation.macroenabled.12',
    '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    '.pqa': 'application/vnd.palm',
    '.prc': 'application/x-pilot',
    '.pre': 'application/vnd.lotus-freelance',
    '.prf': 'application/pics-rules',
    '.provx': 'application/provenance+xml',
    '.ps': 'application/postscript',
    '.psb': 'application/vnd.3gpp.pic-bw-small',
    '.psd': 'image/vnd.adobe.photoshop',
    '.psf': 'application/x-font-linux-psf',
    '.pskcxml': 'application/pskc+xml',
    '.pti': 'image/prs.pti',
    '.ptid': 'application/vnd.pvi.ptid1',
    '.pub': 'application/x-mspublisher',
    '.pvb': 'application/vnd.3gpp.pic-bw-var',
    '.pwn': 'application/vnd.3m.post-it-notes',
    '.pya': 'audio/vnd.ms-playready.media.pya',
    '.pyv': 'video/vnd.ms-playready.media.pyv',
    '.qam': 'application/vnd.epson.quickanime',
    '.qbo': 'application/vnd.intu.qbo',
    '.qfx': 'application/vnd.intu.qfx',
    '.qps': 'application/vnd.publishare-delta-tree',
    '.qt': 'video/quicktime',
    '.qwd': 'application/vnd.quark.quarkxpress',
    '.qwt': 'application/vnd.quark.quarkxpress',
    '.qxb': 'application/vnd.quark.quarkxpress',
    '.qxd': 'application/vnd.quark.quarkxpress',
    '.qxl': 'application/vnd.quark.quarkxpress',
    '.qxt': 'application/vnd.quark.quarkxpress',
    '.ra': 'audio/x-realaudio',
    '.ram': 'audio/x-pn-realaudio',
    '.raml': 'application/raml+yaml',
    '.rapd': 'application/route-apd+xml',
    '.rar': 'application/x-rar-compressed',
    '.ras': 'image/x-cmu-raster',
    '.rcprofile': 'application/vnd.ipunplugged.rcprofile',
    '.rdf': 'application/rdf+xml',
    '.rdz': 'application/vnd.data-vision.rdz',
    '.relo': 'application/p2p-overlay+xml',
    '.rep': 'application/vnd.businessobjects',
    '.res': 'application/x-dtbresource+xml',
    '.rgb': 'image/x-rgb',
    '.rif': 'application/reginfo+xml',
    '.rip': 'audio/vnd.rip',
    '.ris': 'application/x-research-info-systems',
    '.rl': 'application/resource-lists+xml',
    '.rlc': 'image/vnd.fujixerox.edmics-rlc',
    '.rld': 'application/resource-lists-diff+xml',
    '.rm': 'application/vnd.rn-realmedia',
    '.rmi': 'audio/midi',
    '.rmp': 'audio/x-pn-realaudio-plugin',
    '.rms': 'application/vnd.jcp.javame.midlet-rms',
    '.rmvb': 'application/vnd.rn-realmedia-vbr',
    '.rnc': 'application/relax-ng-compact-syntax',
    '.rng': 'application/xml',
    '.roa': 'application/rpki-roa',
    '.roff': 'text/troff',
    '.rp9': 'application/vnd.cloanto.rp9',
    '.rpm': 'application/x-redhat-package-manager',
    '.rpss': 'application/vnd.nokia.radio-presets',
    '.rpst': 'application/vnd.nokia.radio-preset',
    '.rq': 'application/sparql-query',
    '.rs': 'application/rls-services+xml',
    '.rsat': 'application/atsc-rsat+xml',
    '.rsd': 'application/rsd+xml',
    '.rsheet': 'application/urc-ressheet+xml',
    '.rss': 'application/rss+xml',
    '.rtf': 'text/rtf',
    '.rtx': 'text/richtext',
    '.run': 'application/x-makeself',
    '.rusd': 'application/route-usd+xml',
    '.s': 'text/x-asm',
    '.s3m': 'audio/s3m',
    '.saf': 'application/vnd.yamaha.smaf-audio',
    '.sass': 'text/x-sass',
    '.sbml': 'application/sbml+xml',
    '.sc': 'application/vnd.ibm.secure-container',
    '.scd': 'application/x-msschedule',
    '.scm': 'application/vnd.lotus-screencam',
    '.scq': 'application/scvp-cv-request',
    '.scs': 'application/scvp-cv-response',
    '.scss': 'text/x-scss',
    '.scurl': 'text/vnd.curl.scurl',
    '.sda': 'application/vnd.stardivision.draw',
    '.sdc': 'application/vnd.stardivision.calc',
    '.sdd': 'application/vnd.stardivision.impress',
    '.sdkd': 'application/vnd.solent.sdkm+xml',
    '.sdkm': 'application/vnd.solent.sdkm+xml',
    '.sdp': 'application/sdp',
    '.sdw': 'application/vnd.stardivision.writer',
    '.sea': 'application/x-sea',
    '.see': 'application/vnd.seemail',
    '.seed': 'application/vnd.fdsn.seed',
    '.sema': 'application/vnd.sema',
    '.semd': 'application/vnd.semd',
    '.semf': 'application/vnd.semf',
    '.senmlx': 'application/senml+xml',
    '.sensmlx': 'application/sensml+xml',
    '.ser': 'application/java-serialized-object',
    '.setpay': 'application/set-payment-initiation',
    '.setreg': 'application/set-registration-initiation',
    '.sfd-hdstx': 'application/vnd.hydrostatix.sof-data',
    '.sfs': 'application/vnd.spotfire.sfs',
    '.sfv': 'text/x-sfv',
    '.sgi': 'image/sgi',
    '.sgl': 'application/vnd.stardivision.writer-global',
    '.sgm': 'text/sgml',
    '.sgml': 'text/sgml',
    '.sh': 'application/x-sh',
    '.shar': 'application/x-shar',
    '.shex': 'text/shex',
    '.shf': 'application/shf+xml',
    '.shtml': 'text/html',
    '.sid': 'image/x-mrsid-image',
    '.sieve': 'application/sieve',
    '.sig': 'application/pgp-signature',
    '.sil': 'audio/silk',
    '.silo': 'model/mesh',
    '.sis': 'application/vnd.symbian.install',
    '.sisx': 'application/vnd.symbian.install',
    '.sit': 'application/x-stuffit',
    '.sitx': 'application/x-stuffitx',
    '.siv': 'application/sieve',
    '.skd': 'application/vnd.koan',
    '.skm': 'application/vnd.koan',
    '.skp': 'application/vnd.koan',
    '.skt': 'application/vnd.koan',
    '.sldm': 'application/vnd.ms-powerpoint.slide.macroenabled.12',
    '.sldx': 'application/vnd.openxmlformats-officedocument.presentationml.slide',
    '.slim': 'text/slim',
    '.slm': 'text/slim',
    '.sls': 'application/route-s-tsid+xml',
    '.slt': 'application/vnd.epson.salt',
    '.sm': 'application/vnd.stepmania.stepchart',
    '.smf': 'application/vnd.stardivision.math',
    '.smi': 'application/smil+xml',
    '.smil': 'application/smil+xml',
    '.smv': 'video/x-smv',
    '.smzip': 'application/vnd.stepmania.package',
    '.snd': 'audio/basic',
    '.snf': 'application/x-font-snf',
    '.so': 'application/octet-stream',
    '.spc': 'application/x-pkcs7-certificates',
    '.spdx': 'text/spdx',
    '.spf': 'application/vnd.yamaha.smaf-phrase',
    '.spl': 'application/x-futuresplash',
    '.spot': 'text/vnd.in3d.spot',
    '.spp': 'application/scvp-vp-response',
    '.spq': 'application/scvp-vp-request',
    '.spx': 'audio/ogg',
    '.sql': 'application/x-sql',
    '.src': 'application/x-wais-source',
    '.srt': 'application/x-subrip',
    '.sru': 'application/sru+xml',
    '.srx': 'application/sparql-results+xml',
    '.ssdl': 'application/ssdl+xml',
    '.sse': 'application/vnd.kodak-descriptor',
    '.ssf': 'application/vnd.epson.ssf',
    '.ssml': 'application/ssml+xml',
    '.st': 'application/vnd.sailingtracker.track',
    '.stc': 'application/vnd.sun.xml.calc.template',
    '.std': 'application/vnd.sun.xml.draw.template',
    '.stf': 'application/vnd.wt.stf',
    '.sti': 'application/vnd.sun.xml.impress.template',
    '.stk': 'application/hyperstudio',
    '.stl': 'model/stl',
    '.stpx': 'model/step+xml',
    '.stpxz': 'model/step-xml+zip',
    '.stpz': 'model/step+zip',
    '.str': 'application/vnd.pg.format',
    '.stw': 'application/vnd.sun.xml.writer.template',
    '.styl': 'text/stylus',
    '.stylus': 'text/stylus',
    '.sub': 'text/vnd.dvb.subtitle',
    '.sus': 'application/vnd.sus-calendar',
    '.susp': 'application/vnd.sus-calendar',
    '.sv4cpio': 'application/x-sv4cpio',
    '.sv4crc': 'application/x-sv4crc',
    '.svc': 'application/vnd.dvb.service',
    '.svd': 'application/vnd.svd',
    '.svg': 'image/svg+xml',
    '.svgz': 'image/svg+xml',
    '.swa': 'application/x-director',
    '.swf': 'application/x-shockwave-flash',
    '.swi': 'application/vnd.aristanetworks.swi',
    '.swidtag': 'application/swid+xml',
    '.sxc': 'application/vnd.sun.xml.calc',
    '.sxd': 'application/vnd.sun.xml.draw',
    '.sxg': 'application/vnd.sun.xml.writer.global',
    '.sxi': 'application/vnd.sun.xml.impress',
    '.sxm': 'application/vnd.sun.xml.math',
    '.sxw': 'application/vnd.sun.xml.writer',
    '.t': 'text/troff',
    '.t3': 'application/x-t3vm-image',
    '.t38': 'image/t38',
    '.taglet': 'application/vnd.mynfc',
    '.tao': 'application/vnd.tao.intent-module-archive',
    '.tap': 'image/vnd.tencent.tap',
    '.tar': 'application/x-tar',
    '.tcap': 'application/vnd.3gpp2.tcap',
    '.tcl': 'application/x-tcl',
    '.td': 'application/urc-targetdesc+xml',
    '.teacher': 'application/vnd.smart.teacher',
    '.tei': 'application/tei+xml',
    '.teicorpus': 'application/tei+xml',
    '.tex': 'application/x-tex',
    '.texi': 'application/x-texinfo',
    '.texinfo': 'application/x-texinfo',
    '.text': 'text/plain',
    '.tfi': 'application/thraud+xml',
    '.tfm': 'application/x-tex-tfm',
    '.tfx': 'image/tiff-fx',
    '.tga': 'image/x-tga',
    '.thmx': 'application/vnd.ms-officetheme',
    '.tif': 'image/tiff',
    '.tiff': 'image/tiff',
    '.tk': 'application/x-tcl',
    '.tmo': 'application/vnd.tmobile-livetv',
    '.toml': 'application/toml',
    '.torrent': 'application/x-bittorrent',
    '.tpl': 'application/vnd.groove-tool-template',
    '.tpt': 'application/vnd.trid.tpt',
    '.tr': 'text/troff',
    '.tra': 'application/vnd.trueapp',
    '.trig': 'application/trig',
    '.trm': 'application/x-msterminal',
    '.ts': 'video/mp2t',
    '.tsd': 'application/timestamped-data',
    '.tsv': 'text/tab-separated-values',
    '.ttc': 'font/collection',
    '.ttf': 'font/ttf',
    '.ttl': 'text/turtle',
    '.ttml': 'application/ttml+xml',
    '.twd': 'application/vnd.simtech-mindmapper',
    '.twds': 'application/vnd.simtech-mindmapper',
    '.txd': 'application/vnd.genomatix.tuxedo',
    '.txf': 'application/vnd.mobius.txf',
    '.txt': 'text/plain',
    '.u32': 'application/x-authorware-bin',
    '.u8dsn': 'message/global-delivery-status',
    '.u8hdr': 'message/global-headers',
    '.u8mdn': 'message/global-disposition-notification',
    '.u8msg': 'message/global',
    '.ubj': 'application/ubjson',
    '.udeb': 'application/x-debian-package',
    '.ufd': 'application/vnd.ufdl',
    '.ufdl': 'application/vnd.ufdl',
    '.ulx': 'application/x-glulx',
    '.umj': 'application/vnd.umajin',
    '.unityweb': 'application/vnd.unity',
    '.uoml': 'application/vnd.uoml+xml',
    '.uri': 'text/uri-list',
    '.uris': 'text/uri-list',
    '.urls': 'text/uri-list',
    '.usdz': 'model/vnd.usdz+zip',
    '.ustar': 'application/x-ustar',
    '.utz': 'application/vnd.uiq.theme',
    '.uu': 'text/x-uuencode',
    '.uva': 'audio/vnd.dece.audio',
    '.uvd': 'application/vnd.dece.data',
    '.uvf': 'application/vnd.dece.data',
    '.uvg': 'image/vnd.dece.graphic',
    '.uvh': 'video/vnd.dece.hd',
    '.uvi': 'image/vnd.dece.graphic',
    '.uvm': 'video/vnd.dece.mobile',
    '.uvp': 'video/vnd.dece.pd',
    '.uvs': 'video/vnd.dece.sd',
    '.uvt': 'application/vnd.dece.ttml+xml',
    '.uvu': 'video/vnd.uvvu.mp4',
    '.uvv': 'video/vnd.dece.video',
    '.uvva': 'audio/vnd.dece.audio',
    '.uvvd': 'application/vnd.dece.data',
    '.uvvf': 'application/vnd.dece.data',
    '.uvvg': 'image/vnd.dece.graphic',
    '.uvvh': 'video/vnd.dece.hd',
    '.uvvi': 'image/vnd.dece.graphic',
    '.uvvm': 'video/vnd.dece.mobile',
    '.uvvp': 'video/vnd.dece.pd',
    '.uvvs': 'video/vnd.dece.sd',
    '.uvvt': 'application/vnd.dece.ttml+xml',
    '.uvvu': 'video/vnd.uvvu.mp4',
    '.uvvv': 'video/vnd.dece.video',
    '.uvvx': 'application/vnd.dece.unspecified',
    '.uvvz': 'application/vnd.dece.zip',
    '.uvx': 'application/vnd.dece.unspecified',
    '.uvz': 'application/vnd.dece.zip',
    '.vbox': 'application/x-virtualbox-vbox',
    '.vbox-extpack': 'application/x-virtualbox-vbox-extpack',
    '.vcard': 'text/vcard',
    '.vcd': 'application/x-cdlink',
    '.vcf': 'text/x-vcard',
    '.vcg': 'application/vnd.groove-vcard',
    '.vcs': 'text/x-vcalendar',
    '.vcx': 'application/vnd.vcx',
    '.vdi': 'application/x-virtualbox-vdi',
    '.vds': 'model/vnd.sap.vds',
    '.vhd': 'application/x-virtualbox-vhd',
    '.vis': 'application/vnd.visionary',
    '.viv': 'video/vnd.vivo',
    '.vmdk': 'application/x-virtualbox-vmdk',
    '.vob': 'video/x-ms-vob',
    '.vor': 'application/vnd.stardivision.writer',
    '.vox': 'application/x-authorware-bin',
    '.vrml': 'model/vrml',
    '.vsd': 'application/vnd.visio',
    '.vsf': 'application/vnd.vsf',
    '.vss': 'application/vnd.visio',
    '.vst': 'application/vnd.visio',
    '.vsw': 'application/vnd.visio',
    '.vtf': 'image/vnd.valve.source.texture',
    '.vtt': 'text/vtt',
    '.vtu': 'model/vnd.vtu',
    '.vxml': 'application/voicexml+xml',
    '.w3d': 'application/x-director',
    '.wad': 'application/x-doom',
    '.wadl': 'application/vnd.sun.wadl+xml',
    '.war': 'application/java-archive',
    '.wasm': 'application/wasm',
    '.wav': 'audio/x-wav',
    '.wax': 'audio/x-ms-wax',
    '.wbmp': 'image/vnd.wap.wbmp',
    '.wbs': 'application/vnd.criticaltools.wbs+xml',
    '.wbxml': 'application/vnd.wap.wbxml',
    '.wcm': 'application/vnd.ms-works',
    '.wdb': 'application/vnd.ms-works',
    '.wdp': 'image/vnd.ms-photo',
    '.weba': 'audio/webm',
    '.webapp': 'application/x-web-app-manifest+json',
    '.webm': 'video/webm',
    '.webmanifest': 'application/manifest+json',
    '.webp': 'image/webp',
    '.wg': 'application/vnd.pmi.widget',
    '.wgt': 'application/widget',
    '.wif': 'application/watcherinfo+xml',
    '.wks': 'application/vnd.ms-works',
    '.wm': 'video/x-ms-wm',
    '.wma': 'audio/x-ms-wma',
    '.wmd': 'application/x-ms-wmd',
    '.wmf': 'image/wmf',
    '.wml': 'text/vnd.wap.wml',
    '.wmlc': 'application/vnd.wap.wmlc',
    '.wmls': 'text/vnd.wap.wmlscript',
    '.wmlsc': 'application/vnd.wap.wmlscriptc',
    '.wmv': 'video/x-ms-wmv',
    '.wmx': 'video/x-ms-wmx',
    '.wmz': 'application/x-msmetafile',
    '.woff': 'font/woff',
    '.woff2': 'font/woff2',
    '.wpd': 'application/vnd.wordperfect',
    '.wpl': 'application/vnd.ms-wpl',
    '.wps': 'application/vnd.ms-works',
    '.wqd': 'application/vnd.wqd',
    '.wri': 'application/x-mswrite',
    '.wrl': 'model/vrml',
    '.wsc': 'message/vnd.wfa.wsc',
    '.wsdl': 'application/wsdl+xml',
    '.wspolicy': 'application/wspolicy+xml',
    '.wtb': 'application/vnd.webturbo',
    '.wvx': 'video/x-ms-wvx',
    '.x32': 'application/x-authorware-bin',
    '.x3d': 'model/x3d+xml',
    '.x3db': 'model/x3d+fastinfoset',
    '.x3dbz': 'model/x3d+binary',
    '.x3dv': 'model/x3d-vrml',
    '.x3dvz': 'model/x3d+vrml',
    '.x3dz': 'model/x3d+xml',
    '.x_b': 'model/vnd.parasolid.transmit.binary',
    '.x_t': 'model/vnd.parasolid.transmit.text',
    '.xaml': 'application/xaml+xml',
    '.xap': 'application/x-silverlight-app',
    '.xar': 'application/vnd.xara',
    '.xav': 'application/xcap-att+xml',
    '.xbap': 'application/x-ms-xbap',
    '.xbd': 'application/vnd.fujixerox.docuworks.binder',
    '.xbm': 'image/x-xbitmap',
    '.xca': 'application/xcap-caps+xml',
    '.xcs': 'application/calendar+xml',
    '.xdf': 'application/xcap-diff+xml',
    '.xdm': 'application/vnd.syncml.dm+xml',
    '.xdp': 'application/vnd.adobe.xdp+xml',
    '.xdssc': 'application/dssc+xml',
    '.xdw': 'application/vnd.fujixerox.docuworks',
    '.xel': 'application/xcap-el+xml',
    '.xenc': 'application/xenc+xml',
    '.xer': 'application/patch-ops-error+xml',
    '.xfdf': 'application/vnd.adobe.xfdf',
    '.xfdl': 'application/vnd.xfdl',
    '.xht': 'application/xhtml+xml',
    '.xhtml': 'application/xhtml+xml',
    '.xhvml': 'application/xv+xml',
    '.xif': 'image/vnd.xiff',
    '.xla': 'application/vnd.ms-excel',
    '.xlam': 'application/vnd.ms-excel.addin.macroenabled.12',
    '.xlc': 'application/vnd.ms-excel',
    '.xlf': 'application/xliff+xml',
    '.xlm': 'application/vnd.ms-excel',
    '.xls': 'application/vnd.ms-excel',
    '.xlsb': 'application/vnd.ms-excel.sheet.binary.macroenabled.12',
    '.xlsm': 'application/vnd.ms-excel.sheet.macroenabled.12',
    '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.xlt': 'application/vnd.ms-excel',
    '.xltm': 'application/vnd.ms-excel.template.macroenabled.12',
    '.xltx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    '.xlw': 'application/vnd.ms-excel',
    '.xm': 'audio/xm',
    '.xml': 'text/xml',
    '.xns': 'application/xcap-ns+xml',
    '.xo': 'application/vnd.olpc-sugar',
    '.xop': 'application/xop+xml',
    '.xpi': 'application/x-xpinstall',
    '.xpl': 'application/xproc+xml',
    '.xpm': 'image/x-xpixmap',
    '.xpr': 'application/vnd.is-xpr',
    '.xps': 'application/vnd.ms-xpsdocument',
    '.xpw': 'application/vnd.intercon.formnet',
    '.xpx': 'application/vnd.intercon.formnet',
    '.xsd': 'application/xml',
    '.xsl': 'application/xslt+xml',
    '.xslt': 'application/xslt+xml',
    '.xsm': 'application/vnd.syncml+xml',
    '.xspf': 'application/xspf+xml',
    '.xul': 'application/vnd.mozilla.xul+xml',
    '.xvm': 'application/xv+xml',
    '.xvml': 'application/xv+xml',
    '.xwd': 'image/x-xwindowdump',
    '.xyz': 'chemical/x-xyz',
    '.xz': 'application/x-xz',
    '.yaml': 'text/yaml',
    '.yang': 'application/yang',
    '.yin': 'application/yin+xml',
    '.yml': 'text/yaml',
    '.ymp': 'text/x-suse-ymp',
    '.z1': 'application/x-zmachine',
    '.z2': 'application/x-zmachine',
    '.z3': 'application/x-zmachine',
    '.z4': 'application/x-zmachine',
    '.z5': 'application/x-zmachine',
    '.z6': 'application/x-zmachine',
    '.z7': 'application/x-zmachine',
    '.z8': 'application/x-zmachine',
    '.zaz': 'application/vnd.zzazz.deck+xml',
    '.zip': 'application/zip',
    '.zir': 'application/vnd.zul',
    '.zirz': 'application/vnd.zul',
    '.zmm': 'application/vnd.handheld-entertainment+xml',
};
export function getMimeTypeFromFileName(fileName) {
    if (!!!fileName) {
        return defaultMIMEType;
    }
    const fileParts = fileName.split('.');
    if (fileParts.length === 0) {
        return defaultMIMEType;
    }
    const extension = fileParts[fileParts.length - 1];
    if (!!!extension) {
        return defaultMIMEType;
    }
    const match = extensionMimeTypeLookup[`.${extension}`];
    if (!!!match) {
        return match;
    }
    return defaultMIMEType;
}
