var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'PayrollReportFactor';
let PayrollReportFactor = class PayrollReportFactor {
    description;
    ratingValue;
};
__decorate([
    FieldData({ recordTypeId })
], PayrollReportFactor.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', formatting: '4' })
], PayrollReportFactor.prototype, "ratingValue", void 0);
PayrollReportFactor = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        displayNameSingular: 'Payroll Report Factor',
        displayNamePlural: 'Payroll Report Factor',
    })
], PayrollReportFactor);
export { PayrollReportFactor };
