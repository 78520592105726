var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'ReadReceipt';
let ReadReceipt = class ReadReceipt {
    id;
    readUserId;
    readUTC;
    isRead;
};
__decorate([
    FieldData({ recordTypeId, label: 'ID', fieldType: 'uuid' })
], ReadReceipt.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], ReadReceipt.prototype, "readUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Read Date/Time', fieldType: 'datetime' })
], ReadReceipt.prototype, "readUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], ReadReceipt.prototype, "isRead", void 0);
ReadReceipt = __decorate([
    RecordTypeData({
        usesNewDataModel: true,
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Read Receipt',
        displayNamePlural: 'Read Receipts',
        dataModelCustomization: {
            hideFromAdmin: true
        }
    })
], ReadReceipt);
export { ReadReceipt };
